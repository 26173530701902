import React, { Component } from 'react';
import { getRightInput } from 'helpers/Utils';
import { inject, observer } from 'mobx-react';
import { StartLink, Wrapper } from './ClickOnStyle';
import { logger } from '../../../helpers/Utils';

@inject('assetStore')
@inject('appStore')
@observer
class ClickOn extends Component {
	images = {};
	ss = {};
	exportRoot = null;
	stage = null;
	state = {
		toggle: true
	};
	isUnmounted = false;
	lastW = 1;
	lastH = 1;
	lastS = 1;
	lastFunc = '';
	ref = null;
	played = false;
	int = 0;
	counter = 0;
	isSpeaking = false;
	scope = null;
	newAnimateVersion = false;
	audioPlayer = null;

	componentDidMount() {
		/*let loader = new createjs.LoadQueue(false);
         loader.addEventListener("fileload", this.handleFileLoad);
         loader.addEventListener("complete", this.handleComplete);
         loader.loadManifest(this.props.scope.properties.manifest);
         */
		this.newAnimateVersion = false;
		this.handleComplete(this.props.assets || this.props.assetStore.globalAssets);
		window.$('body').on('kknApp.resized', this.resizeMe);
		if (this.audioPlayer) this.audioPlayer.addEventListener('ended', this.handleAudioEvent);
	}

	componentDidUpdate = (prevProps, prevState, snapshot) => {
		this.newAnimateVersion = false;

		if (this.props.progress === undefined || this.exportRoot === null) {
			this.handleComplete(this.props.assets, this.props);
		} else {
			this.exportRoot.gotoAndStop(Math.round(this.props.progress * 100));
			if (this.props.progress === 1) this.exportRoot.play();
		}

		if (this.props.startSpeak === true) this.startSpeak(this.props);
	};

	resizeMe = () => {
		this.resizeCanvas(this.props, true, 'both', true, 1);
	};
	handleAudioEvent = (evt) => {
		this.endSpeak();
	};
	componentWillUnmount = () => {
		this.isUnmounted = true;
		this.lastFunc = '';
		this.counter = 0;
		window.$('body').off('frameUpdate.' + this.props.stageRef);
		window.$('body').off('kknApp.resized', this.resizeMe);
		if (this.audioPlayer) this.audioPlayer.removeEventListener('ended', this.handleAudioEvent);

		clearInterval(this.int);

		window.createjs.Sound.off('fileload', this.onSoundLoad, this);

		if (window[this.props.stageRef]) {
			// createjs.Touch.disable(window[this.props.stageRef]);

			window[this.props.stageRef].enableDOMEvents(false);
			window[this.props.stageRef].removeChildAt(0);

			//window[this.props.stageRef].canvas = null;
			window[this.props.stageRef] = undefined;
		}
	};

	handleFileLoad = (evt) => {
		if (evt.item.type === 'image') {
			window.images[evt.item.id] = evt.result;
		}
	};
	handleComplete = (evt, newProps) => {
		if (this.isUnmounted) return false;
		if (evt === undefined) return false;

		let props = newProps || this.props;

		this.scope = props.scope || window[props.func];
		if (window.AdobeAn && window.AdobeAn.compositions)
			if (!this.scope && window.AdobeAn && window.AdobeAn.compositions && window.AdobeAn.compositions[props.func]) {
				this.newAnimateVersion = true;
				this.scope = window.AdobeAn.compositions[props.func].getLibrary();
			}

		if (!this.scope) {
			if (props.stageRef && window[props.stageRef]) window[props.stageRef].clear();
			return undefined;
		}

		// this.stage = null;
		if (evt !== null) {
			let queue = evt.target;
			if (queue === undefined) queue = evt;

			let ssMetadata = this.scope.ssMetadata;
			if (ssMetadata && ssMetadata.length > 0)
				for (let i = 0; i < ssMetadata.length; i++) {
					if (ssMetadata[i] !== undefined)
						if (queue.getItem(ssMetadata[i].name)) {
							var img = new Image();
							img.src = queue.getItem(ssMetadata[i].name).src;
							var spr = new window.createjs.SpriteSheet({
								images: [img],
								frames: ssMetadata[i].frames
							});
							if (this.newAnimateVersion === false) {
								window.ss[ssMetadata[i].name] = spr;
							} else {
								window.AdobeAn.compositions[props.func].getSpriteSheet()[ssMetadata[i].name] = spr;
							}
						}
				}
		}

		if (window[props.stageRef] === undefined) {
			window[props.stageRef] = new window.createjs.Stage(this.refs.canvas);
			//createjs.Touch.enable(window[props.stageRef]);

			// Performance intensive
			// window[props.stageRef].enableMouseOver();

			window.$('body').on('frameUpdate.' + props.stageRef, () => {
				if (window.kknApp.forceAnimateStop !== true && window[props.stageRef]) window[props.stageRef].update();
			});
		}

		if (this.lastFunc !== props.func) {
			this.played = false;

			this.exportRoot = new this.scope[props.func]();

			window[props.stageRef].enableDOMEvents(false);
			window[props.stageRef].clear();
			window[props.stageRef].canvas = this.refs.canvas;
			window[props.stageRef].removeChildAt(0);

			window[props.stageRef].addChild(this.exportRoot);
			window[props.stageRef].enableDOMEvents(true);
			window[props.stageRef].update();
		}

		this.resizeCanvas(props, true, 'both', true, 1);
		this.lastFunc = props.func;

		if (props.pos === 'helpRight' || props.pos === 'helpHomeRight') {
			this.exportRoot.gotoAndStop(1);
			this.stopInnerMc('introSide');
			this.playHelpIntro(props);
		}

		if (props.pos === 'help' || props.pos === 'helpHome' || props.pos === 'helpLock') {
			this.exportRoot.gotoAndStop(0);
			this.stopInnerMc('introSide');
			this.playHelpIntro(props);
		}

		if (props.play !== undefined && props.play !== null) {
			this.play(props.play);
		}

		if (props.playAniFrame) this.playAnimationFrame(props.playAniFrame);
	};
	playHelpIntro = (props) => {
		if (!this.played) {
			setTimeout(() => {
				this.playInnerMc('introSide');
				this.played = true;
				clearInterval(this.int);
				if (props.func === 'globalKkn')
					this.int = setInterval(() => {
						if (this.isSpeaking === false) {
							let ct = this.counter % 2 === 0 ? 'action1' : 'action2';
							this.playInnerMc(ct);
							this.counter++;
						}
					}, 10000);
			}, 1000);

			if (props.pos === 'helpLock')
				setTimeout(() => {
					this.startSpeak('alarm');
				}, 2000);
		} else {
			this.playInnerMc('idle', true);
		}
	};

	resizeCanvas = (props, isResp, respDim, isScale, scaleType) => {
		if (this.scope === undefined || window[props.stageRef] === undefined) return false;

		var w = this.scope.properties.width,
			h = this.scope.properties.height;
		var iw = window.kknApp.width,
			ih = window.kknApp.height;

		if (props.width) {
			iw = props.width * window.kknApp.scale;
		}
		if (props.height) {
			ih = props.height * window.kknApp.scale;
		}
		var ratio =  window.devicePixelRatio > 1 ?  window.devicePixelRatio :2
		var pRatio = ratio,
			xRatio = iw / w,
			yRatio = ih / h,
			sRatio = 1;
		if (isResp) {
			if ((respDim == 'width' && this.lastW == iw) || (respDim == 'height' && this.lastH == ih)) {
				sRatio = this.lastS;
			} else if (!isScale) {
				if (iw < w || ih < h) sRatio = Math.min(xRatio, yRatio);
			} else if (scaleType == 1) {
				sRatio = Math.min(xRatio, yRatio);
			} else if (scaleType == 2) {
				sRatio = Math.max(xRatio, yRatio);
			}
		}
		window[props.stageRef].canvas.width = w * pRatio * sRatio;
		window[props.stageRef].canvas.height = h * pRatio * sRatio;
		window[props.stageRef].canvas.style.width = w * sRatio + 'px';
		window[props.stageRef].canvas.style.height = h * sRatio + 'px';
		window[props.stageRef].scaleX = pRatio * sRatio;
		window[props.stageRef].scaleY = pRatio * sRatio;
		this.lastW = iw;
		this.lastH = ih;
		this.lastS = sRatio;
	};

	onClick = () => {
		this.startSpeak();
		this.play();
		if (this.props.onClicked) this.props.onClicked();
	};
	play = (id) => {
		if (this.exportRoot.mcMain) id < 1 ? this.exportRoot.mcMain.gotoAndPlay(1) : this.exportRoot.mcMain.play();
	};
	playAnimationFrame = (id) => {
		if (id === 'startpos') {
			this.exportRoot.gotoAndStop(0);
		} else {
			if (this.played !== true || id !== 'intro') this.exportRoot.gotoAndPlay(id);
			this.played = true;
		}
	};

	startSpeak(nextProps) {
		let id = null;
		let url = null;
		//if (this.isSpeaking === true) return false;

		this.isSpeaking = true;
		let helpMedia = (nextProps && nextProps.audio) || this.props.assetStore.getHelpMediaForIndex(this.props.appStore.pageIndex);

		if (helpMedia && this.audioPlayer) {
			id = helpMedia.id;
			url = helpMedia.url;
			this.audioPlayer.src = url;
			this.audioPlayer.load();
			this.audioPlayer.play();
			this.playInnerMc('speakStart');

			return false;
		}

		if (nextProps && nextProps.pos === 'helpLock') {
			id = 'alarm';
			url = null;
		}

		if (id !== null) {
			window.createjs.Sound.stop();
			this.getSound(id, url);
		}
	}

	getSound = (id, url) => {
		if (this.isUnmounted) return false;
		this.playInnerMc('speakStart');
		window.kknApp.playSound(id, url, this.handleSoundComplete);
	};

	getHelpMc = () => {
		let mc = 'mcLeft';
		if (this.props.pos === 'helpRight' || this.props.pos === 'helpHomeRight') {
			mc = 'mcRight';
		}
		return mc;
	};
	playInnerMc = (id, fast) => {
		if (this.exportRoot[this.getHelpMc()] !== undefined)
			fast !== true ? this.exportRoot[this.getHelpMc()].gotoAndPlay(id) : this.exportRoot[this.getHelpMc()].gotoAndStop(id);
	};
	stopInnerMc = (id) => {
		if (this.exportRoot[this.getHelpMc()] !== undefined) this.exportRoot[this.getHelpMc()].gotoAndStop(0);
	};

	handleSoundComplete = (event) => {
		return this.endSpeak();
	};

	endSpeak() {
		this.isSpeaking = false;
		this.playInnerMc('speakEnd');
	}
	isHelp = () => {
		return this.props.pos.indexOf('help') !== -1;
	};

	render() {
		let style = '';
		if (this.props.pos !== undefined) style = this.props.pos;
		if (this.props.animate === true) style += ' ' + this.props.animationName;
		let forceClick = this.props.forceClick ? true : false;
		let classArray = ['kkn-clickOn', this.props.pos, this.props.animationName];
		return (
			<Wrapper className={classArray} type={this.props.type}>
				{this.props.onClicked !== undefined || this.isHelp() ? (
					<StartLink
						width={this.isHelp() ? '20em' : null}
						height={this.isHelp() ? '35em' : null}
						{...getRightInput(this.onClick, forceClick)}
					/>
				) : null}
				{this.props.pos.indexOf('help') !== -1 ? <audio ref={(r) => (this.audioPlayer = r)} /> : null}
				<canvas ref="canvas" style={{ pointerEvents: this.isHelp() ? 'none' : null }} className={'canvas-' + this.props.func} />
			</Wrapper>
		);
	}
}

export default ClickOn;
