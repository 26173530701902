import styled from 'styled-components';
import { blueRabbitHand, gfx } from 'helpers/layoutVariables';

export const Container = styled.div`
	background-color: rgba(255, 255, 255, 0.1);
	padding: 2.5% 5% 5% 5%;
	border-radius: 2em;
	margin-bottom: 3%;

	> div > h4 {
		margin-top: 0;
	}

	img {
		width: 100%;
	}
`;

export const FormWrapper = styled.form`
	display: flex;
	flex-direction: column;
	position: relative;

	.kkn-buffer {
		transform: scale(0.5);
	}
`;

export const FormButtonWrapper = styled.div`
	position: relative;
	margin-top: 1em;
	margin-bottom: 1em;
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	min-height: 3em;
`;

export const SubmitButton = styled.input`
	transition: all 0.2s ease-out;
	font-size: 3em;
	width: auto;
	border-radius: 1em;
	outline: 0;
	border: 0;
	${blueRabbitHand};

	background-color: hsla(0, 0%, 100%, 0.5);
	color: #5e4200;
	display: flex;
	padding: 0.5em 1em;
	align-self: center;
	align-items: center;
	justify-self: center;
	text-decoration: none;
	position: relative;
	.no-touch & {
		&:hover {
			background-color: #fff;
		}
	}
`;

export const ErrorMessage = styled.p`
	font-weight: bold;
`;

export const Legal = styled.p`
	p {
		font-size: 1.875em !important;
	}
	span {
		font-size: 1em !important;
	}
	p,
	span {
		color: ${gfx.brown} !important;
	}
`;

export const Message = styled.div`
	font-size: 1.875em;
	color: ${gfx.brown};
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	display: block;
`;

export const Label = styled.label`
	min-width: 20%;
	max-width: 20%;
	font-size: 1.875em;
	text-align: right;
	margin-right: 1em;
	display: block;
	color: ${gfx.brown};
	margin-top: 0.75em;
`;
export const InputWrapper = styled.div`
	width: 80%;
	margin-right: 1em;
`;

export const FormItem = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-bottom: 1em;
	justify-content: center;
`;
