import React from 'react';
import { getImageUrl } from '../../../helpers/Utils';
import { Wrapper, Item, Title, Image } from './AutoSuggestStyle';

interface ITeaser {
    title: string;
	id: string;
	url?: string;
	site?: string;
	teaserImage?: any;
}

interface IAutoSuggestItem {
	teaser: ITeaser;
}

interface IAutoSuggestProps {
	results: Array<IAutoSuggestItem>;
    onClick?: (url: string, site?: string, title?: string) => void;
}

export const AutoSuggest = (props: IAutoSuggestProps) => {
	return <>
		<Wrapper>
            {props.results.map(item => {
                const imageUrl = item.teaser.teaserImage ? getImageUrl(item.teaser.teaserImage.urlScheme,128, 'ident') : null;
                return (
                    <Item
                        key={item.teaser.id}
                        onClick={() => {
                            props.onClick(item.teaser.url, item.teaser.site, item.teaser.title);
                        }}
                    >
                        <Image src={imageUrl} />
                        <Title>{item.teaser.title}</Title>
                    </Item>
                );
            })}
		</Wrapper>
    </>;
};

export default AutoSuggest;
