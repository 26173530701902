import { observable } from 'mobx';
import { isTablet, loadScript, logger } from '../helpers/Utils';
import stores from './index';
import qs from 'query-string';

export default class TrackingStore {
	atiTag = null;
	enabled = true;
	atiEnabled = true;
	atiTeaserEnabled = true;
	page = '0';
	trackerLayerVars = null;
	trackingData = {};
	atiRichMediaTag = null;
	toolName = '';
	query = '';
	@observable globalAssets = null;
	sophoraError = '[an error occurred while processing this directive]';

	constructor(props) {
		window.$('body').on('kknApp.externalTrackingEvt', this.trackFromExternal);
	}

	checkActiveTracking = () => {
		this.atiTeaserEnabled = stores.assetStore.docData.settings && stores.assetStore.docData.settings.atiTeaserTracking;
		document.documentElement.setAttribute(
			'data-nielsen',
			stores.assetStore.docData.settings && stores.assetStore.docData.settings.nielsenTracking
		);
	};
	replacePaths = str => {
		str = str.split('kika.kikaninchen').join('kikaninchen');
		str = str.split(this.sophoraError).join('kikaninchen');
		return str;
	};

	trackFromExternal = async vo => {
		if (vo.evt === window.Application.EVENT_TRACKING && vo.params && vo.params.st) {
			return false;
		}
		if (stores.appStore.settings.tracking.ati === false) return false;
		if (typeof window.ATInternet === 'undefined') await loadScript('/static_kkn/global/js/smarttag.js');
		if (vo.params.mediaTheme3) {
			vo.params.mediaTheme3 = this.replacePaths(vo.params.mediaTheme3);
		}
		if (vo.params.mediaLevel2) {
			vo.params.mediaLevel2 = '2';
		}

		switch (vo.evt) {
			case window.Tracking.EVENT_TRACKING_ATI_RMPAGEIMPRESSION:
				// vo.params.customVars.site = vo.params.customVars;
				vo.params.page.level2 = '2';
				this.trackLayerView({
					name: 'avPlayer',
					toolName: 'avPlayer',
					atiTracking: vo.params,
					path: 'tool.avPlayer.play'
				});
				break;

			case window.Tracking.EVENT_TRACKING_ATI_ADDRMPLAYER:
				this.atiRichMediaTag = new window.ATInternet.Tracker.Tag({
					site: this.trackingData.site,
					cookieDomain: this.getCookieDomain(),
					cookieSecure: true,
					secure: true
				});
				this.atiRichMediaTag.page = this.trackingData.page;
				this.atiRichMediaTag.richMedia.add(vo.params);
				break;

			case window.Tracking.EVENT_TRACKING_ATI_RMTRACKING:
				if (this.atiRichMediaTag) this.atiRichMediaTag.richMedia.send(vo.params);

				break;
		}
	};

	trackView = params => {
		//this.trackerLayerVars = null;
		this.formatTracker(params);
	};

	formatTracker = params => {
		this.query = qs.parse(stores.routingStore.location.search);
		this.page = this.query && this.query.page ? '.' + this.query.page : '.0';
		if (params.name !== 'page') this.page = '';
		this.toolName = params.toolName ? params.toolName : '';
		if (params.type === 'click' && params.chapter1 === 'printRequest') {
			params.name = 'Drucken';
			params.chapter1 = this.query.detailId || '';
			params.chapter2 = document.location.href;
			params.action = 'action';
		}
		let p = params.path ? '.' + params.path : '';
		this.page = !this.trackerLayerVars || this.toolName ? this.page + p : p;

		if (params.context && params.context.indexOf('adultLayer') !== -1) {
			this.page = '.' + p;
		}

		this.trackAtiPageView(params);
	};
	resetLayerTracker = () => {
		this.trackerLayerVars = null;
	};

	trackLayerView = params => {
		this.trackerLayerVars = params.atiTracking;
		this.formatTracker(params);
	};

	formatAtiParam = value => {
		return encodeURIComponent(value)
			.replace(/-/g, '%2D')
			.replace(/_/g, '%5F');
	};

	replaceParam = (txt, value, partNew) => {
		if (txt) {
			var reg = new RegExp(value + '(\\d*)');
			var part = txt.match(reg);
			if (part !== null) {
				return txt.split(part[0]).join(partNew);
			}
		}
		return txt;
	};

	getChapterDataFromUrl = () => {
		let url = document.location.href.split('/');
		let last = url[url.length - 1].split('.')[0];
		let chapter1 = '';
		let chapter2 = '';
		let chapter3 = '';

		url.map((data, i) => {
			if (i === 3) chapter1 = data;
			if (i === 4 && i < url.length - 1) chapter2 = data;
			if (i === 4 && i === url.length - 1) {
				chapter2 = last;
			} else {
				if (i > 4 && i < url.length - 1) chapter3 += (chapter3.length > 0 ? '.' : '') + data;
				if (i === url.length - 1 && chapter3.length > 0) chapter3 = chapter3 + '.' + last;
			}
		});

		let vo = { chapter1: chapter1, chapter2: chapter2, chapter3: chapter3 };
		return vo;
	};
	getCookieDomain = () => {
		const domain = document.location.hostname.substring(
			document.location.hostname.lastIndexOf('.', document.location.hostname.lastIndexOf('.') - 1) + 1
		);
		return undefined;

		return '.' + domain;
	};

	trackAtiPageView = async params => {
		if (this.enabled === false) return false;
		if (this.atiEnabled === false) return false;
		if (stores.appStore.settings.tracking.ati === false) return false;
		if (!stores.assetStore.docData) return false;
		if (!stores.assetStore.docData.document) return false;

		if (typeof window.ATInternet === 'undefined') await loadScript('/static_kkn/global/js/smarttag.js');

		this.trackingData = this.trackerLayerVars || stores.assetStore.docData.document.atiTracking;
		if (!this.trackingData) return false;

		if (params.type !== 'click') {
			this.atiTag = new window.ATInternet.Tracker.Tag({
				site: this.trackingData.site,
				cookieDomain: this.getCookieDomain(),
				cookieSecure: true,
				secure: true
			});
		}

		if (!this.atiTag) return false;

		let customVarsFormatted = {};
		let customVars = this.trackingData.customVars.site || this.trackingData.customVars;
		for (var item in customVars) {
			customVarsFormatted[item] = customVars[item];
		}

		// rewrite params if layer or deeplink
		if (this.trackerLayerVars) {
			let urlData = this.getChapterDataFromUrl();
			this.trackingData.page.chapter1 = this.formatAtiParam(urlData.chapter1);
			this.trackingData.page.chapter2 = this.formatAtiParam(urlData.chapter2);
			this.trackingData.page.chapter3 = this.formatAtiParam(urlData.chapter3);
			let rewriteX6 = customVars['6'].split('.tool.');
			if (params.name === 'avPlayer') {
				rewriteX6[1] = 'avPlayer.play]';
			}
			if (params.name === 'adultLayer') {
				rewriteX6[1] =
					customVars['5']
						.split('[')
						.join('')
						.split(']')
						.join('') + ']';
			}
			let detailId = this.formatAtiParam('.' + this.trackingData.page.name);
			if (this.trackingData.page.chapter3.indexOf(detailId) !== -1) {
				this.trackingData.page.chapter3 = this.trackingData.page.chapter3.split(detailId).join('');
			}

			let rewriteX6First = rewriteX6[0].split('.');
			customVars['6'] =
				rewriteX6First[0] +
				'.' +
				this.trackingData.page.chapter1 +
				'.' +
				this.trackingData.page.chapter2 +
				'.' +
				this.trackingData.page.chapter3 +
				detailId +
				'.tool.' +
				rewriteX6[1];
			customVars['6'] = customVars['6'].split('..').join('.');
		}

		customVarsFormatted['1'] = '[' + this.formatAtiParam(document.location.href) + ']';
		let referrer = stores.appStore.referrer ? stores.appStore.referrer : document.referrer.length > 0 ? document.referrer : '';
		if (referrer.indexOf('http') === -1) referrer = (document.location.origin + referrer).split('.json').join('.html');

		customVarsFormatted['2'] = '[' + this.formatAtiParam(referrer) + ']';
		if (this.toolName && this.toolName.length > 0) customVarsFormatted['5'] = '[' + this.toolName + ']';
		if (params.name === 'page') {
			if (customVars['6'].indexOf('page.') === -1) customVarsFormatted['6'] = customVars['6'].split('page').join('page' + this.page);
			else {
				customVarsFormatted['6'] = this.replaceParam(customVars['6'], 'page.', 'page' + this.page) + ']';
			}
		}

		if (params.name !== 'page') {
			customVarsFormatted['6'] = customVars['6'];
			customVarsFormatted['6'] = this.replacePaths(customVarsFormatted['6']);
		}

		if (params.type === 'click') {
			let context = params.context || '';
			let url = params.chapter2 || '';
			if (url) {
				if (url.indexOf('http') === -1 && (url.indexOf('.html') !== -1 || url.indexOf('.pdf') !== -1))
					url = document.location.origin + params.chapter2;
				url = url.split('-download').join('');
			}
			let name = this.formatAtiParam(params.name);
			let chapter1 = this.formatAtiParam(params.chapter1);
			let chapter2 = this.formatAtiParam(url);
			let chapter3 =
				customVarsFormatted['6']
					.split('[')
					.join('')
					.split(']')
					.join('') + context;

			let callback = function() {
				let elem = params.elem;
				if (elem.dataset && elem.dataset.ctrlLink) return false;
				document.location = elem.href;
			};
			let clickParams = {
				elem: params.elem,
				name: name,
				chapter1: chapter1,
				chapter2: chapter2,
				chapter3: chapter3,
				level2: this.trackingData.page.level2,
				type: params.action
			};
			if (params.elem && params.elem.tagName === 'A' && params.elem.href) {
				if (!params.includePageView) clickParams.callback = callback;
				clickParams.elem = undefined;
			} else {
				if (params.includePageView) clickParams.elem = undefined;
			}
			this.atiTag.click.send(clickParams);
			logger('tracking ATI click ', clickParams);

			return false;
		}

		this.atiTag.page.set(this.trackingData.page);
		this.atiTag.customVars.set({
			site: customVarsFormatted
		});
		logger('tracking ATI page view with\npageData: ', this.trackingData.page, '\ncustomVars: ', customVarsFormatted);

		this.atiTag.dispatch();
	};

	trackAtiClick = data => {
		if (this.atiTeaserEnabled === false) return false;
		if (stores.appStore.settings.tracking.ati === false) return false;

		data.type = 'click';
		this.formatTracker(data);
	};
}
