import React, { Component } from 'react';
import ClickOn from 'components/fragments/ClickOn/ClickOn';
import { inject, observer } from 'mobx-react';
import { Container, Logo, StartLink } from './NotFoundStyle';
import axios from 'axios';

@inject('appStore')
@inject('assetStore')
@inject('routingStore')
@observer
class NotFound extends Component {
	onClicked = () => {
		this.props.routingStore.push('/index.html');
	};

	componentDidMount = () => {
		this.props.appStore.error = true;
		this.props.appStore.addAnimations();
		const fetchData = async () => {
			const { data } = await axios.get('/index.json');
			this.props.assetStore.docData = data;
			this.props.appStore.clicked = false;
		};
		fetchData();
	};

	onBreadcrumbClicked = url => {
		this.props.pushState(url);
	};
	onDetailClosed = () => {};

	render() {
		let globalAssets = this.props.assetStore.globalAssets;
		let bgUrl = this.props.assetStore.globalAssets.getItem('bgZwischenwelt').src;

		return (
			<Container
				style={{
					backgroundSize: 'cover',
					backgroundImage: 'url(' + bgUrl + ')'
				}}
			>
				<Logo src="/static_kkn/global/images/logo-kikaninchen.svg" />
				{this.props.appStore.clicked === true ? (
					<ClickOn stageRef="fourNilfour" pos={'top'} scope={window['global404']} assets={globalAssets} func={'_404'} />
				) : null}
				<StartLink href="/index.html" />
			</Container>
		);
	}
}

export default NotFound;
