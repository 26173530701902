import styled, { css } from 'styled-components';
import { gfx, blueRabbitHand } from 'helpers/layoutVariables';

export const Wrapper = styled.a`
	display: flex;
	align-items: center;
	position: relative;
	text-decoration: none;
	color: ${(props) => (props.textColor ? props.textColor : gfx.brown)};
	font-size: ${(props) => (props.sizeFont ? props.sizeFont : '1em')};

	transition: transform 0.3s ease-out;

	${blueRabbitHand}

	${(props) =>
		props.flexDir &&
		css`
			flex-direction: ${props.flexDir};
		`}
`;
