import { getImageUrl, getMergedTeaserData, Statics } from './Utils';

const createjs = window.createjs;

export default class Preloader {
	callback = null;
	onErrorCb = null;
	onProgressCb = null;
	preloader = null;
	url = null;
	lastProgress = 0;
	error = false;
	addItems = false;
	API_PREFIX = '';
	basePath = '';

	constructor(req) {}

	handleComplete = (evt) => {
		this.callback(evt);
	};

	load(vo) {
		this.error = false;
		this.callback = vo.cb;
		this.url = vo.url;
		this.onErrorCb = vo.onError;
		this.onProgressCb = vo.onProgress;

		if (this.preloader === null) {
			this.preloader = new createjs.LoadQueue(false, vo.basePath || '');
			this.basePath = vo.basePath || '';
			this.preloader.setMaxConnections(1);
			this.preloader.installPlugin(createjs.Sound);
			this.preloader.on('error', this.onError);
			this.preloader.on('progress', this.onProgress);
			this.preloader.on('fileload', this.onFileLoad);
			this.preloader.on('complete', (evt) => {
				if (this.error === false) this.callback(evt, this.preloader);
			});
		} else {
			this.preloader.close();
		}
		this.loadManifest(this.url);
	}

	close = () => {
		//if (this.preloader !== null	) this.preloader.close();
	};

	loadManifest = (data) => {
		this.error = false;
		if (data && this.preloader) this.preloader.loadManifest(data);
	};
	onProgress = (evt) => {
		let p = this.lastProgress >= evt.progress && this.lastProgress !== 1 ? this.lastProgress : evt.progress;
		if (this.onProgressCb !== null && this.onProgressCb !== undefined) this.onProgressCb({ progress: p });

		if (this.lastProgress <= evt.progress) this.lastProgress = evt.progress;
	};
	onFileLoad = (evt) => {
		if (this.error) return false;

		let item = evt.item; // A reference to the item that was passed in to the LoadQueue
		let type = item.type;
		let items = [];

		// check old IEs for audio preloading issues > https://github.com/CreateJS/SoundJS/issues/40
		if (type === createjs.Types.SOUND && (navigator.userAgent.indexOf('IE') !== -1 || navigator.userAgent.indexOf('Trident') !== -1)) {
			window.$('body > audio').eq(0).remove();
		}
		let data = this.preloader._loadedResults[evt.item.id];

		if (type === createjs.Types.JSON) {
			// add dynamically items to preload queue, based on gallery json
			if (evt.item.id === 'content_gallery') {
				if (!data.document) return false;
				data.document.documentProperties.multimediaEntries.map((data, i) => {
					let imgUrl = getImageUrl(data.media && data.media.urlScheme, '512', undefined);
					if (imgUrl.length > 0) {
						items.push({
							id: data.media.sophoraId,
							src: imgUrl
						});
					}
					let audioUrl = data.audio && data.audio.url;
					if (audioUrl && audioUrl.length > 0) {
						items.push({
							id: data.audio.id,
							src: process.env.REACT_APP_DEV_PROXY + audioUrl
						});
					}
				});
			}
			// add dynamically items to preload queue, based on content json
			if (evt.item.id === 'content') {
				let data = this.preloader.getResult(evt.item.id);
				if (!data.document) return false;

				let mergedItems = getMergedTeaserData(data.document);

				mergedItems.map((teaserData, i) => {
					// don`t preload images
					/*
                     let url = (getImageUrl(data.standard.teaserImage && data.standard.teaserImage.urlScheme, "512", (data.standard.contentType === "index" || data.standard.documentType === "contentKit" ? "ident" : undefined)));
                     if (url.length > 0) {
                     items.push({
                     id: data.standard.sophoraId,
                     src: url
                     });
                     }
                     */

					if (data.document.documentCanvasId.indexOf(Statics.CONTENT_TYPE_INTERACTIVE) === -1) {
						if (teaserData.standard.canvasId && teaserData.standard.canvasId.length > 0) {
							items.push({
								id: teaserData.standard.canvasId,
								src: teaserData.standard.canvasId + '.js'
							});
						}
						if (teaserData.standard.teaserCanvasId && teaserData.standard.teaserCanvasId.length > 0) {
							items.push({
								id: teaserData.standard.teaserCanvasId,
								src: teaserData.standard.teaserCanvasId + '.js'
							});
						}
					}
				});
			}

			if (items.length > 0) {
				this.loadManifest(items);
			}
		}

		// adds dynamically assets from clickon js to preload queue

		if (type === createjs.Types.JAVASCRIPT) {
			let params = null;
			if (window.AdobeAn && window.AdobeAn.compositions && window.AdobeAn.compositions[item.id]) {
				if (window.AdobeAn.getComposition(item.id)) params = window.AdobeAn.getComposition(item.id).getLibrary();
			}

			if (params === null && window[item.id] !== undefined) {
				if (window[item.id].properties.manifest.length > 0) params = window[item.id];
			}

			if (params !== null) {
				let manifest = [];
				params.properties.manifest.map((data, i) => {
					if (data.src.indexOf('mp3') === -1) {
						manifest.push(data);
					} else {
						if (window.kknApp && window.kknApp.sounds) {
							window.kknApp.sounds[data.id] = {
								instance: createjs.Sound.createInstance(data.id),
								url: this.basePath + data.src,
								loaded: false
							};
						}
					}
				});
				this.loadManifest(manifest);
			}
		}
	};

	onError = (evt) => {
		console.error('#preloader: error', evt.data);
		if (evt.data && evt.data.id === 'content') {
			this.error = true;
			if (this.onErrorCb) {
				this.onErrorCb();
			}
		}
	};
}
