import { gfx } from 'helpers/layoutVariables';
import styled from 'styled-components';

export const Wrapper = styled.div`
	position: absolute;
	top: -100%;
	left: 0%;
	z-index: 4;
	background-color: ${gfx.yellow};
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	transition: transform 0.3s ease-out;
	transform: translate3d(0, -100%, 0);

	video {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}

	&.open {
		top: 0;
		transform: translate3d(0, 0, 0);
	}

	p {
		font-size: 2em;
		width: 60%;
		text-align: center;
		color: $brown;
		line-height: 1.5;
		z-index: 1;
	}
`;

export const Logo = styled.img`
	height: 20%;
	width: 100%;
	transition: all 0.2s ease-in-out;
	transform-origin: 50% 50%;
	left: 0;
	transform: translate3d(0, -60%, 0);
	top: 50%;
	z-index: 1;
`;
