import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
	AdultBar,
	AdultButtonWrapper,
	AdultTitle,
	AdultTitleText,
	IconBackground,
	Search,
	Timer,
	TimerHintWrapper,
	TimerWrapper
} from './AdultMenuStyle';
import FullscreenButton from 'components/fragments/FullscreenButton/FullscreenButton';
import Icon from '../../basics/Icon/Icon';
import Animator from '../../basics/Animation/Animator';
import Toast from '../Toast/Toast';
import { TimerHint } from '../KknButton/KknButtonStyle';
import ProgressCircle from '../../basics/Progress/ProgressCircle';
import AdultDocumentState from '../../kits/AdultLayer/AdultDocumentState';

@inject('assetStore')
@inject('appStore')
@inject('routingStore')
@observer
class AdultMenu extends Component {
	state = {
		clicked: false
	};
	not = null;

	getTimer = () => {
		let adultTimer = this.props.appStore.adultTimer;
		let adultEndTime = this.props.appStore.adultEndTime;
		let btnSize = 44;
		if (this.props.appStore.isSmallView()) btnSize = 32;
		let appPadding = `${String(btnSize)}px`;
		let appPaddingCircle = `${String(btnSize - 4)}px`;
		let homePadding = `${String(btnSize + 8)}px`;
		let timerIcon = this.props.appStore.adultLayerOpen && this.props.appStore.adultTimerOpen ? 'close' : 'clock';
		return (
			<TimerWrapper marginLeft={this.props.pos === 'top' ? '0' : null}>
				<TimerHintWrapper small={this.props.appStore.isSmallView()} pos={this.props.pos}>
					<Toast active={this.props.appStore.showAdultTimerHint}>
						<TimerHint pos={this.props.pos}>Hier kann ihr Kind den Ablauf der Zeit nachvollziehen.</TimerHint>
					</Toast>
				</TimerHintWrapper>
				<Animator duration={300} type={'scaleY'} active={this.props.appStore.adultLayerOpen && this.props.appStore.adultTimerOpen}>
					<IconBackground size={appPadding} sizeHeight={homePadding} />
				</Animator>

				<Timer
					pos={this.props.pos}
					size={appPadding}
					sizeHeight={appPadding}
					onClick={() => {
						if (this.props.appStore.adultTimerOpen === false) {
							this.props.appStore.showParentalGate({
								open: true,
								delay: this.props.appStore.adultLayerOpen ? '0s' : '.5s',
								type: 'adultTimer'
							});
							this.props.appStore.adultSearchOpen = false;
							this.props.appStore.adultTimerOpen = true;
						} else {
							this.props.appStore.adultTimerOpen = false;
							this.props.appStore.closeAdultLayer();
							this.props.appStore.clearParentalGate();
						}
						// this.props.appStore.noPageLockAnimation = false;
						//  this.props.appStore.openAdultLayer();
					}}
					code={timerIcon}
				/>
				{adultTimer > -1 ? (
					<ProgressCircle
						size={appPaddingCircle}
						pos={this.props.pos}
						progress={adultTimer > 0 ? adultTimer / adultEndTime : 0}
					/>
				) : null}
			</TimerWrapper>
		);
	};

	render() {
		let btnSize = 44;
		if (this.props.appStore.isSmallView()) btnSize = 32;
		let fontSize = '20px';
		if (this.props.appStore.isSmallView()) fontSize = '16px';
		if (this.props.appStore.isVerySmallView()) {
			fontSize = '11px';
		}

		let appPadding = `${String(btnSize)}px`;
		let homePadding = `${String(btnSize + 8)}px`;
		let menuIcon =
			this.props.appStore.adultLayerOpen && !this.props.appStore.adultTimerOpen && !this.props.appStore.adultSearchOpen
				? 'close'
				: 'menu';
		if (this.props.appStore.adultTimerOpen) menuIcon = 'menu';

		return (
			<AdultBar
				padding={this.props.pos === 'top' && !this.props.appStore.isVerySmallView() ? '0 16px 1em 16px' : '0 0 1em 0'}
				width={this.props.pos === 'top' ? '100%' : 'auto'}
			>
				{this.props.pos === 'top' ? (
					<>
						<FullscreenButton size={appPadding} pos={this.props.pos} hideText={true} />
						<AdultDocumentState />
						{this.getTimer()}
						<Search
							position="relative"
							onClick={this.props.appStore.toggleSearch}
							width={appPadding}
							height={appPadding}
							display="flex"
							alignItems="center"
							justifyContent="center"
						>
							<Animator duration={200} type={'scaleY'} active={this.props.appStore.adultSearchOpen}>
								<IconBackground borderRadius={'4px 4px 0 0'} size={'100%'} sizeHeight={homePadding} />
							</Animator>
							{this.props.appStore.adultSearchOpen ? (
								<Icon size={appPadding} sizeHeight={appPadding} code={'close'} />
							) : (
								<Icon size={appPadding} sizeHeight={appPadding} code={'search'} />
							)}
						</Search>
					</>
				) : null}

				<AdultButtonWrapper
					margin={this.props.pos === 'top' ? '0 1em 0 0' : '0 1em 0 1em'}
					pos={this.props.pos}
					onClick={this.props.appStore.toggleAdultLayer}
				>
					{this.props.pos === 'top' ? (
						<AdultTitle pos={this.props.pos} size={fontSize}>
							<Animator
								duration={200}
								type={'scaleY'}
								active={
									this.props.appStore.adultLayerOpen &&
									!this.props.appStore.adultTimerOpen &&
									!this.props.appStore.adultSearchOpen
								}
							>
								<IconBackground borderRadius={'4px 4px 0 0'} size={'100%'} sizeHeight={homePadding} />
							</Animator>
							<Icon size={appPadding} sizeHeight={appPadding} code={menuIcon} />
							<AdultTitleText>Für Eltern</AdultTitleText>
						</AdultTitle>
					) : (
						<>
							<Animator
								duration={300}
								type={'scaleX'}
								active={
									this.props.appStore.adultLayerOpen &&
									!this.props.appStore.adultTimerOpen &&
									!this.props.appStore.adultSearchOpen
								}
							>
								<IconBackground borderRadius={'0 4px 4px 0'} size={homePadding} sizeHeight={appPadding} />
							</Animator>
							<Icon size={appPadding} sizeHeight={appPadding} code={menuIcon} />
						</>
					)}
				</AdultButtonWrapper>
				{this.props.pos === 'right' ? (
					<>
						<Search
							position="relative"
							onClick={this.props.appStore.toggleSearch}
							width={appPadding}
							height={appPadding}
							display="flex"
							alignItems="center"
							justifyContent="center"
						>
							<Animator duration={200} type={'scaleY'} active={this.props.appStore.adultSearchOpen}>
								<IconBackground borderRadius={'4px 4px 0 0'} size={'100%'} sizeHeight={homePadding} />
							</Animator>
							{this.props.appStore.adultSearchOpen ? (
								<Icon size={appPadding} sizeHeight={appPadding} code={'close'} />
							) : (
								<Icon size={appPadding} sizeHeight={appPadding} code={'search'} />
							)}
						</Search>
						{this.getTimer()}
						<FullscreenButton size={appPadding} pos={this.props.pos} hideText={true} />
						<AdultDocumentState margin={'0 0 0 1em'} width={appPadding} height={appPadding} isSmall={true} />
					</>
				) : null}
			</AdultBar>
		);
	}
}

export default AdultMenu;

/*<BackgroundAdultBtn src={'/static_kkn/global/images/tabbed/icons/elternbutton-Bg.png'}/>
<KknButton
    bounce={not.length > 0 && !this.state.clicked ? true : false}
    active={this.props.appStore.adultLayerOpen}
    type={'adult'}
    variant={not}
    onClicked={this.toggleLayer}
    progress={true}
    onProgressEnded={this.onProgressEnded}
/>
<FullscreenStatus open={this.props.appStore.isFullscreen} src={fullscreenIcon && fullscreenIcon.src}/>
*/
