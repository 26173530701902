import styled, { css } from 'styled-components';
import { ContentWrapper } from './AdultLayerStyle';
import { gfx, blueRabbitHand } from 'helpers/layoutVariables';

export const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;
	background-color: ${gfx.blue};
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	transition: all 0.3s ease-out;
	overflow: hidden;

	transform: translate3d(-100%, 0, 0);

	${(props) =>
		props.open &&
		css`
			transform: translate3d(0, 0, 0);
		`};
`;

export const MainScreen = styled.div`
	width: 60%;
	border-radius: 3em;
	position: relative;

	h4 {
		margin-top: 0;
		padding-top: 0.5em;
		font-size: 2.666em;
		span {
			color: ${gfx.brown};
		}
	}
`;
export const InnerWrapper = styled(ContentWrapper)`
	margin: 0 auto;
	align-items: center;
	text-align: center;
	overflow: hidden;
	height: auto;
	justify-content: center;
`;

export const Timer = styled.div`
	position: absolute;
	padding-left: 2em;
	font-size: 3em;
	top: 1em;
	left: 50%;
	transform: translate3d(-50%, 0, 0);
	display: flex;
	color: ${gfx.brown};
	align-items: center;
	justify-content: center;
	text-align: left;
	transition: transform 0.3s ease-out;
	&:after {
		position: absolute;
		border-radius: 0.5em;
		right: -1em;
		height: 2em;
		background-color: hsla(0, 0%, 100%, 0.5);
		content: '';
		width: 41em;
		padding-right: 12em;
	}
	span {
		z-index: 1;
	}
	span:nth-child(2) {
		padding-top: 0.1em;
	}
`;

export const TimeBar = styled.div`
	height: 5em;
	min-height: 20px;
	width: 100%;
	border: 0.666em solid rgba(255, 255, 255, 0.5);
	border-radius: 0.666em;
	position: relative;
	overflow: hidden;
	margin-bottom: 3%;
	button {
		border: 0;
		background: none;
		color: ${gfx.brown};
		font-size: 2em;
		text-transform: uppercase;
		${blueRabbitHand}

		line-height: 1.5;
		padding: 0.25em 0.25em 0 0.25em;
		min-height: 16px;
		height: 100%;
		left: 0;
		z-index: 1;
		position: absolute;
		&:nth-child(2) {
			left: 25%;
		}
		&:nth-child(3) {
			left: 50%;
		}
		&:nth-child(4) {
			left: 75%;
		}
	}
`;

export const TimerProgress = styled.span`
	width: 50%;
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	background-color: #fff;
	transition: width 0.3s ease-out;
`;

export const BottomButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 2%;
	margin-bottom: 4%;
	button {
		border: 0;
		background-color: #fff;
		color: ${gfx.brown};
		font-size: 3em;
		padding: 0.5em 0.5em 0.25em 0.5em;
		border-radius: 0.5em;
		text-transform: uppercase;
		box-shadow: 0 0 0 0.2em rgba(255, 255, 255, 0.5);
		${blueRabbitHand}
	}
`;
