import styled, { css } from 'styled-components';
import { SliderBaseStyle } from './SliderBaseStyle';
export const ScrollerWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	&.swiper-slide {
		height: auto;
	}
`;

export const Wrapper = styled(SliderBaseStyle)`
	flex-wrap: wrap;
	display: flex;
	width: ${(props) => (props.width ? props.width : props.type === 'vertical' ? '100%' : 'auto')};
	height: ${(props) => (props.height ? props.height : '100%')};
	position: relative;
	justify-content: flex-start;
	flex-direction: column;

	.swiper-container-horizontal > .swiper-scrollbar {
		position: absolute;
		left: 45em;
		bottom: 1em;
		z-index: 50;
		height: 5px;
		width: calc(100% - 90em);
		background-color: rgba(0, 0, 0, 0.1);
		.swiper-scrollbar-drag {
			background-color: rgba(0, 0, 0, 0.5);
		}
	}
	.swiper-slide {
		width: auto !important;
		height: 95% !important;
		display: flex;
		align-items: center;
	}

	${(props) =>
		props.type === 'vertical' &&
		css`
			.swiper-wrapper {
				flex-direction: column;
			}
			.swiper-scrollbar {
				position: absolute;
				left: 92em;
				top: 0;
				z-index: 2;
				width: 5px;
				height: calc(100%);
				background-color: rgba(0, 0, 0, 0.1);
			}
			.swiper-slide {
				height: auto !important;
				width: 100% !important;
				flex-direction: row;
				display: flex;
				justify-content: center;
			}
		`};

	${(props) =>
		props.variant === 'sub' &&
		props.type === 'vertical' &&
		css`
			.swiper-scrollbar {
				left: auto;
				right: 0;
			}
		`};

	${(props) =>
		props.noSlider === true &&
		css`
			align-items: center;
			justify-content: center;

			.swiper-slide {
				height: auto !important;
			}
		`}

	.swiper-wrapper {
		align-items: center;
	}

	.swiper-container {
		width: 100%;
		height: ${(props) => (props.height ? props.height : '100%')};
		padding-bottom: 4em;
		padding-top: 1em;
		overflow: visible;
	}
	${(props) =>
		props.grid &&
		css`
			.swiper-slide {
				display: flex;
				align-items: flex-end;
			}
			.swiper-slide:nth-child(odd) {
				align-items: flex-start;
			}
			.swiper-slide:last-child {
				align-items: center;
			}
		`}
`;
