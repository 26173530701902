import styled from 'styled-components';

export const StartLink = styled.a`
	position: absolute;
	top: 34%;
	left: 64%;
	width: 16%;
	height: 32%;
	z-index: 1;
	cursor: pointer;
`;

export const Logo = styled.img`
	height: 14%;
	display: flex;
	align-self: center;
	margin-left: auto;
	width: 100%;
	margin-top: 2%;
	left: 0;
	position: absolute;
	transition: all 0.2s ease-in-out;
	transform-origin: 50% 50%;
`;
export const Container = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
`;
