import styled, { css } from 'styled-components';
import { gfx, blueRabbitHand } from 'helpers/layoutVariables';

export const Wrapper = styled.div`
	position: absolute;
	top: 3%;
	left: -98%;
	height: 94%;
	z-index: 10;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 4em;
	width: 96%;
	background-color: ${gfx.deepBlue};
	border-radius: 3em;
	overflow: hidden;

	.swiper-slide {
		padding: 0 25em 0 2em;
	}

	p {
		color: #fff;
	}

	.trackingSettings {
		border-radius: 4px;
		display: flex;
		justify-content: flex-end;
		width: 100%;
		background-color: rgba(255, 255, 255, 0.25);
		align-items: center;
		padding: 1em;
		position: relative;
		margin-bottom: 10em;
	}
	.trackingSettings-title {
		margin-right: auto;
		font-size: 3em;
		color: #fff;
	}

	.copytext button {
		width: auto;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		border: 0;
		position: relative;
		font-size: 1em;
		padding: 0 6px;
		background: none;
		${blueRabbitHand}

		&:after {
			transition: background-color 0.3s ease-out;
			border-radius: 5em;
			border: solid 2px #5f4100;
			background-color: #ea690b;
			position: relative;
			content: '';
			width: 7em;
			height: 4.25em;
			min-width: 32px;
			min-height: 20px;
			padding: 0 0.75em;
			/* top: 50%; */
			/* transform: translate3d(0, -50%, 0); */
			/* right: 4px;*/
		}

		&.open {
			&:after {
				background-color: #94c42f;
			}

			svg {
				transform: translate3d(0, -50%, 0);
			}
		}

		svg {
			pointer-events: none;
			width: 4em;
			min-width: 16px;
			min-height: 16px;
			transition: transform 0.3s ease-out;
			z-index: 1;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-100%, -50%, 0);

			> g#point {
				display: block;
			}
		}
	}
`;

export const AdultButtonPositioner = styled.div`
	position: absolute;
	right: 2em;
	top: 2em;
	z-index: 1;
`;
