import 'babel-polyfill';
import 'react-app-polyfill/ie11';

import { Provider } from 'mobx-react';
import React from 'react';
import ReactDom from 'react-dom';
import App from './App';
import AppWrapper from './components/templates/AppWrapper/AppWrapper';
import NotFound from './components/templates/NotFound/NotFound';

import stores from './store/index';

import createHistory from 'history/createBrowserHistory';
import { syncHistoryWithStore } from 'mobx-react-router';
import { Route, Router, Switch } from 'react-router-dom';

const browserHistory = createHistory();
const history = syncHistoryWithStore(browserHistory, stores.routingStore);
const dest = document.getElementById('content');

const renderMe = () => {
	let routes = [
		{ path: '/404', name: 'Error', Component: NotFound },
		{ path: '*', name: 'Error', Component: AppWrapper }
	];

	if (
		!document.location.href.includes('.html') &&
		!document.location.href.includes('.json') &&
		!document.location.href.includes('/404')
	) {
		let lastChar = document.location.href.slice(-1) === '/' ? '' : '/';
		let redirect = document.location.href + lastChar + 'index.html';
		document.location.href = redirect;
	}

	ReactDom.render(
		<Provider {...stores} history={history}>
			<App>
				<Router history={history}>
					<Switch>
						{routes.map(({ path, Component }) => (
							<Route history={history} key={path} exact path={path}>
								{({ match }) => <Component />}
							</Route>
						))}
					</Switch>
				</Router>
			</App>
		</Provider>,
		dest
	);
};

renderMe();
/*
if ('serviceWorker' in navigator) {
	// Use the window load event to keep the page load performant
	
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.register('/sw2.js')
		.then(function(registration) {
			console.log("Service Worker Registered", registration);
		})
		.catch(function(err) {
			console.log("Service Worker Failed to Register", err);
		})
	}
  }
*/
