import React from 'react';
import PreloaderView from 'components/kits/PreloaderView/PreloaderView';
import { isIos, isTouch, logger, Statics } from './helpers/Utils';
import { inject, observer } from 'mobx-react';
import info from './timestamp.js';
import AdultLayer from 'components/kits/AdultLayer/AdultLayer';
import ParentalGate from 'components/kits/ParentalGate/ParentalGate';
import './App.css';
import styled, { css } from 'styled-components';

import Lockscreen from 'components/kits/Lockscreen/Lockscreen';
import SliderButton from './components/fragments/SliderButton/SliderButton';
import Animator from './components/basics/Animation/Animator';
import AdultDocumentState from 'components/kits/AdultLayer/AdultDocumentState';
import BaseComponent from 'BaseComponent';
import AdultMenu from './components/fragments/AdultMenu/AdultMenu';
import ReactResizeDetector from 'react-resize-detector';
import ImageRotate from './components/basics/ImageRotate/ImageRotate';
import { Frame, InnerWrapper, TurnHint, TopMenu, AppWrapper } from './AppStyled';

const $ = window.$;
const brAv = window.brAv;

@inject('assetStore')
@inject('appStore')
@inject('routingStore')
@observer
class App extends BaseComponent {
	int = 0;
	isUnmounted = false;
	lastScale = 0;
	scale = 1;
	scrollValueX = 0;
	scrollValueY = 0;
	ref = null;
	timer = 0;
	pageSwitch = false;
	lastNot = null;
	not = '';

	state = {
		mount: true,
		loaded: false,
		progress: 0,
		orientation: 'landscape',
		adultButtonPos: null
	};

	constructor() {
		super();
		this.ref = React.createRef();
	}

	componentDidMount() {
		// Animate Version 2018
		let settingsWorked = this.props.appStore.getSettings();
		this.props.appStore.appNode = this.ref;
		this.props.appStore.animate = 'in';
		let animTimeout = 0;
		let clickonTimeout = 0;

		window['buildInfo'] = {
			commitId: process.env.REACT_APP_COMMIT,
			tag: process.env.REACT_APP_TAG
		};

		console.info('📦 Commit:' + window['buildInfo'].commitId + 'Tag:' + window['buildInfo'].tag);

		this.props.routingStore.history.listen((location, action) => {
			logger(`listen: The current URL is ${location.pathname}${location.search}${location.hash}`);
			logger(`listen: The last navigation action was ${action}`);
			this.props.appStore.newRoute = location.pathname;
			logger(`listen: change ${this.props.appStore.hasPageChanged()}`);

			if (this.props.appStore.hasPageChanged() === true) this.props.appStore.clickonsActive = false;

			window.clearTimeout(clickonTimeout);
			window.clearTimeout(animTimeout);
			window.createjs.Sound.stop();

			if (this.props.assetStore.hasDetail() !== true) {
				this.props.appStore.animate = 'out';
			}
			window.animTimeout = setTimeout(() => {
				this.props.appStore.animate = 'in';
			}, 750);

			if (
				this.props.appStore.lastRoute !== null &&
				this.lastLinkType !== 'back' &&
				this.props.assetStore.hasDetail() === false &&
				this.props.appStore.getCurrentQuery().page !== '0'
			)
				window.kknApp.routes.push(location.pathname + location.search);
			this.props.assetStore.apiRequested = false;
		});

		this.props.appStore.lastRoute = this.props.routingStore.location.pathname;

		window.AdobeAn = window.AdobeAn || {};
		if (window.kknApp) window.kknApp.version = info.KKN_COMPILED_TIMESTAMP;

		window.$(this.ref).on('mousedown', function(evt) {
			window.$('html').addClass('kkn-pressed');
		});
		window.$(this.ref).on('mouseup', function(evt) {
			window.$('html').removeClass('kkn-pressed');
		});
		this.props.assetStore.loadGlobalAssets();
		this.handleResize();

		brAv.utils.setCookie({ cookieId: brAv.getCookies().subtitles, value: 'on' });

		if (window.screenfull && window.screenfull.isEnabled) {
			window.screenfull.on('change', () => {
				this.props.appStore.onFullscreenChanged();
				setTimeout(() => {
					this.handleResize();
				}, 0);
			});
		}

		window.$('body').on('avPlayerExtEvent', (evt, url) => {
			let el = document.createElement('a');
			el.href = url;
			if (url && el.pathname) this.props.routingStore.push(el.pathname);
		});
	}

	componentWillUnmount() {
		cancelAnimationFrame(this.int);
		this.isUnmounted = true;
	}

	componentDidUpdate = (prevProps, prevState, snapshot) => {
		this.lastNot = this.not;
	};

	handleResize = params => {
		this.props.appStore.redrawSlider = true;
		let $el = window.$(this.ref);
		let appPadding = this.props.appStore.getAppPadding();

		let refWidth = window.innerWidth;
		let refHeight = window.innerHeight;
		let elWidth = 1920;
		let elHeight = 1080;
		let scale = 1;
		let minRatio = refHeight / refWidth;
		// limit downscaling on iOS, if key

		let tempScaleX = refWidth / elWidth;
		let tempScaleY = refHeight / elHeight;
		let tempScale = Math.min(tempScaleX, tempScaleY);
		let ratio = tempScaleX / tempScaleY;
		window.$('html').removeClass('kkn-top kkn-right');

		let posSpaceButton = 'top';
		window.$('html').addClass('kkn-top');

		if (refWidth < 1008 && ratio >= 0.85) {
			posSpaceButton = 'right';
			window.$('html').removeClass('kkn-top');

			if (ratio < 1.1) {
				refWidth = refWidth - appPadding;
				window.$('html').addClass('kkn-right');
			}
		} else {
			refHeight = refHeight - appPadding * 2;
		}

		let scaleX = refWidth / elWidth;
		let scaleY = refHeight / elHeight;

		scale = Math.abs(Math.min(scaleX, scaleY));

		if (scale > 1) scale = 1;
		// this.props.appStore.forceLandscape = minRatio > 1.5 && this.props.appStore.width < 768;

		if (scale !== this.lastScale) {
			window.kknApp.isAnimating = false;
			window.kknApp.scale = scale;
			window.kknApp.width = elWidth * scale;
			window.kknApp.height = elHeight * scale;
			window.kknApp.isSmallView = this.props.appStore.isSmallView();
			this.props.appStore.width = window.kknApp.width;
			this.props.appStore.height = window.kknApp.height;
			this.props.appStore.scale = scale;
			this.props.appStore.appPadding = appPadding;
			$el.width(window.kknApp.width);
			$el.height(window.kknApp.height);

			// $el.attr("style", "transform:scale(" + scale + "); transform-origin: 0 0; left:50%; margin-left:" + (-(Number($el.width()) * scale / 2)) + "px");
			//let orientation = window.screen.orientation  ||  window.orientation;
			let orient = 'landscape';
			var orientation =
				(window.screen.orientation || {}).type || window.screen.mozOrientation || window.screen.msOrientation || window.orientation;
			if (
				(typeof orientation !== 'string' && orientation === 0) ||
				(typeof orientation === 'string' && orientation.indexOf('portrait') !== -1)
			) {
				orient = 'portrait';
			}

			if (!this.isUnmounted && this.lastScale !== this.scale) {
				this.setState({
					lastScale: scale,
					orientation: orient,
					appPadding: appPadding,
					adultButtonPos: posSpaceButton
				});
				window.$('body').trigger('kknApp.resized');
				//this.props.notifClear("app");
				/*this.props.notifSend({
                 data: window.kknApp,
                 kind: 'success'
                 }, "app");
                 */
			}

			/*TweenMax.set($el, {
             css: {
             scale: this.state.scale,
             left: "50%",
             marginLeft: -(Number($el.width()) * scale / 2),
             }
             });
             */
		} else {
			if (window.kknApp.forceAnimateStop !== true) window.kknApp.isAnimating = true;
		}
		this.lastScale = scale;

		setTimeout(() => {
			this.props.appStore.redrawSlider = false;
		}, 0);
	};

	handleLogout = event => {
		event.preventDefault();
		this.props.logout();
	};
	getPreloader = () => {
		if (this.props.assetStore.isLoading === false && this.props.appStore.clicked === true) return false;

		return (
			<PreloaderView
				match={this.props.match}
				loaded={this.state.loaded}
				assets={this.props.assetStore.globalAssets}
				onReady={this.onPreloaderReady}
			/>
		);
	};
	onPreloaderReady = () => {
		if (!this.isUnmounted) this.setState({ loaded: true });
	};
	onContentSlidePrev = () => {
		let targetOffset = (this.props.appStore.width * 0.6 * 0.5625) / 2;

		if (this.props.assetStore.docInfos.contentType === Statics.CONTENT_TYPE_INDEX) {
			this.props.appStore.prevPage();
			return false;
		}
		this.props.appStore.resetContentScrollerNavi();

		let scrollPos = this.props.appStore.contentScroller.translate + targetOffset;
		if (this.props.appStore.contentScroller) this.props.appStore.contentScroller.translateTo(scrollPos, 200);
	};
	onContentSlideNext = () => {
		let targetOffset = (this.props.appStore.width * 0.6 * 0.5625) / 2;

		if (this.props.assetStore.docInfos.contentType === Statics.CONTENT_TYPE_INDEX) {
			this.props.appStore.nextPage();
			return false;
		}

		this.props.appStore.resetContentScrollerNavi();

		let scrollPos = this.props.appStore.contentScroller.translate - targetOffset;
		if (this.props.appStore.contentScroller) this.props.appStore.contentScroller.translateTo(scrollPos, 200);
	};

	render() {
		return (
			<React.Fragment>
				<div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
					<ReactResizeDetector refreshMode={'debounce'} refreshRate={100} handleWidth handleHeight onResize={this.handleResize} />
				</div>
				{this.getApp()}
			</React.Fragment>
		);
	}

	getApp = () => {
		let sceneTemplate = ' template-';
		let scopeTemplate = ' scope-';
		let seasonTemplate = ' season-';
		let contentTemplate = ' content-';

		if (this.props.assetStore.docInfos) {
			sceneTemplate +=
				this.props.assetStore.docInfos.template && this.props.assetStore.docInfos.template.length > 0
					? this.props.assetStore.docInfos.template
					: 'base';
			scopeTemplate +=
				this.props.assetStore.docInfos.scope && this.props.assetStore.docInfos.scope.length > 0
					? this.props.assetStore.docInfos.scope
					: 'base';
			seasonTemplate +=
				this.props.assetStore.docInfos.seasonId && this.props.assetStore.docInfos.seasonId.length > 0
					? this.props.assetStore.docInfos.seasonId
					: 'base';
			contentTemplate +=
				this.props.assetStore.docInfos.category && this.props.assetStore.docInfos.category.length > 0
					? this.props.assetStore.docInfos.category
					: 'base';
		}

		const template =
			'app ' +
			sceneTemplate +
			scopeTemplate +
			seasonTemplate +
			contentTemplate +
			' ' +
			(this.props.appStore.animationReady === false ? 'animationZeroDuration ' : ' ');
		let extOpen = ' ' + (this.props.appStore.isFullscreen ? 'kkn-fullscreen' : '');
		let viewSize = !this.props.appStore.isSmallView() && window.kknApp.width !== undefined ? '' : ' view-small';
		let appBaseStyle = {
			fontSize: String(window.kknApp.scale ? (16 * window.kknApp.scale).toFixed(2) : 16) + 'px',
			backgroundSize: 'cover',
			backgroundImage: 'url(/static_kkn/global/images/bg_start.jpg)'
		};

		let urlFrame = '/static_kkn/global/images/rahmen-re.png';
		let urlFrameVert = '/static_kkn/global/images/rahmen_down.png';
		let showSliderButtons = false;
		let paging = false;
		let sliderDirection = 'vertical';

		if (this.props.assetStore.isLoading === false && this.props.assetStore.firstCurtainDropped === true) {
			if (this.props.assetStore.docInfos.contentType === Statics.CONTENT_TYPE_INDEX) {
				showSliderButtons = true;
				paging = true;
			}

			if (!isTouch() && this.props.appStore.animate === 'in') {
				if (this.props.assetStore.isBundle() && !isTouch() && this.props.assetStore.globalAssetsLoaded) {
					showSliderButtons = true;
				}

				if (this.props.assetStore.isHome() || this.props.assetStore.docInfos.contentType === Statics.CONTENT_TYPE_INDEX_TABBED) {
					if (!this.props.appStore.detailOpen) showSliderButtons = true;
					sliderDirection = 'horizontal';
				}
			}
			if (
				this.props.assetStore.docInfos.contentType !== Statics.CONTENT_TYPE_INDEX &&
				(this.props.assetStore.hasDetail() ||
					this.props.appStore.adultLayerOpen === true ||
					this.props.appStore.adultTimerEnded === true ||
					this.props.appStore.contentScrollerItemsLength < 5)
			) {
				showSliderButtons = false;
			}
		}

		let isStart = this.props.appStore.contentScrollerIsBeginning;
		let isEnd = this.props.appStore.contentScrollerIsEnd;
		if (paging) {
			isStart = this.props.appStore.pageIndex < 1;
			isEnd = this.props.appStore.pageIndex >= this.props.appStore.getMaxPages() - 1;
		}
		return (
			<>
				<AppWrapper ref={ref => (this.ref = ref)} style={appBaseStyle} className={template + viewSize + extOpen}>
					<InnerWrapper>
						<Frame src={urlFrame} type={'left'} />
						<Frame src={urlFrameVert} type={'top'} />
						<Frame src={urlFrame} type={'right'} />
						<Frame src={urlFrameVert} type={'bottom'} />
						{this.props.assetStore.globalAssetsLoaded ? (
							<React.Fragment>
								{this.props.children}
								<ParentalGate />
								<AdultLayer />
								<Lockscreen />
							</React.Fragment>
						) : null}
						{this.getPreloader()}
						{showSliderButtons ? (
							<>
								<Animator
									active={isStart === false}
									type={sliderDirection === 'horizontal' ? 'moveInFromLeft' : 'moveInFromTop'}
								>
									<SliderButton
										type={sliderDirection === 'horizontal' ? 'left' : 'top'}
										onClick={this.onContentSlidePrev}
									></SliderButton>
								</Animator>
								<Animator
									active={isEnd === false}
									type={sliderDirection === 'horizontal' ? 'moveInFromRight' : 'moveInFromBottom'}
								>
									<SliderButton
										type={sliderDirection === 'horizontal' ? 'right' : 'bottom'}
										onClick={this.onContentSlideNext}
									></SliderButton>
								</Animator>
							</>
						) : null}
					</InnerWrapper>
					{this.props.assetStore.globalAssetsLoaded && this.props.assetStore.firstCurtainDropped === true ? (
						<TopMenu
							isSmall={this.props.appStore.isSmallView()}
							pos={this.state.adultButtonPos}
							height={this.state.appPadding / 2}
						>
							<AdultMenu pos={this.state.adultButtonPos} />
						</TopMenu>
					) : null}
					{this.props.appStore.forceLandscape ? (
						<TurnHint>
							<p>Bitte drehen</p>
						</TurnHint>
					) : null}
				</AppWrapper>

				{this.state.orientation === 'portrait' ? <ImageRotate /> : null}
			</>
		);
	};
}

export default App;
