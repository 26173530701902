import styled from 'styled-components';

export const Scene = styled.div`
	position: absolute;
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;

	&.index {
		background-color: #fff7d3;
	}

	&.layerOpen {
		.backLayer {
			transition: none !important;
		}
	}

	&.active-videos .backLayer.right {
		// background-image: url('./fg_video.png');
		img {
			left: -1920px;
		}
	}
	&.active-lieder .backLayer.right {
		// background-image: url('./fg_lieder.png');
		img {
			left: 0;
			top: -1080px;
		}
	}
	&.active-selbermachen .backLayer.right {
		// background-image: url('./fg_selbermachen.png');
		img {
			left: -1920px;
			top: -1080px;
		}
	}

	&.active-ueber .backLayer.right {
		// background-image: url('./fg_ueber.jpg');
		> img {
			left: 0;
			top: -2160px;
		}
	}
	&.active-spielen .backLayer.right {
		// background-image: url('./fg_ueber.jpg');
		> img {
			left: -1920px;
			top: -2160px;
		}
	}
`;
