import styled, { css } from 'styled-components';
import { blueRabbitHand, gfx } from 'helpers/layoutVariables';

export const BackMeadow = styled.img`
	position: absolute;
	left: 0;
	bottom: 0;
	width: 25em;
`;

export const Wrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	${(props) =>
		props.bgImage &&
		css`
			background: transparent url(${(props) => props.bgImage}) no-repeat 0 0;
			background-size: cover;
		`}
`;

export const Category = styled.li`
	position: relative;
	display: flex;
	width: 10em;
	height: 10em;
	overflow: hidden;
	border-radius: 50%;
	background-color: ${gfx.vanille};
	align-items: center;
	justify-content: center;
	color: ${gfx.brown};
	z-index: 2;
	margin: 4em 2em 5em 2em;
	transition: all 0.3s ease-out;
	box-shadow: 0 0 0.5em 0.1em ${gfx.brown};
	border: 0.75em solid ${gfx.lightBlue};

	img {
		width: 100%;
		height: 100%;
	}

	${blueRabbitHand};

	${(props) =>
		props.active &&
		css`
			background-color: #fff;
			border-color: #fff;
			box-shadow: none;
		`}
`;

export const Menu = styled.ul`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 2.25em;

	${(props) =>
		props.activeBg &&
		css`
			&:before {
				transition: all 0.3s ease-out;
				background: transparent url(${(props) => props.activeBg}) no-repeat 0 0;
				background-size: cover;
				content: '';
				position: absolute;
				left: 0;
				transform: translate3d(${(props) => (props.position ? String(props.position * 14) + 'em' : 0)}, 0, 0);
				transition-delay: 0;
				bottom: 3.333em;
				width: 16em;
				height: 14em;
				z-index: 1;
				top: 1.75em;
				margin-left: -1em;
			}
		`}
`;
