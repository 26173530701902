import styled from 'styled-components';
import { DetailAnimationFlavour } from 'helpers/layoutVariables';
import { gfx } from '../../../helpers/layoutVariables';

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	overflow: hidden;

	${DetailAnimationFlavour};
`;

export const Outer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: ${gfx.vanille};
	overflow: hidden;
`;

export const PreloaderWrapper = styled.div`
	transition: all 0.3s ease-out;
	position: absolute;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform: translate3d(0, 0, 0);

	&.enter {
		transform: translate3d(0, 0, 0);
	}
	&.enter-active,
	&.enter-done {
		transform: translate3d(0, 0, 0);
	}
	&.exit,
	&.exit-active,
	&.exit-done {
		transform: translate3d(0, 100%, 0);
	}
`;
export const ExternalWrapper = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100% !important;
	height: 100% !important;
	opacity: 0;
	transition: opacity 0.6s ease-out;

	canvas {
		width: 100% !important;
		height: 100% !important;
	}
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	&.enter {
		opacity: 0;
	}
	&.enter-active,
	&.enter-done {
		opacity: 1;
	}
	&.exit,
	&.exit-active {
		opacity: 0;
	}
`;
export const MainImage = styled.div`
	position: absolute;
	width: 100%;
	left: 0;
	top: 0;
	height: 100%;
	pointer-events: none;
	transition: all 0.6s ease-out;

	img {
		width: 100%;
	}
	&.enter {
		opacity: 1;
	}
	&.enter-active,
	&.enter-done {
		opacity: 1;
	}
	&.exit,
	&.exit-done,
	&.exit-active {
		opacity: 0;
	}
`;
