import styled, { css } from 'styled-components';
import { blueRabbitHand } from 'helpers/layoutVariables';

export const Wrapper = styled.a`
	display: flex;
	align-items: center;
	position: absolute;
	left: 0;
	top: 0;
	text-align: left;
	transition: transform 0.3s ease-out;
	color: #5f4100;

	${blueRabbitHand};

	${(props) =>
		props.pos === 'right' &&
		css`
			position: relative;
			transform: rotate(270deg);
		`};
	${(props) =>
		props.hidden === true &&
		css`
			opacity: 0;
			pointer-events: none;
		`};
`;
