import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {NotificationText, Wrapper} from './AdultDocumentStateStyle';
import Icon from '../../basics/Icon/Icon';
import Animator from '../../basics/Animation/Animator';

@inject('assetStore')
@inject('appStore')
@observer
class AdultDocumentState extends Component {
    lastNot = null;
    not = null;
    componentDidUpdate = () => {
        this.lastNot = this.not;
    };

    render() {
        let adultContent = this.props.assetStore.adultLayerContent;
        this.not = this.props.appStore.getNotificationStyle(adultContent);

        if (this.not.indexOf('competition') !== -1 || this.not.indexOf('contest') !== -1 || this.not.indexOf('win') !== -1)
            this.not = 'win';

        if (this.props.appStore.adultLayerOpen) this.not = 'close';
        let msg = 'Zusatzinhalt verfügbar';
        let width = this.props.width;

        msg = this.props.appStore.getNotificationText(this.not);
        if (this.not === 'close') {
            this.not = '';
        }
        let fontSize = '18px';
        let useHeight = '32px';

        if (this.props.appStore.isSmallView()) {
            fontSize = '16px';
            useHeight = '24px';
        }
        if (this.props.appStore.isVerySmallView()) {
            fontSize = '11px';
            useHeight = '24px';
        }

        if (this.props.isSmall) {
            useHeight = this.props.height;
            width = this.props.width;
        }

        if (this.not && this.not.length > 0) {
            return (
                <Animator duration={200} active={true}>
                    <Wrapper
                        padding={!this.props.isSmall ? '0 1.5em 0 0.5em' : null}
                        width={width}
                        margin={this.props.margin}
                        isSmall={this.props.isSmall}
                        height={useHeight}
                        className={''}
                        onClick={this.props.appStore.toggleAdultLayer}
                    >
                        <Icon
                            size={useHeight}
                            marginRight={this.props.isSmall ? '0' : '1em'}
                            direction={this.props.isSmall === 'right' ? '270deg' : null}
                            code={this.not || this.lastNot}
                        />
                        {this.props.isSmall !== true ?
                            <NotificationText fontSize={fontSize}>{msg}</NotificationText> : null}
                    </Wrapper>
                </Animator>
            );
        }

        return null;
    }
}

export default AdultDocumentState;
