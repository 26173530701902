import styled from 'styled-components';

export const GalleryItem = styled.a`
	display: flex;
	width: 100%;
	max-width: 6em;
	margin-left: 1em;
	margin-right: 1em;
	position: relative;
`;

export const Wrapper = styled.div`
	width: 92%;
	height: auto;
	position: absolute;
	top: 0;
	left: 0;
	min-height: 74.25%;
	z-index: 2;

	transition: all 0.6s ease-out;
	transform-origin: 0 0;

	> * {
		transition: all 0.6s ease-out;
		transform-origin: 0 0;
	}
	transform: translate3d(${(props) => (props.x ? props.x : 0)}, ${(props) => (props.y ? props.y : 0)}, 0);
	> * {
		transform: scale(${(props) => (props.scaleVal ? props.scaleVal : 0)});
	}

	&.enter {
		transform: translate3d(${(props) => (props.x ? props.x : 0)}, ${(props) => (props.y ? props.y : 0)}, 0);
		> * {
			transform: scale(${(props) => (props.scaleVal ? props.scaleVal : 0)});
		}
	}
	&.enter-active,
	&.enter-done {
		transform: translate3d(4%, 0, 0);
		> * {
			transform: scale(1);
		}
	}
	&.exit,
	&.exit-active {
		transform: translate3d(0, -260%, 0);
	}
`;

export const GalleryList = styled.div`

  position: absolute;
  z-index: 1;
  top: 89%;
  padding-top: 2.5em;
  left: 0;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  min-height: 25%;
  

  img {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;

  }
}`;

export const MainImage = styled.div`
	position: relative;
	z-index: 2;
	width: 100%;
	min-height: 50em;
	img {
		width: 100%;
	}
`;

export const BigLink = styled.div`
	background: none;
	border: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 4;
	align-items: center;
	justify-content: center;
	display: flex;
	font-size: 1em;
`;
