import styled from 'styled-components';
import { BaseProps } from './IBox';

const drawDebug = () => ['background: rgba(0, 255, 255, 0.1) !important;', 'border: 1px dashed rgba(255, 0, 255, 1) !important;'];

export const Box = styled.div<BaseProps>`
	${({ debug }) => debug && drawDebug()};
	display: ${({ display }) => display};
	position: ${({ position }) => position};
	top: ${({ top }) => top};
	bottom: ${({ bottom }) => bottom};
	left: ${({ left }) => left};
	right: ${({ right }) => right};
	margin: ${({ margin }) => margin};
	margin-top: ${({ marginTop }) => marginTop};
	margin-bottom: ${({ marginBottom }) => marginBottom};
	margin-left: ${({ marginLeft }) => marginLeft};
	margin-right: ${({ marginRight }) => marginRight};
	padding: ${({ padding }) => padding};
	padding-top: ${({ paddingTop }) => paddingTop};
	padding-bottom: ${({ paddingBottom }) => paddingBottom};
	padding-left: ${({ paddingLeft }) => paddingLeft};
	padding-right: ${({ paddingRight }) => paddingRight};
	color: ${({ color }) => color};
	opacity: ${({ opacity }) => opacity};

	background-color: ${({ backgroundColor }) => backgroundColor};
	background: ${({ background }) => background};
	background-size: ${({ backgroundSize }) => backgroundSize};
	background-image: ${({ backgroundImage }) => backgroundImage};

	width: ${({ width }) => width};
	height: ${({ height }) => height};
	max-width: ${({ maxWidth }) => maxWidth};
	min-width: ${({ minWidth }) => minWidth};
	max-height: ${({ maxHeight }) => maxHeight};
	min-height: ${({ minHeight }) => minHeight};
	z-index: ${({ zIndex }) => zIndex};
	outline: ${({ outline }) => outline};

	box-shadow: ${({ boxShadow }) => boxShadow};
	text-shadow: ${({ textShadow }) => textShadow};
	border: ${({ border }) => border};
	border-radius: ${({ borderRadius }) => borderRadius};
	cursor: ${({ cursor }) => cursor};
	pointer-events: ${({ pointerEvents }) => pointerEvents};
	justify-content: ${({ justifyContent }) => justifyContent};
	align-items: ${({ alignItems }) => alignItems};
	align-self: ${({ alignSelf }) => alignSelf};
	flex-basis: ${({ flexBasis }) => flexBasis};
	flex: ${({ flex }) => flex};
	flex-wrap: ${({ flexWrap }) => flexWrap};
	flex-grow: ${({ flexGrow }) => flexGrow};
	flex-direction: ${({ flexDirection }) => flexDirection};

	flex-shrink: ${({ flexShrink }) => flexShrink};
	grid-area: ${({ gridArea }) => gridArea};
	grid-column: ${({ gridColumn }) => gridColumn};
	grid-row: ${({ gridRow }) => gridRow};
	grid-row-start: ${({ gridRowStart }) => gridRowStart};
	grid-row-end: ${({ gridRowEnd }) => gridRowEnd};
	grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
	grid-template-rows: ${({ gridTemplateRows }) => gridTemplateRows};
	grid-auto-rows: ${({ gridAutoRows }) => gridAutoRows};
	grid-auto-columns: ${({ gridAutoColumns }) => gridAutoColumns};
	grid-auto-flow: ${({ gridAutoFlow }) => gridAutoFlow};

	grid-template-areas: ${({ gridTemplateAreas }) => gridTemplateAreas};
	align-content: ${({ alignContent }) => alignContent};

	text-align: ${({ textAlign }) => textAlign};

	white-space: ${({ whiteSpace }) => whiteSpace};
	gap: ${({ gap }) => gap};
	transform: ${({ transform }) => transform};
	transition: ${({ transition }) => transition};
	animation: ${({ animation }) => animation};

	font-family: ${({ fontFamily }) => fontFamily};
	font-weight: ${({ fontWeight }) => fontWeight};
	font-size: ${({ fontSize }) => fontSize};
	line-height: ${({ lineHeight }) => lineHeight};
	text-align: ${({ textAlign }) => textAlign};

	letter-spacing: ${({ letterSpacing }) => letterSpacing};
	overflow: ${({ overflow }) => overflow};
	overflow-x: ${({ overflowX }) => overflowX};
	overflow-y: ${({ overflowY }) => overflowY};
	object-fit: ${({ objectFit }) => objectFit};
`;
