/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { BottomButton, InnerWrapper, MainScreen, TimeBar, Timer, TimerProgress, Wrapper } from './AdultTimerStyle';
import Icon from 'components/basics/Icon/Icon';

@inject('assetStore')
@inject('appStore')
@observer
class AdultTimer extends Component {
	state = {
		open: false,
		time: 0,
		percentTime: 0,
		voice: 0
	};
	intVal = 0;
	matched = false;
	hitted = false;
	isUnmounted = false;

	endItems = [
		{ id: 0, name: 'Internetzeit ist vorbei' },
		{ id: 1, name: 'Wir wollen rausgehen' },
		{ id: 2, name: 'Jetzt ist Schlafenszeit' },
		{ id: 3, name: 'Lies doch mal ein Buch' },
		{ id: 4, name: 'Essen kommen' },
		{ id: 5, name: 'Frag, ob du nochmal darfst' }
	];

	componentDidMount() {
		this.setState({ percentTime: 0.075, time: 0 });
	}

	componentWillUnmount() {
		this.isUnmounted = true;
		clearInterval(this.intVal);
	}

	onCancel = () => {
		this.props.onCancel();
	};

	getMainScreen = () => {
		return (
			<MainScreen>
				<h4>Nutzungszeit auswählen</h4>
				<TimeBar>
					<button
						onClick={() => {
							this.onClicked(0.075, 0);
						}}
					>
						Aus
					</button>
					<button
						onClick={() => {
							this.onClicked(0.48, 10);
						}}
					>
						10 Minuten
					</button>
					<button
						onClick={() => {
							this.onClicked(0.72, 15);
						}}
					>
						15 Minuten
					</button>
					<button
						onClick={() => {
							this.onClicked(1, 20);
						}}
					>
						20 Minuten
					</button>
					<TimerProgress style={{ width: String(this.state.percentTime * 100) + '%' }} />
				</TimeBar>

				<BottomButton>
					<button onClick={this.startTimer}>Speichern und Schliessen</button>
				</BottomButton>
			</MainScreen>
		);
	};
	onClicked = (id, time) => {
		this.setState({ percentTime: id, time: time > 0 ? window.kknApp.forceAdultTime || time * 60 : 0 });
	};
	startTimer = () => {
		this.props.appStore.adultTimerStop(true);
		this.props.appStore.adultTimerStart({ time: this.state.time, end: this.endItems[this.state.voice] });
		this.props.onClose();
		this.matched = false;
	};

	render() {
		return (
			<Wrapper open={this.props.open}>
				<Timer>
					<Icon code="clock" />
					<span>Webwecker</span>
				</Timer>
				<InnerWrapper width={'90%'}>
					<p>
					Der Webwecker unterstützt Eltern dabei, mit Kindern eine feste Nutzungsdauer zu vereinbaren. Dadurch wird eine kontrollierte und ausgewogene Medienzeit gefördert. 
					Tipps und Empfehlungen zum Umgang mit Medien findet ihr&nbsp;
						<a href="https://www.kika.de/eltern/index.html" target="_blank">
						auf den Elternseiten
						</a>
						.
					</p>

					{this.getMainScreen()}
				</InnerWrapper>
			</Wrapper>
		);
	}
}

export default AdultTimer;
