import React, { Component } from 'react';
import ClickOn from 'components/fragments/ClickOn/ClickOn';
import { inject, observer } from 'mobx-react';
import KknButton from 'components/fragments/KknButton/KknButton';
import FullscreenButton from 'components/fragments/FullscreenButton/FullscreenButton';
import { FullscreenButtonWrapper, Logo, PreloaderWrapper } from './PreloaderViewStyle';

@inject('assetStore')
@inject('appStore')
@observer
class PreloaderView extends Component {
	state = {
		loaded: false,
		visible: true,
		currentReference: ''
	};
	isUnmounted = false;
	lastReference = 'first';
	lastProgress = 0;

	componentDidMount = () => {
		this.lastProgress = 0;
	};

	componentWillReact() {
		// show start button if a deeplink is present, for all contentkit query deeplinks and for interactive content / mmg urls

		if (this.props.assetStore.isLoading === false) {
			const detailId = this.props.appStore.getCurrentQuery().detailId;
			let currentDocType = '';
			if (detailId) {
				const currentDetail = this.props.assetStore.getTeaserDataById(detailId);
				if (currentDetail?.standard) currentDocType = currentDetail.standard.documentType;
				if (currentDetail?.standard && currentDocType.toLowerCase().indexOf('video') === -1) return false;
			}

			if (
				this.props.assetStore.docData.document?.documentType === 'interactiveContent' ||
				this.props.assetStore.docData.document?.documentType === 'multimediaGallery'
			) {
				return false;
			}
			if (!currentDocType && this.props.assetStore.docData.document?.documentType) {
				currentDocType = this.props.assetStore.docData.document?.documentType;
			}
			this.onStartClick(currentDocType);
		}
	}

	componentDidUpdate() {
		if (this.progress > this.lastProgress) this.lastProgress = this.progress;
	}

	componentWillUnmount() {
		this.isUnmounted = true;
	}

	createPlayerForAudios = () => {
		if (window.$('#hiddenAudioPlayer').length === 0) {
			window.createjs.Sound.play('importantFirstClickAudio');
			window.createjs.Sound.stop('importantFirstClickAudio');
			window.$('body').append(window.$('<audio id="hiddenAudioPlayer" />'));
			window.$('video,audio').each(function(i) {
				window.$(this)[0].load();
				window.$(this)[0].play();
			});
		}
	};

	onStartClick = currentDocType => {
		if (this.props.appStore.clicked === false) {
			this.createPlayerForAudios();
			this.hide(currentDocType);
		}
	};
	hide = currentDocType => {
		this.props.appStore.clicked = true;
		this.setState({ visible: false });
		if (!this.isUnmounted && this.props.onQueueComplete) this.props.onQueueComplete();
		if (!currentDocType || currentDocType.toLowerCase().indexOf('video') === -1) this.props.assetStore.firstCurtainDropped = true;
	};

	render() {
		var touch = true;
		let assets = this.props.assetStore.globalAssets;
		this.progress = this.props.progress || this.props.assetStore.preloadProgress;
		let checkedProgress = this.lastProgress < this.progress ? this.progress : this.lastProgress;
		if (checkedProgress > 1) checkedProgress = 1;
		let hide = false;
		let classNames = '';
		if (this.props.progress === undefined) {
			if (!touch && this.props.assetStore.ready === true && this.props.assetStore.isLoading === false) hide = true;
		} else {
			if (this.props.progress === 1 && !touch) hide = true;
		}
		if (this.state.visible === false && this.props.assetStore.isLoading === false) hide = true;

		let showClickHint = false;
		if (this.props.assetStore.ready && touch && this.props.appStore.clicked === false) showClickHint = true;

		if (this.props.appStore.stateError) {
			!touch || this.props.appStore.clicked ? (hide = true) : (showClickHint = true);
		}

		return (
			<PreloaderWrapper
				hide={hide}
				className={classNames}
				style={{
					backgroundSize: 'cover',
					backgroundImage:
						this.props.assetStore.initStart === false || (touch && this.props.assetStore.firstCurtainDropped === false)
							? 'url(/static_kkn/global/images/bg_start.jpg)'
							: ''
				}}
			>
				{this.props.sub !== true && showClickHint ? (
					<React.Fragment>
						<ClickOn
							stageRef="clickstart"
							width={256}
							height={256}
							onClicked={this.onStartClick}
							forceClick={true}
							pos={'iosStart'}
							scope={window['iosClickStart']}
							assets={this.props.assets}
							func={'iosClickStart'}
						/>
						<KknButton animateIn={true} image={null} type={'start'} icon={'start'} onClicked={this.onStartClick} size={'20'} />
					</React.Fragment>
				) : null}

				{!showClickHint && !this.props.assetStore.initStart ? <Logo src="/static_kkn/global/images/logo-kikaninchen.svg" /> : null}
				{!showClickHint ? (
					<ClickOn
						stageRef={this.props.stageRef || 'preloader'}
						pos={'top'}
						progress={checkedProgress}
						scope={window['globalPreloader']}
						assets={this.props.assets}
						func={'globalPreloader'}
					/>
				) : null}
			</PreloaderWrapper>
		);
	}
}

export default PreloaderView;
