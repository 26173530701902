import styled from 'styled-components';
import InputField from '../../basics/Primitives/InputField';
import { gfx, blueRabbitHand } from '../../../helpers/layoutVariables';

export const FullSizeWrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

	&.verticalScroll {
		padding: 0 3%;
	}
`;

export const SearchWrapper = styled.div`
  position: relative;
  height: 100%;
  padding: 5.5em 0 3.5em 0;

  &.verticalScroll {
    overflow-y: auto;

    /* width */

    ::-webkit-scrollbar {
      width: 0.25rem;
    }

    /* Track */

    ::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1);
    }

    /* Handle */

    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.6);
    }

    /* Handle on hover */

    ::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.8);
    }
  }
`;

export const ResultContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 1em;
	width: calc(100% - 2em);
	margin: 3em auto 0;

    .view-small & {
		gap: 1.5em;
		width: auto;
        margin: 3em 1.5em 0 1.5em;
    }
`;

export const SearchInputWrapper = styled.div`
    position: relative;
	display: flex;
	width: 60em;
	margin: 0 auto 1.8em;
	flex-direction: row;
	align-items: center;
	padding: 0.5em 0.5em 0.5em 2.4em;
	border-radius: 0.71em;
	background-color: #fff;

	.view-small & {
		width: auto;
		height: 8.158em;
		margin: 0 2.5em 1.5em;
		padding: 0 0.5em 0 2.4em;
		border-radius: 4.079em;
	}
`;

export const SearchInputField = styled(InputField)`
	flex: 1 1 auto;
`;

export const SubmitButton = styled.button`
	flex: 0 0 auto;
	min-width: 5.6259em;
	height: 1.969em;
	border-radius: 0.2em;
	font-size: 2.37em;
	line-height: 2.15;
	border: none;
	color: ${gfx.brown};
	background-color: ${gfx.yellow};
	text-transform: uppercase;
	${blueRabbitHand};
`;

export const LoadMoreContainer = styled.div``;

export const LoadMoreButton = styled.button`
	display: block;
	margin: 1.012em auto;
	padding: 0 1em;
	height: 1.968em;
	border-radius: 0.2em;
	font-size: 2.3712em;
	line-height: 2.15;
	border: none;
	color: ${gfx.brown};
	background-color: rgba(255,255,255,0.5);
	${blueRabbitHand};

	&:disabled {
		color: #5e420030;
	}
`;

export const FlexSpacer = styled.div`
	flex: 1 0 auto;
`;

export const Scrolltop = styled.div`
	position: absolute;
	bottom: -0.25em;
	right: 7em;
	width: 5.33em;
	height: 6.83em;
	padding: 0.083em 0 1em;
	box-shadow: 0 0 0.8em 0.4em rgb(95 65 0 / 75%);
	background-color: #5eb6c9;
	${blueRabbitHand};

	.view-small & {
		bottom: 0.5em;
		width: 10.88em;
		height: 10.88em;
	}
`;

export const NoSearchResults = styled.div`
	display: flex;
	justify-content: center;
	margin: 1em 0;
`;
export const NoResultsContainer = styled.div`
	position: relative;
	text-align: center;
	color: ${gfx.brown};
	font-size: 2.835em;
`;
export const Worm = styled.img`
	position: absolute;
	width: 3em;
	left: -3.25em;
	top: -0.75em;
`;
