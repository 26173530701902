/* @flow */
import { action, observable } from 'mobx';
import { getPath, isSessionStorageAllowed, Statics } from '../helpers/Utils';
import stores from './index';
import screenfull from 'screenfull';
import qs from 'query-string';

export default class AppStore {
	version = '1.6.3-1';
	@observable index = 0;
	@observable parentalGateOpen = null;
	@observable adultTimer = -1;

	@observable adultScrollerHeightUpdate = false;
	@observable width = 0;
	@observable scale = 1;
	@observable appPadding = 44;
	@observable forceLandscape = false;

	@observable adultTimerEnded = false;
	@observable stateError = false;
	@observable clicked = false;

	@observable adultLayerOpen = false;
	@observable adultTimerOpen = false;
	@observable adultSearchOpen = false;

	@observable showAdultTimerHint = false;

	@observable adultSubLayerData = null;

	@observable detailOpen = null;
	@observable interactiveContentOpen = false;
	@observable interactiveContentSub = false;
	@observable animationReady = false;
	@observable requestRenderUpdate = false;
	@observable showAdultHighlight = false;
	@observable clickonsActive = true;

	@observable settings = { tracking: { ati: true, nielsen: true } };
	@observable smallLoader = false;

	newRoute = null;
	referrer = '';
	lastRoute = -1;

	@observable isLoading = false;
	@observable isFullscreen = false;

	@observable animate = 'out';
	@observable contentScrollerIsBeginning = true;
	@observable contentScrollerIsEnd = false;
	@observable contentScrollerItemsLength = 6;
	@observable redrawSlider = false;

	error = false;
	pageIndex = 0;
	adultInt = 0;
	pageSize = 6;
	adultEndTime = 0;
	helpAudio = null;
	visitedTeaser = {};
	lastVisited = null;
	adultScroller = null;
	contentScroller = null;
	contentWrapper = null;
	appNode = null;

	resetContentScrollerNavi = () => {
		this.contentScrollerIsEnd = false;
		this.contentScrollerIsBeginning = false;
	};

	isSmallView = () => {
		return this.width < 768;
	};
	isVerySmallView = () => {
		return this.width < 540;
	};

	getCurrentQuery = () => {
		let query = qs.parse(stores.routingStore.location.search);
		return query;
	};
	adultTimerStart = payload => {
		this.adultTimer = -1;
		this.adultTimerEnded = false;
		this.showAdultTimerHint = true;
		this.adultEndTime = payload.time;
		stores.trackingStore.trackAtiClick({
			name: 'webwecker',
			path: 'adultLayer.webwecker',
			chapter1: 'Webwecker',
			chapter2: this.adultEndTime < 1 ? 'Aus' : this.adultEndTime / 60 + ' Minuten',
			toolName: 'webwecker',
			type: 'action'
		});
		if (this.adultEndTime > 0) {
			this.adultInt = setInterval(() => {
				this.raiseTimer();
			}, 1000);
			this.raiseTimer();
		}
		setTimeout(() => {
			this.showAdultTimerHint = false;
		}, 5000);
	};
	raiseTimer = () => {
		if (this.adultTimer >= this.adultEndTime - 1) {
			clearInterval(this.adultInt);
			this.adultTimerEnded = true;
			this.adultTimer = -1;
		}
		this.adultTimer++;
	};
	adultTimerStop = forceClear => {
		if (forceClear) {
			this.adultTimer = -1;
			this.adultTimerEnded = false;
			this.adultEndTime = 0;
			this.showAdultTimerHint = false;
		}

		clearInterval(this.adultInt);
	};

	getNotificationType = function(id, teaser) {
		let not = id;
		if (id === '' || teaser?.standard?.contentCategory === 'adultContent') return false;

		if (id.indexOf('info') !== -1) not = 'info';
		if (id.indexOf('download') !== -1) not = 'download';
		if (id.indexOf('recipe') !== -1) not = 'food';
		if (id.indexOf('games') !== -1) not = 'download';
		if (id.indexOf('print') !== -1) {
			not = 'download';
		}
		if (id.indexOf('competition' || 'contest' || 'win') !== -1) not = 'win';

		return not;
	};
	getNotificationText = function(id) {
		let txt = 'Download verfügbar';
		switch (id) {
			case 'tinker':
				txt = 'Bastelanleitung verfügbar';
				break;
			case 'song':
				txt = 'Liedtext verfügbar';
				break;
			case 'paint':
				txt = 'Ausmalbild verfügbar';
				break;
			case 'food':
				txt = 'Rezept verfügbar';
				break;
			case 'print':
				txt = 'Bild ausdrucken';
				break;
			case 'win':
				txt = 'am Gewinnspiel teilnehmen';
				break;
		}

		return `${txt}`;
	};

	getNotificationStyle = adultContent => {
		let not = '';
		if (adultContent && adultContent.data && adultContent.data.teaserBox && adultContent.data.teaserBox.teasers) {
			adultContent.data.teaserBox.teasers.map((teaser, i) => {
				let notTemp = this.getNotificationType(teaser.standard.contentCategory, teaser);
				if (not.length === 0 && notTemp && notTemp.length > 0) not = notTemp;
			});
			if (not.length === 0)
				adultContent.data.teaserBox.teasers.map((teaser, i) => {
					let notTemp = this.getNotificationType(teaser.notificationType === 'download' ? 'download' : '', teaser);
					if (not.length === 0 && notTemp && notTemp.length > 0) not = notTemp;
				});
		}
		if (adultContent && adultContent.externalData) {
			if (adultContent.externalData.length && adultContent.externalData.length > 0) {
				adultContent.externalData.slice().map((extTeaser, i) => {
					if (i === 0) {
						if (extTeaser.type === 'printRequest') not = 'print';
						if (extTeaser.type === 'download') not = 'download';

						if (extTeaser.type === 'link') not = 'link';
					}
					if (extTeaser.forceNotification) not = extTeaser.forceNotification;
				});
			} else {
				if (adultContent.externalData.type === 'printRequest') not = 'print';
				if (adultContent.externalData.type === 'download') not = 'download';
				if (adultContent.externalData.type === 'link') not = 'link';
				if (adultContent.externalData.forceNotification) not = adultContent.externalData.forceNotification;
			}
		}
		return not;
	};
	clearSoundCache = () => {
		window.createjs.Sound.removeAllSounds();
	};

	@action
	addHelpAudio = params => {
		this.helpAudio = params;
	};
	@action
	clearHelpAudio = () => {
		this.helpAudio = null;
	};

	@action
	toggleFullscreen = () => {
		screenfull.toggle();
	};

	@action
	getTrackingSettingsState = id => {
		return this.settings.tracking[id];
	};
	@action
	setTrackingSettingsState = (id, value) => {
		this.settings.tracking[id] = value;
		this.saveSettings();
	};
	@action saveSettings = () => {
		let worked = true;
		try {
			if (window.localStorage) window.localStorage.setItem('kikaSettings', JSON.stringify(this.settings));
		} catch (e) {
			worked = false;
		}
		return worked;
	};

	isInteractiveContent = data => {
		if (!data) return false;
		if (data.standard && data.standard.documentType === Statics.CONTENT_TYPE_INTERACTIVE) {
			return true;
		}
		if (data.document && data.document.documentType === Statics.CONTENT_TYPE_INTERACTIVE) {
			return true;
		}
		return false;
	};
	isImageGallery = data => {
		if (!data) return false;
		if (data.standard && data.standard.documentType === Statics.CONTENT_TYPE_MMG) {
			return true;
		}
		if (data.document && data.document.documentType === Statics.CONTENT_TYPE_MMG) {
			return true;
		}
		return false;
	};

	isIndexContent = data => {
		return !this.isInteractiveContent(data) && !this.isVideo(data) && !this.isImageGallery(data);
	};
	hasPageChanged = () => {
		return this.lastRoute !== this.newRoute;
	};
	isVideo = data => {
		if (!data) return false;
		if (
			data.standard &&
			(data.standard.documentType === Statics.CONTENT_TYPE_VIDEO ||
				data.standard.documentType === Statics.CONTENT_TYPE_EXTERNAL_VIDEO ||
				data.standard.documentType === Statics.CONTENT_TYPE_RELATED_VIDEO ||
				data.standard.documentType === Statics.CONTENT_TYPE_AUDIO)
		) {
			return true;
		}
		if (
			data.document &&
			(data.document.documentType === Statics.CONTENT_TYPE_VIDEO ||
				data.document.documentType === Statics.CONTENT_TYPE_EXTERNAL_VIDEO ||
				data.document.documentType === Statics.CONTENT_TYPE_RELATED_VIDEO ||
				data.document.documentType === Statics.CONTENT_TYPE_AUDIO)
		) {
			return true;
		}
		return false;
	};

	@action getSettings = () => {
		let worked = true;
		if (window.navigator && window.navigator.cookieEnabled === false) this.deactivateAllTracking();

		try {
			if (window.localStorage) {
				let savedData = window.localStorage.getItem('kikaSettings');
				if (savedData !== null) {
					this.settings = JSON.parse(savedData);
				} else {
					this.saveSettings();
				}
			}
		} catch (e) {
			worked = false;
			this.deactivateAllTracking();
		}
	};
	@action deactivateAllTracking = () => {
		this.settings.tracking = { ati: false, nielsen: false };
	};

	@action toggleAdultLayer = () => {
		this.clearParentalGate();
		if (this.adultTimerOpen) {
			this.adultTimerOpen = false;
			return false;
		}
		if (this.adultSearchOpen) {
			this.adultSearchOpen = false;
			return false;
		}
		this.adultLayerOpen === true ? this.closeAdultLayer() : this.openAdultLayer();
		if (this.adultLayerOpen === false) {
			this.showParentalGate({ open: false, type: 'adultTimer' });
		}
		const docData = stores?.assetStore?.docData;
		if (docData) {
			if (docData?.breadcrumb && docData.document?.documentType === 'download') {
				stores.routingStore.push(docData?.breadcrumb[docData?.breadcrumb.length - 1].url);
			}
		}
	};
	@action toggleSearch = () => {
		this.clearParentalGate();
		if (this.adultSearchOpen) {
			this.closeAdultLayer();
		} else {
			this.adultSearchOpen = true;
			this.adultTimerOpen = false;
			if (!this.adultLayerOpen) this.openAdultLayer();
		}
	};
	getAppPadding = () => {
		let btnSize = 44;
		if (this.isSmallView()) btnSize = 32;
		return btnSize;
	};

	@action
	openAdultLayer = () => {
		this.adultLayerOpen = true;
		window.$('body').addClass('standardCursor');
		window.$('.avPlayerWrapper').addClass('adultOpen');
		let atiTracking = stores.assetStore.docData && stores.assetStore.docData.document && stores.assetStore.docData.document.atiTracking;

		stores.trackingStore.trackView({
			name: 'adultLayer',
			atiData: atiTracking,
			path: 'adultLayer',
			toolName: 'adultLayer'
		});
		window.$('body').trigger('stopSound');
		window.kknApp.pauseGlobalPlayer(true);
		window.$('body').trigger('kknApp.adultLayerOpen');
	};
	@action
	closeAdultLayer = () => {
		window.$('body').removeClass('standardCursor');

		this.adultLayerOpen = false;
		this.adultSearchOpen = false;
		this.adultTimerOpen = false;
		setTimeout(() => {
			window.$('.avPlayerWrapper').removeClass('adultOpen');
			window.kknApp.playGlobalPlayer(true);
			window.$('body').trigger('restartSound');
		}, 600);

		window.$('.avPlayerWrapper').removeClass('adultOpen');
		window.$('body').trigger('kknApp.adultLayerClosed');
	};

	@action prevPage = () => {
		if (this.pageIndex > 0) {
			this.onNavigateToPage(this.pageIndex - 1);
		}
	};

	getMaxPages = () => {
		let length = 0;
		if (stores.assetStore.docData && stores.assetStore.docData.document && stores.assetStore.docData.document.teaserBoxes) {
			length = stores.assetStore.docData.document.teaserBoxes.length;
		}
		if (stores.assetStore.isBundle() === true) {
			let merged = stores.assetStore.getMergedItems().length;
			Math.ceil(merged / this.pageSize);
		}

		return length;
	};

	@action nextPage = () => {
		let length = stores.assetStore.docData.document.teaserBoxes.length;
		let temp = 0;
		if (stores.assetStore.isBundle() === true) {
			length = stores.assetStore.getMergedItems().length;
			temp = (this.pageIndex + 1) * this.pageSize;
		}

		if ((stores.assetStore.isBundle() === true ? temp : this.pageIndex) < length - 1) {
			this.onNavigateToPage(this.pageIndex + 1);
		}
	};
	onNavigateToPage = (data, params) => {
		this.animate = 'out';

		let timeout = 0;
		if (params && params.type === 'round' && params.boxClassifier !== 'broadcastStart') {
			timeout = 0;
		}
		this.smallLoader = false;

		if (params && params.boxClassifier === 'broadcastStart') {
			this.smallLoader = true;
		}

		clearTimeout(this.int);
		this.int = setTimeout(() => {
			if (
				data.url === undefined ||
				data.standard.documentType === Statics.CONTENT_TYPE_INTERACTIVE ||
				data.standard.documentType === Statics.CONTENT_TYPE_MMG ||
				data.standard.documentType === Statics.CONTENT_TYPE_EXTERNAL_VIDEO ||
				data.standard.documentType === Statics.CONTENT_TYPE_RELATED_VIDEO ||
				data.standard.documentType === Statics.CONTENT_TYPE_VIDEO ||
				data.standard.documentType === Statics.CONTENT_TYPE_AUDIO
			) {
				var page = this.pageIndex;
				var id = '';
				if (typeof data === 'number') {
					page = data;
				} else {
					id = '&detailId=' + data.standard.sophoraId;
				}
				stores.routingStore.push(getPath(stores.routingStore, '?page=' + page + id));

				return false;
			}

			if (data.url !== undefined) stores.routingStore.push(data.url);
		}, timeout);
	};

	@action onFullscreenChanged() {
		this.isFullscreen = (window.screenfull && window.screenfull.isFullscreen) || false;
	}

	@action
	showParentalGate = params => {
		this.parentalGateOpen = params;
	};
	@action
	redirectAfterParentalCheck = () => {
		var url = this.parentalGateOpen.url;
		var target = this.parentalGateOpen.target;
		if (this.parentalGateOpen.type === 'adultTimer') {
			let atiTracking = stores.assetStore.docData.document.atiTracking;
			stores.trackingStore.trackView({
				name: 'webwecker',
				atiData: atiTracking,
				path: 'adultLayer.webwecker',
				toolName: 'webwecker'
			});

			this.adultSearchOpen = false;
			this.adultTimerOpen = true;
			this.openAdultLayer();
			this.clearParentalGate();
			return false;
		} else {
			setTimeout(() => {
				stores.trackingStore.trackAtiClick({
					name: 'externer Link',
					chapter1: this.parentalGateOpen.data.secondTitle || '',
					chapter2: url,
					action: 'exit',
					context: 'adultLayer'
				});

				this.clearParentalGate();
			}, 500);
		}

		setTimeout(() => {
			if (target && typeof target === 'string' && target.indexOf('blank') !== -1) {
				window.open(url);
			} else {
				document.location.href = url;
			}
		}, 1000);
	};
	@action
	clearParentalGate = () => {
		this.parentalGateOpen = null;
	};

	@action
	addVisitedTeaser = payload => {
		this.lastVisited = payload;
		this.visitedTeaser[payload.id] = 'true';
		if (typeof window !== 'undefined' && isSessionStorageAllowed())
			window.sessionStorage.setItem('kknVisitedTeaser', JSON.stringify(this.visitedTeaser));
	};
	@action
	clearVisitedTeaser = () => {
		this.visitedTeaser = {};
	};
	addAnimations = () => {
		this.animationReady = true;
	};
}
