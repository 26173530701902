import styled from 'styled-components';
import { DetailAnimationFlavour } from 'helpers/layoutVariables';

export const Container = styled.div`
	width: 92%;
	height: auto;
	position: absolute;
	top: 0;
	left: 0;
	min-height: 74.25%;
	z-index: 2 !important;
	${DetailAnimationFlavour}

	&.enter-active,
	&.enter-done {
		transform: translate3d(4%, 0, 0);
		> * {
			transform: scale(1);
		}
	}
`;

export const MainImage = styled.div`
  position: relative;
  cursor: pointer;
  z-index: 2;
  width: 100%;

  .play-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

export const SongBackground = styled.img`
	display: block;
	position: absolute;
	width: 100%;
	top: 90%;
	left: 0;
`;
