import React, { Component } from 'react';
import styled from 'styled-components';
import { gfx } from 'helpers/layoutVariables';
import { getIconUrl, isTouch } from 'helpers/Utils';

const Wrapper = styled.div`
	width: 100%;
	position: relative;
	background-color: #fff;
    border-radius: 0.71em;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

	.view-small & {
		border-radius: 4.079em;
	}
}
`;
const Input = styled.input`
	max-width: 100%;
	width: 100%;
	height: 2em;
	border: 0;
	padding: 0 2.4em 0 0.514em;
	margin: 0;
	font-size: 2.3333em;
	color: ${gfx.brown};
	outline: 0;
	line-height: 2.3333em;
	background-color: transparent;

	.view-small & {
		height: 2.399em;
		font-size: 3.4em;
		line-height: 3.4em;
		padding: 0 2.082em 0 0.379em;
	}
`;

const PlaceHolderLabel = styled.label`
	position: absolute;
	top: 50%;
	transform: translate3d(0, -50%, 0);
	left: 0.514em;
	pointer-events: none;
	font-size: 2.3333em;
	color: ${gfx.brown};

	.view-small & {
		font-size: 3.4em;
		left: 0.379em;
	}
`;

const ClearButtonWrapper = styled.div`
	right: 1.8em;
	top: 50%;
	position: absolute;
	transform: translate3d(0, -50%, 0);
	width: 2.7em;
	height: 2.7em;
	display: flex;
	align-items: center;
	justify-content: center;
	> img {
		width: 100%;
	}

	.view-small & {
		right: 1.93em;
		width: 2.9em;
		height: 2.9em;
	}
`;

export default class InputField extends Component {
	inputRef = null;
	initFocus = false;
	state = {
		focus: false,
		value: ''
	};
	int = 0;
	constructor(props) {
		super(props);
		this.inputRef = React.createRef();
	}

	componentDidMount = () => {

		// console.log('++++++ componentDidMount');

		clearTimeout(this.int);

		this.int = setTimeout(() => {

			// console.log('++++++ Mount Timeout touch=' + isTouch() + ' requestFocus=' + this.props.requestFocus + ' focus=' + this.state.focus);
			// console.log(this.inputRef);
			// console.dir(this.inputRef);

			// !isTouch() stopped working on chrome, seems to work on live page
			if (this.props.requestFocus === true && this.state.focus === false && this.inputRef.current) {
				// console.log('-- set Focus');

				this.inputRef.current.focus();
			}
		}, 500);
	};

	componentDidUpdate = () => {
		// clearTimeout(this.int);

		// console.log(
		// 	'--> componentDidUpdate: isTouch=' +
		// 		isTouch() +
		// 		' props.requestFocus=' +
		// 		this.props.requestFocus +
		// 		' this.state.focus=' +
		// 		this.state.focus +
		// 		' this.state=' +
		// 		this.state
		// );
		// console.dir(this.state);

		// this.int = setTimeout(() => {
		// 	// !isTouch() stopped working on chrome, seems to work on live page
		// 	if (this.props.requestFocus === true && this.state.focus === false && this.inputRef.current) {
		// 		this.inputRef.current.focus();
		// 	}
		// }, 1000);
	};

	getLabel = () => {
		if (this.props.value && this.props.value.length > 0) return null;
		if (this.state.focus === true && this.state.value.length > 0) return null;
		if (this.state.focus === false && this.state.value.length > 0) return null;

		if (this.props.placeHolder === true) {
			return <PlaceHolderLabel htmlFor={this.props.id}>{this.props.label}</PlaceHolderLabel>;
		}
		return <label htmlFor={this.props.id}>{this.props.label}</label>;
	};
	onFocused = () => {

		// console.log('----- set focus: true');

		this.setState({ focus: true });
		this.props.onFocused(true);
	};
	onBlur = () => {

		// console.log('----- set focus: false');

		this.setState({ focus: false });
		this.props.onFocused(false);
	};
	setValue = evt => {
		this.propagateValue(evt.target.value);
	};
	clearInput = evt => {
		evt.preventDefault();
		this.propagateValue('');
		this.props.onClear();
		return false;
	};
	propagateValue = value => {
		// remove?? -> this causes react to render the component twice and maked the curser jump to the end
		// this.setState({ value: value }, () => {
		// 	this.props.onChanged(value);
		// });
		this.props.onChanged(value);
	};

	render = () => {
		let value = this.props.value || this.state.value;
		return (
			<Wrapper className={this.props.className}>
				{this.getLabel()}
				<Input
					ref={this.inputRef}
					onKeyUp={this.props.onKeyUp}
					onKeyDown={this.props.onKeyDown}
					onFocus={this.onFocused}
					onBlur={this.onBlur}
					type={this.props.type || 'text'}
					id={this.props.id}
					autoComplete="off"
					name={this.props.name}
					value={value}
					maxLength={this.props.maxLength}
					onClick={this.props.onClick}
					onChange={this.setValue}
				/>
				{this.props.hasClearButton && value.length > 0 ? (
					<ClearButtonWrapper onClick={this.clearInput}>
						<img src={getIconUrl('search-reset.svg')} />
					</ClearButtonWrapper>
				) : null}
			</Wrapper>
		);
	};
}
