import React from 'react';
import { SearchFilterWrapper, FilterButton, FilterIcon } from './SearchFilterStyle';

export interface ISearchFilter {
	isDisabled: boolean | string;
	filterText: string;
	filterKey: string;
	active: boolean | string;
	url: string;
	numberOfDocuments: string;
	icon?: string;
}

interface ISearchFilterProps {
	filters: Array<ISearchFilter>;
    currentFilter: string;
    onFilterClick: (url: string, filterKey: string) => void;
}

const SearchFilter = (props: ISearchFilterProps) => {

	// translate filter ids from json to svg ids
	const filterIds = {
		'filter-video': 'play',
		'filter-song': 'song',
		'filter-tinker': 'tinker',
		'filter-adultContent': 'adult',
		'filter-games': 'game',
		'filter-recipe': 'food',
		'filter-paint': 'paint',
		'filter-kika': null
	};

	return (
		<>
			<SearchFilterWrapper>
				{props.filters.map(filter => {
					const iconId = filterIds[filter.filterKey];

					return (
						<FilterButton
                            key={filter.filterKey}
                            // active={filter.active === "true" || filter.active === true ? true : false}
                            active={filter.filterKey === props.currentFilter}
                            isDisabled={filter.isDisabled === "true" || filter.isDisabled === true ? true : false}
                            onClick={() => {props.onFilterClick(filter.url, filter.filterKey)}}
                            // conditional operator doesn't work, disabled pointer-events in css
                            // onClick={!filter.isDisabled ? () => {props.onFilterClick(filter.url, filter.filterKey)} : undefined}
                        >
							{iconId &&
								<FilterIcon code={iconId} />
							}
							{filter.filterText}
							{ /* filter.numberOfDocuments */ }
						</FilterButton>
					);
				})}
			</SearchFilterWrapper>
		</>
	);
};

export default SearchFilter;
