import styled from 'styled-components';
import { moveInFromRight, moveLeft } from 'helpers/layoutVariables';

export const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	padding: 0;
	padding-left: 0;
	z-index: 4;
	width: 100%;
`;

export const Navi = styled.div`
	display: flex;
	left: 1.5em;
	top: 3em;
	position: absolute;

	&.detailOpenNav {
		top: 1.666em;
		min-width: 15em;
		display: block;

		> img {
			position: absolute;
			top: -28%;
		}
	}
`;

export const NaviBg = styled.img`
	left: -1em;
	width: 16em;
	position: absolute;
	animation-fill-mode: forwards;

	animation: ${moveLeft} 0.3s ease-out;
`;

export const AdultBg = styled.img`
	right: -120em;
	width: 14em;
	position: absolute;
	animation-fill-mode: forwards;

	animation: ${moveInFromRight} 0.3s ease-out;
`;
