import styled, { css, keyframes } from 'styled-components';

export const gfx = {
	cursor: '/static_kkn/global/cursor/auto.cur',
	cursorHand: '/static_kkn/global/cursor/pointer.cur',
	cursorHandPressed: '/static_kkn/global/cursor/click.cur',
	textColor: '#333',
	fontSizeBase: '14px',
	fontStandard: 'Helvetica Neue Helvetica, Arial, sans-serif',
	fontKkn: 'Prater',
	deepBlue: '#0f5980',
	lightBlue: '#acddf4',
	orange: '#ff7b00',
	brown: '#5e4200',
	yellow: '#ffc300',
	blue: '#55b4cd',
	vanille: '#fff6d1',
	fontSizeCopyText: '1.875em',
	fontSizeHeadline: '3.5em',
	fontSizeBigHeadline: '4.5em',
	shadowColor: 'rgba(95,65,0,.75)'
};

export const blueRabbitHand = css`
	cursor: url(${gfx.cursorHand}), auto;
	html.kkn-pressed & {
		cursor: url(${gfx.cursorHandPressed}), auto !important;
	}
`;

export const DetailAnimationFlavour = css`
	transition: all 0.6s ease-out;
	transform-origin: 0 0;

	> * {
		transition: all 0.6s ease-out;
		transform-origin: 0 0;
	}
	transform: translate3d(${(props) => (props.x ? props.x : 0)}, ${(props) => (props.y ? props.y : 0)}, 0);
	> * {
		transform: scale(${(props) => (props.scaleVal ? props.scaleVal : 0)});
	}

	&.enter {
		transform: translate3d(${(props) => (props.x ? props.x : 0)}, ${(props) => (props.y ? props.y : 0)}, 0);
		> * {
			transform: scale(${(props) => (props.scaleVal ? props.scaleVal : 0)});
		}
	}
	&.enter-active,
	&.enter-done {
		transform: translate3d(0, 0, 0);
		> * {
			transform: scale(1);
		}
	}
	&.exit,
	&.exit-active {
		transform: translate3d(0, -260%, 0);
	}
`;

export const blendIn = keyframes`
	0% {
		opacity:0;
	}
	10% {
		opacity:1;
	}
`;
export const blendOut = keyframes`
	0% {
		opacity:1;
	}
	10% {
		opacity:0;
	}
`;
export const scaleIn = keyframes`
	0% {
		transform:scale(0);
	}
	100% {
		transform:scale(1);
	}
`;

export const moveToCenter = keyframes`
	0% {
		transform: scale(0.75);
	}
	50% {
		transform: scale(1);
	}

	75% {
		
		transform: scale(.9);
	}
	100% {
	

		transform: scale(1);
	}

`;

export const moveInStandardLeft = keyframes`
	0% {
	transform: translate3d(-100%,0, 0);
	}
	100% {
	  transform: translate3d(0, 0, 0);
	}
  `;

export const moveInFromTop = keyframes`
	0% {
	transform: scale(1) translate3d(0, -100em, 0);
	}
	1% {
	  transform: scale(1) translate3d(0, -100em, 0);
	}
	100% {
	  transform: scale(1) translate3d(0, 0, 0);
	}
  `;

export const moveInFromLeft = keyframes`
	0% {
	  transform: scale(1) translate3d(-100em, 0, 0);
	}
	100% {
	  transform: scale(1) translate3d(0, 0, 0);
	}
  }`;

export const moveInFromRight = keyframes`
0% {
  transform:translate3d(100%, 0, 0);
}
100% {
  transform: translate3d(0, 0, 0);
}
}`;

export const moveOutToLeft = keyframes`
	0% {
	  transform: scale(1) translate3d(0, 0, 0);
	}
	100% {
	  transform: scale(1) translate3d(-100em, 0, 0);
	}
  `;

export const moveInFromBottom = keyframes`
	0% {
	  transform: scale(1) translate3d(0, -100em, 0);
	}
	1% {
	  transform: scale(1) translate3d(0, 100em, 0);
	}
	100% {
	  transform:scale(1)  translate3d(0, 0, 0);
	}`;

export const moveOutToTop = keyframes`
	0% {
	  transform: scale(1) translate3d(0, 0, 0);
	}
	100% {
	  transform: scale(1) translate3d(0, -100em, 0);
	}
  `;

export const moveOutToBottom = keyframes`
	0% {
	  transform: scale(1) translate3d(0, 0, 0);
	}
	100% {
	  transform: scale(1) translate3d(0, 100em, 0);
	}
  `;

export const MouseHandButton = styled.button`
	${blueRabbitHand}
`;
export const MouseHandLink = styled.a`
	${blueRabbitHand}
`;

export const moveIn = keyframes`
	0% {
		transform: translate3d(0, 100%, 0);
	}
	100% {
		transform: translate3d(0, 0, 0);
	}
`;

export const moveRight = keyframes`
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(-50%, 0, 0);
	}
`;
export const moveUp = keyframes`
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(0, 100%, 0);
	}
`;

export const moveDown = keyframes`
	0% {
		transform: translate3d(0, 100%, 0);
	}
	100% {
		transform: translate3d(0, 0, 0);
	}
`;

export const moveUpToDown = keyframes`
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(0, -100%, 0);
	}
`;
export const moveDownToUp = keyframes`
	0% {
		transform: translate3d(0, -100%, 0);
	}
	100% {
		transform: translate3d(0, 0, 0);
	}
`;

export const moveLeft = keyframes`
	0% {
		transform: translate3d(-50%, 0, 0);
	}
	100% {
		transform: translate3d(0, 0, 0);
	}
`;
