import styled, { css, keyframes } from 'styled-components';

const color = '#074f77';

const spinnerAni = keyframes`

	0%,
	100% {
		box-shadow: 2vw 2vw #55b4cd, -2vw 2vw #074f77, -2vw -2vw #55b4cd, 2vw -2vw #074f77;
	}

	25% {
		box-shadow: -2vw 2vw #074f77, -2vw -2vw #55b4cd, 2vw -2vw #074f77, 2vw 2vw #55b4cd;
	}

	50% {
		box-shadow: -2vw -2vw #55b4cd, 2vw -2vw #074f77, 2vw 2vw #55b4cd, -2vw 2vw #074f77;
	}

	75% {
		box-shadow: 2vw -2vw #074f77, 2vw 2vw #55b4cd, -2vw 2vw #074f77, -2vw -2vw #55b4cd;
	}

`;

export const AppWrapper = styled.div`
	position: relative;
	width: 1920px;
	height: 1080px;
	margin: 0 auto 0 0;

	.kkn-buffer {
		z-index: 1000;
		position: absolute;
		margin: auto;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		border-radius: 100%;
		width: 2vw;
		height: 2vw;
		box-shadow: 2vw 2vw #55b4cd, -2vw 2vw #074f77, -2vw -2vw #55b4cd, 2vw -2vw #074f77;
		animation: ${spinnerAni} ease infinite 4s;
	}

	.fa {
		font-size: 2em;
		line-height: 20px;
	}
`;

export const TopMenu = styled.div`
	position: absolute;
	right: 0;
	top: 0;
	max-width: 100%;
	width: 100%;
	border: 0;
	z-index: 10;
	display: flex;
	transform-origin: 0 0;
	transform: translate3d(0, -95%, 0);
	justify-content: ${(props) => (props.variant !== 'adultDocState' ? 'flex-start' : 'center')};

	${(props) =>
		props.pos === 'right' &&
		css`
			position: absolute;
			border: 0;
			display: flex;
			right: ${(props) => (props.isSmall ? '-30px' : '-44px')};
			top: 0;
			height: auto;
			justify-content: flex-start;
			width: 0;
			transform: rotate(90deg);
			z-index: 1000;
		`}
`;

export const TurnHint = styled.div`
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1000;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ffc300;
	p {
		font-size: 4em;
	}
`;

export const InnerWrapper = styled.div`
	position: relative;
	width: 100%;
	overflow: hidden;
	height: 100%;
`;
export const Frame = styled.img`
	position: absolute;

	${(props) =>
		(props.type === 'left' || props.type === 'right') &&
		css`
			height: 104%;
			z-index: 7;
			right: -1px;
			top: -2%;
		`}

	${(props) =>
		props.type === 'left' &&
		css`
			right: auto;
			left: -1px;
			transform: rotate(-180deg);
		`}
	
	${(props) =>
		props.type === 'top' &&
		css`
			top: -1px;
			left: 0;
			z-index: 7;
			transform: rotate(-180deg);
			position: absolute;
			width: 100%;
		`}

	${(props) =>
		props.type === 'bottom' &&
		css`
			top: auto;
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 7;
		`}
`;
