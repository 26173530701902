import React, { Component } from 'react';
import Preloader from 'helpers/Preloader';
import MediaPlayer from 'components/kits/MediaPlayer/MediaPlayer';
import TransitionBackground from 'components/kits/TransitionBackground/TransitionBackground';

import { getImageUrl, logger, pixelize, Statics } from 'helpers/Utils';

import { inject, observer } from 'mobx-react';
import PreloaderView from 'components/kits/PreloaderView/PreloaderView';
import ImageGallery from 'components/kits/ImageGallery/ImageGallery';
import { ExternalWrapper, MainImage, Outer, PreloaderWrapper, Wrapper } from './InteractiveContentStyle';
import { CSSTransition } from 'react-transition-group';

@inject('assetStore')
@inject('appStore')
@inject('routingStore')
@inject('trackingStore')
@observer
class InteractiveContent extends Component {
	target = null;
	data = {};
	playerData = {};
	docData = {};
	isUnmounted = false;
	instantOpen = true;
	firstOpen = false;

	styleRef = {};
	state = {
		loaded: false,
		extLoaded: false,
		extProgress: 0,
		showLoader: false,
		isIframe: false,
		showPlayer: false,
		scaleVal: 1,
		x: 0,
		y: 0,
		moveIn: false
	};

	componentDidMount() {
		if (this.props.appStore.isInteractiveContent(this.props.data) === false) return false;

		window.$('body').on('kknApp.resized', this.resizeExternal);
		window.$('body').on('kknApp.locked', this.pauseExternal);
		window.$('body').on('kknApp.adultLayerOpen', this.pauseExternal);
		window.$('body').on('kknApp.adultLayerClosed', this.startExternal);

		this.styleRef = { y: 0, x: pixelize('50em'), width: 1 };
		let scaleVal = 1;

		if (this.props.changeNavi !== false) this.props.appStore.detailOpen = { type: 'interactiveContent' };

		if (this.props.styleRef && this.props.styleRef.type !== 'round') {
			this.styleRef = this.props.styleRef;
			this.instantOpen = false;
		}
		if (typeof this.styleRef.y === 'number') {
			scaleVal = this.styleRef.width / pixelize('110.4em');
			this.setState({
				scaleVal: scaleVal,
				y: String(this.styleRef.y) + 'px',
				x: String(this.styleRef.x) + 'px',
				moveIn: true
			});
		} else {
			this.setState({
				scaleVal: 1,
				y: '0',
				x: '0',
				moveIn: true
			});
		}
	}

	componentDidUpdate = () => {
		if (this.props.appStore.isInteractiveContent(this.props.data) === false) return false;
		if (this.firstOpen === true) return false;

		if (this.firstOpen === false) {
			this.firstOpen = true;

			setTimeout(
				() => {
					if (this.isUnmounted) return false;
					document.addEventListener('visibilitychange', this.handleVisibilityChange);
					if (this.props.changeNavi !== false)
						this.props.appStore.detailOpen = {
							type: 'interactiveContent',
							isSingleDeeplink: this.props.injectedDetailInfos ? true : false
						};

					if (this.refs.iframe) {
						let iframe = this.refs.iframe.contentWindow;
						if (iframe !== undefined) iframe.postMessage('hello there!', '*');
					}
					window.addEventListener('message', this.receiveMessage, false);
					this.loadContent();
				},
				!this.props.clicked || this.instantOpen ? 1000 : 0
			);
		}
	};

	receiveMessage = (e) => {};

	loadContent = (p) => {
		this.firstOpen = true;
		if (this.isUnmounted) return false;
		this.setState({ showLoader: true });
		this.props.appStore.clearSoundCache();
		logger('#external loads detail json');
		let props = p || this.props;

		if (props.injectedDetailInfos) {
			this.afterDetailInfosLoaded(props.injectedDetailInfos);
			return false;
		}
		let preloader = new Preloader();
		let items = [
			{
				id: 'content',
				type: 'json',
				src: props.data.url.split('.html').join('.json')
			}
		];
		preloader.load({
			cb: (contentEvt, content) => {
				this.afterDetailInfosLoaded(content.getResult('content'));
			},
			url: items
		});
	};
	afterDetailInfosLoaded = (data) => {
		this.docData = data;
		this.props.assetStore.layerDocData = this.docData;
		logger('#external init:', data);

		this.updateAdultLayer(this.docData.document.parentalTeaserBox || null);

		if (this.docData.document.documentProperties.contentEmbedMode !== 'iframe') {
			this.loadConfig(this.docData);
		} else {
			this.afterAllDataFetched(true);
			//this.setState({ showLoader: false });
			this.setState({ extProgress: 1 });
			setTimeout(() => {
				this.setState({ showLoader: false, extProgress: 1 });
			}, 1500);
		}
	};

	loadConfig = (data) => {
		if (this.isUnmounted) return false;

		logger('#external loads config json');
		let url =
			data.document.documentProperties.contentUrl && data.document.documentProperties.contentUrl.length > 0
				? data.document.documentProperties.contentUrl
				: '/static_kkn/global/interactiveContent/';
		let preloader = new Preloader();
		let items = [
			{
				id: 'content',
				type: 'json',
				src: url + data.document.documentProperties.contentId + '/config.json'
			}
		];
		preloader.load({
			cb: (contentEvt, content) => {
				this.data = content.getResult('content');
				this.loadScript();
			},
			url: items
		});
	};
	loadScript = () => {
		if (this.isUnmounted) return false;
		if (this.data === undefined || this.data === null) this.afterAllDataFetched();

		let preloader = new Preloader();
		let items = [];

		logger('#external load external libs', this.data.externalLibs);
		if (this.data.externalLibs && this.data.externalLibs.length > 0) {
			let ids = this.data.externalLibs.split(',');
			ids.map((id, i) => {
				if (window.kknApp.externalLibs[id] !== undefined) {
					items.push({ id: id, src: window.kknApp.externalLibs[id] });
				}
			});
		}
		items.push({ id: 'src', src: this.data.externalAppSource });
		if (items.length > 0) {
			preloader.load({
				cb: (contentEvt, content) => {
					this.afterAllDataFetched();
				},
				url: items
			});
		} else {
			this.afterAllDataFetched();
		}
	};

	updateAdultLayer = (data) => {
		// update adult layer if needed
		if (data === null) return false;
		let d = data.externalData ? { externalData: data.externalData } : { data: { teaserBox: data[0] || null } };

		this.adultData = d;
		this.props.assetStore.addAdultInfo(this.adultData, data.externalData ? 'externalData' : null);
	};

	afterAllDataFetched = (isFrame) => {
		if (this.isUnmounted) return false;

		let type = this.docData.document.documentType;

		this.props.trackingStore.trackLayerView({
			name: type,
			atiTracking: this.docData.document.atiTracking
		});

		this.setState({ loaded: true, isIframe: isFrame });

		if (isFrame === true) return false;

		if (this.data !== undefined) {
			window.kknApp.externals[this.data.externalAppScope] = new window[this.data.externalAppScope][
				this.data.externalAppConstructor
			]();

			window.kknApp.externals[this.data.externalAppScope].setup({
				target: this.refs.wrapper,
				configData: this.data,
				contentData: this.docData,
				externalRequestUpdate: this.onExternalUpdate,
				tracking: this.onTracking,
				width: window.kknApp.width,
				height: window.kknApp.height,
				scale: window.kknApp.scale,
				onLoadProgress: this.onExtLoadProgress,
				onLoadSuccess: this.onExtLoadSuccess,
				isSmallView: window.kknApp.isSmallView,
				showPlayer: this.onPlayerShowRequest,
				showContent: this.onContentRequest,
				addCursorBehaviour: this.addCursorBehaviour
			});
		}
	};
	addCursorBehaviour = (target) => {
		window.kknApp.addCursorBehaviour(target);
	};
	onContentRequest = (vo) => {
		// if(vo.url) this.props.routingStore.push(vo.url);
		let docType = vo.standard.documentType;
		if (docType.indexOf(Statics.CONTENT_TYPE_VIDEO) !== -1 || docType.indexOf(Statics.CONTENT_TYPE_MMG) !== -1) {
			this.openDetailLayer(vo);
			return false;
		}
		if (vo.url) this.props.routingStore.push(vo.url);
	};

	// deprecated but used in production

	onPlayerShowRequest = (vo) => {
		this.playerData = {
			special: {
				avCustomUrl: vo.url
			},
			standard: {
				contentCategory: 'video',
				teaserImage: {},
				documentType: 'video'
			}
		};
		if (this.docData.document.teaserBoxes)
			this.docData.document.teaserBoxes.map((data, i) => {
				data.teasers.map((teaser, index) => {
					let adultInfos = [];
					//@todo: check disabledParentalInfos:"download, parentalHint, link",
					if (teaser.parentalTeaserBox && teaser.parentalTeaserBox[0] && teaser.parentalTeaserBox[0].teasers)
						teaser.parentalTeaserBox[0].teasers.map((data, i) => {
							if (data.notificationType && vo.disabledParentalInfos.indexOf(data.notificationType) !== -1) {
							} else {
								adultInfos.push(data);
							}
						});
					teaser.parentalTeaserBox = adultInfos;
					if (teaser.special && teaser.special.avCustomUrl && teaser.special.avCustomUrl === vo.url) {
						this.playerData = teaser;
					}
				});
			});
		if (this.props.changeNavi !== false)
			this.props.appStore.detailOpen = {
				type: 'interactiveContent',
				externalSub: true,
				deprecatedPlayerApi: true,
				externalSubData: this.playerData,
				isSingleDeeplink: this.props.injectedDetailInfos ? true : false
			};
		this.setState({
			showPlayer: true
		});
		this.sendStateToExtApp('onPlayerOpen');
	};
	openDetailLayer = (vo) => {
		this.props.appStore.detailOpen = {
			type: 'interactiveContent',
			externalSub: true,
			externalSubData: vo,
			isSingleDeeplink: false
		};
		this.sendStateToExtApp('onContentOpen');
	};
	getDetailOpenDocType = () => {
		return (
			(this.props.appStore.detailOpen &&
				this.props.appStore.detailOpen.externalSubData &&
				this.props.appStore.detailOpen.externalSubData.standard &&
				this.props.appStore.detailOpen.externalSubData.standard.documentType) ||
			''
		);
	};

	onTracking = (vo) => {
		if (this.isUnmounted) return false;
		logger('#external called tracking', vo);
		let type = this.props.assetStore.layerDocData.document.documentType;
		let atiTracking = this.props.assetStore.layerDocData.document.atiTracking;
		this.props.trackingStore.trackLayerView({ atiTracking: atiTracking, page: vo.name, toolName: type });
	};
	onExtLoadProgress = (vo) => {
		if (this.isUnmounted) return false;
		if (vo.progress && vo.progress < 1) {
			if (this.state.showLoader === false) this.setState({ showLoader: true });
			this.setState({ extProgress: vo.progress, transitionAnimation: 'open' });
			window.$('body').trigger('kknApp.preload', {
				reference: 'external',
				progress: vo.progress
			});
		}
	};
	onExtLoadSuccess = () => {
		if (this.isUnmounted) return false;
		this.setState({ extLoaded: true, showLoader: false, transitionAnimation: 'hide' });
		window.kknApp.forceAnimateStop = true;

		let ext = window.kknApp.externals[this.data.externalAppScope];
		if (ext) ext.start();
	};

	onExternalUpdate = (vo) => {
		if (this.isUnmounted) return false;
		logger('#external update sends:', vo);
		if (vo.type === 'contest') {
			this.props.assetStore.clearAdultInfo();

			this.getContestData(vo);
			return false;
		}
		let data = vo;
		this.adultData = { externalData: data };
		this.updateAdultLayer(this.adultData);
	};
	getContestData = async (vo) => {
		let responseForm = await fetch(vo.contestDescriptionURL, {
			method: 'get'
		}).catch(() => {});
		let responseSuccess = await fetch(vo.responseURL, {
			method: 'get'
		}).catch(() => {});
		const data = responseForm && (await responseForm.text());
		const dataSuccess = responseSuccess && (await responseSuccess.text());
		let teaser = [];
		if (data) {
			teaser.push({
				type: 'htmlInject',
				html: String(data)
			});
		}
		if (dataSuccess) {
			teaser.push({
				type: 'htmlInject',
				teaserStyling: 'contestResponseText',
				html: String(dataSuccess)
			});
		}
		teaser.push({
			type: 'link',

			special: { linkUrl: vo.formUrl },
			standard: {
				teaserImage: {
					title: vo.teaserImageTitle,
					urlScheme: vo.teaserImageURL
				},
				title: vo.contestTitle
			},
			linkType: 'form',
			linkTarget: '_self',
			forceNotification: 'contest'
		});
		this.updateAdultLayer({ externalData: teaser });
	};

	componentWillUnmount() {
		this.isUnmounted = true;
		window.kknApp.forceAnimateStop = false;
		document.removeEventListener('visibilitychange', this.handleVisibilityChange);
		window.$('body').off('kknApp.resized', this.resizeExternal);
		window.$('body').off('kknApp.locked', this.pauseExternal);
		window.$('body').off('kknApp.adultLayerOpen', this.pauseExternal);
		window.$('body').off('kknApp.adultLayerClosed', this.startExternal);

		window.createjs.Sound.stop();

		if (this.data && window.kknApp.externals[this.data.externalAppScope] !== undefined)
			window.kknApp.externals[this.data.externalAppScope].kill();
		//	this.props.notifClear('externalOpen');
		//		this.props.clearDetailOpen('detailInfos');

		window.removeEventListener('message', this.receiveMessage, false);
	}

	onClose = () => {
		this.props.onClosed();
		this.props.clearAdultInfo('adultLayer');
	};

	componentWillReact() {
		if (this.props.appStore.isInteractiveContent(this.props.data) === false) return false;

		if (this.props.appStore.detailOpen && this.props.appStore.detailOpen.externalSub === false) {
			this.resetPlayer();
		}
	}

	/*
	 */

	handleVisibilityChange = (evt) => {
		this.sendStateToExtApp(!document.hidden ? 'onPageVisible' : 'onPageHidden');
	};
	sendStateToExtApp = (state) => {
		let ext = window.kknApp.externals[this.data.externalAppScope];
		if (ext && ext.onState) {
			ext.onState(state);
		}
	};

	closeVideo = () => {
		this.props.appStore.detailOpen = {
			type: 'interactiveContent',
			externalSub: false,
			isSingleDeeplink: this.props.injectedDetailInfos ? true : false
		};
	};
	resetPlayer = () => {
		this.setState({ showPlayer: false });
		window.kknApp.killGlobalPlayer();
		this.sendStateToExtApp('onPlayerClosed');
	};
	pauseExternal = () => {
		this.sendStateToExtApp('layerOpenend');
	};

	startExternal = () => {
		this.sendStateToExtApp('layerClosed');
	};

	resizeExternal = () => {
		if (this.data) {
			let ext = window.kknApp.externals[this.data.externalAppScope];
			if (ext) {
				if (ext.onResize !== undefined)
					ext.onResize({
						width: window.kknApp.width,
						height: window.kknApp.height,
						scale: window.kknApp.scale,
						isSmallView: window.kknApp.isSmallView,
						configData: this.data,
						contentData: this.docData,
						externalRequestUpdate: this.onExternalUpdate,
						tracking: this.onTracking,
						addCursorBehaviour: this.addCursorBehaviour,
						onLoadProgress: this.onExtLoadProgress,
						onLoadSuccess: this.onExtLoadSuccess,
						showPlayer: this.onPlayerShowRequest
					});
			}
		}
	};
	getIframeCode = () => {
		let hashCode = '';
		if (document.location.hash.length > 0) hashCode = document.location.hash;
		return (
			'<iframe frameborder="0" scrolling="no" allowfullscreen src="' +
			(this.docData.document.documentProperties.contentHtmlRef && this.docData.document.documentProperties.contentHtmlRef.length > 0
				? this.docData.document.documentProperties.contentHtmlRef
				: this.docData.document.documentProperties.contentUrl) +
			'?d=' +
			new Date().getTime() +
			hashCode +
			'"></iframe>'
		);
	};

	getDetailLayer = () => {
		if (!this.props.appStore.detailOpen) return null;

		if (this.getDetailOpenDocType().indexOf('video') !== -1 || this.props.appStore.detailOpen.deprecatedPlayerApi === true) {
			return (
				<MediaPlayer
					assets={this.props.assets}
					data={this.props.appStore.detailOpen.externalSubData}
					changeNavi={false}
					variant={'external'}
					routeParams={this.props.routeParams}
					ref="videoplayer"
				/>
			);
		}
		if (this.getDetailOpenDocType().indexOf(Statics.CONTENT_TYPE_MMG) !== -1) {
			return (
				<ImageGallery
					changeNavi={false}
					assets={this.props.assets}
					routeParams={this.props.routeParams}
					data={this.props.appStore.detailOpen.externalSubData}
				/>
			);
		}
	};

	render() {
		let lastVisible = true;
		let clicked = this.props.appStore.clicked;

		if (this.props.appStore.isInteractiveContent(this.props.data) === false) return null;

		this.resizeExternal();
		let showSplashScreen = this.state.showLoader && clicked ? true : false;
		//if (this.state.isIframe) showSplashScreen = false;
		return (
			<CSSTransition in={this.state.moveIn} timeout={0} unmountOnExit={true}>
				<Wrapper {...this.state} className={this.props.type + ' ' + this.props.scope + '-layer'} ref="base">
					<Outer>
						{this.getDetailLayer()}
						<MainImage>
							<img
								src={getImageUrl(
									(this.props.data && this.props.data.standard && this.props.data.standard.teaserImage.urlScheme) ||
										(this.props.data.document && this.props.data.document.teaserImage.urlScheme)
								)}
							/>
						</MainImage>
						<CSSTransition timeout={1} in={showSplashScreen === false}>
							<ExternalWrapper
								ref="wrapper"
								dangerouslySetInnerHTML={this.state.isIframe === true ? { __html: this.getIframeCode() } : null}
								className={'kknApp-externalWrapper'}
							/>
						</CSSTransition>

						<CSSTransition timeout={1} in={showSplashScreen}>
							<PreloaderWrapper>
								<TransitionBackground scope={'external'} open={true} />
								<PreloaderView
									stageRef="preloaderInteractive"
									sub={true}
									loaded={this.state.extLoaded}
									progress={this.state.extProgress}
								/>
							</PreloaderWrapper>
						</CSSTransition>
					</Outer>
				</Wrapper>
			</CSSTransition>
		);
	}
}

export default InteractiveContent;
