import styled from 'styled-components';

export const SliderBaseStyle = styled.div`
	.swiper-container {
		margin: 0 auto;
		position: relative;
		overflow: hidden;
		list-style: none;
		padding: 0;
		z-index: 1;
	}

	.swiper-container-no-flexbox .swiper-slide {
		float: left;
	}

	.swiper-container-vertical > .swiper-wrapper {
		flex-direction: column;
	}

	.swiper-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		transition-property: -webkit-transform;
		transition-property: transform;
		transition-property: transform, -webkit-transform;
		box-sizing: content-box;
	}

	.swiper-container-android .swiper-slide,
	.swiper-wrapper {
		transform: translate3d(0, 0, 0);
	}

	.swiper-container-multirow > .swiper-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.swiper-container-free-mode > .swiper-wrapper {
		transition-timing-function: ease-out;
		margin: 0 auto;
	}

	.swiper-slide {
		flex-shrink: 0;
		width: 100%;
		position: relative;
		transition-property: -webkit-transform;
		transition-property: transform;
		transition-property: transform, -webkit-transform;
	}

	.swiper-slide-invisible-blank {
		visibility: hidden;
	}

	.swiper-container-autoheight,
	.swiper-container-autoheight .swiper-slide {
		height: auto;
	}

	.swiper-container-autoheight .swiper-wrapper {
		align-items: flex-start;
		transition-property: height, -webkit-transform;
		transition-property: transform, height;
		transition-property: transform, height, -webkit-transform;
	}

	.swiper-container-3d {
		-webkit-perspective: 1200px;
		perspective: 1200px;
	}

	.swiper-container-3d .swiper-cube-shadow,
	.swiper-container-3d .swiper-slide,
	.swiper-container-3d .swiper-slide-shadow-bottom,
	.swiper-container-3d .swiper-slide-shadow-left,
	.swiper-container-3d .swiper-slide-shadow-right,
	.swiper-container-3d .swiper-slide-shadow-top,
	.swiper-container-3d .swiper-wrapper {
		transform-style: preserve-3d;
	}

	.swiper-container-3d .swiper-slide-shadow-bottom,
	.swiper-container-3d .swiper-slide-shadow-left,
	.swiper-container-3d .swiper-slide-shadow-right,
	.swiper-container-3d .swiper-slide-shadow-top {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		z-index: 10;
	}

	.swiper-container-3d .swiper-slide-shadow-left {
		background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	}

	.swiper-container-3d .swiper-slide-shadow-right {
		background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	}

	.swiper-container-3d .swiper-slide-shadow-top {
		background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	}

	.swiper-container-3d .swiper-slide-shadow-bottom {
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	}

	.swiper-container-wp8-horizontal,
	.swiper-container-wp8-horizontal > .swiper-wrapper {
		touch-action: pan-y;
	}

	.swiper-container-wp8-vertical,
	.swiper-container-wp8-vertical > .swiper-wrapper {
		touch-action: pan-x;
	}

	.swiper-button-next,
	.swiper-button-prev {
		position: absolute;
		top: 50%;
		width: 27px;
		height: 44px;
		margin-top: -22px;
		z-index: 10;
		cursor: pointer;
		background-size: 27px 44px;
		background-position: center;
		background-repeat: no-repeat;
	}

	.swiper-button-next.swiper-button-disabled,
	.swiper-button-prev.swiper-button-disabled {
		opacity: 0.35;
		cursor: auto;
		pointer-events: none;
	}

	.swiper-button-prev,
	.swiper-container-rtl .swiper-button-next {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
		left: 10px;
		right: auto;
	}

	.swiper-button-next,
	.swiper-container-rtl .swiper-button-prev {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
		right: 10px;
		left: auto;
	}

	.swiper-button-prev.swiper-button-white,
	.swiper-container-rtl .swiper-button-next.swiper-button-white {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
	}

	.swiper-button-next.swiper-button-white,
	.swiper-container-rtl .swiper-button-prev.swiper-button-white {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
	}

	.swiper-button-prev.swiper-button-black,
	.swiper-container-rtl .swiper-button-next.swiper-button-black {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
	}

	.swiper-button-next.swiper-button-black,
	.swiper-container-rtl .swiper-button-prev.swiper-button-black {
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
	}

	.swiper-button-lock {
		display: none;
	}

	.swiper-pagination {
		position: absolute;
		text-align: center;
		transition: 0.3s opacity;
		transform: translate3d(0, 0, 0);
		z-index: 10;
	}

	.swiper-pagination.swiper-pagination-hidden {
		opacity: 0;
	}

	.swiper-container-horizontal > .swiper-pagination-bullets,
	.swiper-pagination-custom,
	.swiper-pagination-fraction {
		bottom: 10px;
		left: 0;
		width: 100%;
	}

	.swiper-pagination-bullets-dynamic {
		overflow: hidden;
		font-size: 0;
	}

	.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
		transform: scale(0.33);
		position: relative;
	}

	.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
		transform: scale(1);
	}

	.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
		transform: scale(1);
	}

	.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
		transform: scale(0.66);
	}

	.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
		transform: scale(0.33);
	}

	.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
		transform: scale(0.66);
	}

	.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
		transform: scale(0.33);
	}

	.swiper-pagination-bullet {
		width: 8px;
		height: 8px;
		display: inline-block;
		border-radius: 100%;
		background: #000;
		opacity: 0.2;
	}

	button.swiper-pagination-bullet {
		border: none;
		margin: 0;
		padding: 0;
		box-shadow: none;
		appearance: none;
	}

	.swiper-pagination-clickable .swiper-pagination-bullet {
		cursor: pointer;
	}

	.swiper-pagination-bullet-active {
		opacity: 1;
		background: #007aff;
	}

	.swiper-container-vertical > .swiper-pagination-bullets {
		right: 10px;
		top: 50%;
		transform: translate3d(0, -50%, 0);
	}

	.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
		margin: 6px 0;
		display: block;
	}

	.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
		top: 50%;
		transform: translateY(-50%);
		width: 8px;
	}

	.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
		display: inline-block;
		transition: 0.2s top, 0.2s -webkit-transform;
		transition: 0.2s transform, 0.2s top;
		transition: 0.2s transform, 0.2s top, 0.2s -webkit-transform;
	}

	.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
		margin: 0 4px;
	}

	.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
		left: 50%;
		transform: translateX(-50%);
		white-space: nowrap;
	}

	.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
		transition: 0.2s left, 0.2s -webkit-transform;
		transition: 0.2s transform, 0.2s left;
		transition: 0.2s transform, 0.2s left, 0.2s -webkit-transform;
	}

	.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
		transition: 0.2s right, 0.2s -webkit-transform;
		transition: 0.2s transform, 0.2s right;
		transition: 0.2s transform, 0.2s right, 0.2s -webkit-transform;
	}

	.swiper-pagination-progressbar {
		background: rgba(0, 0, 0, 0.25);
		position: absolute;
	}

	.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
		background: #007aff;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		transform: scale(0);
		transform-origin: left top;
	}

	.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
		transform-origin: right top;
	}

	.swiper-container-horizontal > .swiper-pagination-progressbar,
	.swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
		width: 100%;
		height: 4px;
		left: 0;
		top: 0;
	}

	.swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
	.swiper-container-vertical > .swiper-pagination-progressbar {
		width: 4px;
		height: 100%;
		left: 0;
		top: 0;
	}

	.swiper-pagination-white .swiper-pagination-bullet-active {
		background: #fff;
	}

	.swiper-pagination-progressbar.swiper-pagination-white {
		background: rgba(255, 255, 255, 0.25);
	}

	.swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill {
		background: #fff;
	}

	.swiper-pagination-black .swiper-pagination-bullet-active {
		background: #000;
	}

	.swiper-pagination-progressbar.swiper-pagination-black {
		background: rgba(0, 0, 0, 0.25);
	}

	.swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill {
		background: #000;
	}

	.swiper-pagination-lock {
		display: none;
	}

	.swiper-scrollbar {
		border-radius: 1rem;
		position: relative;
		-ms-touch-action: none;
		background-color: rgba(0,0,0, 0.1);
	}

	.swiper-scrollbar-drag {
		height: 100%;
		width: 100%;
		position: relative;
		background-color: rgba(0,0,0, 0.25);
		border-radius: 1rem;
		left: 0;
		top: 0;
	}

	.swiper-scrollbar-cursor-drag {
		cursor: move;
	}

	.swiper-scrollbar-lock {
		display: none;
	}

	.swiper-zoom-container {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.swiper-zoom-container > canvas,
	.swiper-zoom-container > img,
	.swiper-zoom-container > svg {
		max-width: 100%;
		max-height: 100%;
		-o-object-fit: contain;
		object-fit: contain;
	}

	.swiper-slide-zoomed {
		cursor: move;
	}

	.swiper-lazy-preloader {
		width: 42px;
		height: 42px;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -21px;
		margin-top: -21px;
		z-index: 10;
		transform-origin: 50%;
	}

	.swiper-container .swiper-notification {
		position: absolute;
		left: 0;
		top: 0;
		pointer-events: none;
		opacity: 0;
		z-index: -1000;
	}

	.swiper-container-fade.swiper-container-free-mode .swiper-slide {
		transition-timing-function: ease-out;
	}

	.swiper-container-fade .swiper-slide {
		pointer-events: none;
		transition-property: opacity;
	}

	.swiper-container-fade .swiper-slide .swiper-slide {
		pointer-events: none;
	}

	.swiper-container-fade .swiper-slide-active,
	.swiper-container-fade .swiper-slide-active .swiper-slide-active {
		pointer-events: auto;
	}

	.swiper-container-cube {
		overflow: visible;
	}

	.swiper-container-cube .swiper-slide {
		pointer-events: none;
		backface-visibility: hidden;
		z-index: 1;
		visibility: hidden;
		transform-origin: 0 0;
		width: 100%;
		height: 100%;
	}

	.swiper-container-cube .swiper-slide .swiper-slide {
		pointer-events: none;
	}

	.swiper-container-cube.swiper-container-rtl .swiper-slide {
		transform-origin: 100% 0;
	}

	.swiper-container-cube .swiper-slide-active,
	.swiper-container-cube .swiper-slide-active .swiper-slide-active {
		pointer-events: auto;
	}

	.swiper-container-cube .swiper-slide-active,
	.swiper-container-cube .swiper-slide-next,
	.swiper-container-cube .swiper-slide-next + .swiper-slide,
	.swiper-container-cube .swiper-slide-prev {
		pointer-events: auto;
		visibility: visible;
	}

	.swiper-container-cube .swiper-slide-shadow-bottom,
	.swiper-container-cube .swiper-slide-shadow-left,
	.swiper-container-cube .swiper-slide-shadow-right,
	.swiper-container-cube .swiper-slide-shadow-top {
		z-index: 0;
		backface-visibility: hidden;
	}

	.swiper-container-cube .swiper-cube-shadow {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: #000;
		opacity: 0.6;
		-webkit-filter: blur(50px);
		filter: blur(50px);
		z-index: 0;
	}

	.swiper-container-flip {
		overflow: visible;
	}

	.swiper-container-flip .swiper-slide {
		pointer-events: none;
		backface-visibility: hidden;
		z-index: 1;
	}

	.swiper-container-flip .swiper-slide .swiper-slide {
		pointer-events: none;
	}

	.swiper-container-flip .swiper-slide-active,
	.swiper-container-flip .swiper-slide-active .swiper-slide-active {
		pointer-events: auto;
	}

	.swiper-container-flip .swiper-slide-shadow-bottom,
	.swiper-container-flip .swiper-slide-shadow-left,
	.swiper-container-flip .swiper-slide-shadow-right,
	.swiper-container-flip .swiper-slide-shadow-top {
		z-index: 0;
		backface-visibility: hidden;
	}

	.swiper-container-coverflow .swiper-wrapper {
		-ms-perspective: 1200px;
	}
`;
