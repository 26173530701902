import styled, { css } from 'styled-components';
import { blueRabbitHand, gfx, moveToCenter, scaleIn } from 'helpers/layoutVariables';

export const TeaserIcon = styled.span`
	position: absolute;
	z-index: 2;
	width: 50px;
	height: 50px;
	background-color: #fff5d1;
	border-radius: 50%;
	bottom: 0;
	left: 0;
	box-shadow: 0 0 0.5em #5f4100;
	overflow: hidden;
	pointer-events: none;

	${(props) =>
		props.parentType === 'round' &&
		css`
			bottom: 0;
			left: 58%;
		`}

	${(props) =>
		props.parentType === 'index' &&
		css`
			left: 100%;
			top: 50%;
			margin: -3em 0 0 -4em;
		`}
	${(props) =>
		props.variant === 'icon' &&
		css`
			background-color: ${gfx.vanille};
			border: 8px solid ${gfx.lightBlue};
			position: relative;
			margin: auto;
			top: 0;
			left: 0;
		`}
`;

export const Wrapper = styled.div`
	font-weight: bold;
	z-index: 1;
	${blueRabbitHand};
	position: relative;
	transform-origin: 50% 50%;
	margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};

	&.scaleIn {
		animation: ${scaleIn} 0.3s ease-out;
		animation-fill-mode: forwards;
	}
	&.instantScale,
	&.solid {
		transform: scale(1);
	}
	&.moveToCenter {
		width: 30em !important;
		height: 30em !important;
		left: 59em !important;
		top: 36em !important;
		margin-left: -15em !important;
		margin-top: -15em !important;
		animation-name: ${moveToCenter};
		animation-duration: 0.6s;
		animation-timing-function: ease-out;
		transform-origin: 50% 50%;
		animation-fill-mode: forwards;
	}

	${(props) =>
		props.teaserType === 'round' &&
		css`
			cursor: inherit;
			border-radius: 50%;
			box-shadow: 0.25em 0.25em 3em 0.333em ${gfx.shadowColor};
		`}

	${(props) =>
		props.variant === 'standard' &&
		css`
			background: rgba(95, 65, 0, 0.45);
			box-shadow: 0 0 3em 0.333em ${gfx.shadowColor};
		`}
    ${(props) =>
		(props.variant === 'home' || props.variant === 'iconTeaser') &&
		css`
			box-shadow: none;
		`};
`;

export const WhiteBorder = styled.div`
	width: 100%;
	position: relative;

	${(props) =>
		props.variant === 'landscape' &&
		css`
			top: -0.25em;
			padding: ${(props) => (props.innerPadding ? props.innerPadding : '4%')};
			position: relative;
			width: 100%;
			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 0.5%;
				height: 88%;
				top: 4%;
				width: 98%;
				background: #5f4100;
				box-shadow: 0 0 1.5em 0.25em #5f4100;
				opacity: 0.75;
			}
			&:after {
				background-size: 100% auto;
				background-repeat: no-repeat;

				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		`};

	${(props) =>
		props.variant === 'standard' &&
		css`
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 1%;
				left: 1%;
				width: 98%;
				height: 98%;
				background: #5f4100;
				box-shadow: 0 0 0.5em 0.5em #5f4100;
				opacity: 0.5;
				border-radius: 50%;
				z-index: 1;
			}
		`};

	${(props) =>
		props.variant === 'layer' &&
		css`
			padding: 4em;
			top: -1.8em;
			top: 1%;
			height: 95%;
		`};
`;

export const ImageWrapper = styled.div`
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;

	${(props) =>
		props.variant === 'standard' &&
		css`
			&:after {
				position: absolute;
				content: '';
				width: 100%;
				height: 100%;
				border-radius: 50%;
				top: 0;
				left: 0;
				z-index: 2;
				box-shadow: inset 0 0 2em 0 ${gfx.shadowColor};
			}
			> img {
				z-index: 2;
				border-radius: 50%;
			}
		`}

	${(props) =>
		props.variant === 'home' &&
		css`
			background-color: #faedc1;
		`}
	${(props) =>
		props.variant === 'iconTeaser' &&
		css`
			padding: 14%;
			background-color: ${gfx.vanille};
		`}

	${(props) =>
		props.teaserType === 'round' &&
		css`
			border-radius: 50%;
			overflow: hidden;
		`}
`;

export const MaskImage = styled.div`
	position: relative;
	overflow: hidden;
	z-index: 2;
	width: 100%;
	align-self: center;
	display: flex;
	height: 100%;
	border-radius: 50%;

	${(props) =>
		props.variant === 'standard' &&
		css`
			z-index: 2;
			padding: 6%;
			border-radius: 50%;
		`}
	${(props) =>
		props.variant === 'home' &&
		css`
			&:after {
				box-shadow: inset 0 0 0.5em 0.5em ${gfx.shadowColor};
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				content: '';
				border-radius: 50%;
				z-index: 1;
			}
		`}

	${(props) => props.variant === 'iconTeaser' && css``};
`;

export const Outer = styled.div`
	position: relative;
	background-size: 100% auto;
	background-repeat: no-repeat;
	width: 100%;
	display: flex;
	flex: 1 1 auto;
	height: 100%;
	padding: 4%;

	${(props) =>
		props.teaserType === 'round' &&
		css`
			display: flex;
			flex: 1 1 auto;
			width: 100%;
			height: 100%;
			padding: 6%;
		`}
	${(props) =>
		props.variant === 'layer' &&
		css`
			&:after {
				height: 88%;
				top: 3%;
				width: 99%;
				left: 0.5%;
				box-shadow: 0 0 3em 1em #5f4100;
			}

			padding: 2% 7%;
		`}
`;

export const TeaserBg = styled.img`
	position: absolute;
	width: 100%;
	left: 0;
	top: 0;
	${(props) =>
		props.variant === 'visited' &&
		css`
			display: none;
		`}
`;

export const TeaserFrame = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	transform: rotate(30deg);
`;

export const StoererImage = styled.img`
	width: 7em;
	left: 92%;
	top: -8%;
	z-index: 3;
	transform: translate3d(-50%, 0, 0);
	position: absolute;

	${(props) =>
		props.teaserType === 'round' &&
		css`
			left: 85%;
		`}
`;

export const TeaserImage = styled.img`
	width: 100%;
	position: relative;
`;
