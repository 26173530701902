import React, { Component } from 'react';
import AdultLayerTeaser from './AdultLayerTeaser';
import AdultTimer from './AdultTimer';
import { inject, observer } from 'mobx-react';
import AdultSubLayer from './AdultSubLayer';
import Preloader from 'helpers/Preloader';
import Search from '../Search';
import {
	BroadcastHint,
	ContentHead,
	ContentWrapper,
	FilterItem,
	FilterWrapper,
	InnerWrapper,
	ListItem,
	Menu,
	Result,
	ResultCategory,
	ResultImage,
	ResultImageWrapper,
	ResultText,
	ResultTextWrapper,
	SmallToggle,
	SmallToggleWrapper,
	Version,
	Wrapper
} from './AdultLayerStyle';
import Link from 'components/basics/Link/Link';
import { getControllerConfig, getImageUrl } from 'helpers/Utils';
import Slider from 'components/basics/Slider/Slider';
import Animator from '../../basics/Animation/Animator';

@inject('assetStore')
@inject('appStore')
@inject('routingStore')
@observer
class AdultLayer extends Component {
	state = {
		open: false,
		clicked: false,
		loaded: false,
		cache: null,
		content: null,
		locked: false,
		isSmallOpen: false,
		afterFormSend: false,
		searchResults: [],
		filterCategories: null,
		currentFilter: 'Alle',
		playerData: null,
		searchValue: ''
	};

	isUnmounted = false;
	isLoading = false;
	cache = null;

	componentDidMount() {}

	componentWillUnmount() {
		this.isUnmounted = true;
	}

	toggleLayer = () => {
		this.setState({ isSmallOpen: false });

		var d = !this.props.appStore.adultLayerOpen;
		if (d) {
			this.sendOpenAdult();
		} else {
			this.sendCloseAdult();
		}
		let t = !this.state.open;
		this.setState({ open: t, clicked: true });
	};
	sendOpenAdult = () => {
		this.props.appStore.openAdultLayer();
	};
	sendCloseAdult = () => {
		this.props.appStore.closeAdultLayer();
	};
	onItemsLoaded = evt => {
		if (this.isUnmounted) return false;

		if (evt !== null) {
			this.setState({ loaded: true, clicked: false, isLoading: false, cache: evt.target });
		}
	};

	componentWillReact() {
		let nextProps = this.props;
		if (nextProps.parentalGateOpen && nextProps.parentalGateOpen.approved === true) {
			if (nextProps.parentalGateOpen.type === 'adultTimer') {
				this.setState({ open: true });
			}

			if (nextProps.parentalGateOpen.type === 'adultLink') {
				if (nextProps.parentalGateOpen.target === '_blank') {
					window.open(nextProps.parentalGateOpen.url);
				} else {
					document.location.href = nextProps.parentalGateOpen.url;
				}
			}
		}
	}

	gotoBroadcastScene = () => {
		this.props.onClick('broadcastScene');
	};
	print = () => {
		window.print();
	};
	download = () => {
		const { adultInfo } = this.props;

		if (adultInfo) window.open(adultInfo.data.special.downloadUrl);
	};
	onFocusedInput = () => {};
	onChanged = evt => {
		if (evt.length === 0 && this.state.searchResults && this.state.searchResults.length > 0) {
			this.setState({ searchResults: [], filterCategories: null });
		}
		this.setState({ searchValue: evt });
	};
	onKeyDown = () => {};
	onFormSubmit = async event => {
		this.setState({ isLoading: true, currentFilter: 'Alle' });

		event.preventDefault();
		let baseUrl = process.env.REACT_APP_DEV_PROXY && process.env.REACT_APP_DEV_PROXY.length > 0 ? '' : 'https://www.kika.de';
		let rKkn = await fetch(`${baseUrl}/suche/suche104.html?q=${this.state.searchValue}&site=kikaninchen`);
		let rVids = await fetch(`${baseUrl}/suche/suche104.html?type=video&q=${this.state.searchValue}`);
		let entries = [];
		let resultsKKn = await rKkn.text();
		let resultsVids = await rVids.text();

		var parser = new DOMParser();
		this.setState({ searchResults: [] });
		// Parse the text
		var doc = parser.parseFromString(resultsKKn, 'text/html');
		var docVideos = parser.parseFromString(resultsVids, 'text/html');
		let finalItems = [];
		let items = doc.querySelectorAll('.box');
		let itemsVideos = docVideos.querySelectorAll('.box');
		for (var i = 0; i < items.length; i++) {
			finalItems.push(items[i]);
		}
		for (var i = 0; i < itemsVideos.length; i++) {
			finalItems.push(itemsVideos[i]);
		}
		let categories = null;
		if (finalItems.length > 0) {
			categories = { Alle: 0 };
		}

		for (var i = 0; i < finalItems.length; i++) {
			let domContainer = finalItems[i];
			//let extVids = await fetch(url);
			//let extVidsResponse = await extVids.text();
			let url = domContainer.querySelector('.linkAll').getAttribute('href');
			let title = domContainer.querySelector('.headline').innerText;
			let img = domContainer.querySelector('img');
			let config = getControllerConfig(img, 'data-ctrl-image');
			let imgUrl = null;

			let category = 'Video';
			if (domContainer.className.indexOf('kikaninchen') !== -1) {
				category = 'Sendung';
			}
			if (domContainer.className.indexOf('paint') !== -1) {
				category = 'Malen';
			}
			if (domContainer.className.indexOf('tinker') !== -1) {
				category = 'Basteln';
			}
			if (domContainer.className.indexOf('video') !== -1 && domContainer.className.indexOf('kikaninchen') === -1) {
				category = 'Video';
			}
			if (domContainer.className.indexOf('broadcast') !== -1) {
				category = 'Sendung';
			}
			if (domContainer.className.indexOf('aktion') !== -1) {
				category = 'Aktion';
			}
			if (category !== 'Sendung') {
				if (categories[category] === undefined) {
					categories[category] = 1;
				} else {
					categories[category]++;
				}
			}

			categories['Alle']++;
			if (config && config.urlScheme) {
				if (config.urlScheme.indexOf('http') === -1) config.urlScheme = 'https://www.kika.de' + config.urlScheme;

				imgUrl = getImageUrl(config.urlScheme, 512);
			}
			entries.push({
				id: i,
				classes: domContainer.className,
				category: category,
				title: title,
				imgUrl: imgUrl,
				url: url
			});
		}

		this.setState({ searchResults: entries, filterCategories: categories, isLoading: false });

		return false;
	};
	onResultClicked = async data => {
		window.kknApp.killGlobalPlayer();
		this.props.appStore.detailOpen = null;

		if (data.url && data.category !== 'Video') {
			let url =
				process.env.REACT_APP_DEV_PROXY && process.env.REACT_APP_DEV_PROXY.length > 0
					? data.url.split('https://www.kikaninchen.de').join('')
					: data.url.split('https://www.kikaninchen.de').join('');

			this.props.routingStore.push(url);
			this.sendCloseAdult();
			return false;
		}

		alert('Das geht leider noch nicht.');
		return false;

		this.setState({ isLoading: true });

		var parser = new DOMParser();
		let extVids = await fetch(data.url.indexOf('kikaninchen.de') === -1 ? 'https://www.kikaninchen.de' + data.url : data.url);
		let extVidsResponse = await extVids.text();
		var doc = parser.parseFromString(extVidsResponse, 'text/html');
		let url = doc
			.querySelector('.av-playerContainer a')
			.getAttribute('onclick')
			.split("dataURL:'")[1]
			.split("'}));")
			.join('');
		this.setState({ isLoading: false });

		this.props.appStore.detailOpen = {
			type: 'mediaPlayer',
			newPlayerData: {
				special: {
					avCustomUrl: url
				},
				standard: {
					contentCategory: 'video',
					teaserImage: {}
				}
			}
		};
		this.sendCloseAdult();
	};
	getResult = (teaser, i) => {
		if (this.state.currentFilter !== 'Alle' && this.state.currentFilter !== teaser.category) return null;
		return (
			<Result
				onClick={() => {
					this.onResultClicked(teaser);
				}}
				key={'result' + i}
			>
				<ResultImageWrapper>
					<ResultImage src={teaser.imgUrl} />
				</ResultImageWrapper>

				<ResultTextWrapper>
					{teaser.category !== 'Sendung' ? <ResultCategory>{teaser.category}</ResultCategory> : null}
					<ResultText>{teaser.title}</ResultText>
				</ResultTextWrapper>
			</Result>
		);
	};
	getFilterItem = (filter, current) => {
		return (
			<FilterItem
				key={'filter' + filter}
				onClick={() => {
					this.setState({ currentFilter: filter });
				}}
				active={this.state.currentFilter === filter}
			>
				<span>{`${filter}`}</span>
			</FilterItem>
		);
	};
	getFilterCategories = () => {
		if (!this.state.filterCategories) return null;
		let filterList = [];
		for (let filter in this.state.filterCategories) {
			let current = this.state.filterCategories[filter];
			filterList.push(this.getFilterItem(filter, current));
		}
		return <FilterWrapper>{filterList}</FilterWrapper>;
	};

	getAdultInfo() {
		if (this.props.appStore.adultLayerOpen !== true) return null;

		let adultInfo = this.props.assetStore.adultLayerContent;
		let teasers = [];

		if (adultInfo) {
			if (adultInfo && adultInfo.externalData) {
				var dataSliced = adultInfo.externalData;
				if (dataSliced.length && dataSliced.length > 0) {
					dataSliced.slice().map((extTeaserData, i) => {
						teasers.push(this.getExternalTeaserList(extTeaserData, 'ext' + i));
					});
				} else {
					teasers.push(this.getExternalTeaserList(dataSliced, 'ext0'));
				}
			}

			if (adultInfo.data && adultInfo.data.contentHead) {
				teasers.push(this.getTitle(adultInfo.data.contentHead));
			}

			if (adultInfo.data && adultInfo.data.teaserBox) {
				adultInfo.data.teaserBox.teasers.map((teaser, i) => {
					teasers.push(this.getTeaser(teaser, i));
				});
			}

			if (
				this.state.searchValue &&
				this.state.searchValue.length > 0 &&
				this.state.searchResults &&
				this.state.searchResults.length > 0
			) {
				teasers = [];
				this.state.searchResults.map((teaser, i) => {
					teasers.push(this.getResult(teaser, i));
				});
			} else {
			}

			if (teasers.length > 0) {
				return (
					<ContentWrapper
						className={'adultInnerContent'}
						isSmallView={this.props.appStore.isSmallView()}
						isSmallOpen={this.props.appStore.isSmallView() && this.state.isSmallOpen}
					>
						{this.getInnerAdultContent(teasers)}
					</ContentWrapper>
				);
			}

			return null;
		}
	}

	// SEARCH FORM
	/*<Form onSubmit={this.onFormSubmit} className="searchForm noResults">
                            <InputField
                                requestFocus={this.props.appStore.adultLayerOpen}
                                onFocused={this.onFocusedInput}
                                value={this.state.searchValue}
                                onChanged={this.onChanged}
                                onKeyDown={this.onKeyDown}
                                state={null}
                                hasClearButton={true}
                                onClick={this.focusInputField}
                                placeHolder={true}
                                id={'headerSearchInput'}
                                label={'Suche'}
                                name={'q'}
                            />
                            <SubmitWrapper type={'submit'}>
                                <img src={getIconUrl('suche-adult')}/>
                            </SubmitWrapper>
                        </Form>*/

	getInnerAdultContent = teasers => {
		if (this.state.isLoading) return <div className={'kkn-buffer'}></div>;

		return (
			<React.Fragment>
				{this.state.searchValue && this.state.searchValue.length > 0 ? this.getFilterCategories() : null}
				<div style={{ width: '100%', height: 'auto', marginBottom: '10em' }} className={'adultInnerContent'}>
					{teasers}
				</div>
			</React.Fragment>
		);
	};

	getExternalTeaserList = (externalData, i) => {
		if (externalData.type === 'htmlInject') {
			return this.getExternalTeaser(externalData, i, externalData.html);
		} else {
			return this.getExternalTeaser(externalData, i);
		}
	};

	getTitle = data => {
		return (
			<ContentHead>
				<h3>{data.title}</h3>
				<p>{data.teaserText}</p>
			</ContentHead>
		);
	};

	onSubmit = data => {
		let adultInfo = this.props.assetStore.adultLayerContent;
		let adultDataTemp = [{ teasers: [] }];
		let externalData = null;
		this.props.assetStore.docAdultData.teasers.map((data, i) => {
			if (data.standard.teaserStyling === 'contestResponseText') {
				adultDataTemp[0].teasers.push(data);
			}
		});

		if (adultDataTemp[0].teasers.length === 0) {
			adultDataTemp.contentHead = {
				title: 'Danke für deine Teilnahme!',
				teaserText: window.kknApp.defaultContestFormSendText
			};
		}
		if (adultInfo.externalData) {
			externalData = [];
			adultInfo.externalData.map((data, i) => {
				if (data.teaserStyling === 'contestResponseText') {
					externalData.push(data);
				}
			});
			this.props.assetStore.clearAdultInfo();

			this.setState({ afterFormSend: true }, () => {
				this.updateAdultLayer({ externalData: externalData });
			});

			return false;
		}

		this.setState({ afterFormSend: true }, () => {
			this.updateAdultLayer({ data: adultDataTemp });
		});
	};
	updateAdultLayer = data => {
		this.props.assetStore.addAdultInfo(data, 'externalData');
	};

	getTeaser = (data, i) => {
		return (
			<AdultLayerTeaser
				assets={this.props.assetStore.globalAssets}
				key={'adultTeaser-' + i}
				data={data}
				afterFormSend={this.state.afterFormSend}
				index={i}
				onSubmit={this.onSubmit}
				cache={this.state.cache}
			/>
		);
	};
	getExternalTeaser = (data, i, raw) => {
		return (
			<AdultLayerTeaser
				external={true}
				data={data}
				afterFormSend={this.state.afterFormSend}
				rawHtml={raw}
				assets={this.props.assetStore.globalAssets}
				key={'adultTeaser-' + i}
				index={i}
				onSubmit={this.onSubmit}
				cache={null}
			/>
		);
	};

	getBroadcastHint = () => {
		let mainInfo = '';
		let secondInfo = '';
		let data = this.props.assetStore.docData;
		let adultContent = this.props.assetStore.adultLayerContent && this.props.assetStore.adultLayerContent.data;

		if (data.document.broadcastHint_mainInfo) mainInfo = data.document.broadcastHint_mainInfo;
		if (data.document.broadcastHint_secondInfo) secondInfo = data.document.broadcastHint_secondInfo;

		if (adultContent && adultContent.special && adultContent.special.broadcastHint_mainInfo)
			mainInfo = adultContent.special.broadcastHint_mainInfo;
		if (adultContent && adultContent.special && adultContent.special.broadcastHint_secondInfo)
			secondInfo = adultContent.special.broadcastHint_secondInfo;

		return (
			<BroadcastHint>
				{mainInfo.length > 0 ? <span>{mainInfo} </span> : null}
				{secondInfo.length > 0 ? <span>{secondInfo}</span> : null}
			</BroadcastHint>
		);
	};
	onClose = () => {
		this.setState({ open: false });
		this.sendCloseAdult();
		return false;
	};
	onTimerClose = () => {
		return false;
	};
	onProgressEnded = () => {
		this.setState({ open: true });
		this.sendOpenAdult();
	};
	openAdultTimer = () => {
		this.props.appStore.showParentalGate({ open: true, type: 'adultTimer' });
	};
	openLink = (type, url, target, title) => {
		this.props.appStore.showParentalGate({
			open: true,
			linkType: type,
			target: target || '_blank',
			type: 'adultLink',
			url: url,
			data: {
				secondTitle: title
			}
		});
	};

	toggleSmall = () => {
		var d = !this.state.isSmallOpen;
		this.setState({ isSmallOpen: d });
	};

	preLoadEvent = src => {
		if (!src) return;

		let preloader = new Preloader();
		preloader.load({
			cb: evt => {
				this.props.appStore.adultSubLayerData = evt.target.getResult('subLayer');
			},
			onProgress: evt => {},
			url: [
				{
					id: 'subLayer',
					type: window.createjs.LoadQueue.HTML,
					src
				}
			]
		});
	};

	getAdditionalNaviItem = data => {
		const storeSettings = this.props?.assetStore?.docData?.settings || null;
		return (
			<ListItem key={data?.title}>
				<Link
					onClick={() => {
						if (storeSettings && data.title === 'Datenschutz') {
							this.preLoadEvent(storeSettings?.optOutKKN);
						} else if (storeSettings && data.title === 'Impressum') {
							this.preLoadEvent(storeSettings?.impressumKKN);
						} else {
							this.openLink(data.title, data.url, data.target, data.title);
						}
					}}
					target={data.target || '_blank'}
				>
					{data.title}
				</Link>
			</ListItem>
		);
	};

	render() {
		let hasDownload = false;
		let not = '';
		let hasBroadcastHint = false;
		if (this.props.assetStore.globalAssets === null) return null;
		let adultContent = this.props.assetStore.adultLayerContent;

		let data = this.props.assetStore.docData;
		if (
			(data && data.document && (data.document.broadcastHint_mainInfo || data.document.broadcastHint_secondInfo)) ||
			(adultContent &&
				adultContent.data &&
				adultContent.data.special &&
				(adultContent.data.special.broadcastHint_mainInfo || adultContent.data.special.broadcastHint_secondInfo))
		) {
			hasBroadcastHint = true;
		}

		let addLinks = [];
		if (window.kknApp.additionalNaviLinks && window.kknApp.additionalNaviLinks.length > 0) {
			window.kknApp.additionalNaviLinks.map((data, i) => {
				addLinks.push(this.getAdditionalNaviItem(data));
			});
		}

		let notClass = not.length > 0 ? 'notification ' + not + ' ' : '';
		if (this.props.appStore.adultTimerEnded) notClass = 'notification lock ';
		let fullscreenIcon = this.props.assetStore.globalAssets.getItem('infoFullscreen');
		return (
			<Wrapper style={this.props.style} className={'adultLayer'}>
				<Animator type={'moveInFromTop'} duration={500} active={this.props.appStore.adultSubLayerData !== null}>
					<AdultSubLayer
						assets={this.props.assetStore.globalAssets}
						onCancel={this.onSubLayerClose}
						onClose={this.onSubLayerClose}
					/>
				</Animator>

				<InnerWrapper className={'adultOpen'} open={this.props.appStore.adultLayerOpen}>
					{this.props.appStore.adultSearchOpen ? (
						<Search />
					) : (
						<>
							<Menu
								collapsed={this.props.appStore.isSmallView()}
								isSmallOpen={
									!this.props.appStore.isSmallView() || (this.props.appStore.isSmallView() && this.state.isSmallOpen)
								}
							>
								{addLinks}
								<SmallToggleWrapper>
									<SmallToggle onClick={this.toggleSmall}>MENÜ</SmallToggle>
								</SmallToggleWrapper>
							</Menu>
							<AdultTimer
								open={this.props.appStore.adultTimerOpen}
								assets={this.props.assetStore.globalAssets}
								onCancel={this.onTimerClose}
								onClose={this.onClose}
							/>
							<Animator duration={200} unmountOnExit={false} active={this.props.appStore.adultLayerOpen}>
								{this.getAdultInfo()}
							</Animator>
							{hasBroadcastHint ? this.getBroadcastHint() : null}
							<Version>{`Version: ${this.props.appStore.version}`}</Version>
						</>
					)}
				</InnerWrapper>
			</Wrapper>
		);
	}
}

export default AdultLayer;

/*<<React.Fragment>
					<BackgroundAdultBtn src={'/static_kkn/global/images/tabbed/icons/elternbutton-Bg.png'} />
					KknButton
						bounce={not.length > 0 && !this.state.clicked ? true : false}
						active={this.props.appStore.adultLayerOpen}
						type={'adult'}
						variant={not}
						onClicked={this.toggleLayer}
						progress={true}
						onProgressEnded={this.onProgressEnded}
					/>
					<FullscreenStatus open={this.props.appStore.isFullscreen} src={fullscreenIcon && fullscreenIcon.src} />
				</React.Fragment>*/
