import styled, { css } from 'styled-components';
import { gfx, MouseHandButton } from 'helpers/layoutVariables';
import React from 'react';

export const StandardButton = styled(MouseHandButton)`
	border: 0;
	background-color: #fff;
	color: ${gfx.brown};
	font-size: 3em;
	padding: 0.5em 0.5em 0.25em 0.5em;
	border-radius: 0.5em;
	text-transform: uppercase;
	box-shadow: 0 0 0 0.2em rgba(255, 255, 255, 0.5);
`;

export const Row = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: ${props => (props.justify ? props.justify : 'center')};
	align-items: ${props => (props.align ? props.align : 'center')};
`;

export const TextInput = styled.input`
	outline: 0;
	padding: 0.5rem;
	color: ${gfx.brown};
	width: 100%;
	font-size: 1.875em;
	border: 0.25em solid #fff;
	background-color: #fff;
	border-radius: 4px;
	${props =>
		props.error &&
		css`
			border-color: ${props => (props.errorColor ? props.errorColor : gfx.brown)};
		`};
`;
export const TextInputArea = styled.textarea`
	outline: 0;
	padding: 0.5rem;
	color: ${gfx.brown};
	min-height:200px;
	width: 100%;
	font-size: 1.875em;
	border: 0.25em solid #fff;
	background-color: #fff;
	border-radius: 4px;
	${props =>
		props.error &&
		css`
			border-color: ${props => (props.errorColor ? props.errorColor : gfx.brown)};
		`};
`;

export const OpacityAnimator = styled.div`
	transition: opacity 0.5s ease-out;

	> * {
		opacity: 0;
		transition: opacity 0.5s ease-out;
		transition-delay: ${props => (props.delay ? props.delay : '0s')};
	}

	&.animation-enter > * {
		opacity: 1;
	}

	&.animation-enter-active > *,
	&.animation-enter-done > * {
		opacity: 1;
	}

	&.animation-exit > *,
	&.animation-exit-active > * {
		opacity: 0;
	}
`;

export const MoveYAnimator = styled.div`
	> * {
		transition: transform 1s ease-out;
		transform: translate3d(0, 150em, 0);
	}

	transition-delay: ${props => (props.delay ? props.delay : '0s')};

	&.animation-enter {
		> * {
			transform: translate3d(0, 150em, 0);
		}
	}

	&.animation-enter-active,
	&.animation-enter-done {
		> * {
			transform: translate3d(0, 0, 0);
		}
	}

	&.animation-exit,
	&.animation-exit-active {
		> * {
			transform: translate3d(0, -150em, 0);
		}
	}
`;
export const MoveYTopAnimator = styled.div`
	> * {
		transform: translate3d(0, -150em, 0);
		transition: transform 1s ease-out;
		transition-delay: ${props => (props.delay ? props.delay : '0s')};
	}

	&.animation-enter {
		> * {
			transform: translate3d(0, -150em, 0);
		}
	}

	&.animation-enter-active,
	&.animation-enter-done {
		> * {
			transform: translate3d(0, 0, 0);
		}
	}

	&.animation-exit,
	&.animation-exit-active {
		> * {
			transform: translate3d(0, 150em, 0);
		}
	}
`;
export const ScaleYAnimator = styled.div`
	> * {
		transform: scale(1, 0);
		transform-origin: 0 100%;
		transition: transform 0.3s ease-out;
		transition-delay: ${props => (props.delay ? props.delay : '0s')};
	}

	&.animation-enter {
		> * {
			transform: scale(1, 0);
		}
	}

	&.animation-enter-active,
	&.animation-enter-done {
		> * {
			transform: scale(1, 1);
		}
	}

	&.animation-exit,
	&.animation-exit-active {
		> * {
			transform: scale(1, 0);
		}
	}
`;
export const ScaleXAnimator = styled.div`
	> * {
		transform: scale(0, 1);
		transform-origin: 0 0;
		transition: transform 0.3s ease-out;
		transition-delay: ${props => (props.delay ? props.delay : '0s')};
	}

	&.animation-enter {
		> * {
			transform: scale(0, 1);
		}
	}

	&.animation-enter-active,
	&.animation-enter-done {
		> * {
			transform: scale(1, 1);
		}
	}

	&.animation-exit,
	&.animation-exit-active {
		> * {
			transform: scale(0, 1);
		}
	}
`;

export const MoveInFromTop = styled.div`
	> * {
		transform: translate3d(0, -100%, 0);
		transition: transform 0.3s ease-out;
	}

	&.animation-enter {
		> * {
			transform: translate3d(0, -100%, 0);
		}
	}

	&.animation-enter-active,
	&.animation-enter-done {
		> * {
			transform: translate3d(0, 0, 0);
		}
	}

	&.animation-exit,
	&.animation-exit-active {
		> * {
			transform: translate3d(0, -100%, 0);
		}
	}
`;

export const MoveInFromBottom = styled.div`
	> * {
		transform: translate3d(0, 100%, 0);
		transition: transform ${props => (props.duration ? props.duration : '0.3s')} ease-out;
		transition-delay: ${props => (props.delay ? props.delay : '0s')};
	}

	&.animation-enter {
		> * {
			transform: translate3d(0, 100%, 0);
		}
	}

	&.animation-enter-active,
	&.animation-enter-done {
		> * {
			transform: translate3d(0, 0, 0);
		}
	}

	&.animation-exit,
	&.animation-exit-active {
		> * {
			transform: translate3d(0, 100%, 0);
		}
	}
`;

export const MoveInFromLeft = styled.div`
	> * {
		transform: translate3d(-100%, 0, 0);
		transition: transform 0.2s ease-out;
	}

	&.animation-enter {
		> * {
			transform: translate3d(-100%, 0, 0);
		}
	}

	&.animation-enter-active,
	&.animation-enter-done {
		> * {
			transform: translate3d(0, 0, 0);
		}
	}

	&.animation-exit,
	&.animation-exit-active {
		> * {
			transform: translate3d(-100%, 0, 0);
		}
	}
`;
export const MoveInFromRight = styled.div`
	> * {
		transform: translate3d(100%, 0, 0);
		transition: transform 0.3s ease-out;
	}

	&.animation-enter {
		> * {
			transform: translate3d(100%, 0, 0);
		}
	}

	&.animation-enter-active,
	&.animation-enter-done {
		> * {
			transform: translate3d(0, 0, 0);
		}
	}

	&.animation-exit,
	&.animation-exit-active {
		> * {
			transform: translate3d(100%, 0, 0);
		}
	}
`;

export const MoveOutRight = styled.div`
	> * {
		transition: transform 0.3s ease-out;
	}

	&.animation-enter {
		> * {
			transform: translateX(100%);
		}
	}

	&.animation-enter-active,
	&.animation-enter-done {
		> * {
			transform: translateX(100%);
		}
	}

	&.animation-exit,
	&.animation-exit-active {
		> * {
			transform: translate3d(100%, 0, 0);
		}
	}
`;

export const MoveOutBottom = styled.div`
	> * {
		transition: transform 0.6s ease-out;
	}

	&.animation-enter {
		> * {
			transform: translate3d(-50%, 100%, 0);
		}
	}

	&.animation-enter-active,
	&.animation-enter-done {
		> * {
			transform: translate3d(-50%, 100%, 0);
		}
	}

	&.animation-exit,
	&.animation-exit-active {
		> * {
		}
	}
`;

export const Plaice = styled.img`
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 2;
	width: 36em;
	pointer-events: none;
`;
export const MoveXAnimator = styled.div`
	height: 100%;

	> * {
		transform: translate3d(100%, 0, 0);
		transition: transform 0.3s ease-out;
		transition-delay: ${props => (props.delay ? props.delay : '0s')};
	}

	&.animation-enter {
		> * {
			transform: translate3d(100%, 0, 0);
		}
	}

	&.animation-enter-active,
	&.animation-enter-done {
		> * {
			transform: translate3d(0, 0, 0);
		}
	}

	&.animation-exit,
	&.animation-exit-active {
		> * {
			transform: translate3d(100%, 0, 0);
		}
	}
`;
