import styled, { css } from 'styled-components';
import { blueRabbitHand } from 'helpers/layoutVariables';

export const StartLink = styled.a`
	position: absolute;
	bottom: 0;
	left: 0;
	width: ${(props) => (props.width ? props.width : '100%')};
	height: ${(props) => (props.height ? props.height : '100%')};
	z-index: 1;
	${blueRabbitHand};
	background-color: transparent;
`;

export const Wrapper = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0;
	&.canvasTeaser {
		position: relative;
		width: 100%;
		height: 100%;
		display:flex;
		align-items:center;
		justify-content:center;
	}

	${(props) =>
		props.type === 'round' &&
		css`
			border-radius: 50%;
			overflow: hidden;
		`}

	&.canvasTeaser canvas {
		position: relative;
		width: auto !important;
		height: 100% !important;
	}

	&.center {
		bottom: auto;
		left: auto;
		margin-left: auto;
		margin-right: auto;
	}

	&.iosStart {
		bottom: auto;
		left: 0;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		z-index: 2;
		canvas {
			top: 14%;
			position: relative;
		}
	}

	&.right {
		left: auto;
		right: 0;
	}

	&.helpRight {
		position: absolute;
		right: -5%;
		left: auto;
		bottom: 23%;
		z-index: 2;
	}

	&.help {
		z-index: 2;
		top: auto;
		left: 0;
		bottom: 0;

		canvas {
			position: relative;
		}
	}

	&.top {
		top: 0;
		bottom: auto;
		z-index: 1;
	}

	&.helpHome,
	&.helpLock {
		top: auto;
		left: 0;
		bottom: 0;
		z-index: 1;

		canvas {
			position: relative;
		}
	}

	&.helpHomeRight {
		right: 0;
		left: auto;
		z-index: 1;

		canvas {
			position: relative;
		}
	}

	&.stoerer {
		z-index: 3;
		width: 7em;
		left: 92%;
		bottom: auto;
		top: -8%;
		transform: translate3d(-50%, 0, 0);
	}

	&.stoererRound {
		top: -5%;
		bottom: auto;
		z-index: 3;
		width: 10em;
		left: 68%;
	}

	&.preloader {
		position: absolute;
		left: 0;
		top: 0;
		bottom: auto;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		pointer-events: none;

		canvas {
			width: 15% !important;
			height: auto !important;
		}
	}

	&.animate-in {
		transition: all 0s ease-out;
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}

	&.animate-out {
		transition: all 0.6s ease-out;
		transform: translate3d(0, -120%, 0);
		opacity: 0;
	}
`;
