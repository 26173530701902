import React from 'react';
import { getIconIdByTeaserType } from '../../../helpers/Utils';
import { Wrapper, Icon, TitleContainer, Title, Image, ImageContainer, Tag, TagKika, TagAdult, TagIcon } from './SearchResultStyle';

interface ISearchResultProps {
	title: string;
	topline?: string;
	url?: string;
	site?: string;
	imageUrl?: string;
	documentType?: string;
	contentType?: string;
	contentCategory?: string;
	downloadNotification?: string;
	adultContent?: boolean;
	roundImage?: boolean;
	onClick?: (url: string, site?: string, title?: string) => void;
}

export const SearchResult = (props: ISearchResultProps) => {
	let addClassNames = '';
	if (props.roundImage) addClassNames += 'roundImage ';
	if (props.contentType === 'start') addClassNames += 'contentTypeStart ';

	const iconId = getIconIdByTeaserType(props);

	// const tagTitles = {
	// 	'video': 'Video',
	// 	'song': 'Lied',
	// 	'tinker': 'Basteln',
	// 	'adultContent': 'Artikel',
	// 	'games': 'Spiel',
	// 	'recipe': 'Rezept',
	// 	'paint': 'Malen',
	// 	'kika': 'Artikel'
	// };
	// const tagTitlesByContentType = {
	// 	'start': 'Übersicht',
	// 	'bundle': 'Übersicht',
	// 	'video': 'Video'
	// };
	const tagTitlesByDocumentType = {
		'contentKit': 'Übersicht',
		'multimediaGallery': 'Mediengalerie',
		'interactiveContent': 'Spiel',
		'relatedVideo': 'Video',
		'externalVideo': 'Video',
		'video': 'Video',
		'broadcastSeries': 'Sendung'
	};
	// Übersicht (contentType	"start,bundle")

	// const tagTitle = tagTitles[props.contentCategory];
	// const tagTitle = tagTitlesByContentType[props.contentType];
	const tagTitle = tagTitlesByDocumentType[props.documentType];

	const downloadIds = {
		'tinker': 'Bastelanleitung verfügbar',
		'song': 'Liedtext verfügbar',
		'games': 'Spielanleitung verfügbar',
		'paint': 'Ausmalbild verfügbar',
		'recipe': 'Rezept verfügbar'
	};
	const downloadText = (downloadNotification: string): string => {
		// example: downloadNotification: "[tinker, paint, keine Angabe, paint]"
		if (!downloadNotification || typeof downloadNotification !== 'string') return null;

		const allIds = downloadNotification.slice(downloadNotification.indexOf('[') + 1, downloadNotification.indexOf(']'));

		const id = allIds.split(',')[0].trim();
		if (!id) return null;

		const text = downloadIds[id];
		if (text) return text;

		return 'Download verfügbar';
	}

	return <>
		<Wrapper
			onClick={() => {
				props.onClick(props.url, props.site, props.title);
			}}
			className={addClassNames}>
			<ImageContainer>
				<Image src={props.imageUrl} />
				{ iconId && <Icon code={iconId} /> }
			</ImageContainer>
			<TitleContainer>
				<Title>{ props.title }</Title>
				{ tagTitle &&
					<Tag>{tagTitle}</Tag>
				}

				{ downloadText(props.downloadNotification) &&
					<Tag><TagIcon code={'download'}/>{ downloadText(props.downloadNotification) }</Tag>
				}
				{ props.adultContent &&
					<TagAdult><TagIcon code={'toright'}/>für Eltern</TagAdult>
				}
				{ !props.adultContent && props.site === 'kika' &&
					<TagKika><TagIcon code={'toright'}/>zu kika.de</TagKika>
				}
			</TitleContainer>
		</Wrapper>
    </>;
};

export default SearchResult;
