import React, { Component } from 'react';
import KknButton from 'components/fragments/KknButton/KknButton';
import { inject, observer } from 'mobx-react';
import { Navi, NaviBg, Wrapper } from './BreadcrumbStyle';
import { Statics } from 'helpers/Utils';

@inject('assetStore')
@inject('appStore')
@inject('routingStore')
@observer
class Breadcrumb extends Component {
	state = {
		open: '',
		blendMode: '',
		detailOpen: false
	};
	breadNavi = null;
	rootImage = null;
	lastRootImage = '';

	componentDidUpdate() {
		this.lastRootImage = this.rootImage;
	}

	componentWillUnmount() {}

	checkIfToggleOrLink = () => {};

	gotoLink = (url, type, index, currentDefault) => {
		console.log('#type', type);
		let urlCall = url;
		if (type === 'back') {
			urlCall = this.props.appStore.lastRoute;
		}
		if (type === 'search') {
			this.props.appStore.toggleSearch(true);
			return false;
		}

		//this.props.addVisitedTeaser({ id: 'reset' });
		this.props.onClick(urlCall, type, index, this.breadNavi.length, currentDefault);
	};

	mainButtonAction = type => {
		this.props.appStore.lastVisited = null;
		this.props.appStore.smallLoader = false;

		if (type === 'start' || this.props.appStore.detailOpen === null || this.props.appStore.detailOpen.isSingleDeeplink === true) {
			let url = '/start-neu/kikaninchen-startseite-100.html';

			if (this.props.appStore.detailOpen && this.props.appStore.detailOpen.isSingleDeeplink === true) {
				url = this.breadNavi[this.breadNavi.length - 2].url;
			} else {
				url = this.breadNavi[0].url;
				//url = '/start-neu/kikaninchen-startseite-100.html';
			}

			this.gotoLink(url, null, 0);
		} else {
			this.props.onDetailClosed(this.props.appStore.detailOpen);
		}
	};
	getNavi = (hasToggle, naviClass) => {
		let detailOpen = this.props.appStore.detailOpen;

		this.breadNavi = this.props.assetStore.docData.breadcrumb;
		const { template, contentType } = this.props.assetStore.docInfos;

		if (this.breadNavi.length === 0) return null;
		if (!detailOpen && this.breadNavi.length === 1 && this.breadNavi[0].sceneId === Statics.CONTENT_TYPE_START) return null;

		let breadItems = [];

		const isSearchRef = this.props.appStore.lastRoute === '/search';
		const hasRef = this.props.appStore.lastRoute !== document.location.pathname;
		const isHomepageRef = this.props.appStore.lastRoute === '/index.html';

		const isBroacastStart = (hasRef && template === 'broadcast' && contentType === 'start' && isHomepageRef);
		const isGlobal = template === 'global';

		let clickType = 'back';
		if (isSearchRef) clickType = 'search';

		this.breadNavi.map((box, i) => {
			let lastItem = i > 0;
			if (i === this.breadNavi.length - 1 && isBroacastStart) return null;
			if (i> 0 && isGlobal && !isSearchRef) return null;

			if (i > 0) breadItems.push(this.getBreadButton(box, i, lastItem ? clickType : type));
		});
		let globalAssets = this.props.assetStore.globalAssets;
		let interactiveOpen = detailOpen && detailOpen.type === 'interactiveContent';
		if (detailOpen) naviClass = 'detailOpenNav';
		this.rootImage =
			this.props.appStore.detailOpen !== null && this.breadNavi && this.breadNavi[1] && this.breadNavi[1].breadcrumbImage;
		let type = this.props.appStore.detailOpen ? 'externalBack' : this.breadNavi[0].sceneId;

		return (
			<React.Fragment>
				<Navi className={naviClass}>
					<KknButton
						animateIn={this.lastRootImage !== this.rootImage}
						image={null}
						type={this.props.appStore.detailOpen ? 'close' : type}
						onClicked={() => {
							this.mainButtonAction(clickType);
						}}
					/>
					{detailOpen ? null : breadItems}
				</Navi>
				{interactiveOpen ? <NaviBg src={globalAssets.getItem('btnBgExt').src} /> : null}
			</React.Fragment>
		);
	};
	getBreadButton = (box, i, clickType) => {
		let url = box.url;
		let size = null;
		let type = box.sceneId;
		let variant = 'start';

		// limit to max 3 items

		if (i > 2) {
			return null;
		}

		// no redirect to itself
		if (box.url === document.location.pathname && box.sceneId.indexOf('broadcast') === -1) {
			return null;
		}

		if (i === 1 && box.sceneId.indexOf('broadcast') !== -1 && this.breadNavi.length === 2) {
			type = 'back';
		}

		if (i > 1 && box.isCurrentDefaultDocument === 'true' && box.sceneId.indexOf('broadcast') === -1) {
			return null;
		}

		if (i === 1 && box.isCurrentDefaultDocument === 'true' && box.sceneId.indexOf('broadcast') === -1) {
			return null;
		}

		if (i === 1 || i === 2) size = 8.75;

		if (i === 1 && box.sceneId.indexOf('broadcast') === -1) {
			type = 'back';
			variant = this.props.animateDir;
		}
		if (i === 2 && box.sceneId.indexOf('broadcast') !== -1) {
			url = this.breadNavi[i - 1].url;
			type = 'back';
			variant = this.props.animateDir;
		}
		return (
			<KknButton
				animateIn={type !== 'back'}
				variant={variant}
				size={size}
				image={type !== 'back' && type !== 'start' ? box.breadcrumbImage : null}
				type={type}
				key={'breadcrumb-' + i}
				sceneId={box.sceneId}
				onClicked={() => {
					this.gotoLink(url, clickType, i, box.isCurrentDefaultDocument);
				}}
			/>
		);
	};

	render() {
		let scaleIn = '';

		if (this.props.assetStore.docData.breadcrumb === undefined) return <div />;
		if (this.props.appStore.detailOpen && this.props.appStore.detailOpen.type === 'interactive') {
			scaleIn = 'scaleIn';
		}

		return <Wrapper className={scaleIn}>{this.getNavi(true, 'naviBig')}</Wrapper>;
	}
}

export default Breadcrumb;
