import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { BottomButtons, ParentalBack, ParentalCheck, ParentalGateWrapper, ParentalItem, ParentalItemWrapper } from './ParentalGateStyle';
import { StandardButton } from 'components/basics/Primitives';

@inject('appStore')
@observer
class ParentalGate extends Component {
	state = {
		open: false,
		matched: false,
		feedback: false,
		time: 0,
		percentTime: 0,
		voice: 0
	};
	initialized = false;
	matched = false;
	hitted = false;
	parentalIndex = 0;
	lastOpened = false;

	parentalItems = [
		{ id: 0, name: ' eine Nuss ' },
		{ id: 1, name: ' einen Apfel ' },
		{ id: 2, name: ' eine Birne ' },
		{ id: 3, name: ' eine Möhre ' }
	];
	isUnmounted = false;

	componentDidMount() {
		this.parentalItems.map((data, i) => {
			this.addDragListener(data);
		});
		this.setState({ percentTime: 0.065, time: 0 });
	}

	componentDidUpdate = () => {
		this.initialized = true;
		this.lastOpened = this.props.appStore.parentalGateOpen;
	};

	addDragListener = (target) => {
		let element = window.$(this.refs['dragItem' + String(target.id)]);
		element.data('id', target.id);
		element.css('left', '');
		element.css('top', '');

		let hammertime = element.hammer();
		hammertime.off('drag.adult');
		hammertime.off('dragend.adult');

		element.on('click', (evt) => {
			/*let x = evt.gesture !== undefined ? evt.gesture.center.pageX : evt.pageX;
            let y = evt.gesture !== undefined ? evt.gesture.center.pageY : evt.pageY;

            let cont = window.$(this.refs.cont);
            let mouse = window.$(this.refs.mouse);
            let mouseLeft = mouse.offset().left - cont.offset().left;
            let mouseRight = mouseLeft + mouse.width();
            let mouseTop = mouse.offset().top - cont.offset().top;
            let mouseBottom = mouseLeft + mouse.height();

            let finalX = (x - element.width() / 2) - cont.offset().left;
            let finalY = (y - element.height() / 2) - cont.offset().top;
            let borderLeft = cont.width();
            let borderTop = cont.height();

            if (finalY < 0) finalY = 0;
            if (finalX < 0) finalX = 0;
            if (finalX > borderLeft - element.width() / 2) finalX = borderLeft - element.width() / 2;
            if (finalY > borderTop - element.height() / 2) finalY = borderTop - element.height() / 2;

            element.css("left", finalX);
            element.css("top", finalY);
            */
			this.hitted = true;
			if (this.parentalIndex === element.data().id) {
				this.matched = true;
			} else {
				this.hitted = false;
				this.matched = false;
			}
		});
		element.on('mousedown', (evt) => {
			this.hitted = true;
			if (this.parentalIndex === element.data().id) {
				this.matched = true;
			} else {
				this.matched = false;
			}

			if (this.state.parentalIndex !== element.data().id && this.matched === false) {
				element.css('left', '');
				element.css('top', '');
				this.setState({ open: true, feedback: this.hitted });
			} else {
				this.setState({ feedback: this.hitted });

				if (this.matched === true) this.props.appStore.redirectAfterParentalCheck();
				this.matched = false;
			}

			setTimeout(
				() => {
					this.setState({ feedback: false });
					this.hitted = false;

					element.css('left', '');
					element.css('top', '');
				},
				this.hitted ? 1000 : 0
			);
		});
	};

	componentWillUnmount() {
		this.isUnmounted = true;
	}

	getParentalGate = (open) => {
		let bgImage = { backgroundImage: 'url(/static_kkn/global/images/parentalGate.png)' };
		if (this.state.feedback !== true) this.parentalIndex = Math.floor(Math.random() * this.parentalItems.length);

		let parentalCurrent = this.parentalItems[this.parentalIndex].name;

		return (
			<ParentalCheck>
				<ParentalItemWrapper
					className={(this.matched ? 'hasMatch' : null) + ' ' + (this.state.feedback ? 'hasFeedback' : null)}
					ref="cont"
				>
					<h3>Kindersicherung</h3>
					<h4>
						Bestätigen Sie, dass Sie wirklich ein Erwachsener sind, indem sie <span>{parentalCurrent + ' '}</span>
						für die Maus auswählen.
					</h4>
					<ParentalItem ref="dragItem0" className={'nut'}>
						<span style={bgImage} />
					</ParentalItem>
					<ParentalItem ref="dragItem1" className={'apple'}>
						<span style={bgImage} />
					</ParentalItem>
					<ParentalItem ref="dragItem2" className={'citron'}>
						<span style={bgImage} />
					</ParentalItem>
					<ParentalItem ref="dragItem3" className={'carrot'}>
						<span style={bgImage} />
					</ParentalItem>
					<ParentalItem ref="feedback" className={'feedback'}>
						<span style={bgImage} />
					</ParentalItem>
					<ParentalItem ref="mouse" className={'mouse'}>
						<span style={bgImage} />
					</ParentalItem>
				</ParentalItemWrapper>
				<BottomButtons>
					<StandardButton onClick={this.onCancel}>Abbrechen</StandardButton>
				</BottomButtons>
			</ParentalCheck>
		);
	};
	onCancel = () => {
		// leave parent layer open, in case search was open // this.props.appStore.closeAdultLayer();
		this.props.appStore.adultTimerOpen = false;
		this.props.appStore.clearParentalGate();
	};

	render() {
		let open = this.props.appStore.parentalGateOpen && this.props.appStore.parentalGateOpen.open === true ? 'open' : '';
		return (
			<ParentalGateWrapper
				delay={(this.props.appStore.parentalGateOpen && this.props.appStore.parentalGateOpen.delay) || null}
				className={open}
			>
				<ParentalBack onClick={this.onCancel} />
				{this.getParentalGate()}
			</ParentalGateWrapper>
		);
	}
}

export default ParentalGate;
