import styled from 'styled-components';
import { blueRabbitHand, gfx } from 'helpers/layoutVariables';

export const ParentalBack = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	background-color: rgba(0, 0, 0, 0);
	${blueRabbitHand};
`;

export const ParentalGateWrapper = styled.div`
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: translate3d(0, 0, 0);
	transition: 0.2s transform ease-out;
	transition-delay: ${(props) => (props.delay ? props.delay : '0')};

	&.open {
		transform: translate3d(0, -100%, 0);
	}
`;

export const ParentalItemWrapper = styled.div`
	width: 100%;
	min-height: 44em;
	position: relative;
	z-index: 1;

	h3 {
		color: ${gfx.yellow};
		font-size: ${gfx.fontSizeBigHeadline};
		text-align: center;
		display: block;
		margin-bottom: 1em;
	}
	h4 {
		margin-top: 0;
		font-size: 3em;
		color: #fff;
		text-align: center;
		padding: 0 3em;
		line-height: 1.5em;
		span {
			color: ${gfx.yellow};
		}
	}

	&.hasMatch {
		.feedback {
			span {
				background-position: -33em 0;
			}
		}
	}
	&.hasFeedback {
		.feedback {
			transform: scale(1);
		}
	}
`;

export const ParentalItem = styled.button`
	border: 0;
	background-color: transparent;
	position: absolute;
	width: 15em;
	height: 12em;
	overflow: hidden;
	z-index: 1;
	font-size: 1em;
	${blueRabbitHand};

	span {
		position: absolute;
		width: 100%;
		height: 24em;
		top: 0;
		left: 0;
		background-size: cover;
	}
	&.mouse {
		top: 21em;
		left: 26em;
		width: 40em;
		height: 24em;
		z-index: 0;
		span {
			height: 30em;
			left: 5em;
			width: 25em;
		}
	}
	&.feedback {
		width: 15em;
		top: 43%;
		left: 41%;
		height: 20em;
		transform: scale(0);
		transition: transform 0.3s ease-out;

		span {
			background-position: -19em 0;
		}
	}
	&.nut {
		left: 13em;
		top: 19em;

		span {
			background-position: -61em 0;
		}
	}
	&.apple {
		left: 13em;
		top: 31em;

		span {
			background-position: -90em -11em;
		}
	}
	&.citron {
		right: 13em;
		top: 19em;

		span {
			background-position: -61em -11em;
		}
	}
	&.carrot {
		right: 13em;
		top: 31em;

		span {
			background-position: -90em 0;
		}
	}
`;

export const BottomButtons = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 2%;
	margin-bottom: 4%;
`;

export const ParentalCheck = styled.div`
	width: 80%;
	background-color: ${gfx.deepBlue};
	border-radius: 3em;
`;
