import styled, { css } from 'styled-components';

export const FullscreenButtonWrapper = styled.div`
	position: absolute;
	left: 2.5em;
	top: 3em;
	transform: scale(2.5);
	transform-origin: 0 0;
	z-index: 2;
`;

export const Logo = styled.img`
	height: 20%;
	width: 100%;
	position: absolute;
	transition: all 0.2s ease-in-out;
	transform-origin: 50% 50%;
	left: 0;
	transform: translate3d(0, -60%, 0);
	top: 50%;
`;

export const PreloaderWrapper = styled.div`
	width: 100%;
	left: 0;
	top: 0;
	height: 100%;
	display: flex;
	z-index: 5;
	position: absolute;
	justify-content: center;
	align-items: center;
	transition: transform 0.4s ease-out;

	${(props) =>
		props.hide === true &&
		css`
			transform: translate3d(0, 100%, 0);
		`}
`;
