import React, { Component } from 'react';
import Teaser from 'components/kits/Teaser/Teaser';

import { inject, observer } from 'mobx-react';
import { Background, Wrapper } from './TransitionBackgroundStyle';
import Animator from '../../basics/Animation/Animator';
import { logger } from '../../../helpers/Utils';

@inject('assetStore')
@inject('appStore')
@inject('routingStore')
@observer
class TransitionBackground extends Component {
	state = {
		open: false
	};
	lastScope = false;
	scope = false;
	isUnmounted = false;

	componentDidMount() {}

	componentWillUnmount() {
		this.isUnmounted = true;
	}
	componentDidUpdate() {
		this.lastScope = this.scope;
	}

	render() {
		let teaserParams = null;
		let bgId = 'teaserBgIndex';
		let bgUrl = null;
		let skinnedAssets = null;
		if (this.props.assetStore.globalAssets && this.props.assetStore.globalAssets.getItem(bgId)) {
			bgUrl = this.props.assetStore.globalAssets.getItem(bgId).src;
		}
		if (this.props.assetStore.cachedThemeAssets[this.props.assetStore.docInfos.scope]) {
			skinnedAssets = this.props.assetStore.cachedThemeAssets[this.props.assetStore.docInfos.scope].assets;
			if (skinnedAssets.getItem(bgId)) bgUrl = skinnedAssets.getItem(bgId).src;
		}
		this.scope = false;
		let isBroadcast = '';

		if (this.props.appStore.lastVisited && this.props.appStore.lastVisited.props) {
			teaserParams = this.props.appStore.lastVisited.props;
		}

		if (this.props.scope === 'external' || this.props.appStore.smallLoader === true) {
			isBroadcast = 'isExternal';
		}

		teaserParams = {
			style: {
				width: '40em',
				height: '40em'
			},
			...this.props.assetStore.docData.document,
			bgUrl: bgUrl,
			imageUrl: this.props.assetStore.docData.document.teaserImage && this.props.assetStore.docData.document.teaserImage.urlScheme,
			standard: {
				sophoraId: this.props.assetStore.docData.document.sophoraId
			}
		};

		return (
			<Wrapper active={this.props.assetStore.isLoading === false && this.props.scope !== 'external'} open={this.props.open}>
				<Background variant={isBroadcast}>
					<img src={'/static_kkn/global/images/rahmen_down.png'} />
				</Background>
				{this.props.assetStore.isHome() === false &&
				this.props.assetStore.isLoading === true &&
				this.props.assetStore.lastSophoraId !== this.props.assetStore.docData.document.sophoraId &&
				teaserParams &&
				this.props.scope !== 'external' ? (
					<Animator  key={'ani-trans-teaser'} id={'ani-trans-teaser'} active={true}>
						<Teaser
							scaleClass={teaserParams.scaleClass}
							stoererId={teaserParams.stoererId}
							skinnedAssets={skinnedAssets}
							globalAssets={this.props.assetStore.globalAssets}
							indexAssets={teaserParams.indexAssets}
							data={teaserParams}
							onClick={() => {}}
							playCanvasFrame={'loading'}
							key={'teaser' + teaserParams.key}
							scope={teaserParams.scope}
							id={teaserParams.id}
							imageUrl={teaserParams.imageUrl}
							bgUrl={teaserParams.bgUrl}
							isHome={false}
							isBundle={false}
							type={'round'}
							isBroadcastStart={true}
							style={teaserParams.style}
						/>
					</Animator>
				) : null}
			</Wrapper>
		);
	}
}

export default TransitionBackground;
