import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Paginator from '../../basics/Paginator/Paginator';
import { Wrapper } from './BundleScrollerStyle';
import Slider from '../../basics/Slider/Slider';

@inject('assetStore')
@inject('appStore')
@observer
class BundleScroller extends Component {
	componentDidMount = () => {
		let passedChilds = React.Children.toArray(this.props.children);
		this.props.appStore.contentScrollerItemsLength = passedChilds.length;
	};
	isActive = () => {
		return React.Children.toArray(this.props.children).length > 4;
	};

	render() {
		return (
			<Wrapper>
				{this.isActive() ? <Paginator /> : null}
				{this.props.appStore.redrawSlider !== true ? (
					<Slider
						isActive={this.isActive()}
						isGrid={true}
						type={'vertical'}
						innerPadding={'12.5em 25em'}
						offsetBefore={this.props.appStore.scale * 200}
						offsetAfter={this.props.appStore.scale * 200}
						width={'100%'}
						height={'100%'}
					>
						{this.props.children}
					</Slider>
				) : null}
			</Wrapper>
		);
	}
}

export default BundleScroller;
