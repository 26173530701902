/* @flow */
import { observable } from 'mobx';
import Preloader from '../helpers/Preloader';
import { capitalizeFirstLetter, getInfosFromDocumentCanvasId, getMergedTeaserData, getPath, Statics } from '../helpers/Utils';
import stores from './index';
import React from 'react';

export default class AssetStore {
	@observable globalAssets = null;

	lazyAssets = {};
	cachedLazyAssets = {};
	docAssets = {};
	@observable docInfos = {};
	lastSophoraId = '';
	@observable docData = {};
	layerDocData = {};
	docAdultData = {};
	@observable adultLayerContent = { data: null, externalData: null };

	cachedThemeAssets = {};
	@observable cachedAdultTips = {};
	@observable globalAssetsLoaded = false;

	@observable ready = false;
	@observable isLoading = false;
	@observable initStart = false;
	@observable firstCurtainDropped = false;

	@observable preloadProgress = 0;
	lastProgress = -1;

	preloaderDocs = null;
	preloaderGlobal = null;
	preloaderAssets = null;
	preloaderAdult = null;
	loadCallback = null;
	fallbackManifestLoaded = false;
	apiRequested = false;
	int = 0;

	startLoading() {
		this.onLoadingStart();
		this.preloadProgress = 0;
	}

	improvementActive = () => {
		let active = false;
		if (this.docData.settings && this.docData.settings.improvementActive) {
			active = true;
		}
		return active;
	};

	improvementActive = () => {
		let active = false;
		if (this.docData.settings && this.docData.settings.improvementActive) {
			active = true;
		}
		return active;
	};

	loadGlobalAssets() {
		// basic assets for complete kkn
		this.preloaderGlobal = new Preloader();
		this.preloadProgress = 0;
		this.lastProgress = 0;

		this.onLoadingStart();

		let canvasBase = '/static_kkn/global/clickons/';

		this.preloaderGlobal.load({
			cb: this.handleComplete,
			basePath: canvasBase,
			onProgress: evt => {
				this.updateProgress(evt, 'first');
			},
			url: '/static_kkn/global/json/assets.json'
		});
	}

	handleComplete = (evt, queue) => {
		this.globalAssets = queue;
		this.globalAssetsLoaded = true;

		if (stores.appStore.error) {
			stores.appStore.stateError = true;
		}
	};

	loadDocument = p => {
		// document json data
		if (this.ready === true) this.initStart = true;

		this.preloaderDocs = new Preloader();
		if (this.ready === true) {
			this.preloadProgress = 0;
			this.lastProgress = -1;
		}

		this.onLoadingStart();

		let props = p;

		let items = [
			{
				id: 'content',
				type: 'json',
				src: getPath(props)
			}
		];
		this.preloaderDocs.load({
			basePath: '/static_kkn/global/clickons/',
			cb: this.onDocumentDataLoaded,
			url: items,
			onError: this.onErrorDocumentLoad,
			onProgress: evt => {
				this.updateProgress(evt, this.ready ? 'first' : 'sec');
			}
		});
	};

	updateProgress = (evt, start) => {
		if (this.lastProgress < evt.progress) {
			if (start === 'first') this.preloadProgress = evt.progress * 0.5;
			if (start === 'sec') this.preloadProgress = 0.5 + evt.progress * 0.5;
		}
		//this.preloadProgress = evt.progress;
		//this.lastProgress = this.preloadProgress;
	};

	onErrorDocumentLoad = () => {
		//this.loaderReady();
		this.onAllLoadingEnd();
		stores.routingStore.push('/404');
	};

	// Check if broadcast or "normal" content, load assets if needed

	onDocumentDataLoaded = (evt, queue) => {
		this.docData = queue.getResult('content');
		this.docAssets = queue;
		stores.trackingStore.checkActiveTracking();

		if (this.docData.document === undefined) {
			this.onErrorDocumentLoad();
			return false;
		}
		let tempDocs = getInfosFromDocumentCanvasId(this.docData.document.documentCanvasId);
		tempDocs.seasonId = capitalizeFirstLetter(this.docData.settings.seasonId);
		this.docInfos = tempDocs;

		if (this.docData.document.parentalTeaserBox) {
			this.docAdultData = this.docData.document.parentalTeaserBox[0];
			this.addAdultInfo({ data: { teaserBox: this.docAdultData } });
		}
		window.kknApp.checkMetaTags(this.docData);

		// load lazy other assets as needed
		let preloadItems = [];
		let startAssets = 0;
		let globalAssets = 0;
		let pool = this.globalAssets.getResult('assetsPool') && this.globalAssets.getResult('assetsPool').manifest;
		let init = 'startIntro';
		let boxes = [];
		if (this.docData.document.teaserBoxes) {
			this.docData.document.teaserBoxes.map((data, i) => {
				let id = data.boxClassifier;
				if (i > 0) {
					id = i % 2 !== 0 ? 'startMainOdd' : 'startMainEven';
				}
				if (this.docInfos.seasonId) {
					id = id + this.docInfos.seasonId;
				}
				boxes.push(id);
			});
		}
		if (pool) {
			pool.map((data, i) => {
				let startAsset =
					this.docInfos.template === Statics.SCENE_TYPE_HOME &&
					boxes.toString().indexOf(data.id) !== -1 &&
					data.id.indexOf(this.docInfos.seasonId) !== -1;
				if (startAsset) startAssets++;

				let globalAsset = this.getGlobalAsset(data);
				if (globalAsset) globalAssets++;

				let helpAsset = null;

				if (this.docData.document.documentProperties && this.docData.document.documentProperties.supportDocument) {
					if (
						this.docData.document.documentProperties.supportDocument.canvasId &&
						this.docData.document.documentProperties.supportDocument.canvasId.length > 0 &&
						this.docData.document.documentProperties.supportDocument.canvasId === data.id
					)
						helpAsset = this.docData.document.documentProperties.supportDocument.canvasId;
				}
				let stoererAsset = null;
				if (this.docData.document.teaserBoxes) {
					this.docData.document.teaserBoxes.map((teaserBox, i) => {
						teaserBox.teasers.map((teaser, j) => {
							if (teaser.standard.teaserStyling === data.id) {
								stoererAsset = data;
							}
						});
					});
				}

				if (
					this.lazyAssets[data.id] === undefined &&
					(startAsset || (this.docInfos.scope === null && globalAsset) || helpAsset || stoererAsset)
				) {
					preloadItems.push(data);
					this.lazyAssets[data.id] = data;
				}
			});
			if (startAssets === 0 && this.docInfos.template === Statics.SCENE_TYPE_HOME) {
				pool.map((data, i) => {
					let startAsset = data.id === init || data.id === 'startMainOdd' || data.id === 'startMainEven';
					if (this.lazyAssets[data.id] === undefined && startAsset) {
						preloadItems.push(data);
						this.lazyAssets[data.id] = data;
					}
				});
			}
			if (globalAssets === 0 && this.docInfos.template === Statics.SCENE_TYPE_GLOBAL) {
				pool.map((data, i) => {
					if (this.lazyAssets[data.id] === undefined && this.getThemedId(this.docInfos.category) === data.id) {
						preloadItems.push(data);
						this.lazyAssets[data.id] = data;
					}
				});
			}
		}
		//Fallback Start
		preloadItems.length > 0 ? this.loadLazyAssets(preloadItems) : this.onDocLoadingEnd();
	};
	getGlobalAsset = data => {
		let assetCheck = false;
		let asset = this.getThemedId(this.docInfos.category);
		let assetCheckFallback = this.getThemedId(this.docInfos.category, true);
		assetCheck = asset === data.id;
		if (!assetCheck) {
			assetCheck = assetCheckFallback === data.id;
		}
		return assetCheck;
	};
	hasDetail = () => {
		let id = stores.appStore.getCurrentQuery().detailId;
		return this.getTeaserDataById(id) ? true : false;
	};

	getTeaserDataById = (id, boxPassed) => {
		let boxesBase = (this.docData && this.docData.document && this.docData.document.teaserBoxes) || null;
		let boxes = null;
		if (boxesBase) {
			boxes = boxesBase[stores.appStore.pageIndex];
		}
		if (boxPassed) boxes = boxPassed;

		let teaser = null;
		if (boxes) {
			let items = boxes.teasers;
			if (items)
				items.map((teaserData, i) => {
					if (id === teaserData.standard.sophoraId) teaser = teaserData;
				});
		}
		return teaser;
	};
	isHome = () => {
		return (
			this.docInfos && this.docInfos.template === Statics.SCENE_TYPE_HOME && this.docInfos.contentType === Statics.CONTENT_TYPE_START
		);
	};
	isTabbed = () => {
		return this.isHome() || (this.docInfos && this.docInfos.contentType === Statics.CONTENT_TYPE_INDEX_TABBED);
	};
	isBundle = () => {
		return this.docInfos && this.docInfos.contentType === Statics.CONTENT_TYPE_BUNDLE ? true : false;
	};
	getHelpMediaForIndex = index => {
		let item = null;
		let helpMedia = getMergedTeaserData(this.docData.document, 'helpMedia');
		let teaserBoxForIndex =
			this.docData && this.docData.document && this.docData.document.teaserBoxes && this.docData.document.teaserBoxes[index];
		if (index !== undefined && teaserBoxForIndex && teaserBoxForIndex.helpMedia) {
			helpMedia = teaserBoxForIndex.helpMedia;
		}
		let rand = Math.floor(Math.random() * (helpMedia.length - 1));
		return helpMedia[rand];
	};

	getThemedId = (state, fallback) => {
		let scope = 'global';
		let seasonAssetsLoaded = false;
		if (this.isTabbed()) {
			scope = Statics.CONTENT_TYPE_INDEX_TABBED;
		}
		let themeAssets = this.cachedThemeAssets[this.docInfos.scope];
		if (themeAssets) {
			scope = themeAssets.broadcastId;
		}
		//Fallback to Video tab screen if teaserboxes > 1
		if (!this.isHome() && this.isTabbed() && this.docData.document.teaserBoxes.length > 1) {
			scope = Statics.CONTENT_TYPE_INDEX_TABBED;
			state = 'videos';
		}
		let t = scope + capitalizeFirstLetter(state);

		let temp = null;

		if (state === 'manifest') t = 'manifest';
		if (fallback !== true && this.docInfos.seasonId && state.indexOf(this.docInfos.seasonId) === -1) {
			temp = t + this.docInfos.seasonId;
			if (!themeAssets) {
				t = temp;
			} else {
				if (themeAssets.assets.getResult(temp) !== null) t = temp;
			}
		}

		return t;
	};
	getMergedItems = () => {
		let items = [];
		if (stores.assetStore.docData && stores.assetStore.docData.document)
			items = getMergedTeaserData(stores.assetStore.docData.document);
		return items;
	};
	getHelpMedia = () => {
		let items = [];
		if (stores.assetStore.docData && stores.assetStore.docData.document)
			items = getMergedTeaserData(this.props.assetStore.docData.document, 'helpMedia');

		return items;
	};
	getSceneAnimation = id => {
		let name = this.getThemedId(id);
		let themeAssets = this.cachedThemeAssets[this.docInfos.scope];
		if (themeAssets) {
			if (this.cachedThemeAssets[this.docInfos.scope].assets._loadedResults[name] === undefined) {
				name = this.getThemedId(id, true);
			}
		} else {
			if (this.lazyAssets[this.getThemedId(id)] === undefined) name = this.getThemedId(id, true);
		}
		return name;
	};

	onDocLoadingEnd = () => {
		// cached theme assets?
		if (this.docInfos.scope) {
			if (this.cachedThemeAssets[this.docInfos.scope] !== undefined) {
				this.onAllLoadingEnd();
			} else {
				this.loadThemedAssets();
			}
		} else {
			this.onAllLoadingEnd();
		}
	};
	onAllLoadingEnd = () => {
		this.preloadProgress = 1;
		stores.appStore.addAnimations();
		clearTimeout(this.int);
		this.int = setTimeout(this.forceRender, 1000);
	};
	forceRender = () => {
		this.ready = true;
		this.isLoading = false;
		stores.appStore.animate = 'in';
		stores.appStore.clickonsActive = true;
		stores.appStore.lastVisited = null;
		if (this.loadCallback) this.loadCallback();
		this.preloadProgress = -1;
		if (this.docData && this.docData.document) this.lastSophoraId = this.docData.document.sophoraId;

		const docData = this.docData.document;
		if (docData?.documentType === 'download') {
			this.addAdultInfo({
				data: {
					teaserBox: {
						teasers: [
							{
								standard: docData,
								special: docData.special
							}
						]
					}
				}
			});
		}
		const openWithAdultLayer = stores.appStore.getCurrentQuery().eLayer;
		if (openWithAdultLayer === 'active' || docData?.documentType === 'download') {
			stores.appStore.adultLayerOpen = true;
		}
	};
	onLoadingStart = () => {
		this.apiRequested = true;
		this.isLoading = true;
	};
	clearAdultInfo = () => {
		this.adultLayerContent = { data: null, externalData: null };
	};
	addAdultInfo = (data, s) => {
		let adultData = data;
		let preloadItems = [];
		let not = '';
		let scope = s || 'data';

		if (adultData && adultData.data && adultData.data.teaserBox) {
			adultData.data.teaserBox.teasers.map((teaser, i) => {
				if (teaser.notificationType && teaser.notificationType.length > 0)
					not = stores.appStore.getNotificationType(
						teaser.standard.contentCategory === 'competition' ? teaser.standard.contentCategory : teaser.notificationType
					);
				if (teaser.standard.documentType === 'parentalHint') {
					const id = 'parentTip-' + teaser.standard.sophoraId;
					if (this.cachedAdultTips[id] === undefined)
						preloadItems.push({
							id: 'parentTip-' + teaser.standard.sophoraId,
							src: process.env.REACT_APP_DEV_PROXY_API + teaser.url
						});
				}
			});
			if (preloadItems.length > 0) {
				let preloader = new Preloader();
				preloader.load({
					cb: evt => {
						for (let item in evt.target._loadedResults) {
							this.cachedAdultTips[item] = evt.target._loadedResults[item];
						}
						this.propagateAdultUpdate(scope, adultData);
					},
					onProgress: evt => {
						this.updateProgress(evt, 'sec');
					},
					url: preloadItems
				});
			} else {
				this.propagateAdultUpdate(scope, adultData);
			}
		} else {
			this.propagateAdultUpdate(scope, adultData);
		}
	};
	propagateAdultUpdate = (scope, data) => {
		this.adultLayerContent[scope] = data[scope];
		stores.appStore.showAdultHighlight = true;
		setTimeout(() => {
			stores.appStore.showAdultHighlight = false;
		}, 1000);
	};

	loadThemedAssets = fallback => {
		if (this.preloaderAssets) this.preloaderAssets.close();
		this.preloaderAssets = new Preloader();
		let basePath = '/static_kkn/sendungen/';

		if (this.docInfos.template === Statics.SCENE_TYPE_STYLED_GLOBAL) {
			basePath = '/static_kkn/styledGlobal/';
		}

		this.preloaderAssets.load({
			cb: this.onThemedAssetsLoaded,
			onProgress: evt => {
				this.updateProgress(evt, 'sec');
			},
			onError: evt => {
				if (this.fallbackManifestLoaded) this.loadThemedAssets(true);
				this.fallbackManifestLoaded = true;
			},
			basePath: basePath + this.docInfos.scope + '/clickons/',
			url: basePath + this.docInfos.scope + '/json/' + 'manifest' + '.json'
		});
	};

	loadLazyAssets = items => {
		this.preloaderGlobal.load({
			cb: this.onLazyAssetsLoaded,
			onProgress: evt => {
				this.updateProgress(evt, 0.5);

				// complete sometimes doesnt fire

				if (this.lastProgress === evt.progress) {
					this.onLazyAssetsLoaded();
				}
				this.lastProgress = evt.progress;
			},
			basePath: '/static_kkn/global/clickons/',
			url: items
		});
	};
	onLazyAssetsLoaded = (evt, queue) => {
		this.onDocLoadingEnd();
	};

	onThemedAssetsLoaded = (evt, queue) => {
		if (queue.getResult('config') === null) {
			this.fallbackManifestLoaded = true;
			return false;
		}

		let cache = {};

		cache.assets = queue;
		cache.broadcastId = queue.getResult('config').broadcastId;
		cache.clickonsData = evt;
		this.cachedThemeAssets[this.docInfos.scope] = cache;
		window.kknApp.cachedAssets[this.docInfos.scope] = cache;

		this.onAllLoadingEnd();
	};
}
