import React from 'react';
import { CenterWrapper, Wrapper, Item, Title, Intro } from './LastSearchStyle';
import Icon from '../../basics/Icon/Icon';

interface ILastSearchProps {
	searchTerms: string[];
    onClick?: (title: string) => void;
    onRemoveClick?: (title: string) => void;
}

export const LastSearch = (props: ILastSearchProps) => {
	return <>
		<CenterWrapper>
            <Wrapper>
                <Intro>Letzte Suche</Intro>
                {props.searchTerms.map((term, index) => {
                    return (
                        <Item
                            key={index}
                            onClick={() => {
                                props.onClick(term);
                            }}
                        >
                            <Title>{term}</Title>
                            <Icon
                                code={'close'}
                                onClick={(event) => {
                                    props.onRemoveClick(term);
                                    event.stopPropagation();
                                }}
                            />
                        </Item>
                    );
                })}
            </Wrapper>
        </CenterWrapper>
    </>;
};

export default LastSearch;
