import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import screenfull from 'screenfull';
import { Wrapper } from './FullscreenButtonStyle';
import Icon from 'components/basics/Icon/Icon';

@inject('appStore')
@inject('assetStore')
@observer
class FullscreenButton extends Component {
	toggleFullscreen = () => {
		this.props.appStore.toggleFullscreen();
	};

	render() {
		if (screenfull.isEnabled !== true) return null;

		return (
			<Wrapper pos={this.props.pos} onClick={this.toggleFullscreen}>
				<Icon
					size={this.props.size || null}
					sizeHeight={this.props.size || null}
					code={this.props.appStore.isFullscreen ? 'circleSmallscreenDarkmode' : 'circleFullscreenDarkmode'}
				/>
				{this.props.hideText !== true ? <span className={'settingsTitle'}>Vollbildmodus</span> : null}
			</Wrapper>
		);
	}
}

export default FullscreenButton;
