import AppStore from './appStore';
import AssetStore from './assetStore';
import TrackingStore from './trackingStore';

import { RouterStore } from 'mobx-react-router';

export default {
	appStore: new AppStore(),
	routingStore: new RouterStore(),
	assetStore: new AssetStore(),
	trackingStore: new TrackingStore()
	// place for other stores...
};
