import React, { Component } from 'react';
import { TextInput } from 'components/basics/Primitives';
import { FormItem, InputWrapper, Label, Message } from './FormStyle';
import { TextInputArea } from '../../basics/Primitives';

class FormField extends Component {
	state = {
		error: false
	};
	lastValidate = -1;
	isUnmounted = false;
	input = null;

	componentDidMount() {}

	componentWillUnmount() {
		this.isUnmounted = true;
	}

	componentWillReceiveProps = nextProps => {
		if (this.props.data.type === 'hidden') return null;

		if (this.lastValidate !== nextProps.forceValidate) {
			this.lastValidate = nextProps.forceValidate;
			this.onBlur();
		}
	};

	validEmail = value => {
		return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i.test(
			value
		);
	};
	validNumber = value => {
		return /^-?[0123456789]+$/i.test(value);
	};
	validEmpty = value => {
		return value.length !== 0;
	};
	onBlur = evt => {
		let valid = false;
		switch (this.props.data.type) {
			default:
				valid = this.validEmpty(this.input.value);
				break;
			case 'email':
				valid = this.validEmail(this.input.value);
				break;
		}
		this.setState({ error: !valid });
		let data = this.props.data;
		data.value = this.input.value;
		if (evt === undefined) this.props.onValidated(data, valid);
	};
	getErrorMessage = () => {
		if (this.state.error === false) return null;
		if (this.props.data.type === 'hidden') return null;

		let msg = 'Bitte das Feld ausfüllen';
		if (this.props.data.type === 'email') msg = 'Bitte eine gültige Email eintragen';
		return <Message>{msg}</Message>;
	};

	render() {
		let data = this.props.data;
		if (this.props.data.type === 'hidden') return <input ref={input => (this.input = input)} type={data.type} name={data.name} />;

		const textProps = {
			error: this.state.error,
			ref: input => (this.input = input),
			onBlur: this.onBlur,
			type: data.type,
			name: data.name
		};

		return (
			<FormItem key={data.name}>
				<Label>{data.label}</Label>
				<InputWrapper>
					{data.type === 'textarea' ? <TextInputArea {...textProps} /> : <TextInput {...textProps} />}
					{this.getErrorMessage()}
				</InputWrapper>
			</FormItem>
		);
	}
}

export default FormField;
