import Form from 'components/kits/Form/Form';
import { getImageUrl } from 'helpers/Utils';
import React, { Component } from 'react';

import Icon from 'components/basics/Icon/Icon';
import Link from 'components/basics/Link/Link';
import { Row } from 'components/basics/Primitives';
import { inject, observer } from 'mobx-react';
import {
	Button,
	ClaimerText,
	DownloadInner,
	DownloadLegal,
	Help,
	Inner,
	LinkText,
	TeaserDetails,
	TeaserText,
	Wrapper
} from './AdultLayerTeaserStyle';

@inject('assetStore')
@inject('appStore')
@inject('trackingStore')
@observer
class AdultLayerTeaser extends Component {
	state = {
		open: false
	};
	isUnmounted = false;
	wrapper = null;
	classDocType = null;

	componentDidMount() {
		this.classDocType = this.props.data && this.props.data.standard ? this.props.data.standard.documentType : '';
		window.$(this.wrapper).on('click', '.paragraph a', evt => {
			this.onHtmlLinkClicked(evt.target);
			return false;
		});
	}

	componentWillUnmount() {
		this.isUnmounted = true;
		window.$(this.wrapper).off('click.parentalHint');
	}

	toggleLayer = () => {
		let d = !this.state.open;
		this.setState({ open: !this.state.open });
		this.props.appStore.adultScrollerHeightUpdate = d;
	};
	onHtmlLinkClicked = target => {
		let item = window.$(target);
		this.gotoUrl(
			item.attr('href'),
			item.attr('target'),
			{
				type: 'link',
				secondTitle: item.attr('title') && item.attr('title').length > 0 ? item.attr('title') : 'unbekannt',
				id: this.props.data.standard.sophoraId
			},
			false
		);
	};

	download = () => {
		const { adultInfo } = this.props;

		if (adultInfo) window.open(adultInfo.data.special.downloadUrl);
	};

	onSubmit = params => {
		if (this.props.onSubmit) this.props.onSubmit(params);
	};

	getStandardTeaserInner = data => {
		let url = '';
		let target = '_blank';
		if (data.standard.documentType === 'download') {
			url = data.special.downloadLink;
		}
		if (
			data.standard.documentType === 'link' ||
			data.special.linktype === 'external' ||
			data.standard.contentCategory === 'competition'
		) {
			url = data.url;
			if (data.standard.teaserStyling === 'contestJson' || data.standard.contentCategory === 'competition') {
				return <Form teaser={data} />;
			}
		}
		if (data.special.linktarget) {
			target = data.special.linktarget;
		}

		return (
			<Inner>
				<Link
					type={'button'}
					flexDir="column"
					onClick={() => {
						this.gotoUrl(
							url,
							target,
							data,
							data.standard.documentType === 'download' || url.indexOf('kikaninchen.de') !== -1 ? true : false
						);
					}}
				>
					{data.standard.title ? <h4>{data.standard.title}</h4> : null}
					<TeaserDetails>
						<img
							src={getImageUrl(
								data.standard.teaserImage && data.standard.teaserImage.urlScheme,
								'512',
								data.standard.documentType !== 'link' ? 'original' : 'tlarge169'
							)}
						/>
						{data.standard.documentType === 'download' ||
						data.special.linktype.indexOf('external') !== -1 ||
						data.special.linktype.indexOf('internal') !== -1
							? this.getDownloadButton(data)
							: null}
					</TeaserDetails>
				</Link>
				{data.standard.documentType === 'download' ? this.getDownloadLegal(data) : null}
				{data.standard.documentType === 'link' && data.standard.teaserText.length > 0 ? (
					<TeaserText>{data.standard.teaserText}</TeaserText>
				) : null}
			</Inner>
		);
	};
	sendTrackingEvent = (data, url) => {
		let value = url.split('/');
		this.props.trackingStore.trackAtiClick({
			name: data.secondTitle || '',
			chapter1: value[value.length - 1]
				.split('-download')
				.join('')
				.split('.pdf')
				.join(''),
			chapter2: url,
			action: data.action,
			context: 'adultLayer'
		});
	};
	setPrintImage = url => {
		let printImage = window.$('<img class="print" src="' + url + '" />');
		window.$('img.print').remove();
		window.$('body').prepend(printImage);
	};
	gotoUrl = (url, target, data, force) => {
		data.action = data.type || data.standard.documentType;
		if (data.type === 'printRequest') {
			this.sendTrackingEvent(data, 'printRequest');
			this.setPrintImage(url);

			setTimeout(() => {
				window.print();
			}, 500);
			return false;
		}

		if (data.type !== 'printRequest' && force !== true) {
			data.action = 'link';
			this.props.appStore.showParentalGate({ open: true, type: 'link', url: url, target: target, data: data });
			return false;
		}
		this.sendTrackingEvent(data, url);

		if (target === '_blank') {
			window.open(url);
		} else {
			document.location.href = url;
		}
	};

	getDownloadButton = data => {
		let title = 'Herunterladen';
		let classN = 'downloadButton';
		let claimerText = null;

		if (
			(data.standard && data.standard.documentType === 'link') ||
			data.type === 'link' ||
			(data.special && data.special.linktype === 'external')
		) {
			classN = 'linkButton';
			title = 'Link aufrufen';
			claimerText = 'kikaninchen.de verlassen';
		}
		let ariaTitle = title;
		let clickTitle = title;
		if (data.standard && data.standard.title) {
			ariaTitle = data.standard.title + ' ' + title;
			clickTitle = data.standard.title;
		}
		if (data.type === 'printRequest') {
			ariaTitle = data.title + ' ' + title;
			clickTitle = data.title;
		}

		data.secondTitle = clickTitle;

		return (
			<Button btnType={classN} aria-label={ariaTitle}>
				<Icon marginLeft={'2%'} size={'16%'} code={classN === 'downloadButton' ? 'download' : 'toright'} />
				<LinkText>{title}</LinkText>
				{claimerText !== null ? <ClaimerText>{claimerText}</ClaimerText> : null}
			</Button>
		);
	};
	getDownloadLegal = data => {
		return (
			<DownloadLegal>
				<Row justify={'space-between'}>
					<Link sizeFont={'2em'} textColor={this.state.open ? '#fff' : null} onClick={this.toggleLayer}>
						Rechtliche Hinweise
						<Icon
							marginLeft={'0.5em'}
							code="down"
							direction={this.state.open ? '-180deg' : null}
							fill={this.state.open ? '#fff' : null}
						/>
					</Link>
					<Help href="https://www.kika.de/eltern/sendungen/kikaninchen/pdf-download-hilfe-100.html" target="_blank">
						Hilfe zu Downloads
					</Help>
				</Row>
				<DownloadInner open={this.state.open}>{window.kknApp.legalText}</DownloadInner>
			</DownloadLegal>
		);
	};

	getExternalTeaser = data => {
		if (data.linkType === 'form') {
			return <Form onSubmit={this.onSubmit} teaser={data} />;
		}
		return (
			<Inner>
				<Link
					flexDir="column"
					onClick={() => {
						this.gotoUrl(data.url, data.linkTarget || '_blank', data, data.type !== 'link' ? true : false);
					}}
				>
					{data.title ? <h4>{data.title}</h4> : null}
					<TeaserDetails>
						<img src={data.preview || data.url} />
						{this.getDownloadButton(data)}
					</TeaserDetails>
				</Link>
			</Inner>
		);
	};

	render() {
		//grab them by the image tag
		let parentTip = null;
		let inner = null;
		let tip = null;
		let external = this.props.external ? 'kkn-externalTeaser' : 'kkn-standardTeaser';
		let withImage = null;
		if (this.props.data.standard && this.props.data.standard.sophoraId) {
			let pt = this.props.assetStore.cachedAdultTips['parentTip-' + this.props.data.standard.sophoraId];
			if (pt) {
				parentTip = window.$(pt).clone();
				withImage = parentTip.find('[data-ctrl-image]');

				withImage.each(function(i) {
					let item = window.$(this);
					let val = JSON.parse(
						item
							.attr('data-ctrl-image')
							.split("'")
							.join('"')
					);
					item.attr('src', getImageUrl(val.urlScheme));
				});
			}
		}

		if (parentTip === null) {
			if (this.props.external !== true) {
				inner = this.getStandardTeaserInner(this.props.data);
			} else {
				inner = this.getExternalTeaser(this.props.data);
			}
			if (this.props.rawHtml) {
				inner = null;
				if (this.props.data.teaserStyling === 'contestResponseText' && this.props.afterFormSend !== true) return null;
				tip = { __html: this.props.rawHtml };
			}
		} else {
			if (this.props.data.standard.teaserStyling === 'contestResponseText' && this.props.afterFormSend !== true) return null;
			parentTip.find(".mediaCon").parent().addClass("withImage");
			tip = { __html: parentTip.html() };
		}

		return (
			<Wrapper
				dangerouslySetInnerHTML={tip}
				key={'adultTeaser-' + this.props.index}
				ref={wrapper => wrapper && !this.wrapper && (this.wrapper = wrapper)}
				className={`${this.classDocType} external`}
			>
				{inner}
			</Wrapper>
		);
	}
}

export default AdultLayerTeaser;
