import React, { Component } from 'react';
import { Wrapper } from './ProgressBarStyle';
import { inject, observer } from 'mobx-react';

@inject('assetStore')
@inject('appStore')
@observer
class ProgressBar extends Component {
	render() {
		if (!this.props.assetStore.globalAssets) return null;

		return (
			<Wrapper pos={this.props.pos}>
				<span style={{ width: this.props.progress }} />
				<img src={this.props.assetStore.globalAssets.getItem('prgShad').src} />
			</Wrapper>
		);
	}
}

export default ProgressBar;
