import React, { Component } from 'react';
import AnimatedBackground from 'components/kits/AnimatedBackground/AnimatedBackground';
import IndexPage from 'components/templates/IndexPage/IndexPage';
import TabbedIndexPage from 'components/templates/TabbedIndexPage/TabbedIndexPage';
import TransitionBackground from 'components/kits/TransitionBackground/TransitionBackground';
import Breadcrumb from 'components/kits/Breadcrumb/Breadcrumb';
import { getMergedTeaserData, getPath, logger, Statics } from 'helpers/Utils';
import { inject, observer } from 'mobx-react';
import MediaPlayer from 'components/kits/MediaPlayer/MediaPlayer';
import { Scene } from './AppWrapperStyle';
import { withRouter } from 'react-router-dom';

@inject('appStore')
@inject('assetStore')
@inject('routingStore')
@inject('trackingStore')
@observer
class AppWrapper extends Component {
	state = {
		firstLoad: false,
		locked: false,
		transitionAnimation: 'hide'
	};
	int = 0;
	isUnmounted = false;
	cache = {};
	lastCategory = '';
	newRoute = null;
	clickonsActive = true;
	isBundle = false;
	indexAssets = null;
	lastLinkType = '';
	globalAssets = null;

	// Init mount

	componentDidMount(callback) {
		// called when container is routed
		if (this.isUnmounted) return false;
		this.startScreen(this.props);
		this.props.appStore.animate = 'in';
	}

	componentWillReceiveProps(nextProps) {
		this.startScreen(nextProps);
	}

	componentDidUpdate() {
		this.lastIndex = this.pageIndex;
		if (this.props.assetStore.docInfos && this.props.assetStore.docInfos.category)
			this.lastCategory = this.props.assetStore.docInfos.category;
	}

	componentWillUnmount() {
		this.isUnmounted = true;
	}

	startScreen = nextProps => {
		this.cache = null;
		nextProps.appStore.referrer = nextProps.appStore.newRoute;
		window.kknApp.killGlobalPlayer();

		nextProps.assetStore.clearAdultInfo();

		if (nextProps.appStore.hasPageChanged()) {
			this.lastLinkType = '';

			nextProps.assetStore.loadCallback = () => {
				this.startScene(nextProps);
			};
			nextProps.assetStore.loadDocument(nextProps);
		} else {
			if (nextProps.assetStore.docData && nextProps.assetStore.docData.document.parentalTeaserBox) {
				nextProps.assetStore.addAdultInfo({ data: { teaserBox: nextProps.assetStore.docData.document.parentalTeaserBox[0] } });
			}

			if (this.props.assetStore.hasDetail() === false) nextProps.appStore.detailOpen = null;
			this.startScene(nextProps);
		}
	};
	startScene = p => {
		let props = p || this.props;
		if (!props.assetStore.docData.document) return null;

		let page = 0;
		this.props.assetStore.initStart = true;

		if (props.location.pathname === '/index.html') {
			window.kknApp.lastHomepageRoute = props.location.pathname + props.location.search;
		}
		window.kknApp.killGlobalPlayer();

		//track page requests
		let docType = this.props.assetStore.docData.document.documentType;
		let isDeeplink = false;
		if (
			docType === Statics.CONTENT_TYPE_INTERACTIVE ||
			docType === Statics.CONTENT_TYPE_VIDEO ||
			docType === Statics.CONTENT_TYPE_EXTERNAL_VIDEO ||
			docType === Statics.CONTENT_TYPE_RELATED_VIDEO ||
			docType === Statics.CONTENT_TYPE_MMG
		)
			isDeeplink = true;
		if (this.props.assetStore.hasDetail() === false && isDeeplink === false) {
			this.props.trackingStore.resetLayerTracker();
			this.props.trackingStore.trackView({ name: 'page' });
		}
		if (!this.isUnmounted) {
			this.setState({
				transitionAnimation: 'hide'
			});
		}
		this.props.appStore.lastRoute = this.props.appStore.newRoute;
	};

	onBreadcrumbClicked = (url, type, index, length, defaultDoc) => {
		this.setState({ transitionAnimation: 'open' });
		if (type === 'back') {
			//window.history.back();
			if (window.kknApp.routes.length > 0) url = window.kknApp.routes[window.kknApp.routes.length - 2];

			window.kknApp.routes.splice(window.kknApp.routes.length - 1, 1);
			this.lastLinkType = 'back';

			url ? this.props.routingStore.push(url) : window.history.go(-1);
		} else {
			this.props.routingStore.push(url);
		}
	};
	onDetailClosed = data => {
		window.kknApp.killGlobalPlayer();
		if (data && data.externalSub === true) {
			this.props.appStore.detailOpen = { type: 'interactiveContent', externalSub: false };
			return false;
		}
		this.props.appStore.detailOpen = null;

		this.props.routingStore.push(getPath(this.props, '?page=' + this.props.appStore.pageIndex));
	};
	onDetailOpenend = () => {};

	// get background and foreground corresponding to category and scope

	getBackground = (moveDir, config) => {
		let themeCache = this.props.assetStore.cachedThemeAssets[this.props.assetStore.docInfos.scope];
		if (themeCache === undefined) themeCache = null;
		let bgUrl = this.props.assetStore.globalAssets.getItem('bgZwischenwelt').src;

		if (this.props.assetStore.isHome()) {
			bgUrl = this.props.assetStore.globalAssets.getItem('bgStart').src;
		}

		let clickOnActive = this.props.appStore.clickonsActive;

		if (this.props.assetStore.hasDetail()) {
			clickOnActive = false;
		}
		return (
			<AnimatedBackground
				config={config}
				clickonsActive={clickOnActive}
				isBundle={this.props.assetStore.isBundle()}
				bgUrl={bgUrl}
				data={this.props.assetStore.docData}
				cache={themeCache}
				animateDir={moveDir}
				scope={this.props.assetStore.docInfos.scope}
				lastCategory={this.lastCategory}
				category={this.props.assetStore.docInfos.category}
				pageIndex={this.props.appStore.pageIndex}
			/>
		);
	};
	getForeground = config => {
		if (!this.props.assetStore.docData.document) return null;

		let isBroadcastStart =
			this.props.assetStore.docInfos.contentType === Statics.CONTENT_TYPE_START &&
			this.props.assetStore.docInfos.template === Statics.SCENE_TYPE_BROADCAST
				? true
				: false;

		if (this.props.assetStore.isHome())
			return (
				<TabbedIndexPage
					variant={'home'}
					assets={this.cache && this.cache.assets !== undefined ? this.cache.assets : null}
					indexAssets={this.props.assetStore.docAssets}
					pageIndex={this.props.appStore.pageIndex}
					items={this.props.assetStore.docData.document.teaserBoxes}
				/>
			);

		if (this.props.assetStore.docInfos.contentType === Statics.CONTENT_TYPE_INDEX_TABBED) {
			return (
				<TabbedIndexPage
					pageIndex={this.props.appStore.pageIndex}
					assets={this.cache && this.cache.assets !== undefined ? this.cache.assets : null}
					indexAssets={this.props.assetStore.docAssets}
					items={this.props.assetStore.docData.document.teaserBoxes}
				/>
			);
		}
		if (!isBroadcastStart && this.props.assetStore.docInfos.contentType === Statics.CONTENT_TYPE_BUNDLE) {
			return (
				<TabbedIndexPage
					isBundle={this.props.assetStore.isBundle()}
					indexAssets={this.props.assetStore.docAssets}
					pageIndex={this.props.appStore.pageIndex}
					assets={this.cache && this.cache.assets !== undefined ? this.cache.assets : null}
					items={this.props.assetStore.docData.document.teaserBoxes}
				/>
			);
		}
		if (this.props.assetStore.docInfos && this.props.assetStore.docInfos.scope)
			this.cache = this.props.assetStore.cachedThemeAssets[this.props.assetStore.docInfos.scope];

		return (
			<IndexPage
				pageIndex={this.props.appStore.pageIndex}
				config={config}
				firstLoad={this.state.firstLoad}
				clickonsActive={this.clickonsActive}
				isHome={this.props.assetStore.isHome()}
				transitionAnimation={this.state.transitionAnimation}
				data={this.props.assetStore.docData}
				indexAssets={this.props.assetStore.docAssets}
				category={this.props.assetStore.docInfos.category}
				isBroadcastStart={isBroadcastStart}
				items={this.props.assetStore.docData.document.teaserBoxes}
				mergedItems={getMergedTeaserData(this.props.assetStore.docData.document)}
				helpMedia={getMergedTeaserData(this.props.assetStore.docData.document, 'helpMedia')}
				assets={this.cache && this.cache.assets !== undefined ? this.cache.assets : null}
				showDetail={this.props.assetStore.hasDetail()}
				currentDetailId={this.props.appStore.getCurrentQuery().detailId}
				onDetail={this.onDetailOpenend}
				onBundleClicked={this.onBundleClicked}
				onDetailClosed={this.onDetailClosed}
				routeParams={this.props.routeParams}
				ref="bundleGroup"
			/>
		);
	};
	onAdultToggled = value => {};
	onLocked = state => {
		this.setState({ locked: state });
	};

	render() {
		if (this.props.assetStore.ready === false) return null;
		this.globalAssets = this.props.assetStore.globalAssets;

		this.props.appStore.pageIndex = 0;

		if (this.props.appStore.getCurrentQuery().page !== undefined) {
			this.props.appStore.pageIndex = Number(this.props.appStore.getCurrentQuery().page);
		}
		// active clickons on broadcast pages and intro boxes
		let moveDir = '';
		let upTween = '';
		let downTween = '';
		let animationConfig = null;
		let firstRender = true;
		this.cache = null;
		if (this.props.assetStore.docInfos && this.props.assetStore.docInfos.scope)
			this.cache = this.props.assetStore.cachedThemeAssets[this.props.assetStore.docInfos.scope];

		if (this.props.assetStore.docInfos.category !== Statics.CONTENT_TYPE_START) moveDir = 'right';

		if (this.props.assetStore.docInfos.category !== Statics.CONTENT_TYPE_START && this.lastCategory === Statics.CONTENT_TYPE_START)
			moveDir = 'right';

		if (this.props.assetStore.docInfos.contentType === Statics.CONTENT_TYPE_INTERACTIVE) {
			moveDir = '';
		}

		if (this.cache && this.cache.assets) {
			animationConfig = this.cache.assets.getResult('config');
			if (animationConfig) {
				if (animationConfig.upTween) {
					upTween = animationConfig.upTween;
					if (
						upTween.indexOf(this.props.assetStore.docInfos.category) !== -1 &&
						upTween.indexOf(this.lastCategory) !== -1 &&
						this.props.assetStore.docInfos.category !== Statics.CONTENT_TYPE_START
					) {
						moveDir = 'up';
					}
					if (
						upTween.indexOf(this.props.assetStore.docInfos.category) !== -1 &&
						upTween.indexOf(this.lastCategory) !== -1 &&
						this.props.assetStore.docInfos.category === Statics.CONTENT_TYPE_START
					) {
						moveDir = 'down';
					}
				}
				if (animationConfig.downTween) {
					downTween = animationConfig.downTween;
					if (
						downTween.indexOf(this.props.assetStore.docInfos.category) !== -1 &&
						downTween.indexOf(this.lastCategory) !== -1 &&
						this.props.assetStore.docInfos.category !== Statics.CONTENT_TYPE_START
					) {
						moveDir = 'upToDown';
					}
					if (
						downTween.indexOf(this.props.assetStore.docInfos.category) !== -1 &&
						downTween.indexOf(this.lastCategory) !== -1 &&
						this.props.assetStore.docInfos.category === Statics.CONTENT_TYPE_START
					) {
						moveDir = 'downToUp';
					}
				}
			}
			//moveDir = "upToDown";
		}

		if (this.props.assetStore.ready !== true) return null;
		if (this.props.appStore.clicked !== true) return null;

		return (
			<Scene className={this.props.assetStore.docInfos.contentType}>
				<Breadcrumb
					pageIndex={this.props.appStore.pageIndex}
					location={this.props.location}
					isBundle={this.props.assetStore.isBundle()}
					ref="breadcrumb"
					data={this.props.assetStore.docData}
					category={this.props.assetStore.docInfos.category}
					animateDir={moveDir}
					onClick={this.onBreadcrumbClicked}
					onDetailClosed={this.onDetailClosed}
				/>
				{this.getBackground(moveDir, animationConfig)}
				<TransitionBackground open={this.props.assetStore.ready && this.props.assetStore.isLoading ? true : false} />
				{this.props.assetStore.isLoading === false ? this.getForeground(animationConfig) : null}
				{this.props.appStore.detailOpen && this.props.appStore.detailOpen.newPlayerData ? (
					<MediaPlayer
						assets={this.props.assetStore.globalAssets}
						data={this.props.appStore.detailOpen.newPlayerData}
						changeNavi={false}
						variant={'external'}
						routeParams={this.props.routeParams}
						ref="videoplayer"
					/>
				) : null}
				<div className="avPlayerWrapper inv" dangerouslySetInnerHTML={{ __html: window.kknApp.getPlayerCode() }} />
			</Scene>
		);
	}
}

export default withRouter(AppWrapper);
