import React, { Component } from 'react';
import { getImageUrl, pixelize, Statics } from 'helpers/Utils';
import { inject, observer } from 'mobx-react';
import { CSSTransition } from 'react-transition-group';
import { Container, MainImage, SongBackground } from './MediaPlayerStyle';
import { Outer, TeaserBg, WhiteBorder } from '../Teaser/TeaserStyle';
import BaseComponent from 'BaseComponent';

@inject('assetStore')
@inject('appStore')
@inject('routingStore')
@observer
class MediaPlayer extends BaseComponent {
	//DUMMMY!
	teaserWidth = 455;
	teaserHeight = 286;
	player = null;
	url = '';
	isUnmounted = false;
	adultData = null;
	importantAdultData = false;
	instantOpen = true;
	firstOpen = false;

	state = {
		currentId: 1,
		scaleVale: 1,
		x: 0,
		y: 0,
		moveIn: false
	};

	componentDidUpdate = () => {
		if (this.props.appStore.isVideo(this.props.data) === false) return false;
		if (this.firstOpen === true) return false;
		if (this.firstOpen === false) {
			this.firstOpen = true;
			setTimeout(() => {
				if (this.props.assetStore.firstCurtainDropped === true || this.props.variant === 'external') this.playVideo();
				this.updateAdultLayer(this.props.data);
				this.props.assetStore.firstCurtainDropped = true;
			}, 1000);
		}
	};

	componentDidMount() {
		if (this.props.appStore.isVideo(this.props.data) === false) return false;

		this.styleRef = { y: 0, x: pixelize('50em'), width: 1 };
		let scaleVal = 1;

		if (this.props.changeNavi !== false)
			this.props.appStore.detailOpen = {
				type: 'mediaPlayer',
				isSingleDeeplink: this.props.data.document ? true : false
			};

		if (this.props.styleRef && this.props.styleRef.type !== 'round') {
			this.styleRef = this.props.styleRef;
			this.instantOpen = false;
		}
		if (typeof this.styleRef.y === 'number') {
			scaleVal = this.styleRef.width / pixelize('110.4em');
			this.setState({
				scaleVal: scaleVal,
				y: String(this.styleRef.y) + 'px',
				x: String(this.styleRef.x) + 'px',
				moveIn: true
			});
		} else {
			this.setState({
				scaleVal: 1,
				y: '0',
				x: '0',
				moveIn: true
			});
		}
	}

	updateAdultLayer = data => {
		// update adult layer if needed
		if (data === null) return false;
		if (!data.special || !data.special.parentalTeaserBox) return false;

		this.adultData = { data: { teaserBox: data.special.parentalTeaserBox[0] || null } };

		this.adultData.data.contentHead = {
			title: this.getTeaserBase(this.props.data).title,
			teaserText: this.getTeaserBase(this.props.data).teaserText
		};

		if (this.adultData.data && this.adultData.data.teaserBox) {
			this.adultData.data.teaserBox.teasers.map((data, index) => {
				if (data.notificationType) this.importantAdultData = true;
			});
		}
		this.adultData.data.special = data.special;
		this.adultData.data.notTimeout = 4000;
		this.props.assetStore.addAdultInfo(this.adultData);
	};

	playVideo = () => {
		if (this.isUnmounted) {
			window.kknApp.killGlobalPlayer();
			return false;
		}

		this.url = '';
		if (this.getTeaserSpecial(this.props.data).avCustomUrl) this.url = this.getTeaserSpecial(this.props.data).avCustomUrl;

		// for local dev, force Proxy for avCustom

		if (process.env.REACT_APP_DEV_PROXY && process.env.REACT_APP_DEV_PROXY.length > 0) {
			this.url =
				process.env.REACT_APP_DEV_PROXY +
				this.getTeaserSpecial(this.props.data)
					.avCustomUrl.split(process.env.REACT_APP_DEV_BASE_VIDEO_URL)
					.join('');
		}

		window.$('.avPlayerWrapper').removeClass('video song');
		window.$('.avPlayerWrapper').addClass(this.getTeaserBase(this.props.data).contentCategory);
		window.kknApp.startGlobalPlayer(this.url, this.props.data);
		window.$('body').on(window.BRavFramework.AV_PLAYER_STATE + '.react', (evt, params) => {
			if (params.state === 'playing') window.kknApp.addDataToPlayer(this.props.data.document || this.props.data);
			if (params.state === window.BRavFramework.AV_PLAYER_FINISHED && this.importantAdultData === false) this.onClose();
		});

		this.playerLoaded = true;
	};

	componentWillUnmount() {
		if (this.props.changeNavi !== false) this.props.appStore.detailOpen = null;
		window.$('.avPlayerWrapper').removeClass('video song');
		window.kknApp.killGlobalPlayer();
		this.isUnmounted = true;
		window.$('body').off(window.BRavFramework.AV_PLAYER_READY);
		window.$('body').off(window.BRavFramework.AV_PLAYER_STATE + '.react');
	}

	onClose = () => {
		this.props.onClosed();
	};

	getTeaserBase = data => {
		return data.document || data.standard;
	};
	getTeaserSpecial = data => {
		return (data.document && data.document.special) || data.special;
	};

	render() {
		let data = this.props.data;

		if (this.props.appStore.isVideo(data)) {
			let variant = this.props.variant ? ' ' + this.props.variant : '';
			let classes = variant + ' ' + this.getTeaserBase(this.props.data).contentCategory + ' ' + this.props.scope + '-layer';
			return (
				<CSSTransition in={this.state.moveIn} timeout={0} unmountOnExit={true}>
					<Container {...this.state} className={classes} ref="base">
						<Outer
							variant={this.getTeaserBase(this.props.data).contentCategory === 'song' ? 'layer' : null}
							teaserType={'landscape'}
							className={this.props.scope + '-layer-outer'}
						>
							<TeaserBg src={this.props.assets.getItem('teaserBgNormal').src} />
							<WhiteBorder variant={'landscape'} className={this.props.scope + '-layer-border'}>
								<TeaserBg src={this.props.assets.getItem('teaserBgFrame').src} />
								<MainImage
									onClick={() => {
										this.playVideo();
									}}
								>
									{this.getTeaserBase(this.props.data).teaserImage &&
									this.getTeaserBase(this.props.data).teaserImage.urlScheme ? (
										<img src={getImageUrl(this.getTeaserBase(this.props.data).teaserImage.urlScheme)} />
									) : null}
									<div className={'av-playerContainer'}>
										<span className="icon-font play-icon"></span>
									</div>
								</MainImage>
								{this.getTeaserBase(this.props.data).contentCategory === 'song' ? (
									<SongBackground src={this.props.assets.getItem('bgLiedtext').src} />
								) : null}
							</WhiteBorder>
						</Outer>
					</Container>
				</CSSTransition>
			);
		}

		return null;
	}
}

export default MediaPlayer;
