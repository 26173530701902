import React, { Component } from 'react';
import { Wrapper } from './IconStyle';
import { inject, observer } from 'mobx-react';

@inject('assetStore')
@inject('appStore')
@observer
class Icon extends Component {
	render() {
		if (!this.props.assetStore.globalAssets) return null;
		return (
			<Wrapper
				{...this.props}
				dangerouslySetInnerHTML={{ __html: this.props.assetStore.globalAssets.getResult('buttonIcons', true) }}
			></Wrapper>
		);
	}
}

export default Icon;
