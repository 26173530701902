import styled, { css, keyframes } from 'styled-components';
import { blueRabbitHand, gfx, moveInFromBottom, moveInFromTop, moveOutToBottom, moveOutToTop, scaleIn } from 'helpers/layoutVariables';

export const bundleLeftRight = css`
	top: 26em;
	bottom: auto;
	height: 17em !important;
	z-index: 0;
	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 2.5%;
		left: 0.5%;
		width: 98%;
		height: 98%;
		background: ${gfx.brown};
		box-shadow: 0 0 1em 0.25em ${gfx.brown};
		opacity: 0.75;
	}
`;
export const blueBackground = css`
	background-color: #fff;

	&:before {
		position: absolute;
		width: 90%;
		left: 5%;
		top: 5%;
		height: 90%;
		background-color: ${gfx.blue};
		content: '';
		z-index: 1;
		border-radius: 50%;
	}
`;

export const hideOut = keyframes`
    0% {
      opacity: 1;
    }
    99% {
      opacity: 0;
    }
    100% {
      opacity: 0;
      transform: translateY(-200%);
  
    }
  `;

export const noteFrames = keyframes`
    0% {
      	opacity:1;
      	transform:translate3d(0,-10em,0) scale(0,1);
      	filter:blur(32px);
    }
    50% {
    transform:translate3d(0,-10em,0) scale(2.5);
    }
 
    100% {
    opacity:0;
    	transform:translate3d(0,-10em,0)  scale(5);
    }
`;

export const InnerButton = styled.div`
	position: absolute;
	box-shadow: 0 0 4px 2px rgba(95, 65, 0, 0.75);
	border-radius: 50%;
	left: 0;
	top: 0;
	padding: ${(props) => (props.padding !== undefined ? props.padding : '1em')};
	height: 100%;
	width: 100%;
	> img {
		position: absolute;
		width: 100%;
	}
	${(props) =>
		props.type === 'start' &&
		css`
			padding: 0;
		`}

	${(props) =>
		props.type === 'adult' &&
		css`
			box-shadow: none;
		`}
	
	${(props) =>
		props.type === 'bundleLeft' &&
		css`
			img {
				transform: rotate(180deg);
			}
		`}

	${(props) =>
		(props.type.indexOf('bundleDown') !== -1 || props.type.indexOf('bundleUp') !== -1) &&
		css`
			${blueBackground};
		`};

	${(props) =>
		props.active &&
		css`
			&:before {
				background-color: ${gfx.orange};
			}
		`};
`;

export const Wrapper = styled.a`
	position: relative;
	top: 0;
	right: 0;
	width: 160px;
	height: auto;
	z-index: 1;
	display: flex;
	align-items: center;
	${blueRabbitHand};
	animation: ${scaleIn} 0.3s ease-out;

	justify-content: center;
	transform-origin: 50% 50%;

	&.moveInFromBottom {
		animation: ${moveInFromBottom} 0.3s ease-out;
		animation-fill-mode: forwards;
	}
	&.moveOutToBottom {
		animation: ${moveOutToBottom} 0.3s ease-out;
		animation-fill-mode: forwards;
	}
	&.moveInFromTop {
		animation: ${moveInFromTop} 0.3s ease-out;
		animation-fill-mode: forwards;
	}
	&.moveOutToTop {
		animation: ${moveOutToTop} 0.3s ease-out;
		animation-fill-mode: forwards;
	}

	${(props) =>
		props.type === 'adult' &&
		css`
			width: 88px;
			height: 88px;
			left: -6.66%;
			top: 3%;
		`}

	${(props) =>
		props.type.indexOf('bundle') !== -1 &&
		css`
			box-shadow: none;
			border-radius: 0;
			z-index: 3;
			position: absolute;
			bottom: -9em;
			top: auto;
			left: 50em;
		`}
    ${(props) =>
		props.type.indexOf('bundleUp') !== -1 &&
		css`
			box-shadow: none;
			border-radius: 0;
			z-index: 3;
			position: absolute;
			bottom: auto;
			top: -9em;
			left: 50em;
		`}

        
    
    ${(props) =>
		props.type.indexOf('bundleRight') !== -1 &&
		css`
			left: 102em;
			${bundleLeftRight};
		`};

	${(props) =>
		props.type.indexOf('bundleLeft') !== -1 &&
		css`
			left: -1.5em;
			${bundleLeftRight};
		`};
`;

export const BgInnerSVG = styled.img`
	z-index: 1;
	left: 0;
	top: 7%;
	width: 86%;
	height: 86%;
	position: absolute;
	${(props) =>
		props.fullBackground &&
		css`
			width: 100%;
			height: 100%;
			top: 0;
		`}
`;

export const Bg = styled.img`
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	position: absolute;
`;
export const BgInner = styled.div`
	z-index: 1;
	left: 7%;
	top: 7%;
	width: 86%;
	height: 86%;
	position: absolute;

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		background-color: ${gfx.blue};
		z-index: 1;
		content: '';
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}

	${(props) =>
		props.type === 'adult' &&
		css`
			&:before {
				background-color: rgba(255, 255, 255, 0.25);
			}
		`};

	${(props) =>
		props.active &&
		css`
			&:before {
				background-color: rgba(255, 255, 255, 1);
		`};
`;

export const AdultHightlight = styled.span`
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	border-radius: 50%;
	background-color: ${gfx.yellow};
	animation: ${noteFrames} ease-out 2s;
	transform-origin: 50% 0;
	animation-fill-mode: forwards; /*when the spec is finished*/
	z-index: 100;
	top: -50em;
`;

export const TeaserImage = styled.img`
	position: relative;
	z-index: 2;
	width: 100%;
	border-radius: 50%;
`;

export const TimerHint = styled.div`
	background-color: #fff;
	color: ${gfx.brown};
	font-size: 1.25em;
	line-height: 1.333;
	text-align: center;
	position: absolute;
	width: 16em;
	right: 0;
	top: 50%;
	padding: 0.5em;
	border-radius: 1em;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

	&:after {
		left: 97%;
		top: 50%;
		border: solid transparent;
		content: ' ';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(255, 255, 255, 0);
		border-left-color: #ffffff;
		border-width: 1em;
		margin-top: -1em;
	}
	${(props) =>
		props.pos &&
		props.pos === 'top' &&
		css`
			left: -2em;
			right: auto;

			&:after {
				left: 1em;
				top: -0.75em;
				border-color: transparent transparent rgba(255, 255, 255, 1) transparent;
			}
		`}
`;
