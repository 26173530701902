import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import axios from 'axios';
import { getCookie, getImageUrl, setCookie, Statics } from '../../../helpers/Utils';
import { BaseProps } from '../../basics/Box/IBox';
import Icon from '../../basics/Icon/Icon';
import AutoSuggest from './AutoSuggest';
import LastSearch from './LastSearch';
import SearchFilter from './SearchFilter';
import SearchResult from './SearchResult';
import {
	FullSizeWrapper,
	LoadMoreButton,
	LoadMoreContainer,
	NoResultsContainer,
	NoSearchResults,
	ResultContainer,
	Scrolltop,
	SearchInputField,
	SearchInputWrapper,
	SearchWrapper,
	SubmitButton,
	Worm,
	FlexSpacer
} from './SearchStyle';
import SearchSuggestions from './SearchSuggestions';
import { PulseLoader } from 'react-spinners';

interface ISearch extends BaseProps {
	isActive?: boolean;
	appStore?: any;
	assetStore?: any;
	routingStore?: any;
}

interface IState {
	searchValue: string;
	inputFocussed: boolean;
	filters: Array<any>;
	currentFilter: string;
	results: Array<any>;
	totalEntriesCount: number;
	noResultsFound: boolean;
	resultsAutoSuggest: Array<any>;
	nextLink: string;
	isLoading: boolean;
	lastSearchValues: string[];
	showScrolltop: boolean;
}

@inject('assetStore')
@inject('appStore')
@inject('routingStore')
@observer
class Search extends Component<ISearch> {
	state: IState = {
		searchValue: '',
		inputFocussed: false,
		filters: [],
		currentFilter: '',
		results: [],
		totalEntriesCount: 0,
		noResultsFound: false,
		resultsAutoSuggest: [],
		nextLink: '',
		isLoading: false,
		lastSearchValues: [],
		showScrolltop: false
	};
	developSearchHost = 'https://test.kikaninchen.de';
	inputId = 'headerSearchInput';
	searchUrl: string;
	abortController: AbortController;
	autoSuggestUrl: string;
	searchNoResultsUrl: string;
	searchRecommendationUrl: string;
	abortControllerAutoSuggest: AbortController;
	autoSuggestTimeout: ReturnType<typeof setTimeout>;
	scrollContainer = React.createRef<HTMLDivElement>();

	componentDidMount() {
		// for local development, urls have to be added to setupProxy.js
		// so they are loaded from test.kikaninchen.de without CORS errors

		// console.log('componentDidMount()');
		// console.log("#search",this.props.assetStore.docData);

		// this.searchUrl = '/administratives/search/kikaninchen-search-100-searchResults.json';
		// this.autoSuggestUrl = '/administratives/search/autosuggest-search104-searchResults.json';
		// this.searchNoResultsUrl = '/administratives/search/suche-vorschlaege100.json';
		// this.searchRecommendationUrl = '/administratives/search/suche-redaktionsvorschlaege100.json';
		this.searchUrl = this.props.assetStore.docData.settings.searchDocumentKKN;
		this.autoSuggestUrl = this.props.assetStore.docData.settings.autoSuggestDocumentKKN;
		this.searchNoResultsUrl = this.props.assetStore.docData.settings.searchNoResultsKKN;
		this.searchRecommendationUrl = this.props.assetStore.docData.settings.searchRecommendationKKN;

		this.getLastSearchTerms();

		this.scrollContainer.current.addEventListener('scroll', this.updateScrollPos);
	}

	componentWillUnmount(): void {
		if (this.abortController) this.abortController.abort();
		this.clearAutoSuggest();
		this.scrollContainer.current.removeEventListener('scroll', this.updateScrollPos);
	}

	openExternalLink = (url: string, title: string) => {
		this.props.appStore.showParentalGate({
			open: true,
			linkType: 'external',
			target: '_blank',
			type: 'adultLink',
			url: url,
			data: {
				secondTitle: title
			}
		});
	};

	updateScrollPos = () => {
		if (this.scrollContainer.current.scrollTop > 100) {
			this.setState({ showScrolltop: true });
		} else {
			this.setState({ showScrolltop: false });
		}
	};

	onFocusedInput = (focussed: boolean) => {
		this.setState({ inputFocussed: focussed });
	};
	onKeyDown = () => {};
	onChanged = async val => {
		this.setState({ searchValue: val });

		this.setAutoSuggestTimeout();
	};
	onFormSubmit = async event => {
		event.preventDefault();
		if (this.state.searchValue.trim() === '') {
			this.resetForm();
			return false;
		}

		// blur input after submit on mobile, to close the keyboard
		if (this.props.appStore.isSmallView()) {
			const inputField = event.target.querySelector('#' + this.inputId);
			inputField.blur();
		}

		// this.getSearchResults(`${this.searchUrl}?q=${this.state.searchValue}&cb=${new Date().getTime()}`, true);
		this.getSearchResults(`${this.searchUrl}?q=${this.state.searchValue}`, true);
		this.addLastSearchTerm(this.state.searchValue);

		return false;
	};
	onAutoSuggestTimeout = () => {
		const searchValue = this.state.searchValue.trim();

		if (searchValue.length >= 3) {
			// console.log('-> load new AutoSuggest');

			this.getAutoSuggestResults(`${this.autoSuggestUrl}?q=${searchValue}`);
		} else {
			// console.log('-> empty AutoSuggest');

			this.clearAutoSuggest();
		}
	};
	setAutoSuggestTimeout = () => {
		this.clearAutoSuggestTimeout();
		this.autoSuggestTimeout = setTimeout(this.onAutoSuggestTimeout, 1000);
	};
	clearAutoSuggestTimeout = () => {
		if (this.autoSuggestTimeout) {
			clearTimeout(this.autoSuggestTimeout);
			this.autoSuggestTimeout = null;
		}
	};
	clearAutoSuggest = () => {
		this.clearAutoSuggestTimeout();
		if (this.abortControllerAutoSuggest) this.abortControllerAutoSuggest.abort();
		this.setState({ resultsAutoSuggest: [] });
	};

	getAutoSuggestResults = async (url: string) => {
		// cancel old request
		if (this.abortControllerAutoSuggest) this.abortControllerAutoSuggest.abort();

		this.abortControllerAutoSuggest = new AbortController();
		const results = await axios.get(url, { signal: this.abortControllerAutoSuggest.signal });

		// console.log('autoSuggest:\n');
		// console.dir(results.data.searchResults);

		if (Array.isArray(results.data.searchResults)) {
			// max 4 entries
			this.setState({ resultsAutoSuggest: results.data.searchResults.slice(0, 4) });
		} else {
			this.setState({ resultsAutoSuggest: [] });
		}
	};

	getSearchResults = async (url: string, clearResults?: boolean, keepFilters?: boolean) => {
		// cancel old request
		if (this.abortController) this.abortController.abort();
		this.clearAutoSuggest();

		this.abortController = new AbortController();
		this.setState({ isLoading: true });

		try {
			const results = await axios.get(url, { signal: this.abortController.signal });

			this.setState({ isLoading: false });
			if (results.data) this.updateSearchResults(results, clearResults, keepFilters);
		} catch (error) {
			this.setState({ isLoading: false });
		}
	};

	getNextResults = () => {
		this.getSearchResults(this.state.nextLink);
	};

	updateSearchResults = (results, clearResults?: boolean, keepFilters?: boolean) => {
		// console.log('#val', this.state.searchValue, results);

		if (results.data.totalEntriesCount == 0 && clearResults && !keepFilters) {
			// set no results only for new search requests, not for filters
			this.noSearchResults();
		} else if (results.data['searchResults']) {
			// console.log(results.data['searchResults'].length + ' / ' + results.data.totalEntriesCount);

			// results.data['searchResults'].forEach(entry => {
			// 	console.log(entry.teaser.title + ' (' + entry.teaser.site + ', ' + entry.teaser.contentCategory + ')');
			// });

			const searchResults = results.data['searchResults'].map((item, index) => {
				// currently JSON may contain duplicate results, add timestamp to keep unique keys
				item.key = results.data.pageIndex + '_' + index + '_' + item.teaser.id + '_' + Date.now();
				return item;
			});

			if (clearResults) {
				this.setState({
					results: searchResults,
					totalEntriesCount: results.data.totalEntriesCount,
					noResultsFound: false,
					nextLink: results.data.nextLink ? results.data.nextLink : ''
				});
				if (!keepFilters) {
					if (Array.isArray(results.data.facetResults) && results.data.facetResults.length > 0) {
						this.setFilters(results.data.facetResults);
					} else {
						this.setFilters([]);
					}
				}
			} else {
				// only append results
				this.setState((state: IState) => ({
					results: state.results.concat(searchResults),
					totalEntriesCount: results.data.totalEntriesCount,
					noResultsFound: false,
					nextLink: results.data.nextLink ? results.data.nextLink : ''
				}));
			}
		} else if (results.data.type === 'searchResults') {
			// workaround for empty results in filters or nextlinks
			// (may happen when results are not published, totalEntriesCount is positive, but no results for request)
			if (clearResults) {
				this.setState({
					results: [],
					totalEntriesCount: results.data.totalEntriesCount,
					noResultsFound: false,
					nextLink: results.data.nextLink ? results.data.nextLink : ''
				});
				if (!keepFilters) {
					if (Array.isArray(results.data.facetResults) && results.data.facetResults.length > 0) {
						this.setFilters(results.data.facetResults);
					} else {
						this.setFilters([]);
					}
				}
			} else {
				this.setState({
					totalEntriesCount: results.data.totalEntriesCount,
					noResultsFound: false,
					nextLink: results.data.nextLink ? results.data.nextLink : ''
				});
			}
		}
	};

	setFilters = (newFilters: Array<any>) => {
		let newCurrentFilter = '';
		// don't show broadcast (Sendungen) filter, currently can't be disabled in Sophora
		newFilters = newFilters.filter(filter => filter.filterKey !== 'filter-broadcast');
		// check for active filter
		newFilters.forEach(filter => {
			if (filter.active === 'true' || filter.active === true) {
				newCurrentFilter = filter.filterKey;
			}
		});

		this.setState({ filters: newFilters, currentFilter: newCurrentFilter });
	};

	noSearchResults = () => {
		this.setState({
			filters: [],
			results: [],
			totalEntriesCount: 0,
			noResultsFound: true,
			nextLink: ''
		});
	};

	focusInputField = () => {};

	resetForm = () => {
		if (this.abortController) this.abortController.abort();
		this.clearAutoSuggest();
		this.setState({ filters: [], results: [], totalEntriesCount: 0, nextLink: '', noResultsFound: false });
	};

	onFilterClick = (url: string, filterKey: string) => {
		let filterUrl = url;
		// currently the facet/filter url only contains the query string, so we have to add the url
		// JSON might be fixed later
		if (url.substring(0, 1) === '?') {
			filterUrl = this.searchUrl + url;
		} else {
			filterUrl = url;
		}

		this.getSearchResults(filterUrl, true, true);
		this.setState({ nextLink: '', currentFilter: filterKey });
	};

	onResultClick = (url: string, site?: string, title?: string) => {
		if (site === 'kikaninchen') {
			// close search layer

			this.props.appStore.toggleSearch();
			//404
			if (this.props.appStore.error === true) {
				document.location.href = this.removeDomain(url);
				return false;
			}
			// workaround for wrong url comming from sophora
			this.props.appStore.lastRoute = '/search';
			this.props.routingStore.push(this.removeDomain(url), { from: 'search' });
		} else {
			// window.open(url);
			this.openExternalLink(url, title);
		}
	};

	removeDomain = (url: string): string => {
		// workaround for wrong url comming from sophora

		if ((url && url.startsWith('https://')) || url.startsWith('http://')) {
			const domainStartIndex = url.indexOf('://') + 3;
			const domainEndIndex = url.indexOf('/', domainStartIndex);

			return url.substring(domainEndIndex);
		}
		return url;
	};

	scrollToTop = () => {
		this.scrollContainer.current.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	getLastSearchTerms = () => {
		const cookieString = getCookie('kknLastSearches');

		let jsonArray;
		let searchArray = [];

		try {
			jsonArray = JSON.parse(cookieString);
		} catch (error) {}

		if (Array.isArray(jsonArray)) {
			for (let ii = 0; ii < Math.min(4, jsonArray.length); ii++) {
				const element = jsonArray[ii];
				if (typeof element === 'string') searchArray.push(element);
			}
		}

		this.setState({ lastSearchValues: searchArray });
	};

	addLastSearchTerm = (searchTerm: string) => {
		let searchArray = this.state.lastSearchValues;

		if (searchArray[0] !== searchTerm) {
			searchArray = searchArray.filter(term => term !== searchTerm);
			searchArray.unshift(searchTerm);
			if (searchArray.length > 4) searchArray = searchArray.slice(0, 4);

			this.setState({ lastSearchValues: searchArray });
			setCookie('kknLastSearches', JSON.stringify(searchArray), 365);
		}
	};

	removeLastSearchTerm = (searchTerm: string) => {
		let searchArray = this.state.lastSearchValues.filter(term => term !== searchTerm);

		this.setState({ lastSearchValues: searchArray });
		setCookie('kknLastSearches', JSON.stringify(searchArray), 365);
	};

	searchLastSearchTerm = (searchTerm: string) => {
		if (searchTerm.trim() !== '') {
			this.setState({ searchValue: searchTerm });
			this.getSearchResults(`${this.searchUrl}?q=${searchTerm}`, true);
			this.addLastSearchTerm(searchTerm);
		}
	};

	render = () => {
		const showingResults = this.state.results.length > 0 || this.state.totalEntriesCount > 0;
		const addClassNames = showingResults ? 'verticalScroll' : '';

		return (
			<FullSizeWrapper className={addClassNames}>
				<SearchWrapper className={addClassNames} ref={this.scrollContainer}>
					<form onSubmit={this.onFormSubmit}>
						<SearchInputWrapper>
							<Icon
								size={this.props.appStore.isSmallView() ? '5.79em' : '5.4em'}
								sizeHeight={this.props.appStore.isSmallView() ? '6.436em' : '4.8em'}
								code={'search'}
							/>
							<SearchInputField
								// requestFocus={this.props.appStore.adultLayerOpen}
								requestFocus={true}
								onFocused={this.onFocusedInput}
								value={this.state.searchValue}
								onChanged={this.onChanged}
								onKeyDown={this.onKeyDown}
								state={null}
								hasClearButton={!this.state.isLoading}
								onClick={this.focusInputField}
								placeHolder={true}
								id={this.inputId}
								label={this.props.appStore.isSmallView() ? 'Suche' : ''}
								name={'q'}
								maxLength="40"
								onClear={this.resetForm}
							></SearchInputField>
							{this.state.isLoading && (
								<PulseLoader
									cssOverride={{ position: 'absolute', right: 16, top: '50%', marginTop: -6 }}
									size={12}
									color={'#5e4200'}
								></PulseLoader>
							)}
							{!this.props.appStore.isSmallView() && !this.state.isLoading && (
								<SubmitButton type="submit">{'Suchen'}</SubmitButton>
							)}

							{!this.props.appStore.isSmallView() && this.state.resultsAutoSuggest.length > 0 && (
								<AutoSuggest
									results={this.state.resultsAutoSuggest}
									onClick={(url, target, title) => {
										this.onResultClick(url, target, title);
									}}
								/>
							)}
						</SearchInputWrapper>
					</form>

					{this.props.appStore.isSmallView() && this.state.resultsAutoSuggest.length > 0 && (
						<AutoSuggest
							results={this.state.resultsAutoSuggest}
							onClick={(url, target, title) => {
								this.onResultClick(url, target, title);
							}}
						/>
					)}
					{this.state.lastSearchValues.length > 0 && this.state.searchValue === '' && (
						<LastSearch
							searchTerms={this.state.lastSearchValues}
							onClick={this.searchLastSearchTerm}
							onRemoveClick={this.removeLastSearchTerm}
						/>
					)}

					{this.state.filters && (
						<SearchFilter
							filters={this.state.filters}
							onFilterClick={this.onFilterClick}
							currentFilter={this.state.currentFilter}
						/>
					)}
					{showingResults && (
						<ResultContainer>
							{this.state.results.map(item => {
								const roundImage =
									item.teaser.teaserImage &&
									(item.teaser.contentType === Statics.CONTENT_TYPE_START ||
										item.teaser.contentType === Statics.CONTENT_TYPE_BUNDLE ||
										item.teaser.contentType === Statics.CONTENT_TYPE_INDEX ||
										item.teaser.contentType === Statics.CONTENT_TYPE_INDEX_TABBED ||
										item.teaser.documentType === 'contentKit');

								const imageUrl = item.teaser.teaserImage
									? getImageUrl(
											item.teaser.teaserImage.urlScheme,
											roundImage ? 128 : 256,
											roundImage ? 'ident' : 'tlarge169'
									  )
									: null;
								return (
									<SearchResult
										key={item.key}
										title={item.teaser.title}
										imageUrl={imageUrl}
										site={item.teaser.site}
										contentType={item.teaser.contentType}
										documentType={item.teaser.documentType}
										contentCategory={item.teaser.contentCategory}
										downloadNotification={item.teaser.downloadNotification}
										adultContent={item.teaser.adultContent == 'true'}
										roundImage={roundImage}
										url={item.teaser.url}
										onClick={(url, target, title) => {
											this.onResultClick(url, target, title);
										}}
									/>
								);
							})}
						</ResultContainer>
					)}
					{this.state.noResultsFound && (
						<NoSearchResults>
							<NoResultsContainer>
								<Worm src="/static_kkn/global/images/search_not_found_wurm.png" alt="Wurm" />
								Oh, oh, da ist wohl der Wurm drin!
								<br />
								Probiere doch ein anderes Wort!
							</NoResultsContainer>
						</NoSearchResults>
					)}
					<LoadMoreContainer>
						{this.state.nextLink !== '' && (
							<LoadMoreButton disabled={this.state.isLoading} onClick={this.getNextResults}>
								weitere Ergebnisse laden
							</LoadMoreButton>
						)}
					</LoadMoreContainer>
					<FlexSpacer />
					{!showingResults && (
						<SearchSuggestions
							url={this.state.noResultsFound ? this.searchNoResultsUrl : this.searchRecommendationUrl}
							onClick={(url, target, title) => {
								this.onResultClick(url, target, title);
							}}
							redrawSlider={this.props.appStore.redrawSlider !== true}
						/>
					)}

					{this.state.showScrolltop && (
						<Scrolltop onClick={this.scrollToTop}>
							<Icon size={'100%'} sizeHeight={'100%'} code={'arrowUp'} />
						</Scrolltop>
					)}
				</SearchWrapper>
			</FullSizeWrapper>
		);
	};
}

export default Search;
