import { gfx } from 'helpers/layoutVariables';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
	position: absolute;
	width: 100%;
	left: 0;
	bottom: -1em;
	height: 3%;
	z-index: 12;

	opacity: ${(props) => (props.alpha !== undefined ? props.alpha : 1)};
	img {
		width: 100%;
		position: absolute;
		top: 100%;
	}
	background-color: ${gfx.yellow};
	span {
		position: absolute;
		width: 0;
		height: 100%;
		background-color: ${gfx.brown};
	}
`;

export const CircleWrapper = styled.div`
	position: absolute;
    width: ${(props) => (props.size ? props.size : '40px')};
    height:${(props) => (props.size ? props.size : '40px')};
    left: 50%;
    pointer-events: none;
    top: 50%;
    z-index: 4;
    transform: translate3d(-50%,-50%,0);
}
	
	${(props) =>
		props.pos === 'right' &&
		css`
			transform: translate3d(-50%, -50%, 0) rotate(270deg);
		`};


	span {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		transition: all 0.3s ease-out;
	}
	
`;
