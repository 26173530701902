import Icon from 'components/basics/Icon/Icon';
import React, { Component } from 'react';
import { getImageUrl, getRightInput } from 'helpers/Utils';
import { inject, observer } from 'mobx-react';
import Toast from 'components/fragments/Toast/Toast';
import { Bg, BgInnerSVG, InnerButton, TeaserImage, TimerHint, Wrapper } from './KknButtonStyle';

const BTN_SIZE = '10em';

@inject('assetStore')
@inject('appStore')
@observer
class KknButton extends Component {
	state = {
		toggle: true,
		animate: ''
	};

	componentDidMount() {
		if (this.props.bounce === true) {
			this.bounce();
			return false;
		}
		if (this.props.animateIn === true) {
			this.animateIn();
			return false;
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.bounce === true && nextProps.active === false && this.state.animate === '') {
			this.bounce();
			return false;
		} else {
			this.setState({ animate: '' });
		}

		if (nextProps.animateIn) {
			this.animateIn();
			return false;
		}
	}

	bounce() {
		this.setState({ animate: '' });
		setTimeout(() => {
			this.setState({ animate: 'hightlightAni' });
		}, 250);
		setTimeout(() => {
			this.setState({ animate: '' });
		}, 500);
	}

	animateIn() {
		//this.setState({ animate: styles.animIn });
		setTimeout(() => {
			this.setState({ animate: '' });
		}, 250);
	}

	onClick = () => {
		window.$(this.refs.icon).addClass('animation');
		if (this.props.onClicked) this.props.onClicked();
	};
	getTitle = () => {
		if (this.props.type === 'printBtn') {
			return <p>Ausdrucken</p>;
		}
		if (this.props.type === 'downloadBtn') {
			return (
				<p>
					Herunterladen<span>(PDF / 1,2 MB)</span>
				</p>
			);
		}
		return null;
	};

	render() {
		let style = '';
		let assets = this.props.assetStore.globalAssets;
		let adultTimer = this.props.appStore.adultTimer;
		let adultEndTime = this.props.appStore.adultEndTime;

		let btnSize = BTN_SIZE;
		if (this.props.type && this.props.type.indexOf('adult') !== -1) btnSize = '6.25em';
		if (this.props.size !== undefined) btnSize = String(this.props.size) + 'em';

		let scaleValWidth = btnSize;
		let scaleValHeight = btnSize;

		let styleParams = { width: scaleValWidth, height: scaleValHeight };
		let hint = null;
		if (this.props.type === 'adult')
			hint = (
				<Toast active={adultEndTime && adultTimer < 7}>
					<TimerHint>Hier kann ihr Kind den Ablauf der Zeit nachvollziehen.</TimerHint>
				</Toast>
			);

		/*if (adultTimer && this.props.type === 'adult') {
            let time = adultTimer > 0 ? adultTimer / adultEndTime : 0;
            if (this.progressBar) this.progressBar.set(time);
            if (this.progressBarBg) adultEndTime ? this.progressBarBg.set(1) : this.progressBarBg.set(0);
        }
        */
		let hasWobble = this.props.variant && this.props.variant.indexOf('notification') !== -1 && !this.props.active ? true : false;

		let showClass = '';
		if (this.props.type === 'bundleUp') {
			showClass = this.props.visible === false ? 'moveOutToTop' : 'moveInFromTop';
		}
		if (this.props.type === 'bundleDown') {
			showClass = this.props.visible === false ? 'moveOutToBottom' : 'moveInFromBottom';
		}

		let classes = '  ';
		classes += showClass + ' ';
		classes += this.state.animate + '  ';
		classes += (this.props.scope ? this.props.scope : 'global') + '-' + this.props.type + ' ';
		classes += this.props.variant + ' ';
		classes += (this.props.active === true ? 'active' : '') + ' ';
		let icon = this.props.variant || this.props.icon || null;
		let iconDirection = '0';
		let iconHeight = '100%';
		let iconTop = '0';
		let iconLeft = '0';

		if (this.props.type === 'start') {
			iconTop = '-22%';
			iconLeft = '-2%';
			icon = 'start';
		}
		if (this.props.type === 'play') {
			icon = 'play';
		}
		if (this.props.type === 'bundleDown') {
			icon = 'down';
			iconHeight = '58%';
		}
		if (this.props.type === 'bundleRight') {
			icon = 'down';
			iconDirection = '-90deg';
		}
		if (this.props.type === 'bundleLeft') {
			icon = 'down';
			iconDirection = '90deg';
		}
		if (this.props.type === 'bundleUp') {
			icon = 'down';
			iconTop = '46%';
			iconHeight = '58%';
			iconDirection = '-180deg';
		}
		if (this.props.type === 'back' || this.props.type === 'externalBack') {
			icon = 'back';
		}
		if (this.props.variant === 'contest') {
			icon = 'win';
		}
		if ((this.props.type === 'adult' && this.props.active) || this.props.type === 'close') {
			icon = 'close';
		} else {
			if (!icon) icon = 'menu';
		}

		return (
			<Wrapper type={this.props.type} style={styleParams} className={classes} {...getRightInput(this.onClick)}>
				{hint}
				<InnerButton
					active={this.props.variant}
					type={this.props.type}
					className={hasWobble}
					padding={this.props.type === 'adult' ? 0 : undefined}
				>
					{this.props.image && this.props.type !== 'close' ? (
						<span>
							<TeaserImage src={getImageUrl(this.props.image.urlScheme, '512', 'ident')} />
						</span>
					) : null}
					{!this.props.image || this.props.type === 'close' ? (
						<Icon left={iconLeft} top={iconTop} size={'100%'} sizeHeight={iconHeight} direction={iconDirection} code={icon} />
					) : null}
					{this.getTitle()}
					{!this.props.bgUrl && this.props.type !== 'adult' && this.props.type !== 'externalBack' ? (
						<BgInnerSVG fullBackground={icon && icon.indexOf('circle') !== -1} src={assets.getItem('teaserBgIndex').src} />
					) : null}
					{this.props.bgUrl !== false && this.props.type !== 'adult' ? (
						<Bg src={this.props.bgUrl || assets.getItem('auflegerBgFrame').src} />
					) : null}
				</InnerButton>
			</Wrapper>
		);
	}
}

export default KknButton;
