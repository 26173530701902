import styled, { css } from 'styled-components';
import { gfx, blueRabbitHand, blendIn, moveRight, moveLeft, moveDown, moveDownToUp, moveUp, moveUpToDown } from 'helpers/layoutVariables';

export const AnimationBackgound = styled.div``;

export const BackLayer = styled.div`
	position: absolute;
	width: 50%;
	height: 100%;
	z-index: 1;
	canvas {
		animation: ${blendIn} 0.3s ease-out;
		animation-fill-mode: forwards;
	}

	bottom: 0;
	left: 0;
	&.right {
		left: 50%;
	}
	&.layerUp {
		bottom: auto;
		top: -100%;
	}
	&.layerDown {
		bottom: auto;
		top: 100%;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 3840px;
	}
`;

export const Background = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	justify-content: center;
	background-size: cover;
	align-items: center;
	background-color: #fff6d1;

	img {
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
	}
`;

export const MiniImage = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 2;
	pointer-events: none;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 0 100%;
	width: 100%;
	height: 100%;
`;

export const AnimationWrapper = styled.div`
	position: absolute;
	display: block;
	width: 200%;
	left: 0;
	top: 0;
	height: 100%;
	z-index: 1;

	&.move-right {
		animation: ${moveRight} 1s ease-out;
		animation-fill-mode: forwards;
		transform: translate3d(-50%, 0, 0);
	}
	&.move-left {
		animation: ${moveLeft} 1s ease-out;
		transform: translate3d(0, 0, 0);
	}
	&.move-up {
		animation: ${moveUp} 1s ease-in-out;
		animation-fill-mode: forwards;
	}
	&.move-down {
		animation: ${moveDown} 1s ease-in-out;
		animation-fill-mode: forwards;
	}
	&.move-upToDown {
		animation: ${moveUpToDown} 1s ease-in-out;
		animation-fill-mode: forwards;
	}
	&.move-downToUp {
		animation: ${moveDownToUp} 1s ease-in-out;
		animation-fill-mode: forwards;
	}
	canvas {
		opacity: 1;
	}
	&.fadeIn {
		${blendIn};
	}
`;
