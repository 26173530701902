import styled, { css } from 'styled-components';

export const Wrapper = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${(props) => (props.size ? props.size : '1.666em')};
	z-index: 2;
	margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0')};
	margin-right: ${(props) => (props.marginRight ? props.marginRight : '0')};
	height: ${(props) => (props.sizeHeight ? props.sizeHeight : '100%')};
	position: relative;
	top: ${(props) => (props.top ? props.top : '0')};
	left: ${(props) => (props.left ? props.left : '0')};

	${(props) =>
		props.direction &&
		css`
			transform: rotate(${props.direction});
		`}

	svg {
		display: flex;
		width: 100%;
		position: relative;
		height: 100%;
		${(props) =>
			props.code &&
			css`
				g#${props.code} {
					display: flex;
				}
			`};
		${(props) =>
			props.code === 'arrowRight' &&
			css`
				g#${'down'} {
					display: flex;
					transform-origin: 55% 48%;
					transform: rotate(-90deg);
				}
			`};

		${(props) =>
			props.fill &&
			css`
				path {
					fill: ${props.fill} !important;
				}
			`};
	}
`;
