import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getImageUrl, getIconIdByTeaserType, Statics } from '../../../helpers/Utils';
import Slider from  '../../basics/Slider/Slider';
import { Wrapper, Item, SummaryTitle, ItemText, Topline, Title, ImageContainer, Image, Icon } from './SearchSuggestionsStyle';

// interface ITeaser {
//     title: string;
// 	id: string;
// 	url?: string;
// 	site?: string;
// 	teaserImage?: any;
// }

// interface ISearchSuggestionsItem {
// 	teaser: ITeaser;
// }

interface ISearchSuggestionsProps {
	// results: Array<ISearchSuggestionsItem>;
    url: string;
    onClick: (url: string, site?: string, title?: string) => void;
    redrawSlider: boolean;
}

export const SearchSuggestions = (props: ISearchSuggestionsProps) => {
    // @ts-ignore
    const [results, setResults] = useState([]);
    // @ts-ignore
    const [abortController, setAbortController] = useState(null);

    useEffect(() => {
        return () => {
            // componentwillunmount in functional component.
            if (abortController) abortController.abort();
        }
    }, []);

    useEffect(() => {
        setResults([]);
        if (props.url) getSearchSuggestions(props.url);
    }, [props.url]);

    const getSearchSuggestions = async (url: string) => {
		// cancel old request
		if (abortController) abortController.abort();

        const newAbortController = new AbortController();
		const results = await axios.get(
            url,
			{ signal: newAbortController.signal }
        );
        setAbortController(newAbortController);

		// console.log('getSearchSuggestions:\n');
		// console.dir(results.data.document.teaserBoxes[0].teasers);

		if (results.data.document && results.data.document.teaserBoxes[0] && Array.isArray(results.data.document.teaserBoxes[0].teasers)) {
			setResults(results.data.document.teaserBoxes[0].teasers);
		} else {
			setResults([]);
		}
	};

    const siteIsKikaninchen = (url: string): boolean => {
        // workaround for wrong site id comming from sophora

        if (url && url.startsWith('https://') || url.startsWith('http://')) {
            const domainStartIndex = url.indexOf('://') + 3;
            const domainEndIndex = url.indexOf('/', domainStartIndex);
            const domain = url.substring(domainStartIndex, domainEndIndex);

            // console.log('url domain: ' + domain);

            if (domain.endsWith('kikaninchen.de') || domain.endsWith('kikaninchen.ida-dev.de')) return true;
        }
        return false;
    };

	return <>
		<Wrapper>
            { results && results.length > 0 && props.redrawSlider &&
                <Slider offsetBefore={50} offsetAfter={50} variant={'sub'}>
                    <SummaryTitle>Entdecke:</SummaryTitle>

                    { results.map((item, index) => {
                        const teaser = item.standard;
                        const url = item.special.linkUrl;
                        const site = siteIsKikaninchen(url) ? 'kikaninchen' : 'kika';
                        const roundImage = teaser.teaserImage &&
                        (teaser.contentType === Statics.CONTENT_TYPE_START ||
                            teaser.contentType === Statics.CONTENT_TYPE_BUNDLE ||
                            teaser.contentType === Statics.CONTENT_TYPE_INDEX ||
                            teaser.contentType === Statics.CONTENT_TYPE_INDEX_TABBED ||
                            teaser.documentType === 'contentKit');

                        const imageUrl = teaser.teaserImage ?
                            getImageUrl(
                                teaser.teaserImage.urlScheme,
                                roundImage ? 256 : 512,
                                roundImage ? 'ident' : 'tlarge169')
                            : null;

                        const iconId = getIconIdByTeaserType(teaser);

                        let addClassNames = '';
                            if (roundImage) addClassNames += 'roundImage ';
                            if (teaser.contentType === 'start') addClassNames += 'contentTypeStart ';
                            if (iconId) addClassNames += 'hasIcon ';

                        return (
                            <Item className={addClassNames}
                                key={index + '_' + teaser.id}
                                onClick={() => {
                                    props.onClick(url, site, teaser.title);
                                }}
                            >
                                <ImageContainer>
                                    <Image src={imageUrl} alt={''} />
                                    { iconId && <Icon code={iconId} /> }
                                </ImageContainer>
                                <ItemText>
                                    <Topline>{teaser.topline}</Topline>
                                    <Title>{teaser.title}</Title>
                                </ItemText>
                            </Item>
                        );
                    })}

                </Slider>
            }
		</Wrapper>
    </>;
};

export default SearchSuggestions;
