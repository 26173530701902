import React, { Component } from 'react';
import Preloader from 'helpers/Preloader';
import KknButton from 'components/fragments/KknButton/KknButton';
import { getImageUrl, logger, pixelize, timecodeToSeconds } from 'helpers/Utils';
import { inject, observer } from 'mobx-react';
import Icon from 'components/basics/Icon/Icon';
import { BigLink, GalleryItem, GalleryList, MainImage, Wrapper } from './ImageGalleryStyle';
import { Outer, TeaserBg, WhiteBorder } from '../Teaser/TeaserStyle';
import ProgressBar from 'components/basics/Progress/ProgressBar';
import { CSSTransition } from 'react-transition-group';

@inject('assetStore')
@inject('appStore')
@inject('routingStore')
@inject('trackingStore')
@observer
class ImageGallery extends Component {
	state = {
		currentId: 0,
		currentItem: null,
		progress: 0,
		data: null,
		ended: false,
		paused: false,
		buffering: false
	};

	tempPause = false;
	forceDuration = null;
	entries = [];
	instance = null;
	playerReference = null;
	isUnmounted = false;
	styleRef = {};
	docType = null;
	base = null;
	instantOpen = true;
	docData = {};
	firstOpen = false;

	frameInterval = 1000 / 30;
	frameCount = 0;
	now = null;
	then = Date.now();
	int = null;

	componentDidMount() {
		if (this.props.appStore.isImageGallery(this.props.data) === false) return false;
		this.styleRef = { y: 0, x: pixelize('50em'), width: 1 };
		let scaleVal = 1;

		if (this.props.styleRef && this.props.styleRef.type !== 'round') {
			this.styleRef = this.props.styleRef;
			this.instantOpen = false;
		}

		if (typeof this.styleRef.y === 'number') {
			scaleVal = this.styleRef.width / pixelize('110.4em');

			this.setState({
				scaleVal: scaleVal,
				y: this.styleRef.y + 'px',
				x: this.styleRef.x + 'px',
				moveIn: true
			});
		} else {
			scaleVal = pixelize(this.styleRef.width) / pixelize('110.4em');
			this.setState({
				scaleVal: scaleVal,
				y: pixelize(this.styleRef.y) - pixelize(this.styleRef.height || '0em') / 2 + 'px',
				x: pixelize(this.styleRef.x) - pixelize(this.styleRef.width || '0em') / 2 + 'px',
				moveIn: true
			});
		}

		this.docType =
			this.props.data && this.props.data.standard && this.props.data.standard.documentType
				? this.props.data.standard.documentType
				: this.props.injectedDetailInfos && this.props.injectedDetailInfos.document.documentType;

		window.$('body').on('stopSound', this.pauseSound);
		window.$('body').on('restartSound', this.restartSound);
		window.$('body').on(window.BRavFramework.AV_PLAYER_READY, this.setPlayerVolume);
	}

	startGallery = () => {
		if (this.firstOpen === true) return false;
		setTimeout(
			() => {
				if (this.isUnmounted) return false;
				if (this.props.changeNavi !== false)
					this.props.appStore.detailOpen = {
						type: 'imageGallery',
						isSingleDeeplink: this.props.injectedDetailInfos ? true : false
					};
			},
			!this.props.clicked || this.instantOpen ? 0 : 500
		);

		setTimeout(
			() => {
				if (this.isUnmounted) return false;
				this.loadContent();
			},
			!this.props.clicked || this.instantOpen ? 0 : 1000
		);
		this.firstOpen = true;
	};

	setPlayerVolume = () => {
		this.playerReference = window.BRavFramework.api.getPlayer(window.kknApp.PLAYER_ID);
		if (this.state.currentItem.audio !== undefined) {
			this.playerReference.setVolume({ volume: 0 });
		} else {
			this.playerReference.setVolume({ volume: 1 });
		}
	};
	handleComplete = (event) => {
		if (this.forceDuration === null) this.next();
	};

	loadContent = (p) => {
		if (this.isUnmounted) return false;
		this.props.appStore.clearSoundCache();

		
		window.createjs.Sound.on('fileload', this.startSound, this);
		if (!this.isUnmounted) this.setState({ buffering: true });

		let props = p || this.props;

		if (props.injectedDetailInfos) {
			this.afterLoaded(props.injectedDetailInfos);
			return false;
		}

		let preloader = new Preloader();
		let items = [
			{
				id: 'content_gallery',
				type: 'json',
				src: props.data.url.split('.html').join('.json')
			}
		];

		preloader.load({
			cb: (contentEvt, content) => {
				this.afterLoaded(content.getResult('content_gallery'));
			},
			url: items
		});
	};
	afterLoaded = (data) => {
		window.$(this.base).removeClass('animation');
		this.docData = data;
		this.entries = this.docData.document.documentProperties.multimediaEntries;
		this.updateAdultLayer(data.document.parentalTeaserBox);
		if (!this.isUnmounted)
			this.setState({
				data: data,
				buffering: false,
				currentItem: this.entries[this.state.currentId]
			});
		window.$('body').on(window.BRavFramework.AV_PLAYER_STATE, (evt, params) => {
			if (this.state.currentItem.audio !== undefined) return false;

			if (params.state === window.BRavFramework.AV_PLAYER_PAUSED) {
				this.setState({ paused: true });
			}
			if (params.state === window.BRavFramework.AV_PLAYER_PLAYING) {
				this.setState({ paused: false });
			}
			if (params.state === window.BRavFramework.AV_PLAYER_FINISHED) {
				this.next();
			}
		});

		this.frameUpdate();
		this.updateCurrent(0);

		this.playSound();
	};

	updateAdultLayer = (data) => {
		// update adult layer if needed
		if (data === null) return false;
		this.adultData = { data: { teaserBox: data[0] || null } };
		this.props.assetStore.addAdultInfo(this.adultData);
	};

	componentWillUnmount() {
		this.isUnmounted = true;
		window.$('.avPlayerWrapper').removeClass('imageGallery');
		window.$('body').off(window.BRavFramework.AV_PLAYER_READY, this.setPlayerVolume);
		window.$('body').off(window.BRavFramework.AV_PLAYER_STATE);
		window.$('body').off('stopSound', this.pauseSound);
		window.$('body').off('restartSound', this.restartSound);

		if (this.int !== null) cancelAnimationFrame(this.int);
		window.createjs.Sound.stop();
		window.createjs.Sound.off('fileload', this.startSound, this);
		if (this.instance !== null) this.instance.off('complete', this.handleComplete, this);

		this.playerReference = window.BRavFramework.api.getPlayer(window.kknApp.PLAYER_ID);
		if (this.playerReference) {
			this.playerReference.setVolume({ volume: 1 });
		}
	}

	frameUpdate = () => {
		if (this.isUnmounted) return false;

		this.int = requestAnimationFrame(this.frameUpdate);

		this.now = Date.now();
		this.delta = this.now - this.then;

		if (this.delta > this.frameInterval) {
			this.then = this.now - (this.delta % this.frameInterval);

			this.onRender();
		}
	};

	onRender = () => {
		let val = 0;

		if (this.state.paused === true) return false;
		if (this.instance)
			if (!this.forceDuration && this.instance && this.state.currentItem.audio !== undefined) {
				val = (this.instance.position - this.instance.startTime) / this.instance.duration;
				if (val > 0.98) {
					val = 1;
				}
			}

		if (!this.forceDuration && this.state.currentItem.media.type === 'video' && this.state.currentItem.audio === undefined) {
			if (this.playerReference !== null && typeof this.playerReference.duration) {
				val = this.playerReference.currentTime / this.playerReference.duration;
			}
		}

		if (this.forceDuration !== null) {
			this.frameCount = this.frameCount + 1 / this.frameInterval;
			val = this.frameCount / this.forceDuration;

			if (this.frameCount >= this.forceDuration) {
				if (!this.isUnmounted) this.setState({ paused: true });
				this.next();
			}
		}

		window.$(this.refs.progress).attr('style', 'width:' + val * 100 + '%;');
		if (!this.isUnmounted) this.setState({ progress: val * 100 + '%' });
	};

	onClose = () => {
		this.props.clearAdultInfo('adultLayer');

		window.createjs.Sound.stop();
		this.props.onClosed();
	};

	playSound = () => {
		if (this.isUnmounted) return false;

		this.then = Date.now();
		this.frameCount = 0;
		this.forceDuration = null;
		window.createjs.Sound.stop();
		window.kknApp.killGlobalPlayer();
		if (this.state.currentItem.forceDuration !== undefined && this.state.currentItem.forceDuration.length > 0)
			this.forceDuration = timecodeToSeconds(this.state.currentItem.forceDuration);

		if (this.state.currentItem.audio !== undefined) {
			if (window.createjs.Sound.loadComplete(this.state.currentItem.audio.id)) {
				this.startSound();
			} else {
				let protocol = process.env.REACT_APP_DEV_PROXY && process.env.REACT_APP_DEV_PROXY.length > 0 ? 'http://localhost:3001' : '';
				window.createjs.Sound.registerSound(protocol + this.state.currentItem.audio.url, this.state.currentItem.audio.id);
			}
		} else {
			if (!this.isUnmounted) this.setState({ paused: false, ended: false });
		}
		if (this.state.currentItem.media.type === 'video') {
			//setTimeout(() => {
			window.$('.avPlayerWrapper').removeClass('imageGallery');
			window.$('.avPlayerWrapper').addClass('imageGallery');
			let url =
				process.env.REACT_APP_DEV_PROXY && process.env.REACT_APP_DEV_PROXY.length > 0
					? '/dummy/videos/video6242/video6242.xml'
					: this.state.currentItem.media.special.avCustomUrl;

			window.kknApp.startGlobalPlayer(url);
			//}, 250);
		}
		if (!this.isUnmounted) this.setState({ buffering: false });
	};
	startSound = () => {
		if (this.state.currentItem === null || this.state.currentItem.audio === undefined) return false;
		if (this.isUnmounted) return false;
		window.createjs.Sound.stop();
		this.instance = window.createjs.Sound.play(this.state.currentItem.audio.id); // play using id.  Could also use full source path or event.src.
		this.instance.on('complete', this.handleComplete, this);

		setTimeout(() => {
			this.restartSound();
		}, 0);
	};

	next = () => {
		if (this.state.currentId < this.entries.length - 1) {
			this.updateCurrent(this.state.currentId + 1);

			setTimeout(() => {
				this.playSound();
			}, 0);
		} else {
			this.updateCurrent(0);
			setTimeout(() => {
				//   this.playSound();
				this.pauseSound(true);
			}, 0);
		}
	};
	prev = () => {
		if (this.state.currentId > 0) {
			this.updateCurrent(this.state.currentId - 1);
			setTimeout(() => {
				this.playSound();
			}, 0);
		} else {
			//  this.updateCurrent(0);
			setTimeout(() => {
				//this.playSound();
				this.pauseSound();
			}, 0);
		}
	};
	updateCurrent = (id) => {
		if (this.state.data) {
			let type = this.docData.document.documentType;
			this.props.trackingStore.trackLayerView({
				atiTracking: this.docData.document.atiTracking,
				toolName: type,
				path: this.entries[id].media.sophoraId
			});
		}
		if (!this.isUnmounted) this.setState({ buffering: true, currentItem: this.entries[id], currentId: id });
	};

	toggleSound = (i) => {
		if (this.state.currentId !== i) {
			this.updateCurrent(i);
		}
		if (this.state.currentId !== i || this.state.ended === true) {
			setTimeout(this.playSound, 0);
		} else {
			this.state.paused ? this.restartSound() : this.pauseSound();
		}
	};

	pauseSound = (end) => {
		if (this.instance !== null) this.instance.paused = true;
		if (this.playerReference !== null) this.playerReference.pause();

		if (!this.isUnmounted) this.setState({ paused: true, buffering: false, ended: end === true ? true : false });
	};

	restartSound = () => {
		if (this.state.paused === true) {
			if (this.instance !== null) this.instance.paused = false;
			if (this.playerReference !== null) this.playerReference.play();
			if (!this.isUnmounted) this.setState({ paused: false, ended: false });
		}
	};

	getItem = (box, i) => {
		let assets = null;
		if (this.props.notifs && this.props.notifs.kknAssets) {
			assets = this.props.notifs.kknAssets.assets;
		}

		return (
			<GalleryItem
				key={box + '-' + i}
				onClick={() => {
					this.toggleSound(i);
				}}
				className={
					(this.state.currentId === i ? 'active' : '') +
					' ' +
					(this.state.currentId === i ? (this.state.paused ? 'play' : 'pause') : 'play')
				}
			>
				<Icon size={'100%'} code={this.state.currentId === i ? (this.state.paused ? 'circlePlay' : 'circlePause') : 'bullet'} />
			</GalleryItem>
		);
	};

	render() {
		let firstStart = this.props.appStore.clicked;
		if (this.props.appStore.isImageGallery(this.props.data) === false) return null;

		if (firstStart) this.startGallery();
		let teasers = [];
		if (this.state.data !== null) {
			this.entries.map((box, i) => {
				teasers.push(this.getItem(box, i));
			});
		}
		let img =
			this.state.data === null
				? getImageUrl(
						this.props.data &&
							this.props.data.standard &&
							this.props.data.standard.teaserImage &&
							this.props.data.standard.teaserImage.urlScheme
				  )
				: getImageUrl(
						this.state.currentItem.media.urlScheme ||
							(this.state.currentItem.media.standard && this.state.currentItem.media.standard.teaserImage.urlScheme)
				  );

		if (this.props.injectedDetailInfos && this.state.data === null) {
			img = getImageUrl(this.props.injectedDetailInfos.document.teaserImage.urlScheme);
		}

		return (
			<CSSTransition in={this.state.moveIn} timeout={0} unmountOnExit={true}>
				<Wrapper {...this.state} className={this.props.scope + '-layer'} ref={(b) => b && (this.base = b)}>
					<Outer variant={'layer'} className={this.props.scope + '-layer-outer'}>
						{this.state.buffering && firstStart ? <div className="kkn-buffer" /> : null}
						<TeaserBg src={this.props.assets.getItem('teaserBgNormal').src} />
						<WhiteBorder variant={'layer'} className={this.props.scope + '-layer-border'}>
							<TeaserBg src={this.props.assets.getItem('teaserBgFrame').src} />
							<MainImage>
								<BigLink
									onClick={() => {
										this.toggleSound(this.state.currentId);
									}}
								>
									{this.state.paused && !this.state.buffering ? (
										<KknButton
											type={'bigPlay'}
											icon={this.state.ended ? 'circleReplay' : 'circlePlay'}
											size={18.75}
											bgUrl={false}
										/>
									) : null}
								</BigLink>
								<img className={'img' + this.state.currentId} src={img} />
								{this.docType !== 'image' ? <ProgressBar progress={this.state.progress} /> : null}
							</MainImage>
							<GalleryList>
								{this.docType !== 'image' && this.entries.length > 1 ? teasers : null}
								<img src={this.props.assets.getItem('bgLiedtext').src} />
							</GalleryList>
						</WhiteBorder>
					</Outer>

					{this.entries.length > 1 && this.docType !== 'image' ? (
						<KknButton
							onClicked={this.prev}
							scope={this.props.scope}
							type="bundleLeft"
							bgUrl={this.props.assets.getItem('lasche').src}
						/>
					) : null}
					{this.entries.length > 1 && this.docType !== 'image' ? (
						<KknButton
							onClicked={this.next}
							scope={this.props.scope}
							type="bundleRight"
							bgUrl={this.props.assets.getItem('lasche').src}
						/>
					) : null}
				</Wrapper>
			</CSSTransition>
		);
	}
}

export default ImageGallery;
