import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Background, Icon, InnerWrapper, Wrapper } from './SliderButtonStyle';

@inject('appStore')
@inject('assetStore')
@observer
class SliderButton extends Component {
	render() {
		let globalAssets = this.props.assetStore.globalAssets;
		let urlBg = globalAssets.getItem('sliderBtnBg').src;
		let urlIcon = globalAssets.getItem('sliderBtnIcon').src;

		return (
			<Wrapper {...this.props}>
				<InnerWrapper {...this.props}>
					<Background src={urlBg} />
					<Icon src={urlIcon} />
				</InnerWrapper>
			</Wrapper>
		);
	}
}

export default SliderButton;
