import styled, { css } from 'styled-components';
import { gfx, blueRabbitHand } from 'helpers/layoutVariables';

export const Wrapper = styled.div`
	position: absolute;
	left: 92em;
	width: 4em;
	height: 100%;
	z-index: 1;
	background-color: ${gfx.yellow};

	display: flex;
	align-items: center;
	justify-content: center;
	transition: transform 0.3s ease-out;
	transform: translate3d(-1em, 0, 0);

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: flex;
		flex-direction: column;
		height: auto;
		margin-left: -1em;
	}

	img {
		position: absolute;
		left: -1.4em;
		height: 100%;
	}
`;

export const Item = styled.li`
	margin-bottom: 0.5em;
	min-height: 1em;
	margin-top: 0.5em;
	width: 2em;
	height: 2em;
	font-size: 1em;

	button {
		font-size: 1em;
		padding: 0;
		border-radius: 50%;
		border: 0;
		background: none;
		width: 2em;
		height: 2em;
		${blueRabbitHand};

		background-color: ${gfx.vanille};
	}

	${(props) =>
		props.active &&
		css`
			button {
				background-color: ${gfx.blue};
			}
		`};
`;
