import styled from 'styled-components';
import { gfx, blueRabbitHand } from '../../../helpers/layoutVariables';

export const Wrapper = styled.div`
    position: absolute;
    top: 6.3em;
    left: 7.2em;
    z-index: 50;
	display: flex;
    flex-direction: column;
    border-radius: 1em;
    padding: 1.5em;

    box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.5);
    background-color: #fff;

    .view-small & {
        position: relative;
        top: auto;
        left: auto;
        z-index: auto;
        flex-direction: row;
        box-shadow: none;
        gap: 1em 1.5em;
        flex-wrap: nowrap;
        overflow-x: auto;
        border-radius: 0;
        padding: 0;
        margin: 0 2em 2em;
        background-color: transparent;
    }
`;

export const Item = styled.div`
	display: flex;
    align-items: center;
    gap: 1rem;
    min-width: 24em;
    ${blueRabbitHand};

    background-color: #fff;
    padding: 0.75em;

    .view-small & {
        min-width: auto;
        gap: 1em;
        padding: 0.75em 1.5em;
        border-radius: 1.5em;
        background-color: rgba(255, 255, 255, 0.25);
        flex: 0 0 auto;
    }
`;

export const Title = styled.div`
    color: ${gfx.brown};
    font-size: 1.5em;

    .view-small & {
        font-size: 2.269em;
    }
`;

export const Image = styled.img`
    flex: 0 0 auto;
    width: 5em;
    height: 5em;
	border-radius: 50%;
	border: 0.2em solid ${gfx.blue};

    .view-small & {
        border: 0.4em solid ${gfx.blue};
        width: 5em;
        height: 5em;
    }
`;
