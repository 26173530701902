import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Wrapper } from './SliderStyle';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Mousewheel, Navigation, Pagination, Scrollbar } from 'swiper';

SwiperCore.use([Navigation, Pagination, Scrollbar, Mousewheel]);

/*
Here is the list of additional modules imports:
Virtual - Virtual Slides module
Keyboard - Keyboard Control module
Mousewheel - Mousewheel Control module
Navigation - Navigation module
Pagination - Pagination module
Scrollbar - Scrollbar module
Parallax - Parallax module
Zoom - Zoom module
Lazy - Lazy module
Controller - Controller module
A11y - Accessibility module
History - History Navigation module
HashNavigation - Hash Navigation module
Autoplay - Autoplay module
EffectFade - Fade Effect module
EffectCube - Cube Effect module
EffectFlip - Flip Effect module
EffectCoverflow - Coverflow Effect module
Thumbs - Thumbs module


 */

@inject('appStore')
@observer
class Slider extends Component {
	componentDidMount() {
		if (this.props.type !== 'vertical') {
			this.props.appStore.contentScrollerItemsLength = 6;
		}
	}

	componentWillUnmount = () => {
		if (this.props.variant !== 'sub') this.props.appStore.contentScroller = null;
	};

	render() {
		let params = {
			direction: this.props.type === 'vertical' ? 'vertical' : 'horizontal',
			slidesPerView: 'auto',
			freeMode: true,
			observerChildren: true,
			observer: true,
			rebuildOnUpdate: true,
			draggable: false,
			observeParents: true,
			mousewheel: true,
			// Navigation arrows
			spaceBetween: this.props.margin || 0,
			scrollbar: {
				el: '.swiper-scrollbar',
				hide: false
			},

			slidesOffsetBefore: this.props.offsetBefore || 0,
			slidesOffsetAfter: this.props.offsetAfter || 0
		};
		let childs = [];
		let currentSlide = [];
		let passedChilds = React.Children.toArray(this.props.children);
		let counter = 0;
		let isGrid = this.props.isGrid || false;

		passedChilds.map((data, i) => {
			if (isGrid === false) {
				return childs.push(<SwiperSlide key={`swiper${i}`}>{data}</SwiperSlide>);
			}
			if (i % 2 === 0) {
				currentSlide = [];
			}
			currentSlide.push(data);

			if (i % 2 === 1 || i === passedChilds.length - 1) {
				return childs.push(<SwiperSlide key={`swiper${i}`}>{currentSlide}</SwiperSlide>);
			}

			return null;
		});

		if (this.props.isActive === false) {
			return (
				<Wrapper noSlider={true} {...this.props}>
					{childs}
				</Wrapper>
			);
		}

		return (
			<React.Fragment>
				<Wrapper {...this.props} update={this.props.appStore.adultScrollerHeightUpdate}>
					<Swiper
						direction={this.props.type === 'vertical' ? 'vertical' : 'horizontal'}
						spaceBetween={this.props.margin || 0}
						freeMode={true}
						updateOnWindowResize={true}
						observer={true}
						observeParents={true}
						slidesPerView={'auto'}
						slidesOffsetBefore={this.props.offsetBefore || 0}
						slidesOffsetAfter={this.props.offsetAfter || 0}
						mousewheel={true}
						simulateTouch={this.props.simulateTouch || false}
						scrollbar={{
							hide: false,
							snapOnRelease: false,
							dragSize: 'auto',
							draggable: true
						}}
						onSwiper={(swiper) => {
							if (this.props.variant === 'sub') return false;

							if (swiper) this.props.appStore.contentScroller = swiper;
							this.props.appStore.contentScrollerIsEnd = false;
							this.props.appStore.contentScrollerIsBeginning = true;
						}}
						onInit={(swiper) => {
						}}
						onReachEnd={() => {
							this.props.appStore.contentScrollerIsEnd = true;
						}}
						onReachBeginning={() => {
							this.props.appStore.contentScrollerIsBeginning = true;
						}}
						onSetTranslate={(swiper) => {
							this.props.appStore.contentScrollerIsEnd = swiper.isEnd;
							this.props.appStore.contentScrollerIsBeginning = swiper.isBeginning;
						}}
					>
						{childs}
					</Swiper>
				</Wrapper>
			</React.Fragment>
		);
	}
}

export default Slider;
