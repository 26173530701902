export const Statics = {
	SCENE_TYPE_BROADCAST: 'broadcast',
	SCENE_TYPE_GLOBAL: 'global',
	SCENE_TYPE_STYLED_GLOBAL: 'styledGlobal',

	SCENE_TYPE_HOME: 'start',
	SCENE_TYPE_MAIN: 'main',

	CONTENT_TYPE_INDEX: 'index',
	CONTENT_TYPE_INDEX_TABBED: 'indexTabbed',
	CONTENT_TYPE_START: 'start',
	CONTENT_TYPE_BUNDLE: 'bundle',

	CONTENT_TYPE_INTERACTIVE: 'interactiveContent',
	CONTENT_TYPE_VIDEO: 'video',
	CONTENT_TYPE_EXTERNAL_VIDEO: 'externalVideo',
	CONTENT_TYPE_RELATED_VIDEO: 'relatedVideo',
	CONTENT_TYPE_AUDIO: 'audio',
	CONTENT_TYPE_SONG: 'song',
	CONTENT_TYPE_MMG: 'multimediaGallery'
};

export function isTouch() {
	return typeof orientation !== 'undefined';
}

export function trimBrackets(str) {
	return str
		.split('[')
		.join('')
		.split(']')
		.join('');
}

export function capitalizeFirstLetter(string) {
	return string && string.length > 0 ? string[0].toUpperCase() + string.slice(1) : '';
}

export function isIos() {
	let isIos = false;
	let iPhone = new RegExp(/iPhone/i);
	let iPod = new RegExp(/iPod/i);
	let iPad = new RegExp(/iPad/i);

	if (navigator.userAgent.match(iPhone) || navigator.userAgent.match(iPod) || navigator.userAgent.match(iPad)) {
		isIos = true;
	}
	return isIos;
}

export function isAndroid() {
	let reAndroid = new RegExp(/Android/i);

	return reAndroid.test(navigator.userAgent);
}

export function isStringDetected(value) {
	var regexp = new RegExp(value, 'gi');
	return regexp.test(navigator.appVersion) || regexp.test(navigator.userAgent) ? true : false;
}

export function isTablet() {
	if (isStringDetected('iPad|Windows NT|Kindle|PlayBook') || (isStringDetected('Android') && !isStringDetected('Mobile'))) {
		return true;
	}
	return false;
}

export function xmlToJson(xml) {
	// Create the return object
	var obj = '';

	if (xml.nodeType == 1) {
		// element
		// do attributes
		obj = {};
		if (xml.attributes.length > 0) {
			obj['@attributes'] = {};
			for (var j = 0; j < xml.attributes.length; j++) {
				var attribute = xml.attributes.item(j);
				obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
			}
		}
	} else if (xml.nodeType == 3) {
		// text
		obj = xml.nodeValue;
	}
	if (xml.childNodes.length === 0) obj = '[]';
	// do children
	// If just one text node inside
	if (xml.hasChildNodes() && xml.childNodes.length === 1 && xml.childNodes[0].nodeType === 3) {
		obj = xml.childNodes[0].nodeValue;
	} else if (xml.hasChildNodes()) {
		for (var i = 0; i < xml.childNodes.length; i++) {
			var item = xml.childNodes.item(i);
			var nodeName = item.nodeName;
			if (typeof obj[nodeName] == 'undefined') {
				obj[nodeName] = xmlToJson(item);
			} else {
				if (typeof obj[nodeName].push == 'undefined') {
					var old = obj[nodeName];
					obj[nodeName] = [];
					obj[nodeName].push(old);
				}
				obj[nodeName].push(xmlToJson(item));
			}
		}
	}
	return obj;
}

export async function loadScript(url) {
	const scriptPromise = new Promise((resolve, reject) => {
		const script = document.createElement('script');
		document.body.appendChild(script);
		script.onload = resolve;
		script.onerror = reject;
		script.async = true;
		script.src = url;
	});

	let result = await scriptPromise; // wait till the promise resolves (*)

	return result;
}

export function isSessionStorageAllowed() {
	var testKey = 'test',
		storage = window.sessionStorage;
	try {
		storage.setItem(testKey, '1');
		storage.removeItem(testKey);
		return true;
	} catch (error) {
		return false;
	}
}

export function getControllerConfig(dom, attr) {
	let config = null;

	try {
		config = eval('(' + dom.getAttribute(attr) + ')');
	} catch (e) {}

	return config;
}

export function getIconUrl(id) {
	let icon = 'neu';
	switch (id) {
		default:
			icon = id;
			break;
		case 'startIntro':
		case 'start':
			icon = 'buttonStart.svg';
			break;
		case 'introSelbermachen':
		case 'selbermachen':
			icon = 'buttonTutorials.svg';
			break;
		case 'introLieder':
		case 'lieder':
			icon = 'buttonLieder.svg';
			break;
		case 'introMovies':
			icon = 'buttonFilme.svg';
			break;
		case 'introAktion':
		case 'aktion':
			icon = 'ostern.jpg';
			break;
		case 'introSearch':
			icon = 'buttonSuche.svg';
			break;
		case 'bgAdult':
			icon = 'buttonSuche.svg';
			break;
	}

	return `/static_kkn/global/images/tabbed/icons/${icon}`;
}

export function getIconIdByTeaserType(data) {
	if (!data) return null;

	// translate category ids from json to svg icon ids
	const categoryIds = {
		'video': 'play',
		'song': 'song',
		'tinker': 'tinker',
		'doItYourself': 'doItYourself',
		'adultContent': 'adult',
		'games': 'game',
		'recipe': 'food',
		'paint': 'paint',
		'aktion': 'letter',
		'kika': null
	};

	let iconId = categoryIds[data.contentCategory];
	if (!iconId &&
		(
			data.documentType === 'video' ||
			data.documentType === 'relatedVideo' ||
			data.documentType === 'externalVideo'
		)) iconId = 'play';

	return iconId;
}

export function getTeaserType(data) {
	let type = 'standard';

	if (!data) return type;

	if (data.standard.contentType === 'index' || data.standard.documentType === 'contentKit' || data.standard.documentType === 'start') {
		type = 'round';
	}
	return type;
}

export function getInfosFromDocumentCanvasId(id) {
	let values = { baseId: id, template: '', scope: '', contentType: '', category: '' };

	if (id !== undefined) {
		let tempId = id.split('-');
		let baseId = tempId[0];
		values.template = baseId.indexOf('_') !== -1 ? baseId.split('_')[0] : baseId;
		values.scope = baseId.indexOf('_') !== -1 ? baseId.split('_')[1] : null;
		values.contentType = tempId[1] !== undefined ? tempId[1] : Statics.CONTENT_TYPE_INDEX;
		if (tempId[2] !== undefined) {
			values.category = tempId[2];
		} else {
			values.category = Statics.CONTENT_TYPE_INDEX;
		}
	} else {
		values.template = Statics.SCENE_TYPE_MAIN;
		values.scope = 'kikaninchen';
		values.contentType = Statics.CONTENT_TYPE_INDEX;
		values.category = Statics.CONTENT_TYPE_INDEX;
	}

	return values;
}

export function logger(...val) {
	if (window.NO_LOGGING !== true) {
		if (window.console && console.log) {
			console.log(...val);
		}
	}
}

export function pixelize(val) {
	if (val && typeof val === 'string') return Number(val.split('em')[0]) * (window.kknApp.scale * 16);

	return 0;
}

export function getPath(props, add) {
	let url = '';
	let passedUrl = props.location.pathname;

	if (passedUrl) {
		url = add === undefined ? passedUrl.split('.html').join('.json') : passedUrl + add;
		return url;
	}
}

export function getImageUrl(str, w, v) {
	if (str === undefined || str === null) str = '';
	let width = w || '1024';
	let variant = v || 'tlarge169';
	let devProxy = str.indexOf('http') === -1 ? process.env.REACT_APP_DEV_PROXY : '';
	return (
		devProxy +
		str
			.split('**aspectRatio**')
			.join(variant)
			.split('**imageVariant**')
			.join(variant)
			.split('**width**')
			.join(width)
	);
}

export function timecodeToSeconds(hh_mm_ss_ff, showFrameCount, fps) {
	if (typeof showFrameCount == 'undefined') {
		showFrameCount = false;
	} else if (typeof fps == 'undefined') {
		fps = 25;
	}

	let tc_array = hh_mm_ss_ff.split(':'),
		tc_hh = parseInt(tc_array[0], 10),
		tc_mm = parseInt(tc_array[1], 10),
		tc_ss = parseInt(tc_array[2], 10),
		tc_ff = 0,
		tc_in_seconds = 0;

	if (tc_array[0].substr(0, 1) == '1') tc_hh = parseInt('0' + tc_array[0].substr(1));

	if (showFrameCount) {
		tc_ff = parseInt(tc_array[3]) / fps;
	}

	tc_in_seconds = tc_hh * 3600 + tc_mm * 60 + tc_ss + tc_ff;

	return tc_in_seconds;
}

export function getRightInput(callback, forceClick) {
	let props = {};

	if (isTouch() && forceClick !== true) {
		props.onTouchStart = callback;
	} else {
		props.onClick = callback;
	}

	return props;
}

export function secondsToTimeCode(time, forceHours, showFrameCount, fps) {
	//add framecount
	if (typeof showFrameCount === 'undefined') {
		showFrameCount = false;
	} else if (typeof fps === 'undefined') {
		fps = 25;
	}

	var hours = Math.floor(time / 3600) % 24,
		minutes = Math.floor(time / 60) % 60,
		seconds = Math.floor(time % 60),
		frames = Math.floor(((time % 1) * fps).toFixed(3)),
		result =
			(forceHours || hours > 0 ? (hours < 10 ? '0' + hours : hours) + ':' : '') +
			(minutes < 10 ? '0' + minutes : minutes) +
			':' +
			(seconds < 10 ? '0' + seconds : seconds) +
			(showFrameCount ? ':' + (frames < 10 ? '0' + frames : frames) : '');

	return result;
}

export function isTouchDevice() {
	return typeof orientation !== 'undefined';
}

export function hasNativeHlsSupport(videoElement) {
	return !!videoElement.canPlayType('application/vnd.apple.mpegURL');
}

export function debounce(func, wait, immediate) {
	let timeout;
	return function() {
		let context = this,
			args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

export function isSmallView() {
	return window.innerWidth < 768;
}

export function getMergedTeaserData(data, prop) {
	let items = [];
	if (prop === undefined) prop = 'teasers';

	if (data && data.teaserBoxes !== undefined) {
		data.teaserBoxes.map((box, i) => {
			items = items.concat(box[prop]);
		});
	}
	return items;
}

export function getResizedBackgroundPosition(w) {
	let width = w || 0;
	return '0 -' + String(Math.floor(width)) + 'px';
}

export function getResizedTopPos(t) {
	let top = t || 0;
	return '-' + String(Math.floor(window.kknApp.scale * top)) + 'px';
}

export function getResizedBackgroundSize(w, h) {
	let width = w || 0;
	let height = h || 0;
	return '0 -' + String(Math.floor(window.kknApp.scale * width)) + 'px';
}

export function getCookie(cname) {
	let name = cname + '=';
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

export function setCookie(cname, cvalue, exdays) {
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	let expires = 'expires=' + d.toUTCString();
	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}
