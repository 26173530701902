import styled from 'styled-components';
import { gfx, blueRabbitHand } from '../../../helpers/layoutVariables';
import BasicIcon from '../../basics/Icon/Icon';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 2em;
	align-items: center;
	width: 100%;
	padding: 0.35em 1.5em;
	background-color: rgba(255, 255, 255, 0.25);
	border-radius: 0.75em;
	${blueRabbitHand};

	.view-small & {
		border-radius: 1.5em;
	}
`;

export const Icon = styled(BasicIcon)`
	position: absolute;
	width: 3.33em;
	height: 3.33em;
	top: auto;
	right: auto;
	bottom: -0.5em;
	left: -0.5em;
	padding: 0.3em;
	background-color: ${gfx.vanille};
	border-radius: 50%;
`;

export const TitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 0.5em;
	flex-wrap: wrap;
`;

export const Title = styled.div`
	flex: 1 0 100%;
	color: ${gfx.brown};
	font-size: 1.666666em;
	word-break: break-word;

	.view-small & {
		font-size: 2.494em;
		margin: 0.125em 0;
	}
`;

export const ImageContainer = styled.div`
	position: relative;
	display: inline-flex;
	flex: 0 0 auto;

	// standard - 16:9 rectangle
	margin: 0.983em 0;

	.roundImage & {
		// Bundles - circle single border
		margin: 0.983em;
		/* border: 0.983em solid transparent; */
	}
	.contentTypeStart & {
		// Freunde - circle double border
		margin: 0;
		border: 0.65em solid ${gfx.yellow};
	}

	.roundImage & {
		border-radius: 50%;

		&::before {
			box-shadow: inset 0 0.25em 1.5em 0 #5f4100c0;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			content: '';
			border-radius: 50%;
			border: 0.333333em solid transparent;
			box-sizing: border-box;
			z-index: 1;
		}
	}
`;

export const Image = styled.img`
	height: 8em;

	.view-small & {
		height: 10.875em;
	}

	.roundImage & {
		border-radius: 50%;
		border: 0.333333em solid ${gfx.vanille};
		width: 8em;

		.view-small & {
			width: 10.875em;
		}
	}
	.contentTypeStart & {
		width: 8.666em;
		height: 8.666em;
		box-shadow: 0 0 0.4em -0.1em #000000;

		.view-small & {
			width: 11.541em;
			height: 11.541em;
		}
	}
`;

export const Tag = styled.div`
	flex: 0 0 auto;
	background-color: rgba(255, 255, 255, 0.25);
	border-radius: 0.375em;
	color: ${gfx.brown};
	padding: 0.25em 0.5em;
	font-size: 1.168em;
	line-height: 1.2;

	.view-small & {
		border-radius: 0.25em;
		padding: 0.2em 0.5em 0.125em 0.5em;
		font-size: 1.818em;
	}
`;

export const TagAdult = styled(Tag)`
	background-color: #06ebb9;
	color: #005c63;

	path {
		fill: #005c63!important;
	}​
`;

export const TagKika = styled(Tag)`
	background-color: #005c63;
	color: #ffe919;

	path {
		fill: #ffe919!important;
	}​
`;

export const TagIcon = styled(BasicIcon)`
	display: inline-block;
	vertical-align: text-bottom;
	width: 1.25em;
	height: 1.1em;
	margin-left: -0.25em;
	margin-right: 0.125em;

	.view-small & {
		width: 1em;
		height: 1em;
	}
`;
