import React, { Component } from 'react';
import ClickOn from 'components/fragments/ClickOn/ClickOn';
import { isSmallView, logger, Statics } from 'helpers/Utils';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { AnimationBackgound, AnimationWrapper, Background, BackLayer, MiniImage } from './AnimatedBackgroundStyle';

@inject('appStore')
@inject('assetStore')
@inject('routingStore')
@observer
class AnimatedBackground extends Component {
	state = {
		scope: '',
		bgBlob: ''
	};
	initialized = false;
	isUnmounted = false;
	lastBgPosition = '';

	componentDidMount(callback) {
		if (this.isUnmounted) return false;
		this.setState({
			scope: this.props.scope
		});
		//this.getBgBlob();
	}

	componentDidUpdate = () => {
		this.initialized = true;
	};

	componentWillReceiveProps(nextProps) {
		this.setState({ scope: nextProps.scope });
		//this.getBgBlob();
	}

	componentWillUnmount() {
		this.isUnmounted = true;
	}

	getAnimationRelatedState = (forceLast) => {
		let state = this.props.category;
		if (state === Statics.CONTENT_TYPE_START && this.props.cache !== null) {
			state = this.props.lastCategory;
		}
		if (forceLast) state = this.props.lastCategory;
		return state;
	};

	capitalizeFirstLetter = (string) => {
		return string && string.length > 0 ? string[0].toUpperCase() + string.slice(1) : '';
	};

	getScopeFromCategory(cache) {
		let state = this.getAnimationRelatedState();
		let ret = this.props.assetStore.getSceneAnimation(state);
		if (this.props.config && this.props.config.scenes) {
			let params = this.props.config.scenes[String(this.props.pageIndex)];
			if (!params) params = this.props.config.scenes['default'];
			if (!params) return undefined;
			if (
				this.props.isBundle === false &&
				this.props.config &&
				this.props.assetStore.docInfos.template === Statics.SCENE_TYPE_STYLED_GLOBAL
			) {
				ret = params.animation || undefined;
			}
		}
		logger('#scope', ret);
		if (ret && ret.indexOf('globalStart') !== -1) return undefined;
		return ret;
	}

	getBgLayer = (type) => {
		if (this.props.cache === null) return null;

		let bgStyle = {};
		let bgUrl = isSmallView() ? 'bgLayerSpriteSmall' : 'bgLayerSprite';
		bgStyle.backgroundSize = '200% auto';

		//BG, not small
		if (type === 'bg' || this.props.cache.assets.getItem(bgUrl) === undefined) {
			bgStyle.backgroundImage = '';
			return bgStyle;
		}

		// BG, Layer Left
		if (type === 'leftLayer' || type === 'rightLayer' || type === 'up' || type === 'down') {
			bgStyle.backgroundImage = 'url(' + this.props.cache.assets.getItem(bgUrl).src + ')';
		}

		if (this.props.assetStore.docInfos.template === Statics.SCENE_TYPE_STYLED_GLOBAL && type === 'leftLayer') {
			let pageIndex = String(this.props.pageIndex);
			let item = this.props.config.scenes['0'];
			if (this.props.pageIndex > 0 && this.props.config.scenes[pageIndex]) {
				item = this.props.config.scenes[pageIndex];
			}
			if (this.props.pageIndex > 0 && this.props.config.scenes[pageIndex] === undefined) {
				item = this.props.config.scenes['default'];
			}

			if (bgStyle && item && item.backgroundPosition) {
				bgStyle.backgroundPosition = item.backgroundPosition;
			}
		}

		if (type === 'rightLayer' || type === 'up' || type === 'down') {
			switch (this.getAnimationRelatedState()) {
				default:
					bgStyle.backgroundPosition = '-100% 0';
					break;
				case 'videos':
					bgStyle.backgroundPosition = '-100% 0';
					break;

				case 'lieder':
					bgStyle.backgroundPosition = '0 -100%';
					break;
				case 'ueber':
					bgStyle.backgroundPosition = '0 -200%';
					break;

				case 'selbermachen':
					bgStyle.backgroundPosition = '-100% -100%';
					break;
				case 'spiele':
					bgStyle.backgroundPosition = '-100% -200%';
					break;
			}
		}

		return bgStyle;
	};

	getContentPageClickon = () => {
		if (this.props.clickonsActive === false) return null;

		return (
			<ClickOn
				stageRef="STAGE_2"
				pos="top"
				animate={this.props.cache === null ? true : false}
				scope={window[this.getScopeFromCategory(this.props.cache)]}
				scale={false}
				assets={this.props.cache.clickonsData}
				func={this.getScopeFromCategory(this.props.cache)}
			/>
		);
	};
	getBgBlob = async () => {
		let bgUrl =
			this.props.cache === null || this.props.assetStore.docInfos.template === Statics.SCENE_TYPE_STYLED_GLOBAL
				? this.props.bgUrl
				: this.props.cache.assets.getItem('bgImageUrl')
				? this.props.cache.assets.getItem('bgImageUrl').src
				: '';
		//let bgBlob = null;
		let bgBlob = await fetch(bgUrl).then((r) => r.blob());
		this.setState({ bgBlob: URL.createObjectURL(bgBlob) });
	};

	render() {
		let layerBg = 'scene-backLayer-' + this.props.scope + ' ';
		let bgUrl =
			this.props.cache === null || this.props.assetStore.docInfos.template === Statics.SCENE_TYPE_STYLED_GLOBAL
				? this.props.bgUrl
				: this.props.cache.assets.getItem('bgImageUrl')
				? this.props.cache.assets.getItem('bgImageUrl').src
				: '';
		//let bgBlob = null;
		//let bgBlob = this.getBgBlob(bgUrl);
		//if (this.props.cache && this.props.cache.assets.getResult('bgImageUrl')) {
		//	bgBlob = this.props.cache.assets.getResult('bgImageUrl');
		//	window.$('.kkn-globalBackground').html(bgBlob);
		//}

		let funcRefStart = this.getScopeFromCategory(this.props.cache);
		let animate = 'left';
		let forceDuration = this.initialized ? '' : 'noDuration';

		// if cache is null, no broadcast related infos available. > go global

		if (this.props.cache === null) {
			funcRefStart = this.getScopeFromCategory(this.props.cache);
			animate = '';
		} else {
			// Set Fixed Start Themeing Id for Broadcast Start

			if (
				this.getScopeFromCategory(this.props.cache) !== undefined &&
				this.props.assetStore.docInfos.template !== Statics.SCENE_TYPE_STYLED_GLOBAL
			) {
				funcRefStart = this.props.assetStore.getThemedId(Statics.CONTENT_TYPE_START);
				//
			}
			if (this.props.animateDir.length > 0 && this.props.assetStore.docInfos.template === Statics.SCENE_TYPE_BROADCAST) {
				animate = this.props.animateDir;
			}
		}
		if (
			this.props.lastCategory.length === 0 ||
			(this.props.category === Statics.CONTENT_TYPE_START && this.props.category === this.props.lastCategory)
		) {
			animate = '';
		}
		let globalAssets = this.props.assetStore.globalAssets;
		let hasMini = false;
		let blendIn = '';
		if (this.props.category && this.props.category.indexOf('mini') !== -1) hasMini = true;
		let leftBackgroundStyle = this.getBgLayer('leftLayer');

		if (this.props.appStore.animate === 'in')
			if (this.props.assetStore.docInfos.template === Statics.SCENE_TYPE_STYLED_GLOBAL) {
				blendIn =
					leftBackgroundStyle &&
					leftBackgroundStyle.backgroundPosition !== this.lastBgPosition &&
					this.props.appStore.animate === 'in'
						? 'fadeIn'
						: '';
				if (leftBackgroundStyle && this.props.appStore.animate === 'in') this.lastBgPosition = leftBackgroundStyle.backgroundPosition;
			}
		return (
			<AnimationBackgound>
				<Background className={'kkn-globalBackground'} style={{ backgroundImage: `url(${bgUrl})` }}></Background>

				{hasMini ? (
					<MiniImage
						className={'ready'}
						style={{ backgroundImage: "url('/static_kkn/global/mini/" + this.props.category + ".png')" }}
					></MiniImage>
				) : null}

				{hasMini === false ? (
					<AnimationWrapper className={classNames(blendIn, 'move-' + animate, forceDuration)} style={this.getBgLayer('bg')}>
						{this.props.config && this.props.config.upTween ? (
							<BackLayer className={classNames(layerBg, 'layerUp')} style={this.getBgLayer('up')}>
								{animate === 'up' && this.props.category !== Statics.CONTENT_TYPE_START
									? this.getContentPageClickon()
									: null}
							</BackLayer>
						) : null}
						{this.props.config && this.props.config.downTween ? (
							<BackLayer className={classNames(layerBg, 'layerDown')} style={this.getBgLayer('down')}>
								{animate === 'down' && this.props.category !== Statics.CONTENT_TYPE_START
									? this.getContentPageClickon()
									: null}
							</BackLayer>
						) : null}
						<BackLayer className={layerBg} style={leftBackgroundStyle}>
							{animate !== 'right' &&
							animate !== 'up' &&
							funcRefStart !== undefined &&
							funcRefStart.length > 0 &&
							this.props.clickonsActive !== false ? (
								<ClickOn
									useCanvasSize={this.props.cache === null ? true : false}
									stageRef="STAGE"
									pos="top"
									animate={this.props.cache === null ? true : false}
									scope={window[funcRefStart]}
									assets={this.props.cache !== null ? this.props.cache.clickonsData : globalAssets}
									func={funcRefStart}
								/>
							) : null}
						</BackLayer>

						{this.props.cache !== null && this.props.assetStore.docInfos.template === Statics.SCENE_TYPE_BROADCAST ? (
							<BackLayer
								ref="backLayerRight"
								className={classNames(layerBg, 'right', this.getAnimationRelatedState(true))}
								style={this.getBgLayer('rightLayer')}
							>
								{this.props.clickonsActive === true &&
								this.props.category !== Statics.CONTENT_TYPE_START &&
								animate !== 'up' &&
								animate !== 'down'
									? this.getContentPageClickon()
									: null}
							</BackLayer>
						) : null}
					</AnimationWrapper>
				) : null}
			</AnimationBackgound>
		);
	}
}

export default AnimatedBackground;
