import styled, { css } from 'styled-components';
import { gfx } from 'helpers/layoutVariables';

export const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 4;
	width: 100%;
	height: 100%;
	pointer-events: none;
	transition: transform 0.3s ease-out;
	transform: translate3d(0, 105%, 0);
	display: flex;
	align-items: center;
	justify-content: center;

	${(props) =>
		props.open === true &&
		css`
			transform: translate3d(0, 0, 0);
		`}
`;

export const Background = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	background-color: ${gfx.yellow};
	width: 100%;
	height: 100%;
	${(props) =>
		props.variant === 'isExternal' &&
		css`
			transform: translate3d(0, 73%, 0);
		`}
	> img {
		position: absolute;
		top: -1.4em;
		width: 100%;
		left: 0;
	}
`;
