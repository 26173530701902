import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { logger } from 'helpers/Utils';
import { AdultButtonPositioner, Wrapper } from './AdultSubLayerStyle';
import { ContentWrapper } from './AdultLayerStyle';
import { StandardButton } from 'components/basics/Primitives';
import Slider from 'components/basics/Slider/Slider';

@inject('assetStore')
@inject('appStore')
@observer
class AdultSubLayer extends Component {
	state = {
		open: false,
		time: 0,
		percentTime: 0,
		voice: 0,
		isReady: false,
		data: null
	};
	intVal = 0;
	matched = false;
	hitted = false;
	isUnmounted = false;
	content = null;
	slider = null;

	constructor() {
		super();
		this.content = React.createRef();
		//this.slider = React.createRef();
	}

	componentDidMount() {
		let tempData = window.$('<div style="opacity:0;height:0;left:-999em;position:absolute;"></div>');
		window.$('body').append(tempData);
		tempData.html(this.props.appStore.adultSubLayerData);
		if (this.props.appStore.adultSubLayerData) {
			let domHack = tempData;

			tempData.find('button').each((item) => {
				let btn = domHack.find('button').eq(item);
				let title = window.$("<span class='trackingSettings-title'>" + btn.html() + '</span>');
				let parentItem = btn.parent();
				parentItem.prepend(title);
				let gfx = this.props.assetStore.globalAssets.getResult('buttonIcons', true);
				btn.html(gfx);
				if (this.props.appStore.getTrackingSettingsState(parentItem.data().id) === true) {
					btn.addClass('open');
				}
			});

			this.setState({ data: tempData.html() }, () => {
				tempData.remove();
			});
		}
	}

	componentWillUnmount() {}

	componentWillReceiveProps(nextProps) {}

	componentDidUpdate = () => {
		let domHack = window.$(this.content.current);

		domHack.on('click', 'button', (evt) => {
			evt.preventDefault();
			let btn = window.$(evt.target);
			let id = btn.parent().data().id;
			btn.toggleClass('open');
			this.props.appStore.setTrackingSettingsState(id, btn.hasClass('open'));
			return false;
		});
	};

	onCancel = () => {
		this.props.appStore.adultSubLayerData = null;
	};
	getInner = () => {
		return (
			<>
				{this.state.data ? (
					<ContentWrapper width={'100%'} textColor={'#fff'}>
						<div ref={this.content} dangerouslySetInnerHTML={{ __html: this.state.data }}></div>
					</ContentWrapper>
				) : null}
			</>
		);
	};

	render() {
		return (
			<Wrapper>
				{this.getInner()}

				<AdultButtonPositioner>
					<StandardButton onClick={this.onCancel}>Schliessen</StandardButton>
				</AdultButtonPositioner>
			</Wrapper>
		);
	}
}

export default AdultSubLayer;
