import React from 'react';
import { getIconUrl, getImageUrl, getInfosFromDocumentCanvasId, getPath, getTeaserType, Statics } from 'helpers/Utils';
import { inject, observer } from 'mobx-react';
import Teaser from 'components/kits/Teaser/Teaser';
import { Category, Menu, Wrapper } from './TabbedIndexPageStyle';
import Slider from 'components/basics/Slider/Slider';
import ClickOn from 'components/fragments/ClickOn/ClickOn';
import MediaPlayer from 'components/kits/MediaPlayer/MediaPlayer';
import qs from 'query-string';
import BundleScroller from 'components/kits/BundleScroller/BundleScroller';
import Animator from 'components/basics/Animation/Animator';
import InteractiveContent from '../../kits/InteractiveContent/InteractiveContent';
import ImageGallery from '../../kits/ImageGallery/ImageGallery';
import BaseComponent from '../../../BaseComponent';
import { Plaice } from 'components/basics/Primitives';

@inject('assetStore')
@inject('appStore')
@inject('routingStore')
@observer
class TabbedIndexPage extends BaseComponent {
	state = {
		currentAudio: null,
		startSpeak: false,
		switchedCategory: false
	};
	currentPageInfos = null;
	wrapperNode = null;
	currentCategory = null;

	constructor() {
		super();
		this.wrapperNode = React.createRef();
	}

	getBundleData = async data => {
		this.props.assetStore.isLoading = true;
		this.props.routingStore.push(data.url);
	};

	componentDidMount() {
		this.props.appStore.contentWrapper = this.wrapperNode;
	}

	componentDidUpdate = (prevProps, prevState, snapshot) => {
		this.lastCategory = this.currentCategory;
	};

	getTeaser = (data, i, type, maxWidth) => {
		let bgUrl = type === 'standard' ? 'teaserBgNormal' : 'teaserBgIndex';
		let globalAssets = this.props.assetStore.globalAssets;
		let query = qs.parse(this.props.routingStore.location.search);

		let bgFinalUrl = globalAssets.getItem(bgUrl).src;
		if (type === 'round' && data.iconTeaser !== true) {
			bgFinalUrl = globalAssets.getItem('durchguckerBgGreenDots').src;
		}

		if (this.props.assets && this.props.assets.getItem(bgUrl) !== undefined) bgFinalUrl = this.props.assets.getItem(bgUrl).src;

		// Animation Störer
		let stoererId = undefined;
		let innerAnimation = '';
		let teaserStyling = data.standard.teaserStyling;
		let styleArray = (teaserStyling && teaserStyling.split(' ')) || '';
		if (styleArray.length > 0)
			styleArray.map((data, i) => {
				if (data) {
					if (data.indexOf('anim') !== -1) innerAnimation = data;
					if (data.indexOf('btn') !== -1) bgFinalUrl = globalAssets.getItem(data) && globalAssets.getItem(data).src;
					if (data.indexOf('stoerer') !== -1) stoererId = data;
				}
			});
		let size = { width: '40em', height: '24.5em' };
		if (type !== 'standard') size = { width: '25em', height: '25em' };
		if (maxWidth === '50%') size = { width: '34em', height: '21em' };
		if (type === 'round') size = { width: '34em', height: '34em' };

		//if (this.isHome()) size = { width: '32em', height: '32em' };
		if (data.iconTeaser) size = { width: '12em', height: '12em' };

		return (
			<div key={data.standard.sophoraId + '-id-' + i} style={{ width: 'auto', maxWidth: maxWidth }}>
				<Teaser
					positionReference={this.wrapperNode}
					stoererId={stoererId}
					globalAssets={globalAssets}
					indexAssets={this.props.indexAssets}
					skinnedAssets={this.props.assets}
					innerAnimation={innerAnimation}
					scaleClass={'instantScale'}
					data={data}
					isHome={type === 'round' && data.iconTeaser !== true}
					onClick={data => {
						if (this.props.appStore.isIndexContent(data) && data.iconTeaser !== true) {
							this.props.routingStore.push(data.url);
							return false;
						}
						var page = this.props.appStore.pageIndex;

						if (this.currentCategory === 'introSearch') {
							this.getBundleData(data);
							return false;
						}
						//if (data.iconTeaser !== true) this.setState({ currentDetail: data });
						if (data.iconTeaser === true) {
							page = page + 1;
							this.speakCategory(data);
						}
						var id = '';
						if (typeof data === 'number') {
							page = data;
						} else if (data.standard.sophoraId) {
							id = '&detailId=' + data.standard.sophoraId;
						}
						this.props.routingStore.push(getPath(this.props.routingStore, '?page=' + page + id));
					}}
					index={i}
					scope={this.props.assetStore.docInfos.scope}
					id={data.standard.sophoraId}
					imageUrl={data.standard.teaserImage && data.standard.teaserImage.urlScheme}
					bgUrl={bgFinalUrl}
					type={type}
					style={size}
				/>
			</div>
		);
	};

	getSlider = data => {
		let teaser = [];
		data.teasers.map((data, i) => {
			const teaserType = getTeaserType(data);
			teaser.push(this.getTeaser(data, i, teaserType, teaserType === 'round' ? '100%' : '50%'));
		});
		this.nextPageInfos = this.props.items[this.props.appStore.pageIndex + 1];
		let scaleParams = {
			margin: this.props.appStore.scale * 50,
			offsetBefore: this.props.appStore.scale * 400,
			offsetAfter: this.props.appStore.scale * 200
		};

		/*if (this.nextPageInfos && !this.isHome()) {
            teaser.push(
                this.getTeaser(
                    {
                        iconTeaser: true,
                        standard: {
                            contentCategory: Statics.CONTENT_TYPE_INDEX,
                            teaserImage: { url: getIconUrl(this.nextPageInfos.boxClassifier) }
                        },
                        helpMedia: this.nextPageInfos.helpMedia
                    },
                    data.teasers.length,
                    'round',
                    '100%'
                )
            );
        }
        */

		if (this.props.appStore.animate === 'out') return null;
		return (
			<Animator type={'moveX'} active={this.props.appStore.animate !== 'out'}>
				{this.props.appStore.redrawSlider !== true ? (
					<Slider {...scaleParams} grid={true} height={'100%'}>
						{teaser}
					</Slider>
				) : null}
			</Animator>
		);
	};

	getScroller = data => {
		if (this.state.switchedCategory === true) return null;

		let teaser = [];
		data.teasers.map((data, i) => {
			teaser.push(this.getTeaser(data, i, getTeaserType(data), '50%'));
		});
		return <BundleScroller>{teaser}</BundleScroller>;
	};

	onClicked = i => {
		this.setState({ index: i });
	};

	getCategory = (data, i) => {
		let teaser = [];
		let imgUrl = '';
		let img = (data.standard && data.standard.teaserImage && data.standard.teaserImage.urlScheme) || null;

		if (img === null) {
			if (data && data.standard)
				imgUrl = getImageUrl(getInfosFromDocumentCanvasId(data.standard.documentCanvasId).category, 512, 'ident');
			if (data.boxClassifier) imgUrl = getIconUrl(data.boxClassifier);
		} else {
			imgUrl = getImageUrl(img, 512, 'ident');
		}

		return (
			<Category
				key={data.standard ? data.standard.sophoraId : data.boxClassifier}
				onClick={() => {
					if (this.isHome()) {
						if (data.standard && data.standard.teaserAudio)
							window.kknApp.playSound(
								data.standard.teaserAudio.id,
								process.env.REACT_APP_DEV_PROXY + data.standard.teaserAudio.url
							);
						this.props.routingStore.push(data.url);
						return false;
					}
					this.speakCategory(data);
					this.props.routingStore.push(getPath(this.props.routingStore, '?page=' + i));
				}}
				active={this.isHome() ? false : this.props.appStore.pageIndex === i}
			>
				{imgUrl ? <img src={imgUrl} /> : null}
			</Category>
		);
	};

	speakCategory = data => {
		let teaserAudio = (data.helpMedia && data.helpMedia[0]) || (data.standard && data.standard.teaserAudio) || null;
		this.setState({ startSpeak: true, currentAudio: teaserAudio }, () => {
			//	this.setState({ startSpeak: false });
		});
	};

	closeVideo = () => {
		// this.setState({currentDetail: null});
	};

	isHome = () => {
		return this.props.variant === 'home';
	};

	render() {
		let globalAssets = this.props.assetStore.globalAssets;
		let skinnedAssets = this.props.assets;
		let detailInfos = null;

		let query = qs.parse(this.props.routingStore.location.search);
		if (this.props.appStore.animate !== 'out') this.currentPageInfos = this.props.items[this.props.appStore.pageIndex || 0];
		if (!this.currentPageInfos) return null;

		let catItems = this.props.items;
		let currentDetail = null;

		if (this.isHome()) {
			catItems = this.props.items[0].teasers;
			this.currentPageInfos = this.props.items[1];
		}
		if (query.detailId) {
			currentDetail = this.props.assetStore.getTeaserDataById(query.detailId, this.currentPageInfos);
		}

		let categories = [];
		this.currentCategory = this.currentPageInfos.boxClassifier;

		let currentContentSlider = this.getSlider(this.currentPageInfos);
		catItems.map((data, i) => {
			if (i < 4) categories.push(this.getCategory(data, i));
		});

		let help = null;

		// Help Canvas ?
		if (
			this.props.assetStore.docData.document.documentProperties &&
			this.props.assetStore.docData.document.documentProperties.supportDocument
		) {
			if (
				this.props.assetStore.docData.document.documentProperties.supportDocument.canvasId &&
				this.props.assetStore.docData.document.documentProperties.supportDocument.canvasId.length > 0
			)
				help = this.props.assetStore.docData.document.documentProperties.supportDocument.canvasId;
		}
		let isBundleScroller = this.props.isBundle || (currentDetail && currentDetail.contentType === 'bundle');
		let seasonId = (this.props.assetStore.docInfos && this.props.assetStore.docInfos.seasonId) || '';
		let hasMini = this.props.assetStore.docInfos.category && this.props.assetStore.docInfos.category.indexOf('mini') !== -1;

		return (
			<>
				{!currentDetail ? (
					<Wrapper ref={ref => (this.wrapperNode = ref)} isBundle={isBundleScroller}>
						{!isBundleScroller ? (
							<React.Fragment>
								{categories.length > 1 ? (
									<Menu
										position={this.props.appStore.pageIndex}
										activeBg={!this.isHome() ? globalAssets.getItem('bgTabbedHighlight').src : null}
									>
										{categories}
									</Menu>
								) : (
									<div style={{ height: '15em', width: '100%', position: 'relative' }}></div>
								)}
								<div
									style={{
										height: '46em',
										width: '100%',
										zIndex: 1,
										position: 'relative'
									}}
								>
									{currentContentSlider}
								</div>
							</React.Fragment>
						) : (
							<>{this.getScroller(this.currentPageInfos)}</>
						)}
					</Wrapper>
				) : null}
				{!hasMini && this.props.assetStore.docInfos.template !== Statics.SCENE_TYPE_BROADCAST ? (
					<Plaice src={`/static_kkn/global/images/scholle${seasonId}.png`} />
				) : null}
				{!currentDetail && window[help] && help !== null ? (
					<ClickOn
						audio={this.state.currentAudio}
						stageRef="HELP"
						startSpeak={this.state.startSpeak}
						width={window[help].properties.width * 2}
						height={window[help].properties.height * 2}
						pos={'help'}
						scope={window[help]}
						assets={globalAssets}
						func={help}
					/>
				) : null}
				{currentDetail ? (
					<MediaPlayer
						onClosed={this.closeVideo}
						clicked={this.clicked}
						scope={this.props.assetStore.docInfos.scope}
						styleRef={currentDetail.animation}
						assets={skinnedAssets || globalAssets}
						toggle={this.showDetail}
						data={currentDetail}
						routeParams={this.props.routeParams}
						reference={currentDetail}
					/>
				) : null}

				{currentDetail && currentDetail.standard.documentType === Statics.CONTENT_TYPE_INTERACTIVE ? (
					<InteractiveContent
						assets={skinnedAssets || globalAssets}
						routeParams={this.props.routeParams}
						clicked={this.clicked}
						styleRef={currentDetail.animation}
						onClosed={this.closeVideo}
						scope={this.props.assetStore.docInfos.scope}
						injectedDetailInfos={detailInfos}
						data={currentDetail}
						reference={currentDetail}
					/>
				) : null}
				{currentDetail && currentDetail.standard.documentType === Statics.CONTENT_TYPE_MMG ? (
					<ImageGallery
						assets={skinnedAssets || globalAssets}
						routeParams={this.props.routeParams}
						clicked={this.clicked}
						styleRef={currentDetail.animation}
						scope={this.props.assetStore.docInfos.scope}
						onClosed={this.closeVideo}
						data={currentDetail}
						injectedDetailInfos={detailInfos}
						reference={currentDetail}
					/>
				) : null}
			</>
		);
	}
}

export default TabbedIndexPage;
