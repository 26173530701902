import React from 'react';
import ClickOn from 'components/fragments/ClickOn/ClickOn';
import ImageGallery from 'components/kits/ImageGallery/ImageGallery';
import InteractiveContent from 'components/kits/InteractiveContent/InteractiveContent';
import MediaPlayer from 'components/kits/MediaPlayer/MediaPlayer';
import Teaser from 'components/kits/Teaser/Teaser';
import { getTeaserType, Statics } from 'helpers/Utils';
import { inject, observer } from 'mobx-react';
import { Wrapper } from './IndexPageStyle';
import BundleScroller from 'components/kits/BundleScroller/BundleScroller';
import Animator from '../../basics/Animation/Animator';
import BaseComponent from '../../../BaseComponent';
import { Plaice } from '../../basics/Primitives';

@inject('assetStore')
@inject('appStore')
@inject('routingStore')
@observer
class IndexPage extends BaseComponent {
	data = null;
	mergedItems = null;
	state = {
		initPlayerCall: false,
		topEnd: true,
		bottomEnd: true,
		indexVisible: false,
		animate: '',
		loaded: false,
		maxItemsAni: 0,
		showHelp: true
	};
	isUnmounted = false;
	assets = null;
	teasers = [];
	animatedTeasers = {};
	current = 0;
	currentItem = null;
	maxPages = 0;
	maxTeaserVisible = 0;
	lastItem = null;
	pageIndex = 0;
	lastIndex = 0;
	homeAnimationRunning = 0;
	showDetail = false;
	indexAssets = null;
	isHome = false;
	activeTeaserStyle = null;
	bgCanvasId = 'startIntro';
	animationName = '';
	clicked = false;
	homeLoopAdded = false;
	helpPos = 'help';

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		if (!this.isUnmounted) {
			// this.addWheelHandler();
		}

		this.initBundle(this.props);
		this.addHomeAnimateListener();
	}

	addHomeAnimateListener = () => {
		this.homeAnimationRunning = 0;
		if (this.props.assetStore.isHome() && this.homeLoopAdded === false) {
			window.$('body').on('kknApp.onCanvasCallback', this.homeCallbackFromAnimate);
			this.homeLoopAdded = true;
		}
	};
	homeCallbackFromAnimate = (evt) => {
		if (this.isUnmounted) return false;
		if (evt.id === 0) this.homeAnimationRunning++;
		if (this.homeAnimationRunning > 1) return false;

		this.maxTeaserVisible = this.getBoxClassifierBasedLayout(this.data[this.props.pageIndex].boxClassifier).length;
		if (this.data[this.props.pageIndex].teasers.length < this.maxTeaserVisible) {
			this.maxTeaserVisible = this.data[this.props.pageIndex].teasers.length;
		}

		if (this.props.items && this.state.maxItemsAni <= this.maxTeaserVisible) {
			let newId = evt.id;
			let showHelp = newId === this.maxTeaserVisible - 1;
			this.setState({
				maxItemsAni: newId + 1,
				showHelp: showHelp
			});
		}
	};

	componentWillReceiveProps(nextProps) {
		// Do not Update if we do out trans animation
		if (this.props.assetStore.hasDetail() !== true) {
			//this.props.appStore.animate = 'out';
		}

		this.showDetail = nextProps.showDetail;
		if (nextProps.assetStore.isLoading === true) {
			return false;
		}
		this.setState({
			loaded: nextProps.firstLoad === true ? false : true,
			maxItemsAni: 0
		});
		this.initBundle(nextProps);

		this.addHomeAnimateListener();
	}

	initBundle = (nextProps) => {
		this.assets = nextProps.assets;
		if (nextProps.items === undefined || nextProps.mergedItems.length === 0) {
			//return false;
		}

		this.setState({
			maxItems: this.props.assetStore.isHome() === true ? 0 : nextProps.mergedItems.length
		});

		this.data = nextProps.items || [];
		this.mergedItems = nextProps.mergedItems || [];
		this.indexAssets = nextProps.indexAssets;

		this.onItemsLoaded(nextProps.indexAssets, nextProps);
	};

	onItemsLoaded = (evt, nextProps) => {
		if (evt !== null) {
			this.indexAssets = evt;
		}

		this.setState({ loaded: true });
		this.showDetail = nextProps.showDetail;
		this.current = nextProps.currentDetailId;
		this.currentItem = this.getTeaserDataById(this.current);
		this.maxPages = this.data.length;
		if (this.currentItem === null) this.showDetail = false;

		if (this.data) this.updateHelpAudio(this.data[this.props.pageIndex] && this.data[this.props.pageIndex].helpMedia);

		if (this.showDetail !== true) {
			this.props.assetStore.isHome() === false ? this.animateButtons() : setTimeout(this.animateButtons, (this.maxPages - 1) * 1000);
		} else {
			if (!this.isUnmounted) {
				this.setState({
					topEnd: true,
					bottomEnd: true,
					indexVisible: false
				});
			}
		}

		this.lastItem = this.currentItem;
	};
	updateHelpAudio = (data) => {
		// update adult layer if needed
		this.props.appStore.clearHelpAudio();
		if (data) {
			this.props.appStore.addHelpAudio(data);
		}
	};
	getTeaserTypeForIndex = (item) => {
		let teaserType = 'standard';
		if (!item) return teaserType;
		if (this.props.assetStore.isHome() || getTeaserType(item) === 'round' || this.props.isBroadcastStart === true) {
			teaserType = 'round';
		}
		return teaserType;
	};

	getBoxClassifierBasedLayout = (boxClassifier) => {
		let layout = window.kknApp.INDEX_PAGE_TYPES[boxClassifier];
		if (!layout) {
			layout = window.kknApp.INDEX_PAGE_TYPES['main1-4'];
		}

		if (
			this.props.config &&
			this.props.assetStore.isBundle() === false &&
			this.props.assetStore.docInfos.template === Statics.SCENE_TYPE_STYLED_GLOBAL
		) {
			let params = this.props.config.scenes[String(this.props.pageIndex)];
			if (params && params.positions) layout = params.positions;
		}
		if (this.props.isBroadcastStart === true) {
			//  layout = window.kknApp.INDEX_PAGE_TYPES["broadcastStart"];
		}
		// use box classifier for homepage layout, default use startIntro Kombos
		if (this.props.assetStore.isHome() === true) {
			this.bgCanvasId = typeof boxClassifier === 'string' && boxClassifier.indexOf('start') === -1 ? 'startIntro' : boxClassifier;
			if (this.props.pageIndex > 0 && boxClassifier.indexOf('start') === -1)
				this.bgCanvasId = this.props.pageIndex % 2 !== 0 ? 'startMainOdd' : 'startMainEven';
			layout = window.kknApp.INDEX_PAGE_TYPES[this.bgCanvasId];
		}

		return layout;
	};

	getScaleAnimation = (style, i, type) => {
		this.animationName =
			this.props.appStore.animate === 'in' && i <= this.state.maxItemsAni - 1 && this.state.maxItemsAni !== -1 ? 'scaleIn' : '';
		let del = i === 0 ? 0 : i / 10;
		style.animationDelay = String(this.props.isBroadcastStart === true && this.props.appStore.animate === 'in' ? 1 + del : del) + 's';
		if (this.props.assetStore.isHome()) {
			style.animationDelay = '0s';
		}
		return this.animationName;
	};
	getStandardScaleAnimation = (style, i, type) => {
		let animationOut = 'scaleOut';
		let animationIn = 'scaleIn';
		let index = i;

		// get animation related to click behaviour

		this.animationName = this.props.appStore.animate === 'in' ? animationIn : animationOut;
		let del = index / 10 + 's';
		style.animationDelay = del;
		if (this.props.appStore.animate === 'out') {
			//style.opacity = 1;
			style.animationDelay = '0s';
		}

		return this.animationName;
	};

	getMoveAnimation = (style, i, type) => {
		let animationOut = 'moveOutToTop';
		let animationIn = 'moveInFromBottom';
		let index = i;

		// get animation related to click behaviour

		if (this.lastIndex > this.currentPage) {
			animationOut = 'moveOutToBottom';
			animationIn = 'moveInFromTop';
			index = this.props.appStore.pageSize - i;
		}
		this.animationName = this.props.appStore.animate === 'in' ? animationIn : animationOut;
		let del = index === 0 ? '0s' : index / 10 + 's';
		style.animationDelay = del;
		if (this.props.appStore.animate === 'out') {
			//style.opacity = 1;
			style.animationDelay = '0s';
		}
		if (this.props.assetStore.isHome()) style.animationDelay = '0s';

		return this.animationName;
	};

	getTeaser = (data, i, boxClassifier, type) => {
		let bgUrl = type === 'standard' ? 'teaserBgNormal' : 'teaserBgIndex';
		let globalAssets = this.props.assetStore.globalAssets;
		let layoutRef = this.getBoxClassifierBasedLayout(boxClassifier)[i];

		if (!layoutRef) layoutRef = { width: 0 };

		let style = {};
		for (let k in layoutRef) {
			if (typeof layoutRef[k] === 'number') {
				style[k] = String(layoutRef[k] + 'em');
			} else {
				style[k] = k === 'position' ? layoutRef[k] : layoutRef[k] + 'em';
			}
		}
		let teaserSize = data.standard.teaserSize;
		let teaserScale = 1;
		let teaserScaleClass = 'size4';
		if (teaserSize && Number(teaserSize) !== 0 && boxClassifier && this.props.assetStore.isHome() === -1) {
			switch (Number(teaserSize)) {
				case 1:
					teaserScale = 0.34;
					break;
				case 2:
					teaserScale = 0.5;
					break;
				case 3:
					teaserScale = 0.72;
					break;
				case 4:
					teaserScale = 1;
					break;
				case 5:
					teaserScale = 0.84;
					break;
			}
			teaserScaleClass = 'size' + teaserSize;
			if (this.props.assetStore.isBundle()) {
				teaserScale = 1;
				teaserScaleClass = 'instantScale';
			}
		}

		let w = layoutRef.width * teaserScale;
		let h = w * 0.66;
		style.width = String(w) + 'em';
		if (type === 'round') {
			h = w;
		}
		style.height = String(h) + 'em';

		style.marginLeft = -w / 2 + 'em';
		style.marginTop = -h / 2 + 'em';

		if (this.isNewBundleVersion()) {
			style = { width: '36em', height: '22em' };
		}

		let animation = '';
		let innerAnimation = '';
		if (
			this.current === data.standard.sophoraId ||
			this.getTeaserTypeForIndex(this.lastItem) === 'round' ||
			this.isNewBundleVersion()
		) {
			animation = 'solid';
		}

		let bgFinalUrl = globalAssets.getItem(bgUrl).src;

		if (this.assets && this.assets.getItem(bgUrl) !== undefined) bgFinalUrl = this.assets.getItem(bgUrl).src;

		if (this.props.assetStore.isHome() === true && type === 'round') {
			bgFinalUrl = globalAssets.getItem('durchguckerBgGreenDots').src;
		}
		let del = i === 0 ? 0 : i / 10;
		style.transitionDelay = del + 's';

		// Animation Störer
		let stoererId = undefined;
		let teaserStyling = data.standard.teaserStyling;
		let styleArray = (teaserStyling && teaserStyling.split(' ')) || '';
		if (styleArray.length > 0)
			styleArray.map((data, i) => {
				if (data) {
					if (data.indexOf('anim') !== -1) innerAnimation = data;
					if (data.indexOf('btn') !== -1) bgFinalUrl = globalAssets.getItem(data) && globalAssets.getItem(data).src;
					if (data.indexOf('stoerer') !== -1) stoererId = data;
				}
			});
		let dir = 'moveYTop';

		this.log('#animate', this.props.appStore.animate);
		return (
			<Animator
				delay={style.transitionDelay}
				key={'ani-teaser' + i}
				id={'ani-teaser' + i}
				type={dir}
				unmountOnExit={false}
				active={this.props.appStore.animate === 'out' ? false : true}
			>
				<Teaser
					positionReference={this.wrapperNode}
					scaleClass={teaserScaleClass}
					marginBottom={'2em'}
					scale={teaserScale}
					stoererId={stoererId}
					skinnedAssets={this.assets}
					globalAssets={globalAssets}
					indexAssets={this.indexAssets}
					innerAnimation={innerAnimation}
					data={data}
					onClick={(d) => this.onTeaserClicked(d, i, boxClassifier, type)}
					key={'teaser' + data.standard.sophoraId}
					index={i}
					scope={this.props.assetStore.docInfos.scope}
					id={data.standard.sophoraId}
					imageUrl={data.standard.teaserImage && data.standard.teaserImage.urlScheme}
					bgUrl={bgFinalUrl}
					playCanvasFrame={
						this.props.appStore.animate === 'in' && animation.toLowerCase().indexOf('out') === -1 ? 'intro' : 'startpos'
					}
					isHome={this.isHome}
					isBroadcastStart={this.props.isBroadcastStart}
					isBundle={this.props.assetStore.isBundle()}
					type={type}
					style={style}
				/>
			</Animator>
		);
	};

	onTeaserClicked = (data, i, boxClassifier, type) => {
		this.clicked = true;
		this.activeTeaserStyle = data.animation;
		this.props.appStore.onNavigateToPage(data, { index: i, boxClassifier: boxClassifier, type: type });
	};

	getTeaserDataById = (id) => {
		let teaser = null;
		let teaserList =
			this.data[this.props.pageIndex] && this.data[this.props.pageIndex].teasers ? this.data[this.props.pageIndex].teasers : [];
		if (this.props.assetStore.isBundle()) teaserList = this.mergedItems || [];

		teaserList.map((teaserData, i) => {
			if (id === teaserData.standard.sophoraId) teaser = teaserData;
		});
		return teaser;
	};

	openVideo(data) {
		this.initPlayerCall = true;

		this.props.appStore.onNavigateToPage(data);
	}

	componentWillUnmount() {
		this.isUnmounted = true;
		window.$('body').off('kknApp.onCanvasCallback');

		/*let mousewheelItem = $("body")[0];
         if (mousewheelItem.removeEventListener) {

         let debouncer = debounce((evt) => {
         this.onMouseWheel(evt);
         }, 250, true);

         // IE9, Chrome, Safari, Opera
         mousewheelItem.removeEventListener("mousewheel", debouncer, false);
         // Firefox
         mousewheelItem.removeEventListener("DOMMouseScroll", debouncer, false);
         }
         */
	}

	closeVideo = () => {
		if (this.currentItem === null) return false;
		this.props.onDetailClosed();
	};

	prevPage = () => {
		this.props.appStore.prevPage();
	};

	gotoPage = (index) => {
		this.props.appStore.onNavigateToPage(index);
	};

	animateButtons = () => {
		if (this.isUnmounted) return false;

		let length = this.maxPages;

		if (this.props.assetStore.isBundle() === true) {
			length = this.mergedItems.length;
			this.maxPages = Math.ceil(length / this.props.appStore.pageSize);
		}

		let pos = {
			topEnd: false,
			bottomEnd: false,
			currentPage: this.props.pageIndex,
			indexVisible: true
		};
		if (this.props.pageIndex === 0) {
			pos.topEnd = true;
		}
		if (this.props.pageIndex === this.maxPages) {
			pos.bottomEnd = true;
		}
		if (!this.isHome) {
			pos.showHelp = true;
		} else {
			if (this.state.maxItemsAni === this.maxTeaserVisible - 1) {
				pos.showHelp = true;
			}
		}

		if (!this.isUnmounted) {
			//	this.setState(pos);
		}
	};

	nextPage = () => {
		this.props.appStore.nextPage();
	};
	getScroller = (teaser) => {
		return <BundleScroller>{teaser}</BundleScroller>;
	};
	getTeaserList = (teaser) => {
		if (this.showDetail === true) return null;

		return this.isNewBundleVersion() ? this.getScroller(teaser) : teaser;
	};

	isNewBundleVersion = () => {
		return this.props.assetStore.isBundle();
	};

	render() {
		let detailInfos = null;
		this.counterPos = 0;
		let boxClassifier = '';
		let hasMini = false;
		let help = null;
		let globalAssets = this.props.assetStore.globalAssets;
		let skinnedAssets = null;
		let teaserNodes = [];

		// Do we have global or skinned Assets ?

		if (this.assets !== null && this.assets !== undefined) skinnedAssets = this.assets;

		if (this.state.loaded === false) {
			return <div />;
		}

		// no teaser
		if (
			this.data.length === 0 ||
			this.mergedItems.length === 0 ||
			(this.props.data.document &&
				(this.props.data.document.documentType === Statics.CONTENT_TYPE_INTERACTIVE ||
					this.props.data.document.documentType === Statics.CONTENT_TYPE_VIDEO ||
					this.props.data.document.documentType === Statics.CONTENT_TYPE_RELATED_VIDEO ||
					this.props.data.document.documentType === Statics.CONTENT_TYPE_EXTERNAL_VIDEO))
		) {
			detailInfos = this.props.data;
			this.showDetail = true;
		} else {
			// has teaser

			// Detecting Page Start and End
			let pageStart = this.props.pageIndex * this.props.appStore.pageSize;
			let pageEnd = pageStart + this.props.appStore.pageSize;

			// bundle groups are merged teaser boxes
			if (this.props.appStore.animate === 'in') {
				if (this.props.assetStore.isBundle() === true) {
					if (!this.mergedItems[this.props.pageIndex]) {
						this.props.pageIndex = 0;
					}

					boxClassifier = 'bundle';
					if (this.mergedItems.length === 1) boxClassifier = 'bundle1';
					if (this.mergedItems.length === 2) boxClassifier = 'bundle2';
					if (this.mergedItems.length === 3) boxClassifier = 'bundle3';
					if (this.teasers.length === 0) {
						this.teasers = [];
						this.mergedItems.map((data, i) => {
							if (this.isNewBundleVersion() === true || (i >= pageStart && i < pageEnd)) {
								this.teasers.push({
									data: data,
									counterPos: this.counterPos,
									boxClassifier: boxClassifier,
									type: this.getTeaserTypeForIndex(data)
								});
								this.counterPos++;
							}
						});
					}
				} else {
					// default each box is one page
					if (!this.data[this.props.pageIndex]) {
						this.props.pageIndex = 0;
					}
					this.teasers = [];

					boxClassifier = this.data[this.props.pageIndex].boxClassifier;
					let layoutRef = this.getBoxClassifierBasedLayout(boxClassifier);

					if (!this.isHome) this.maxTeaserVisible = layoutRef.length;

					this.data[this.props.pageIndex].teasers.map((data, i) => {
						if (i < this.maxTeaserVisible) {
							this.teasers.push({
								data: data,
								counterPos: this.counterPos,
								boxClassifier: boxClassifier,
								type: this.getTeaserTypeForIndex(data)
							});
							this.counterPos++;
						}
					});
				}
				this.helpPos = 'help';

				if (boxClassifier === 'introVideos' || boxClassifier === 'introLieder') {
					this.helpPos = 'helpRight';
				}
				if (this.isHome) this.helpPos = this.bgCanvasId.indexOf('Odd') === -1 ? 'helpHome' : 'helpHomeRight';
			}
			this.teasers.map((vo, i) => {
				teaserNodes.push(this.getTeaser(vo.data, vo.counterPos, vo.boxClassifier, vo.type));
			});

			// Is it a mini broadcast page?
			if (this.props.category && this.props.category.indexOf('mini') !== -1) hasMini = true;

			// Help Canvas ?
			if (this.props.data.document.documentProperties && this.props.data.document.documentProperties.supportDocument) {
				if (
					this.props.data.document.documentProperties.supportDocument.canvasId &&
					this.props.data.document.documentProperties.supportDocument.canvasId.length > 0
				)
					help = this.props.data.document.documentProperties.supportDocument.canvasId;
			}
			// current teaser related detail ?
		}

		if (this.props.appStore.animate === 'in' || this.props.appStore.animate === 'out') {
			window.$('body').trigger({ type: 'kknApp.animation', animationName: this.animationName });
		}

		let bgCanvasSeason = '';

		if (this.props.assetStore.docInfos && this.props.assetStore.docInfos.seasonId) {
			let temp = this.bgCanvasId + this.props.assetStore.docInfos.seasonId;
			if (window[temp]) {
				bgCanvasSeason = this.props.assetStore.docInfos.seasonId;
			}
		}
		let limit = this.props.pageIndex < this.maxPages - 1 ? 0 : 1;
		let playIntro =
			this.data[this.props.pageIndex] && this.state.maxItemsAni <= this.data[this.props.pageIndex].teasers.length - limit
				? this.state.maxItemsAni
				: null;

		if (this.props.appStore.animate === 'out') {
			playIntro = null;
		}
		let seasonId = (this.props.assetStore.docInfos && this.props.assetStore.docInfos.seasonId) || '';

		return (
			<Wrapper ref={this.wrapperNode} className={this.state.loaded === true ? 'loaded' : ''}>
				{this.props.isHome === true ? (
					<ClickOn
						stageRef="intro"
						pos={'top'}
						animate={this.props.appStore.animate === 'in' ? false : true}
						animationName={this.animationName !== 'scaleIn' ? this.animationName : null}
						scope={window[this.bgCanvasId + bgCanvasSeason]}
						play={playIntro}
						assets={globalAssets}
						func={this.bgCanvasId + bgCanvasSeason}
					/>
				) : null}

				{!this.showDetail && window[help] && this.state.showHelp && help !== null && this.indexAssets ? (
					<>
						{!hasMini && this.props.assetStore.docInfos.template !== Statics.SCENE_TYPE_BROADCAST ? (
							<Plaice src={`/static_kkn/global/images/scholle${seasonId}.png`} />
						) : null}
						<Animator active={this.props.appStore.animate === 'in'}>
							<ClickOn
								stageRef="HELP"
								width={window[help].properties.width * 2}
								height={window[help].properties.height * 2}
								pos={this.helpPos}
								scope={window[help]}
								animate={!this.props.assetStore.isBundle() ? true : false}
								animationName={this.animationName}
								assets={globalAssets}
								indexAssets={this.indexAssets}
								func={help}
							/>
						</Animator>
					</>
				) : null}
				{this.showDetail === true ? (
					<MediaPlayer
						onClosed={this.closeVideo}
						clicked={this.clicked}
						scope={this.props.assetStore.docInfos.scope}
						styleRef={this.activeTeaserStyle}
						assets={skinnedAssets || globalAssets}
						toggle={this.showDetail}
						data={detailInfos || this.currentItem}
						routeParams={this.props.routeParams}
						reference={this.currentItem}
					/>
				) : null}

				{(this.teasers.length === 0 && detailInfos && detailInfos.document.documentType === Statics.CONTENT_TYPE_INTERACTIVE) ||
				(this.currentItem && this.showDetail === true) ? (
					<InteractiveContent
						assets={skinnedAssets || globalAssets}
						routeParams={this.props.routeParams}
						clicked={this.clicked}
						styleRef={this.activeTeaserStyle}
						onClosed={this.closeVideo}
						scope={this.props.assetStore.docInfos.scope}
						injectedDetailInfos={detailInfos}
						data={this.currentItem || detailInfos}
						reference={this.currentItem}
					/>
				) : null}
				{(this.teasers.length === 0 && detailInfos && detailInfos.document.documentType === Statics.CONTENT_TYPE_MMG) ||
				(this.currentItem && this.currentItem.standard.documentType === Statics.CONTENT_TYPE_MMG && this.showDetail === true) ? (
					<ImageGallery
						assets={skinnedAssets || globalAssets}
						routeParams={this.props.routeParams}
						clicked={this.clicked}
						styleRef={this.activeTeaserStyle}
						scope={this.props.assetStore.docInfos.scope}
						onClosed={this.closeVideo}
						data={this.currentItem || detailInfos}
						injectedDetailInfos={detailInfos}
						reference={this.currentItem}
					/>
				) : null}
				{this.showDetail !== true ? this.getTeaserList(teaserNodes) : null}
			</Wrapper>
		);
	}
}

export default IndexPage;
