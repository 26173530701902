import styled, { css } from 'styled-components';
import { blueRabbitHand, gfx, MouseHandButton } from 'helpers/layoutVariables';

export const Wrapper = styled.div`
	position: absolute;
	top: 0;
	right: -100%;
	width: 100%;
	height: 100%;
	padding: 0;
	z-index: 6;
`;
export const ResultTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;
export const FilterWrapper = styled.ul`
	display: flex;
	justify-content: center;
	margin: 1em 0em 2em 2.24em;
	width: 87%;
`;
export const FilterItem = styled.li`
 	display: flex;
    height: 6vw;
    background-color: rgba(255,255,255,0.2);
    margin: 1vw;
    padding: 0.5vw;
    border-radius: 1vw;
    font-size: 2.5vw;
    text-align: center;
    align-items: center;
    min-width: 11vw;
    text-align: center;
    align-items: center;
    justify-content: center;
	color:${gfx.brown};
	${blueRabbitHand};

	html.no-touch & {
		&:hover {
			background-color: #fff;
		}
	}
	${(params) =>
		params.active &&
		css`
			background-color: #fff;
		`}
}
`;
export const SubmitWrapper = styled.button`
	position: absolute;
	top: 50%;
	right: 0;
	transform: translate3d(-2vw, -2.2vw, 0);
	background: none;
	border: 0;
	width: 4vw;
	padding: 0;
	> img {
		width: 100%;
	}
`;

export const Form = styled.form`
	display: flex;
	background-color: #fff;
	border-radius: 4vw;
	position: relative;
	width: 100%;
	max-width: 87%;
	margin: 0 8em 1em 2em;
`;

export const Result = styled.a`
	display: flex;
	padding: 2vw;
	margin-bottom: 2vw;
	min-width: 100%;
	position: relative;
	border-radius: 1vw;
	background-color: rgba(255, 255, 255, 0.25);
	align-items: center;
	${blueRabbitHand};
	font-size: 3vw;
`;
export const ResultText = styled.span`
	font-weight: bold;
	font-size: 1.125em;
	color: ${gfx.brown};
`;

export const ResultCategory = styled.span`
	font-weight: bold;
	font-size: 0.75em;
	margin-right: auto;
	margin-bottom: 1vw;
	color: ${gfx.brown};
	border-radius: 1vw;
	padding: 1vw;
	background-color: rgba(255, 255, 255, 0.25);
`;

export const ResultImage = styled.img`
	display: flex;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
`;
export const ResultImageWrapper = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	max-width: 15vw;
	margin-right: 2vw;
	overflow: hidden;
	margin-bottom: auto;

	&:before {
		padding-top: 56.25%;
		content: '';
	}
`;

export const InnerWrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	padding: 3%;
	overflow: hidden;

	right: 0;
	top: 0;
	background-color: ${gfx.blue};
	transition: transform 0.3s ease-out;

	${(props) =>
		props.open === true &&
		css`
			transform: translate(-100%, 0);
		`}
`;

export const Menu = styled.ul`
	font-size: 3em;
	line-height: 1.5;
	overflow: hidden;
	position: absolute;
	width: 30%;
	top: 0;
	left: 0;
	padding: 2% 0 2% 2%;
	height: 100%;
	z-index: 2;
	transition: transform 0.3s ease-out;
	list-style-type: none;
	.isAdultOpen & {
		li:nth-child(2) {
			display: flex;
		}
	}

	li:nth-child(4) {
		margin-top: 1em;
	}
	.view-small & {
		background-color: rgba(255, 255, 255, 0.25);
		width: 34%;
		${(props) =>
			props.isSmallOpen === false &&
			css`
				transform: translate3d(-74%, 0, 0);
			`}

		${(props) =>
			props.isSmallOpen &&
			css`
				transform: translate3d(0, 0, 0);
			`}
	}
`;

export const Version = styled.p`
	position: absolute;
	bottom: 1.5em;
	left: 2.5em;
	z-index: 10;
`;

export const SmallToggle = styled(MouseHandButton)`
	right: 0;
	transform: translate3d(-50%, -40%, 0) rotate(90deg);
	position: absolute;
	font-size: 2em;
	bottom: 0;
	width: 3.8em;
	height: 2em;
	display: none;
	border: 0;
	background: none;
	color: ${gfx.brown};
	transform-origin: 100% 100%;

	.view-small & {
		display: block;
	}
`;

export const AdultMenuToggler = styled.div`
	font-size: 3em;
	display: flex;
	position: absolute;
	top: 2%;
	left: 2%;
	z-index: 3;
`;

export const ContentWrapper = styled.div`
	width: ${(props) => (props.width ? props.width : '70%')};
	position: relative;
	margin-left: auto;
	-webkit-overflow-scrolling: touch;
	margin-bottom: 2em;
	flex-direction: column;
	display: flex;
	overflow-y: scroll;
	height: 56em;
	padding-right: 1em;

	/* width */
	::-webkit-scrollbar {
		width: .25rem;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: rgba(0,0,0,.1);

	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: rgba(0,0,0,.6);

	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: rgba(0,0,0,.8);
	}

	.kkn-buffer {
		top: 30em;
		left: -8em;
	}

	${(props) =>
		props.isSmallView &&
		css`
			transition: transform 0.3s ease-out;
			width: 100%;
			padding-left: 10%;
		`}
	${(props) =>
		props.isSmallOpen &&
		css`
			transform: translate3d(27%, 0, 0);
		`}



	.swiper-slide {
		padding: 0 8em 0 2em;
	}

	p a {
		color: #fff;
		text-decoration: underline;
	}

	h3,
	h4 {
		font-size: ${gfx.fontSizeHeadline};
		color: #fff;
		line-height: 1.5;
		margin: 0;
		margin-top: 0.75em;
		text-align: center;
		width: 100%;
	}

	h3 {
		font-size: ${gfx.fontSizeHeadline};
	}

	p {
		font-family: ${gfx.fontStandard};

		a {
			font-family: ${gfx.fontStandard};
		}

		span {
			font-size: 1em;
			color: ${gfx.brown};
		}

		color: ${(props) => (props.textColor ? props.textColor : gfx.brown)};
		font-size: ${gfx.fontSizeCopyText};
		line-height: 1.5;
		margin: 0 0 1.5em 0;
	}
`;

export const ContentHead = styled.div`
  margin-bottom: 2em;
}`;

export const BroadcastHint = styled.div`
	background-color: rgba(255, 255, 255, 0.9);
	font-size: 2em;
	color: ${gfx.brown};
	text-align: center;
	display: block;
	position: absolute;
	left: 0;
	width: 100%;
	bottom: 0;
	padding: 1% 1% 2% 1%;
	z-index: 3;
`;

export const SmallToggleWrapper = styled.li`
	position: static;
`;

export const ListItem = styled.li`
	position: relative;
	min-width: 10em;
	margin-bottom: 0.25em;
`;
