import React, { Component } from 'react';
import { CircleWrapper } from './ProgressBarStyle';
import { inject, observer } from 'mobx-react';
import { gfx } from 'helpers/layoutVariables';

@inject('assetStore')
@inject('appStore')
@observer
class ProgressCircle extends Component {
	refBg = null;
	refProgress = null;
	progressBar = null;
	progressBg = null;

	constructor() {
		super();
		this.refBg = React.createRef();
		this.refProgress = React.createRef();
	}

	componentDidMount = () => {
		this.progressBar = new window.ProgressBar.Circle(this.refProgress, {
			strokeWidth: 10,
			color: gfx.blue
		});
		this.progressBar.set(0);
		this.progressBg = new window.ProgressBar.Circle(this.refBg, {
			strokeWidth: 10,
			color: '#fff'
		});
	};
	render() {
		if (!this.props.assetStore.globalAssets) return null;

		let prog = this.props.progress || 0;

		if (this.progressBar) this.progressBar.set(prog);
		if (this.progressBg) this.progressBg.set(1);

		return (
			<CircleWrapper size={this.props.size} pos={this.props.pos}>
				<span ref={(ref) => (this.refBg = ref)} />
				<span ref={(ref) => (this.refProgress = ref)} />
			</CircleWrapper>
		);
	}
}

export default ProgressCircle;
