import styled, { css } from 'styled-components';
import { gfx, blueRabbitHand } from 'helpers/layoutVariables';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;

  .paragraph {
    margin-bottom: 1.5em;
  }

  .paragraph.withImage {
    display: grid;
    gap: 1.5em;
    grid-template-columns: 1fr 1fr;
  }

  .mediaInfo {
    display: none;
  }

  img {
    width: 100%;
  }

  ol,
  ul {
    padding-left: 2em;

    li {
      font-size: 1.875em;
      color: ${gfx.brown};
    }
  }
`;
export const TeaserDetails = styled.div`
	display: flex;
	position: relative;	
`;

export const Inner = styled.div`
	position: relative;
	background-color: rgba(255, 255, 255, 0.1);
	padding: 2.5% 5% 5% 5%;
	border-radius: 2em;
	margin-bottom: 3%;
	h4 {
		font-size: 3.5em;
		text-align: center;
		float: none;
		margin-top: 0;
	}
	img {
		width: 50%;
		height: 100%;
		flex: 0 0 auto;
	}
`;

export const TeaserText = styled.div`
	margin-top: 3%;
`;

export const LinkText = styled.span`
	color: ${gfx.brown};
	font-size: 3em;
	width: 100%;
	text-align: center;
	text-decoration: none;
	margin-top: 1%;
`;

export const Button = styled.span`
	width: 100%;
	margin-left: 2em;
	min-height: 6em;
	border-radius: 1em;
	background-color: rgba(255, 255, 255, 0.5);
	color: ${gfx.brown};
	display: flex;
	max-height: 6em;
	align-self: center;
	align-items: center;
	justify-self: center;
	text-decoration: none;
	position: relative;

	${(props) =>
		props.btnType === 'linkButton' &&
		css`
			margin-left: 0;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		`}

	.claimerText {
		position: absolute;
		width: 100%;
		left: 0;
		bottom: -54%;
		text-align: center;
		font-size: 1.5em;
		font-family: $fontStandard;
		.view-small & {
			font-size: 2.5em;
			bottom: -86%;
		}
	}
	html.no-touch & {
		&:hover {
			background-color: rgba(255, 255, 255, 1);
		}
	}
`;

export const ClaimerText = styled.span`
	position: absolute;
	width: 100%;
	left: 0;
	bottom: -54%;
	text-align: center;
	font-size: 1.5em;
	font-family: ${gfx.fontStandard};
	.view-small & {
		font-size: 2.5em;
		bottom: -86%;
	}
`;

export const DownloadToggle = styled.button`
	border: 0;
	background: none;
	padding: 0;
	color: $brown;
	${blueRabbitHand};
	font-size: 2em;
	position: relative;
	.view-small & {
		font-size: 3em;
	}
`;

export const Help = styled.a`
	font-size: 2em;
	float: right;
	color: #fff;
	.view-small & {
		font-size: 3em;
	}
`;
export const DownloadInner = styled.p`
	display: none;
	${(props) =>
		props.open &&
		css`
			display: block;
			white-space: pre-wrap;
		`}
`;

export const DownloadLegal = styled.div`
	margin-top: 4%;
`;
