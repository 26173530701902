import React, { Component } from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

export const Wrapper = styled.div`
	> * {
		transition: all 0.3s ease-out;
		transform: translate3d(0, -260%, 0);
		opacity: 0;
	}
	&.animation-enter {
		> * {
			transform: translate3d(0, -260%, 0);
			opacity: 0;
		}
	}
	&.animation-enter-active,
	&.animation-enter-done {
		> * {
			transform: translate3d(0, -50%, 0);
			opacity: 1;
		}
	}
	&.animation-exit,
	&.animation-exit-active {
		> * {
			transform: translate3d(0, -260%, 0);
			opacity: 0;
		}
	}
`;

class Toast extends Component {
	render() {
		return (
			<CSSTransition appear={true} unmountOnExit in={this.props.active ? true : false} timeout={500} classNames="animation">
				<Wrapper>{this.props.children}</Wrapper>
			</CSSTransition>
		);
	}
}

export default Toast;
