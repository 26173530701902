import styled, { css } from 'styled-components';
import { blueRabbitHand, gfx } from 'helpers/layoutVariables';

export const Wrapper = styled.div`
	position: relative;
	padding: ${(props) => (props.padding ? props.padding : null)};
	height: 36px;
	z-index: 1001;
	background-color: ${gfx.blue};
	border: 0.25em solid #fff;
	border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '0.25em')};
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
	margin: ${(props) => (props.margin ? props.margin : null)};

	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	${blueRabbitHand};
	height: ${(props) => (props.height ? props.height : '24px')};
	width: ${(props) => (props.width ? props.width : 'auto')};

	${(props) =>
		props.isSmall &&
		css`
			background-color: transparent;
			border: 0;
			box-shadow: none;
			padding: 0.25em;

			> span {
				background-color: ${gfx.blue};
				border: 0.333em solid #fff;
				box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
				border-radius: 50%;
				padding: 0.5em;
			}
			transform: rotate(-90deg);
		`};
`;

export const NotificationText = styled.div`
	color: ${gfx.brown};
	font-size: ${(props) => (props.fontSize ? props.fontSize : '20px')};
	top: 0.05em;
	position: relative;
`;
