import React, { Component } from 'react';
import { Wrapper, Item } from './PaginatorStyle';
import { inject, observer } from 'mobx-react';

@inject('assetStore')
@inject('appStore')
@observer
class Paginator extends Component {
	getPageList = () => {
		let pages = [];

		for (var i = 0; i < this.props.pages; i++) {
			let current = i;
			pages.push(
				<Item key={'pagelist-' + i} active={this.props.index === i}>
					<button
						onClick={() => {
							this.props.onClicked(current);
						}}
					/>
				</Item>
			);
		}
		return <ul>{pages}</ul>;
	};

	render() {
		if (!this.props.assetStore.globalAssets) return null;

		return (
			<Wrapper>
				<img src={'/static_kkn/global/images/rahmen-re.png'} />
			</Wrapper>
		);
	}
}

export default Paginator;
