import styled from 'styled-components';
import { gfx, blueRabbitHand } from '../../../helpers/layoutVariables';
import Icon from '../../basics/Icon/Icon';

interface IFilterButtonProps {
	isDisabled?: boolean;
	active?: boolean;
	icon?: string;
}

export const SearchFilterWrapper = styled.div`
	display: flex;
	justify-content: center;
	gap: 0.75em;
    margin: 0 1.5em 1em 1.5em;

	&:empty {
		margin: 0;
	}
`;

export const FilterIcon = styled(Icon)`
	width: 2.5em;
    height: 2.2em;
	margin-left: -0.5em;

	.view-small & {
		width: 2.0em;
    	height: 1.8em;
	}
`;

// eslint-disable-next-line no-mixed-operators
export const FilterButton = styled("div")<IFilterButtonProps>`
	display: inline-flex;
	align-items: center;
    justify-content: center;
	min-width: 5em;
	height: 2.667em;
	border-radius: 0.75em;
	min-height: 40px;
	background-color: ${props => props.active ? "#fff" : "rgba(255, 255, 255, 0.25)"};
	padding: 0.75em;
	color: ${gfx.brown};
	font-size: 2em;
	white-space: nowrap;
    opacity: ${props => props.isDisabled ? "0.5" : "1"};
    pointer-events: ${props => props.isDisabled ? "none" : "auto"};
    ${props => props.isDisabled ? "" :  blueRabbitHand};

    .view-small & {
        font-size: 2em;
		min-height: 20px;

		height: 2.2em;
        border-radius: 0.5em;
    }
`;
