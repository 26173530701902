import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	background-image: url(/static_kkn/global/images/img-rotate.svg);
	background-size: 70% 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-color: #ffc300;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 100%;

	h2 {
		color: #5f4100;
		font-size: 1.75rem;
	}
`;

const ImageRotate = () => {
	return (
		<Wrapper>
			<h2>Bitte Gerät drehen!</h2>
		</Wrapper>
	);
};

export default ImageRotate;
