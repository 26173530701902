import React, { Component } from 'react';
import ClickOn from 'components/fragments/ClickOn/ClickOn';
import { getImageUrl } from 'helpers/Utils';
import { inject, observer } from 'mobx-react';
import Icon from 'components/basics/Icon/Icon';
import {
	ImageWrapper,
	MaskImage,
	Outer,
	StoererImage,
	TeaserBg,
	TeaserFrame,
	TeaserIcon,
	TeaserImage,
	WhiteBorder,
	Wrapper
} from './TeaserStyle';

@inject('assetStore')
@inject('appStore')
@inject('trackingStore')
@observer
class Teaser extends Component {
	styles = {};
	state = {
		animate: '',
		animationFrame: null
	};
	hasCanvas = false;
	reference = null;
	isUnmounted = false;

	constructor() {
		super();
		this.reference = React.createRef();
	}

	componentDidMount(callback) {
		this.setState({ animationFrame: this.props.playCanvasFrame });
	}

	componentWillLeave(callback) {}

	componentWillReceiveProps = (nextProps) => {
		this.setState({ animationFrame: nextProps.playCanvasFrame });
	};

	componentWillUnmount() {
		this.isUnmounted = true;
	}

	getCategory = () => {
		let str = this.props.data.standard.documentType;
		if (this.props.data.standard.contentCategory && this.props.data.standard.contentCategory.length > 0) {
			str = this.props.data.standard.contentCategory;
		}
		return str;
	};
	getIconName = (id) => {
		let icon = '';
		switch (id) {
			case 'video':
			case 'externalVideo':
			case 'relatedVideo':
				icon = 'play';
				break;
			case 'games':
			case 'interactiveContent':
				icon = 'game';
				break;
			case 'competition':
			case 'contest':
				icon = 'win';
				break;

			case 'recipe':
				icon = 'food';
				break;
			case 'song':
				icon = 'song';
				break;
			case 'aktion':
				icon = 'letter';
				break;
			case 'person':
			case 'charactere':
				icon = 'characters';
				break;
			case 'paint':
				icon = 'paint';
				break;
			case 'tinker':
				icon = 'tinker';
				break;
			case 'doItYourself':
				icon = 'doItYourself';
				break;
			case 'index':
				icon = 'arrowRight';
				break;
		}

		return icon;
	};

	getSound = () => {
		if (this.isUnmounted) return false;
		window.kknApp.playSound(
			this.props.data.standard.teaserAudio.id,
			process.env.REACT_APP_DEV_PROXY + this.props.data.standard.teaserAudio.url
		);
	};

	handleComplete = (data) => {
		this.gotoLink();
	};

	teaserClicked = (data) => {
		//  this.teaserClicked = {data: data, index: i, boxClassifier: boxClassifier, type: type};
		let isCanvasTeaser = data.standard && data.standard.teaserCanvasId && data.standard.teaserCanvasId.length > 0 ? true : false;
		this.props.trackingStore.trackAtiClick({
			name: isCanvasTeaser ? 'Canvas Teaser' : 'Teaser',
			chapter1: data.standard.sophoraId,
			chapter2: data.url,
			action: 'action'
		});

		if (this.reference && typeof this.reference.getBoundingClientRect === 'function') {
			let dim = this.reference.getBoundingClientRect();
			let temp = {
				width: dim.width,
				height: dim.height,
				y: dim.y - this.props.appStore.appNode.getBoundingClientRect().y,
				x: dim.x - this.props.appStore.appNode.getBoundingClientRect().x
			};
			data.animation = temp;
		}

		if (this.isTeaserCanvasAvailable() === true || this.hasCanvas === true) {
			this.setState({ animationFrame: 'outro' });
			setTimeout(() => {
				this.afterTeaserClicked(data);
			}, 500);
			return false;
		}

		this.afterTeaserClicked(data);
	};
	afterTeaserClicked = (data) => {
		// send props if we have a new redirect link
		let p = this.props.type === 'round' ? this.props : {};
		this.props.appStore.addVisitedTeaser({ id: this.props.data.standard.sophoraId, props: p });
		if (data.standard.teaserAudio !== undefined) {
			this.getSound();
			this.gotoLink(data);
		} else {
			this.gotoLink(data);
		}
	};

	gotoLink = (data) => {
		this.props.onClick(data);
	};

	getStoerer = (variant) => {
		if (this.props.stoererId.indexOf('/') === -1) {
			return (
				<ClickOn
					stageRef={'canvas_' + this.props.stoererId}
					width={136}
					height={76}
					pos={variant === 'standard' ? 'stoerer' : 'stoererRound'}
					scope={window[this.props.stoererId]}
					assets={this.props.globalAssets}
					variant={this.variant}
					playAniFrame={this.state.animationFrame}
					func={this.props.stoererId}
				/>
			);
		} else {
			return <StoererImage src={this.props.stoererId} />;
		}
	};

	getRoundTeaser = () => {
		let variant = this.props.isHome ? 'home' : 'standard';
		if (this.props.data.iconTeaser) variant = 'iconTeaser';

		let classArray = [
			'round',
			this.props.scope + '-teaser',
			variant,
			this.props.scaleClass,
			this.props.addClasses,
			this.props.data.standard.sophoraId,
			this.state.animate,
			this.getCategory()
		];
		if (this.props.isBundle) classArray.push('bundle');

		let aufleger = null;
		let durchgucker = null;

		if (this.props.skinnedAssets && this.props.skinnedAssets.getItem('auflegerBgFrame'))
			aufleger = this.props.skinnedAssets.getItem('auflegerBgFrame');
		if (!aufleger) aufleger = this.props.globalAssets.getItem('auflegerBgFrame');

		if (this.props.skinnedAssets && this.props.skinnedAssets.getItem('durchgucker'))
			durchgucker = this.props.skinnedAssets.getItem('durchgucker');
		if (!durchgucker) durchgucker = this.props.globalAssets.getItem('durchguckerRahmenGreen');

		let style = {};
		for (let s in this.props.style) {
			style[s] = this.props.style[s];
		}
		style.display = this.state.display;

		return (
			<Wrapper variant={variant} teaserType={'round'} style={style} ref="base" key={'teaser' + this.props.id} className={classArray}>
				<Outer
					variant={variant}
					onClick={(evt) => {
						this.teaserClicked(this.props.data);
					}}
					teaserType={'round'}
					className={this.props.innerAnimation + ' ' + this.props.scope + '-teaserround-outer'}
				>
					{!this.props.data.iconTeaser ? (
						<WhiteBorder
							innerPadding={this.isTeaserCanvasAvailable() ? '0' : null}
							variant={variant}
							teaserType={'round'}
							className={this.props.scope + '-teaserround-border'}
						>
							{this.props.stoererId !== undefined ? this.getStoerer('round') : null}

							<MaskImage variant={variant} teaserType={'round'}>
								{this.props.isHome ? <TeaserFrame src={durchgucker.src} /> : null}
								<ImageWrapper variant={variant} teaserType={this.isTeaserCanvasAvailable() == false ? 'round' : null}>
									{this.getImage()}
								</ImageWrapper>
								{!this.props.isHome && aufleger ? <TeaserBg src={aufleger.src} /> : null}
							</MaskImage>
						</WhiteBorder>
					) : null}

					{!this.props.isHome && !this.props.isBroadcastStart ? (
						<TeaserIcon
							variant={this.props.data.iconTeaser ? 'icon' : 'standard'}
							parentType={this.getCategory() === 'index' ? 'index' : 'round'}
							style={this.props.data.iconTeaser ? { height: '100%', width: '100%' } : null}
						>
							<Icon size={'100%'} code={this.getIconName(this.getCategory())} />
						</TeaserIcon>
					) : null}

					{this.getCategory() !== 'index' ? <TeaserBg src={this.props.bgUrl} /> : null}
				</Outer>
			</Wrapper>
		);
	};

	getTeaser = () => {
		let classes = [
			'landscape',
			this.props.scaleClass,
			this.props.addClasses,
			this.props.data.standard.sophoraId,
			this.getCategory(),
			this.props.scope + '-teaser'
		];
		let visited =
			this.props.appStore.visitedTeaser && this.props.appStore.visitedTeaser[this.props.data.standard.sophoraId] !== undefined;
		let variant = visited ? 'visited' : 'landscape';
		return (
			<Wrapper
				variant={variant}
				teaserType={'landscape'}
				marginBottom={this.props.marginBottom}
				style={this.props.style}
				ref={(ref) => (this.reference = ref)}
				key={'teaser' + this.props.id}
				onClick={(evt) => {
					this.teaserClicked(this.props.data);
				}}
				className={classes}
			>
				<Outer
					variant={variant}
					teaserType={'landscape'}
					className={this.props.innerAnimation + ' ' + this.props.scope + '-teaser169-outer'}
				>
					{this.props.stoererId !== undefined ? this.getStoerer('standard') : null}

					<TeaserBg src={this.props.bgUrl} />
					<WhiteBorder
						innerPadding={this.isTeaserCanvasAvailable() ? '0' : null}
						variant={variant}
						teaserType={'landscape'}
						className={this.props.scope + '-teaser169-border'}
					>
						<TeaserBg
							variant={variant}
							src={(this.props.skinnedAssets || this.props.globalAssets).getItem('teaserBgFrame').src}
						/>
						<ImageWrapper variant={variant} teaserType={'landscape'}>
							{this.getImage()}
						</ImageWrapper>
					</WhiteBorder>
					<TeaserIcon style={{ height: '6.25em', width: '6.25em' }}>
						<Icon size={'100%'} code={this.getIconName(this.getCategory())} />
					</TeaserIcon>
				</Outer>
			</Wrapper>
		);
	};
	getClickOn = () => {
		let variant = this.props.isHome ? 'home' : 'standard';
		let classArray = ['clickOn', this.props.addClasses, this.props.data.standard.sophoraId, this.getCategory(), variant];
		return (
			<Wrapper style={this.props.style} ref="base" key={'teaser' + this.props.id} className={classArray}>
				{this.props.isBundle ? <TeaserBg src={this.props.bgUrl} /> : null}
				<ClickOn
					type={this.props.type}
					stageRef={'canvas_' + this.props.data.standard.canvasId}
					width={422}
					height={216}
					pos="canvasTeaser"
					playAniFrame={this.state.animationFrame}
					scope={window[this.props.data.standard.canvasId]}
					assets={this.props.indexAssets}
					func={this.props.data.standard.canvasId}
				/>
			</Wrapper>
		);
	};
	isTeaserCanvasAvailable = () => {
		var teaserCanvasId = this.props.data.standard.teaserCanvasId;
		return teaserCanvasId && teaserCanvasId.length > 0 && window[teaserCanvasId] ? true : false;
	};
	getImage = () => {
		var teaserCanvasId = this.props.data.standard.teaserCanvasId;
		this.isTeaserCanvasAvailable();

		if (this.isTeaserCanvasAvailable() === true) {
			return (
				<ClickOn
					stageRef={'canvas_' + this.props.data.standard.sophoraId}
					width={422}
					height={422}
					type={this.props.type}
					pos="canvasTeaser"
					scope={window[teaserCanvasId]}
					playAniFrame={this.state.animationFrame}
					assets={this.props.indexAssets}
					func={teaserCanvasId}
				/>
			);
		} else {
			if (this.props.type === 'round') {
				let url =
					this.props.data.standard.teaserImage && this.props.data.standard.teaserImage.url
						? this.props.data.standard.teaserImage.url
						: getImageUrl(this.props.imageUrl, '512', 'ident');
				return <TeaserImage src={url} />;
			}
			return <TeaserImage src={getImageUrl(this.props.imageUrl)} />;
		}
	};

	render() {
		this.hasCanvas =
			this.props.data && this.props.data.standard && this.props.data.standard.canvasId && this.props.data.standard.canvasId.length > 0
				? true
				: false;

		if (this.props.type === 'round' && this.hasCanvas === false) {
			return this.getRoundTeaser();
		}
		if (this.props.type !== 'round' && this.hasCanvas === false) {
			return this.getTeaser();
		}
		if (this.hasCanvas === true) {
			return this.getClickOn();
		}
		return null;
	}
}

export default Teaser;
