import styled from 'styled-components';
import { gfx, blueRabbitHand } from '../../../helpers/layoutVariables';
import BasicIcon from '../../basics/Icon/Icon';

export const Wrapper = styled.div`
    .swiper-wrapper {
        align-items: stretch;
        min-height: 29.5em;
    }
    .swiper-slide {
        height: auto!important;
    }

	.view-small & {
		.swiper-container {
			padding-bottom: 0;
		}
		.swiper-scrollbar {
			display: none;
		}
	}
`;

export const Item = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
	margin: 0.35em 0.83333em;
	padding: 1.666666em;
	background-color: rgba(255, 255, 255, 0.25);
	border-radius: 0.75em;
	${blueRabbitHand};

	.view-small & {
		border-radius: 1.5em;
	}
`;

export const SummaryTitle = styled.div`
    padding-right: 1em;
	color: #fff;
	font-size: 3.584em;

	.view-small & {
		font-size: 5.363em;
    }
`;

export const ItemText = styled.div`
    max-width: 33.77em;

    .view-small & {
        max-width: 31.998em;
	}

    .hasIcon:not(.roundImage) & {
        padding-left: 3.5em;
    }

	.view-small .hasIcon:not(.roundImage) & {
		padding-top: 0.5em;
		padding-left: 0;
	}

    .roundImage & {
		max-width: 21em;
        text-align: center;
        padding-left: 0;

		.view-small & {
			max-width: 24em;
		}
	}
`;

export const Topline = styled.div`
    margin-top: 0.5em;
	color: ${gfx.brown};
	font-size: 1.168em;

	.view-small & {
		font-size: 1.748em;
    }
`;

export const Title = styled.div`
    // overflow: hidden; // problem with fonts cut off on top and bottom (gÜ..)
    // max-height: 2.88em; // max 3 lines
    margin-top: 0.75em;
	color: ${gfx.brown};
	font-size: 1.666666em;

    // experimental
    -webkit-line-clamp: 3;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.3;

	.view-small & {
        // max-height: 2.95em; // max 3 lines
		font-size: 2.494em;
        margin-top: 0.5em;
    }
`;

export const ImageContainer = styled.div`
	position: relative;
	display: inline-flex;
	flex: 0 0 auto;

	// standard - 16:9 rectangle
	// margin: 1.25em 0;

	.roundImage & {
		// Bundles - circle single border
		margin: 0 auto;
		border: 1.25em solid transparent;
	}
	.contentTypeStart & {
		// Freunde - circle double border
		margin: 0 auto;
		border: 1.25em solid ${gfx.yellow};
	}

	.roundImage & {
		border-radius: 50%;

		&::before {
			box-shadow: inset 0 0.25em 1.5em 0 #5f4100c0;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			content: '';
			border-radius: 50%;
			border: 0.75em solid transparent;
    		box-sizing: border-box;
			z-index: 1;
		}
	}
`;

export const Image = styled.img`
	height: 19em;

	.view-small & {
		height: 18em;
	}

	.roundImage & {
		border-radius: 50%;
		border: 0.75em solid #fff6d1;
		width: 19em;

		.view-small & {
			width: 18em;
		}
	}
	.contentTypeStart & {
		box-shadow: 0 0 0.8em -0.3em #000000;
	}
`;

export const Icon = styled(BasicIcon)`
	position: absolute;
	width: 4.66em;
	height: 4.66em;
    top: auto;
    right: auto;
	bottom: -1.0em;
    left: -1.0em;
	padding: 0.5em;
	background-color: ${gfx.vanille};
	border-radius: 50%;

	.view-small & {
		width: 3.5em;
		height: 3.5em;
		bottom: -0.5em;
    	left: -0.5em;
		padding: 0.25em;
	}

    .roundImage & {
        bottom: 0.5em;
        left: 0.5em;
    }
`;
