import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	position: absolute;
	z-index: 1;
	top: 0;
	height: 100%;
	left: 50%;
	transform: translate3d(-50%, 0, 0);
	width: 70em;

	.swiper-slide > div {
		padding: 0.5em;
	}
`;
