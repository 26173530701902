import styled, { css } from 'styled-components';
import Icon from 'components/basics/Icon/Icon';
import { Box } from 'components/basics/Box';
import { blueRabbitHand, gfx } from 'helpers/layoutVariables';

export const AdultButtonWrapper = styled.button`
	text-transform: uppercase;
	border: 0;
	background-color: transparent;
	color: #5f4100;
	display: flex;
	position: relative;
	padding: 0;
	margin: ${(props) => (props.margin ? props.margin : null)};
	font-size: 1em;
	align-items: center;
	justify-content: center;
	${blueRabbitHand};
	${(props) =>
		props.pos === 'right' &&
		css`
			transform: rotate(-90deg);
		`};
`;
export const AdultTitleText = styled.span`
	top: 0.1em;
	position: relative;
`;
export const AdultTitle = styled.span`
	font-size: ${(props) => (props.size ? props.size : '16px')};
	display: flex;
	margin-right: 0;
	/* align-self: flex-end; */
	line-height: 1;
	position: relative;
	padding: 0 0.5em 0 0.2em;
	align-items: center;
	${(props) => props.pos === 'top' && css``};
`;
export const AdultTitleIconWrapper = styled.div`
	position: relative;
	background-color: #fff;
	width: 1.75em;
	height: 1.75em;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Search = styled(Box)`
	${blueRabbitHand};
`;

export const Timer = styled(Icon)`
	${(props) =>
		props.pos === 'right' &&
		css`
			transform: rotate(270deg);
		`};

	${blueRabbitHand};
`;

export const TimerWrapper = styled.div`
	position: relative;
	margin-left: ${(props) => (props.marginLeft ? props.marginLeft : null)};
`;
export const IconBackground = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: ${(props) => (props.size ? props.size : 0)};
	height: ${(props) => (props.sizeHeight ? props.sizeHeight : 0)};
	background-color: ${gfx.blue};
	border-radius: ${(props) => (props.borderRadius ? props.borderRadius : 0)};
`;
export const TimerHintWrapper = styled.div`
	position: relative;
	transform: translate3d(24px, 70px, 0);
	z-index: 1;

	${(props) =>
		props.small &&
		css`
			transform: translate3d(17px, 60px, 0);
		`};

	font-size: 9px;
	${(props) =>
		props.pos === 'right' &&
		css`
			transform: rotate(-90deg) translate3d(-68px, 0, 0);
			${(props) =>
				props.small &&
				css`
					transform: rotate(-90deg) translate3d(-52px, 0, 0);
				`};
		`}
`;

export const AdultBar = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
	width: ${(props) => props.width};
	top: ${(props) => (props.width === '100%' ? '1em' : '0')};
	padding: ${(props) => (props.padding ? props.padding : null)};
`;

export const FullscreenStatus = styled.img`
	position: absolute;
	top: 4.25%;
	left: -10.5%;
	width: 4.5em;
	height: auto;
	z-index: -1;
	transform: scale(0) translate3d(95%, 0, 0);
	transition: transform 0.3s ease-out;

	pointer-events: none;

	${(props) =>
		props.open &&
		css`
			transform: scale(1) translate3d(0, 0, 0);
		`}
`;

export const BackgroundAdultBtn = styled.img`
	position: absolute;
	top: 0;
	left: -10em;
	width: 10em;
	height: auto;
	z-index: 0;
	pointer-events: none;
`;
