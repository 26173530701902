import React, { Component } from 'react';
import { getImageUrl, logger } from 'helpers/Utils';
import FormField from './FormField';
import { inject, observer } from 'mobx-react';
import { Container, ErrorMessage, FormButtonWrapper, FormWrapper, SubmitButton } from './FormStyle';

@inject('assetStore')
@inject('appStore')
@observer
class Form extends Component {
	state = {
		data: null,
		params: {},
		validate: 0,
		errorMessage: false,
		successMessage: false,
		buffer: false,
		fields: null
	};
	url = null;
	tempParams = {};
	errors = -1;
	isUnmounted = false;

	componentDidMount() {
		this.getFormData();
	}

	componentWillUnmount() {
		this.isUnmounted = true;
	}

	getFormData = async () => {
		this.url = this.props.teaser?.special?.linkUrl;
		//this.url = 'https://www.kika.de/p/gewinnspiele/teilnehmen/wW5fBALHLI6OoYJQIAvGTZtA9K4KU88T.json';
		//this.url = '/dummy/form.json';
		if (!this.url) return false;

		let response = await fetch(this.url + '?cb=' + new Date().getTime(), {
			method: 'get',
			dataType: 'json',
			credentials: 'include'
		}).catch(() => {
			logger('error loading data', this.url);
			this.setState({ error: true });
			return null;
		});
		if (response) {
			const data = await response.json();
			this.setState({ data: data });
		}
	};

	getField = data => {
		if (data.type === 'hidden') this.tempParams[data.name] = data.value;
		return <FormField key={data.name} onValidated={this.onValidated} forceValidate={this.state.validate} data={data} />;
	};
	validEmail = value => {
		return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i.test(
			value
		);
	};
	validNumber = value => {
		return /^-?[0123456789]+$/i.test(value);
	};
	validEmpty = value => {
		return value.length !== 0;
	};
	onValidated = (data, value) => {
		this.tempParams[data.name] = data.value;
		this.setState({ params: this.tempParams }, () => {});
		if (value === false) {
			this.errors = this.errors + 1;
		} else {
			if (this.errors > 0) this.errors = this.error - 1;
		}
	};

	handleSubmit = async event => {
		event.preventDefault();
		this.errors = 0;
		const errorText = 'Deine Teilnahme am Gewinnspiel hat nicht geklappt. Probiere es noch einmal!';
		this.setState({ validate: this.state.validate + 1 }, async () => {
			if (this.errors === 0) {
				this.setState({ buffer: true });
				let searchParams = Object.keys(this.tempParams)
					.map(key => {
						return encodeURIComponent(key) + '=' + encodeURIComponent(this.tempParams[key]);
					})
					.join('&');

				let response = await fetch(this.url + '?cb=' + new Date().getTime(), {
					method: 'post', // or 'PUT'
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded'
					},
					credentials: 'include',
					body: searchParams // data can be `string` or {object}!
				}).catch(error => {
					this.setState({
						buffer: false,
						errorMessage: errorText
					});
				});

				let status = await response.status;

				if (status === 204) {
					if (this.props.onSubmit) {
						this.props.onSubmit(this.tempParams);
						return false;
					}
					this.setState({
						buffer: false,
						successMessage: true
					});
				}

				if (status === 400 || status === 422 || status === 404) {
					this.setState({
						buffer: false,
						errorMessage: errorText
					});
				}

				const data = await response.json();
				if (data && data.message) {
					this.setState({ errorMessage: data.message, buffer: false });
					return false;
				}

				//this.props.clearAdultInfo('adultLayer');
			}
		});
	};

	render() {
		if (this.state.data === null) return null;
		let fields = [];
		this.state.data.fields.map((data, i) => {
			fields.push(this.getField(data));
		});
		const vars = [];

		if (this.state?.data?.fields)
			this.state.data.fields.map(dataEntry => {
				if (dataEntry.label) vars.push(`${dataEntry.label}: ${dataEntry.value}\n`);
			});

		const imgTitle = this.props.teaser.standard.teaserImage?.title;
		if (this.state.successMessage)
			return (
				<Container>
					<h4>Danke für deine Teilnahme!</h4>
					<p>
						{`Du nimmst am Gewinnspiel teil und hast folgende Daten an KiKA übermittelt:`}
						{vars.map(varsText => {
							return <span style={{ display: 'block' }}>{`${varsText}`}</span>;
						})}
					</p>
				</Container>
			);
		return (
			<Container>
				{this.props.teaser.standard.teaserImage && this.props.teaser.standard.contentCategory !== 'competition' && (
					<div>
						{imgTitle && <h4>{this.props.teaser.standard.teaserImage?.title}</h4>}
						<img
							src={getImageUrl(
								this.props.teaser.standard.teaserImage && this.props.teaser.standard.teaserImage.urlScheme,
								'512',
								this.props.teaser.standard.documentType !== 'link' ? 'original' : 'tlarge169'
							)}
						/>
					</div>
				)}
				<h4 style={{marginBottom:'.5em'}}>{this.props.teaser.standard.title}</h4>
				<FormWrapper onSubmit={this.handleSubmit}>
					{fields}
					<FormButtonWrapper>
						{this.state.buffer ? <div className="kkn-buffer" /> : null}
						{this.state.buffer === false ? <SubmitButton type="submit" value={this.state.buffer ? '' : 'Abschicken'} /> : null}
					</FormButtonWrapper>
					{this.state.errorMessage ? <ErrorMessage>{this.state.errorMessage}</ErrorMessage> : null}
				</FormWrapper>
			</Container>
		);
	}
}

export default Form;
