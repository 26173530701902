import styled, { css } from 'styled-components';
import { blueRabbitHand } from 'helpers/layoutVariables';

export const Wrapper = styled.a`
	z-index: 7;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 16em;
	height: 16em;
	position: absolute;
	left: 50%;
	bottom: -8em;
	${blueRabbitHand};
	margin-left: -8em;

	${(props) =>
		props.type === 'top' &&
		css`
			top: -8em;
			bottom: auto;
		`}
	${(props) =>
		props.type === 'left' &&
		css`
			left: 0;
			bottom: auto;
			top: 50%;
		`}
	${(props) =>
		props.type === 'right' &&
		css`
			right: -8em;
			bottom: auto;
			top: 50%;
			left: auto;
		`}
`;

export const InnerWrapper = styled.span`
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	bottom: 0;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	${blueRabbitHand};

	&:after {
		content: '';
		position: absolute;
		box-shadow: 0 -1em 1em rgba(0, 0, 0, 0.25);
		width: 100%;
		height: 100%;
		border-radius: 50%;
		left:0;
	}

	${(props) =>
		props.type === 'top' &&
		css`
			transform: rotate(180deg);
		`}
	${(props) =>
		props.type === 'left' &&
		css`
			align-items: flex-start;
			transform: rotate(90deg);
		`}
	${(props) =>
		props.type === 'right' &&
		css`
			align-items: flex-start;
			transform: rotate(-90deg);
		`}
`;

export const Background = styled.img`
	width: 100%;
	z-index: 1;
	top: 0;
	left: 0;
	position: absolute;
	width: 100%;
	height: 100%;
`;
export const Icon = styled.img`
	width: 50%;
	z-index: 1;
	position: relative;
	top: 0;
`;
