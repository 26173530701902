import styled from 'styled-components';
import { gfx, blueRabbitHand } from '../../../helpers/layoutVariables';

export const CenterWrapper = styled.div`
	display: flex;
    justify-content: center;
`;

export const Wrapper = styled.div`
    position: relative;
	display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1em 1.5em;
    padding: 0 2em;
    margin: 0 0 2em;

    .view-small & {
        flex-wrap: nowrap;
        justify-content: normal;
        overflow-x: auto;
        margin: 0 0 1em;
    }
`;

export const Intro = styled.div`
    padding: 0.5em 0;
    color: ${gfx.brown};
    font-size: 1.5em;
    line-height: 2;
    flex: 0 0 auto;

    .view-small & {
        font-size: 2.269em;
    }
`;

export const Item = styled.div`
	display: flex;
    align-items: center;
    ${blueRabbitHand};
    min-width: auto;
    gap: 1em;
    padding: 0 0.75em;
    border-radius: 0.75em;
    background-color: ${gfx.yellow};
    flex: 0 0 auto;

    .view-small & {
    }
`;

export const Title = styled.div`
    color: ${gfx.brown};
    font-size: 1.5em;
    line-height: 2;

    .view-small & {
        font-size: 2.269em;
    }
`;

