import React, { Component } from 'react';
import {
	MoveInFromBottom,
	MoveInFromLeft,
	MoveInFromRight,
	MoveInFromTop,
	MoveOutBottom,
	MoveXAnimator,
	MoveYAnimator,
	MoveYTopAnimator,
	ScaleYAnimator,
	ScaleXAnimator,
	OpacityAnimator
} from '../Primitives';
import { CSSTransition } from 'react-transition-group';

export default class Animator extends Component {
	getAnimationType = (type) => {
		switch (type) {
			default:
				return <OpacityAnimator>{this.props.children}</OpacityAnimator>;
				break;
			case 'moveX':
				return <MoveXAnimator {...this.props}>{this.props.children}</MoveXAnimator>;
				break;
			case 'scaleY':
				return <ScaleYAnimator {...this.props}>{this.props.children}</ScaleYAnimator>;
				break;
			case 'scaleX':
				return <ScaleXAnimator {...this.props}>{this.props.children}</ScaleXAnimator>;
				break;
			case 'moveY':
				return <MoveYAnimator {...this.props}>{this.props.children}</MoveYAnimator>;
				break;
			case 'moveInFromTop':
				return <MoveInFromTop {...this.props}>{this.props.children}</MoveInFromTop>;
				break;
			case 'moveInFromBottom':
				return <MoveInFromBottom {...this.props}>{this.props.children}</MoveInFromBottom>;
				break;
			case 'moveInFromLeft':
				return <MoveInFromLeft {...this.props}>{this.props.children}</MoveInFromLeft>;
				break;
			case 'moveInFromRight':
				return <MoveInFromRight {...this.props}>{this.props.children}</MoveInFromRight>;
				break;
			case 'moveYTop':
				return <MoveYTopAnimator {...this.props}>{this.props.children}</MoveYTopAnimator>;
				break;
			case 'moveOutBottom':
				return <MoveOutBottom {...this.props}>{this.props.children}</MoveOutBottom>;
				break;
		}
	};
	render = () => {
		return (
			<CSSTransition
				unmountOnExit={this.props.unmountOnExit !== undefined ? this.props.unmountOnExit : true}
				key={this.props.id}
				appear={true}
				in={this.props.active !== undefined ? this.props.active : true}
				timeout={this.props.duration || 0}
				classNames="animation"
			>
				{this.getAnimationType(this.props.type)}
			</CSSTransition>
		);
	};
}
