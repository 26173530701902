import React, { Component } from 'react';
import ClickOn from 'components/fragments/ClickOn/ClickOn';
import { inject, observer } from 'mobx-react';
import { Logo, Wrapper } from './LockScreenStyle';

@inject('assetStore')
@inject('appStore')
@observer
class Lockscreen extends Component {
	state = {
		ended: false
	};
	isUnmounted = false;
	played = false;

	componentDidMount() {
		window.$(this.videoplayer).on('ended', (evt, params) => {
			this.setState({ ended: true });
		});
	}

	componentWillUnmount() {
		this.isUnmounted = true;
		window.$(this.videoplayer).off('ended', (evt, params) => {
			this.setState({ ended: true });
		});
	}

	componentWillReact() {
		if (this.props.appStore.adultTimerEnded === true && this.played === false) {
			this.videoplayer.src = 'https://www.kikaninchen.de/static_kkn/global/mp4/stirnkuss.mp4';
			this.videoplayer.load();
			this.videoplayer.play();
			this.played = true;
			this.setState({ ended: false });
		} else {
			this.played = false;
		}
	}

	render() {
		let globalAssets = this.props.assetStore.globalAssets;

		let open = '';
		if (this.props.appStore.adultTimerEnded === true) {
			open = 'open';
			//window.kknApp.playSound("alarm");
		}

		return (
			<Wrapper className={open}>
				{this.state.ended === true ? <Logo src="/static_kkn/global/images/logo-kikaninchen.svg" /> : null}
				{this.state.ended === true ? (
					<p>
						Die vereinbarte Nutzungsdauer für kikaninchen.de ist abgelaufen. Im Erwachsenenbereich können Sie die Zeit
						verlängern.
					</p>
				) : null}

				{this.state.ended === true ? (
					<ClickOn
						stageRef="HELP_LOCK"
						width={window['globalKkn'].properties.width * 2}
						height={window['globalKkn'].properties.height * 2}
						pos={'helpLock'}
						scope={window['globalKkn']}
						assets={globalAssets}
						func={'globalKkn'}
					/>
				) : null}
				<video ref={(ref) => (this.videoplayer = ref)} playsInline className={this.state.ended ? 'inv' : ''} preload="none" />
			</Wrapper>
		);
	}
}

export default Lockscreen;
